import React, { useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import Select from 'react-select';
import clsx from 'clsx';
import Swal from 'sweetalert2';
import useOptionsTipoCliente from '../../selectOptions/useOptionsTipoClientes';
import useOptionsFuncionariosClientes from '../../selectOptions/useOptionsFuncionariosClientes';
import { useFormik } from 'formik';
import { useAuth } from '../../../modules/auth';
import { useNavigate } from 'react-router-dom';
import { sendFornecedor } from '../cadastro_requests/cadastro_requests';
import useOptionsSegmentos from '../../selectOptions/useOptionsSegmentos';
import useOptionsOrigemPrevenda from '../../selectOptions/useOptionsOrigemPrevenda';
import useOptionsTabelaDePreco from '../../selectOptions/useOptionsTabelaDePreco';
import useBuscaCep from '../../useUtils/useBuscaCep';
import useBuscaCnpj from '../../useUtils/useBuscaCnpj';
import './styles/fornecedores.css';
import { Table, TabContainer } from 'react-bootstrap';
import { TablePessoasContato } from './components/ListagemPessoasContato';
import moment from 'moment';
import PageTitulo from '../../components/Pagetitulo';

const cadastroValidationSchema = Yup.object().shape({
  tipocliente: Yup.number(),

  nome: Yup.string()
    .min(4, 'O nome da empresa deve conter pelo menos 4 caracteres')
    .required('Por favor, informe um nome ou razão social.'),
});

interface TipoFornecedores {
  value: number;
  label: string;
}

interface Vendedores {
  value: number;
  label: string;
}

interface InfosPessoaContato {
  nome: string;
  setor: string;
  email: string;
  telefone: string;
  ramal: string;
}

const initialValues = {
  tipocliente: '1',
  nome: '',
  razaosocial: '',
  fantasia: '',
  cpf: '',
  cnpj: '',
  codigo: '',
  endereco: '',
  numero: '',
  bairro: '',
  pontoreferencia: '',
  complemento: '',
  cidade: '',
  cidadeid: '',
  estado: '',
  estadoid: '',
  pais: '',
  paisid: '',
  cep: '',
  celular: '',
  telefone: '',
  email: '',
  emailComercial: '',
  status: 1,
  contacorrente: 0,
  indicadorie: 0,
  inscricaoestadual: '',
  inscricaosuframa: '',
  inscricaomunicipal: '',
  vendedorid: 0,
  nascimento: '',
  contato: '',
  segmentoid: 0,
  profissaoid: 0,
  responsavel: '',
  responsavelid: '',
  creationdatecliente: '',
  tabelaprecos1: 0,
  regiaotributaria: 0,
  piscofins: 0,
  desconto: '',
  descontoboleto: '',
  obsnotafiscal: '',
  obscliente: '',
  podecomprar: 3,
  podeprotestar: 3,
  podereter: 3,
  website: '',
  usercreation: '',
  emailnfe: '',
  obsContato: '',
  estadoCivil: 0,
  statuscrm: '',
  vendedorPadrao: '',
  vendedorpadraoid: '',
  condicaoPagamentoPadrao: '',
  codregimetributario: 0,
  limiteCredito: '',
  profissao: '',
  sexoCliente: '',
  naturalidade: '',
  nomePai: '',
  cpfPai: '',
  nomeMae: '',
  cpfMae: '',
  documentoidentificacao: '',
  rg: '',
  contribuinteIcms: '',
  telefoneadicional: '',
  tipoorigemid: 0,
};

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#007bff' : state.isFocused ? '#cce5ff' : '#fff',
    color: state.isSelected ? '#fff' : '#000',
  }),
};

export function CadastroFornecedor() {
  const [infosPessoaContato, setInfosPessoaContato] = useState<InfosPessoaContato[]>([]);

  const [showInputRow, setShowInputRow] = useState(false);
  const [newPessoa, setNewPessoa] = useState<InfosPessoaContato>({
    nome: '',
    setor: '',
    email: '',
    telefone: '',
    ramal: '',
  });

  const { optionsOrigemPrevenda } = useOptionsOrigemPrevenda();

  const { optionsFuncionariosClientes } = useOptionsFuncionariosClientes();
  const [selectedVendedor, setSelectedVendedor] = useState<Vendedores[]>([]);

  const [activeSection, setActiveSection] = useState('general');

  const [exibirInfoFiscais, setExibirInfoFiscais] = useState(true);
  const [iconeFiscal, setIconeFiscal] = useState('bi bi-arrow-down-square-fill');

  const { optionsTipoCliente } = useOptionsTipoCliente();
  const [selectedTipoFornecedores, setSelectedTipoFornecedores] = useState<TipoFornecedores[]>([]);

  const navigate = useNavigate();
  const [isPF, setIsPF] = useState(true);
  const [isEstrangeiro, setIsEstrangeiro] = useState(false);
  const [isEstrangeiroNoBrasil, setIsEstrangeiroNoBrasil] = useState(false);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const { optionsSegmentos } = useOptionsSegmentos();
  const { optionsTabelaDePreco } = useOptionsTabelaDePreco();

  const [historicoCompras, setHistoricoCompras] = useState([]);

  const formik = useFormik({
    initialValues,
    validationSchema: cadastroValidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {

      setLoading(true);

      if (values.tipocliente === '3') {
        Swal.fire({
          icon: 'info',
          title: 'Por favor selecione o tipo de fornecedor para continuar o cadastro',
          confirmButtonText: 'Ok',
        });
        setLoading(false);
        setSubmitting(false);
        return;
      }

      if (values.tipocliente === '0' && values.cnpj === null && !values.cnpj) {
        Swal.fire({
          icon: 'info',
          title: 'Por favor, informe o CNPJ do fornecedor.',
          confirmButtonText: 'Ok',
        });
        setLoading(false);
        setSubmitting(false);
        return;
      }

      const cnpjFormatado = values.cnpj.replace(/[^\d]/g, '');

      if (values.cnpj !== null && values.cnpj !== '' && cnpjFormatado.length !== 14) {
        Swal.fire({
          icon: 'info',
          title: 'Por favor, informe o CNPJ completo.',
          confirmButtonText: 'Ok',
        });
        setLoading(false);
        setSubmitting(false);
        return;
      }

      if (values.telefone === '' && values.celular === '') {
        Swal.fire({
          icon: 'info',
          title:
            'Por favor, informe o telefone e/ou celular do fornecedor antes de concluir o cadastro!',
          confirmButtonText: 'Ok',
        });
        setLoading(false);
        setSubmitting(false);
        return;
      }

      try {
        const usuariologado = currentUser?.login;

        const tipoPessoa = selectedTipoFornecedores.map((tipo) => tipo.value);
        const vendedorpadraoid = selectedVendedor.map((tipo) => tipo.value);

        const pessoasContato = infosPessoaContato;

        const response = await sendFornecedor(
          values.tipocliente,
          values.nome,
          values.razaosocial,
          values.fantasia,
          values.cpf,
          values.cnpj,
          values.codigo,
          values.endereco,
          values.numero,
          values.bairro,
          values.pontoreferencia,
          values.complemento,
          values.cidade,
          values.cidadeid,
          values.estado,
          values.estadoid,
          values.pais,
          values.paisid,
          values.cep,
          values.celular,
          values.telefone,
          values.email,
          values.emailComercial,
          values.status,
          values.contacorrente,
          values.indicadorie,
          values.inscricaoestadual,
          values.inscricaosuframa,
          values.inscricaomunicipal,
          values.vendedorid,
          values.nascimento,
          values.contato,
          values.segmentoid,
          values.profissaoid,
          values.responsavel,
          values.responsavelid,
          values.creationdatecliente,
          values.tabelaprecos1,
          values.regiaotributaria,
          values.piscofins,
          values.desconto,
          values.descontoboleto,
          values.obsnotafiscal,
          values.obscliente,
          values.podecomprar,
          values.podeprotestar,
          values.podereter,
          values.website,
          usuariologado,
          values.emailnfe,
          values.obsContato,
          Number(values.estadoCivil),
          Number(values.statuscrm),
          values.vendedorPadrao,
          values.condicaoPagamentoPadrao,
          values.codregimetributario,
          Number(values.limiteCredito),
          values.profissao,
          Number(values.sexoCliente),
          values.naturalidade,
          values.nomePai,
          values.cpfPai,
          values.nomeMae,
          values.cpfMae,
          values.documentoidentificacao,
          values.rg,
          Number(values.contribuinteIcms),
          values.telefoneadicional,
          tipoPessoa.toString(),
          Number(values.tipoorigemid),
          pessoasContato,
          vendedorpadraoid.toString()
        );

        const responseData = response.data;

        if (response.status === 201) {
          setSubmitting(false);
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Fornecedor cadastrado com sucesso!',
            showCancelButton: false,
            confirmButtonText: 'Voltar a listagem de fornecedores',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => {
              navigate('/listagem-fornecedor');
            },
          });
        } else if (responseData) {
          if (responseData.status === 409) {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao salvar o fornecedor, verifique as informações preenchidas e tente novamente',
              confirmButtonText: 'Ok',
            });
            setStatus('Ocorreu um erro ao salvar o fornecedor. Por favor, tente novamente.');
          }
        }
      } catch (error: any) {
        console.error(error);
        const errorMessage = error.response.data.message;
        if (error.response && error.response.data && error.response.data.message === '401') {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            setSubmitting(false); // Resetar ou atualizar o estado conforme necessário
            setLoading(false);
            window.open('/auth', '_blank');
          });
        } else if (error.response && error.response.status === 409) {
          const [titlePart, detailsPart] = errorMessage.split('*Detalhes:*');
          Swal.fire({
            icon: 'info',
            title: titlePart.trim(), // Exibe "CPF informado já em uso."
            text: `Detalhes:\n${detailsPart.trim()}`, // Exibe o restante da mensagem com quebra de linha
            confirmButtonText: 'Ok',
          });
          setSubmitting(false);
          setLoading(false);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao salvar o fornecedor',
            text: 'Por favor, verifique sua conexão de internet e tente novamente.',
            confirmButtonText: 'Ok',
          });
          setSubmitting(false);
          setLoading(false);
        }
        setStatus('Por favor, preencha as informações de usuário corretamente');
      }
    },
  });

  useBuscaCnpj(formik.values.cnpj, formik);
  useBuscaCep(formik.values.cep, formik);

  const handleTiposClientes = (selectedTipoCliente) => {
    setSelectedTipoFornecedores(selectedTipoCliente);
  };

  const handleVendedores = (selectedVendedor) => {
    setSelectedVendedor(selectedVendedor);
  };

  /* MANIPULADOR DE PESSOA DE CONTATO */
  const handleRemovePessoa = (index: number) => {
    setInfosPessoaContato((prevState) => prevState.filter((_, i) => i !== index));
  };

  const handleEditPessoa = (index: number, pessoa: InfosPessoaContato) => {
    setInfosPessoaContato((prevState) => prevState.map((item, i) => (i === index ? pessoa : item)));
  };

  const handleAddPessoa = () => {
    setInfosPessoaContato((prevState) => [...prevState, { ...newPessoa, id: prevState.length + 1 }]);
    setNewPessoa({
      nome: '',
      setor: '',
      email: '',
      telefone: '',
      ramal: '',
    });
    setShowInputRow(false);
  };

  const handleCancel = () => {
    setShowInputRow(false);
    setNewPessoa({
      nome: '',
      setor: '',
      email: '',
      telefone: '',
      ramal: '',
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewPessoa({ ...newPessoa, [name]: value });
  };

  const handleShowInputRow = () => {
    setShowInputRow(true);
  };

  const handleSelectChange = (event, fieldName) => {
    formik.setFieldValue(fieldName, event.target.value);
  };

  const handleTipoClienteChange = (event) => {
    formik.setFieldValue('tipocliente', event.target.value);

    const valorselecionado = event.target.value;
    const nomeSelecionado = formik.getFieldProps('nome');

    if (valorselecionado === '0') {
      setIsPF(false);
      setIsEstrangeiroNoBrasil(false);
      setIsEstrangeiro(false);
      formik.setFieldValue('cpf', '');
    } else if (valorselecionado === '2') {
      setIsPF(false);
      setIsEstrangeiroNoBrasil(false);
      formik.setFieldValue('cpf', '');
      formik.setFieldValue('cnpj', '');
      setIsEstrangeiro(true);
      setInfosPessoaContato([]);
    } else if (valorselecionado === '4') {
      setIsPF(false);
      setIsEstrangeiro(false);
      formik.setFieldValue('cpf', '');
      formik.setFieldValue('cnpj', '');
      setIsEstrangeiroNoBrasil(true);
      setInfosPessoaContato([]);
    } else {
      setIsPF(true);
      formik.setFieldValue('cnpj', '');
      formik.setFieldValue('inscricaoestadual', '');
      if (nomeSelecionado != null) {
        formik.setFieldValue('nome', nomeSelecionado.value);
        formik.setFieldValue('razaosocial', nomeSelecionado.value);
        formik.setFieldValue('fantasia', nomeSelecionado.value);
      }
      setInfosPessoaContato([]);
    }
  };

  // const renderizarHistoricoCompras = (historicoCompras) => {
  //   return (
  //     <div className='text-center table-container table-responsive'>
  //       <table className=' table-hover tablehist table-rounded table-row-bordered'>
  //         <thead className='thead-dark'>
  //           <tr className='fw-bold fs-6 text-gray-800'>
  //             <th className='text-center' style={{ width: '5%' }}>
  //               Id Produto
  //             </th>
  //             <th className='text-center' style={{ width: '40%' }}>
  //               Produto
  //             </th>
  //             <th className='text-center' style={{ width: '5%' }}>
  //               Quant.
  //             </th>
  //             <th className='text-center' style={{ width: '5%' }}>
  //               Preço Venda
  //             </th>
  //             <th className='text-center' style={{ width: '10%' }}>
  //               Tipo Produto
  //             </th>
  //             <th className='text-center' style={{ width: '10%' }}>
  //               Prod. Filho
  //             </th>
  //             <th className='text-center' style={{ width: '10%' }}>
  //               Grupo
  //             </th>
  //             <th className='text-center' style={{ width: '5%', marginRight: '10px' }}>
  //               Data
  //             </th>
  //           </tr>
  //         </thead>
  //         <tbody>
  //           {historicoCompras.map((item, index) => (
  //             <tr key={index}>
  //               <td className='text-center'>{item.idProduto}</td>
  //               <td className='text-center' style={{ width: '50px', height: '50px' }}>
  //                 {item.produto}
  //               </td>
  //               <td className='text-center'>{item.produtoQuantidade}</td>
  //               <td className='text-center'>{`R$${parseFloat(item.precoVenda).toLocaleString(
  //                 'pt-BR',
  //                 { minimumFractionDigits: 2, maximumFractionDigits: 2 }
  //               )}`}</td>
  //               <td className='text-center'>{item.tipoProduto}</td>
  //               <td className='text-center'>{item.produtoFilho}</td>
  //               <td className='text-center'>{item.grupo}</td>
  //               <td className='text-center'>
  //                 {moment(item.dataFinalizacaoPrevenda).format('DD/MM/YYYY')}
  //               </td>
  //             </tr>
  //           ))}
  //         </tbody>
  //       </table>
  //     </div>
  //   );
  // };

  return (
    <form className='form-control-solid' onSubmit={formik.handleSubmit} noValidate>
      <div className='text-light-dark'>
        <PageTitulo id={null} tipoFormulario='Fornecedor' />
        <div style={{ marginBottom: '8px' }}>
          <div style={{ borderBottom: '2px solid #000' }}></div>
        </div>
        <div className='row mt-6'>
          <div className='col-sm-9'>
            <div className='btn-group custom-nav-tabs' role='group'>
              <button
                type='button'
                className={`btn btn-outline-secondary ${activeSection === 'general' ? 'active' : ''
                  }`}
                onClick={() => setActiveSection('general')}
              >
                Dados gerais
              </button>
              <button
                type='button'
                className={`btn btn-outline-secondary ${activeSection === 'dadosComplementares' ? 'active' : ''
                  }`}
                onClick={() => setActiveSection('dadosComplementares')}
                disabled={isPF || isEstrangeiroNoBrasil}
              >
                Dados Complementares
              </button>
              {/* <button
                type='button'
                className={`btn btn-outline-secondary ${activeSection === 'historico' ? 'active' : ''
                  }`}
                onClick={() => setActiveSection('historico')}
              >
                Histórico
              </button> */}
              <button
                type='button'
                className={`btn btn-outline-secondary ${activeSection === 'observacoes' ? 'active' : ''
                  }`}
                onClick={() => setActiveSection('observacoes')}
              >
                Observações
              </button>
            </div>
          </div>
        </div>
        {activeSection === 'general' && (
          <>
            <div className='mb-5 mt-8 row'>
              <div className='col-sm-6'>
                <label className='form-label'>
                  {isPF || isEstrangeiro || isEstrangeiroNoBrasil ? 'Nome *' : 'Razão Social *'}
                </label>
                <input
                  type='text'
                  placeholder={
                    isPF || isEstrangeiro || isEstrangeiroNoBrasil
                      ? 'Nome ou razão social do contato'
                      : 'Razão Social'
                  }
                  {...formik.getFieldProps('nome')}
                  onChange={(event) => {
                    const value = event.target.value.toUpperCase();
                    formik.setFieldValue('nome', value);
                    if (isPF) {
                      formik.setFieldValue('razaosocial', value);
                      formik.setFieldValue('fantasia', value);
                    }
                  }}
                  className={clsx(
                    'form-control bg-transparent',
                    { 'is-invalid': formik.touched.nome && formik.errors.nome },
                    {
                      'is-valid': formik.touched.nome && !formik.errors.nome,
                    }
                  )}
                />
                {formik.touched.nome && formik.errors.nome && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.nome}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className='col-sm-3' style={{ display: 'none' }}>
                <label className='form-label'>Razão Social</label>
                <input
                  type='text'
                  placeholder='Insira a razão social'
                  {...formik.getFieldProps('razaosocial')}
                  className='form-control'
                  onChange={(event) => {
                    const value = event.target.value.toUpperCase();
                    formik.setFieldValue('razaosocial', value);
                  }}
                />
              </div>
              <div className='col-sm-3' style={{ display: isPF ? 'none' : 'block' }}>
                <label className='form-label'>Fantasia</label>
                <input
                  type='text'
                  {...formik.getFieldProps('fantasia')}
                  className='form-control'
                  onChange={(event) => {
                    const value = event.target.value.toUpperCase();
                    formik.setFieldValue('fantasia', value);
                  }}
                />
              </div>
              {isPF ? (
                <div className='col-sm-3'>
                  <label className='form-label'>Segmento</label>
                  <select
                    className='form-select'
                    aria-label='Select example'
                    onChange={(event) => handleSelectChange(event, 'segmentoid')}
                    value={formik.values.segmentoid}
                  >
                    <option value=''>Selecione</option>
                    {optionsSegmentos.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div style={{ display: 'none' }}></div>
              )}
              <div className='col-sm-3'>
                <label className='form-label'>Código</label>
                <input
                  type='text'
                  {...formik.getFieldProps('codigo')}
                  className='form-control'
                  placeholder='Opcional'
                  onChange={(event) => {
                    const value = event.target.value.replace(/\D/g, ''); // Convertendo para upperCase
                    formik.setFieldValue('codigo', value);
                  }}
                />
              </div>
            </div>
            <div className='mb-5 row'>
              <div className='col-sm-3'>
                <label className='form-label'>Tipo de pessoa *</label>
                <select
                  className='form-select'
                  onChange={handleTipoClienteChange}
                  value={formik.values.tipocliente}
                  style={{ marginTop: '0px' }}
                >
                  <option value='3'> Selecione </option>
                  <option value='1'> PF - Pessoa Física </option>
                  <option value='0'> PJ - Pessoa Jurídica </option>
                  <option value='2'> Estrangeiro </option>
                  <option value='4'> Estrangeiro no Brasil </option>
                </select>
              </div>

              {isPF ? (
                <>
                  <div className='col-sm-3'>
                    <label className='form-label'>CPF</label>
                    <InputMask
                      mask='999.999.999-99' // Aplicando a máscara de CNPJ
                      {...formik.getFieldProps('cpf')}
                      className='form-control'
                    />
                    {formik.touched.cpf && formik.errors.cpf && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.cpf}</span>
                        </div>
                      </div>
                    )}
                    <span style={{ color: 'black' }}>
                      Garantia somente pode ser aplicada caso informado
                    </span>
                  </div>
                  <div className='col-sm-3' style={{ display: 'none' }}>
                    <label className='form-label'>RG</label>
                    <input
                      type='text'
                      {...formik.getFieldProps('rg')}
                      className='form-control'
                      maxLength={13}
                    />
                  </div>
                  <div className='col-sm-3' style={{ display: 'none' }}>
                    <label className='form-label'>Contribuinte</label>
                    <select
                      className='form-select'
                      onChange={(event) => handleSelectChange(event, 'contribuinteIcms')}
                      id=''
                      value={formik.values.contribuinteIcms}
                    >
                      <option value='0'> 0 - Não informado</option>
                      <option value='1'> 1 - Contribuinte ICMS</option>
                      <option value='2'>
                        {' '}
                        2 - Contribuinte isento de Inscrição no cadastro de Contribuintes do ICMS
                      </option>
                      <option value='9'>
                        {' '}
                        9 - Não Contribuinte, que pode ou não possuir Inscrição Estadual no Cadastro
                        de Contribuintes do ICMS
                      </option>
                    </select>
                  </div>
                  <div className='col-sm-3'>
                    <label htmlFor='' className='form-label'>
                      Tipo de Origem
                    </label>
                    <select
                      className='form-select'
                      onChange={(event) => handleSelectChange(event, 'tipoorigemid')}
                      name=''
                      id=''
                    >
                      <option value=''> Selecione </option>
                      {optionsOrigemPrevenda.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              ) : isEstrangeiro ? (
                <>
                  <div className='col-sm-3'>
                    <label className='form-label'>Documento Identificação</label>
                    <input
                      type='text'
                      {...formik.getFieldProps('documentoidentificacao')}
                      className='form-control'
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label className='form-label'>Pais</label>
                    <input
                      type='text'
                      {...formik.getFieldProps('paisEstrangeiro')}
                      className='form-control'
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label className='form-label'>Segmento</label>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      onChange={(event) => handleSelectChange(event, 'segmentoid')}
                      value={formik.values.segmentoid}
                    >
                      <option value=''>Selecione</option>
                      {optionsSegmentos.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col-sm-3 mt-5'>
                    <label htmlFor='' className='form-label'>
                      Tipo de Origem
                    </label>
                    <select
                      className='form-select'
                      onChange={(event) => handleSelectChange(event, 'tipoorigemid')}
                      name=''
                      id=''
                    >
                      <option value=''> Selecione </option>
                      {optionsOrigemPrevenda.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              ) : isEstrangeiroNoBrasil ? (
                <>
                  <div className='col-sm-3'>
                    <label className='form-label'>Documento Identificação</label>
                    <input
                      type='text'
                      {...formik.getFieldProps('documentoidentificacao')}
                      className='form-control'
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label className='form-label'>Segmento</label>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      onChange={(event) => handleSelectChange(event, 'segmentoid')}
                      value={formik.values.segmentoid}
                    >
                      <option value=''>Selecione</option>
                      {optionsSegmentos.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col-sm-3'>
                    <label htmlFor='' className='form-label'>
                      Tipo de Origem
                    </label>
                    <select
                      className='form-select'
                      onChange={(event) => handleSelectChange(event, 'tipoorigemid')}
                      name=''
                      id=''
                    >
                      <option value=''> Selecione </option>
                      {optionsOrigemPrevenda.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              ) : (
                <>
                  <div className='col-sm-3'>
                    <label className='form-label'>CNPJ *</label>
                    <InputMask
                      mask='99.999.999/9999-99' // Aplicando a máscara de CNPJ
                      {...formik.getFieldProps('cnpj')}
                      className={clsx(
                        'form-control bg-transparent',
                        { 'is-invalid': formik.touched.cnpj && formik.errors.cnpj },
                        { 'is-valid': formik.touched.cnpj && !formik.errors.cnpj }
                      )}
                    />
                    {formik.touched.cnpj && formik.errors.cnpj && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>
                          <span role='alert'>{formik.errors.cnpj}</span>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='col-sm-6'>
                    <label className='form-label'>Segmento</label>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      onChange={(event) => handleSelectChange(event, 'segmentoid')}
                      value={formik.values.segmentoid}
                    >
                      <option value=''>Selecione</option>
                      {optionsSegmentos.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className='col-sm-3 mt-5'>
                    <label className='form-label'>Inscr. Estad.</label>
                    <input
                      type='text'
                      placeholder=''
                      {...formik.getFieldProps('inscricaoestadual')}
                      className='form-control'
                      onChange={(event) => {
                        formik.setFieldValue(
                          'inscricaoestadual',
                          event.target.value.replace(/\D/g, '')
                        ); // Remove caracteres não numéricos
                      }}
                    />
                  </div>

                  <div className='col-sm-3 mt-5'>
                    <label htmlFor='' className='form-label'>
                      Tipo de Origem
                    </label>
                    <select
                      className='form-select'
                      onChange={(event) => handleSelectChange(event, 'tipoorigemid')}
                      name=''
                      id=''
                    >
                      <option value=''> Selecione </option>
                      {optionsOrigemPrevenda.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </>
              )}
            </div>
            <div className='mb-6 row'>
              <div className='col-sm-3' style={{ display: 'none' }}>
                <label className='form-label'>Contato</label>
                <input
                  type='text'
                  placeholder='Informe um nome para contato'
                  {...formik.getFieldProps('contato')}
                  className='form-control'
                />
              </div>
            </div>

            <div className='mb-6 row'>
              <div
                className=' mb-3 form-label-container'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <h4>Endereço</h4>
                <div
                  style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px' }}
                ></div>
              </div>
              {isEstrangeiro ? (
                <>
                  <div className=''>
                    <div className='row mb-5'>
                      <div className='col-sm-12'>
                        <label className='form-label'> Endereço </label>
                        <input
                          type='text'
                          {...formik.getFieldProps('endereco')}
                          className='form-control'
                        />
                      </div>
                    </div>
                    <div className='row mb-5'>
                      <div className='col-sm-4'>
                        <label className='form-label'>Bairro</label>
                        <input
                          type='text'
                          {...formik.getFieldProps('bairro')}
                          className='form-control'
                        />
                      </div>
                      <div className='col-sm-2'>
                        <label className='form-label'>Número</label>
                        <input
                          type='text'
                          {...formik.getFieldProps('numero')}
                          onChange={(event) => {
                            formik.setFieldValue('numero', event.target.value.replace(/\D/g, '')); // Remove caracteres não numéricos
                          }}
                          className='form-control'
                        />
                      </div>
                      <div className='col-sm-6'>
                        <label className='form-label'>Complemento</label>
                        <input
                          type='text'
                          {...formik.getFieldProps('complemento')}
                          onChange={(event) => {
                            const value = event.target.value;
                            formik.setFieldValue('complemento', value.toUpperCase());
                          }}
                          className='form-control'
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className=''>
                  <div className='row'>
                    <div className='col-sm-3 mb-5'>
                      <label className='form-label'> CEP</label>
                      <InputMask
                        mask='99.999-999'
                        {...formik.getFieldProps('cep')}
                        className='form-control'
                      />
                      {formik.touched.cep && formik.errors.cep && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>
                            <span role='alert'>{formik.errors.cep}</span>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className='col-sm-7'>
                      <label className='form-label'> Município </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('cidade')}
                        className='form-control'
                      />
                    </div>
                    <div className='col-sm-2'>
                      <label className='form-label'> UF </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('estado')}
                        className='form-control'
                      />
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-12'>
                      <label className='form-label'> Endereço </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('endereco')}
                        className='form-control'
                      />
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-4'>
                      <label className='form-label'>Bairro</label>
                      <input
                        type='text'
                        {...formik.getFieldProps('bairro')}
                        className='form-control'
                      />
                    </div>
                    <div className='col-sm-2'>
                      <label className='form-label'>Número</label>
                      <input
                        type='text'
                        {...formik.getFieldProps('numero')}
                        onChange={(event) => {
                          formik.setFieldValue('numero', event.target.value.replace(/\D/g, '')); // Remove caracteres não numéricos
                        }}
                        className='form-control'
                      />
                    </div>
                    <div className='col-sm-6'>
                      <label className='form-label'>Complemento</label>
                      <input
                        type='text'
                        {...formik.getFieldProps('complemento')}
                        onChange={(event) => {
                          const value = event.target.value;
                          formik.setFieldValue('complemento', value.toUpperCase());
                        }}
                        className='form-control'
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className='mb-6 row' style={{ display: 'none' }}>
              <div className='col-sm-3'>
                <label className='form-label'>Ponto de Referência</label>
                <input
                  type='text'
                  placeholder='Ponto de referencia'
                  {...formik.getFieldProps('pontoreferencia')}
                  onChange={(event) => {
                    const value = event.target.value;
                    formik.setFieldValue('pontoreferencia', value.toUpperCase());
                  }}
                  className='form-control'
                />
              </div>
              <div className='col-sm-3'>
                <label className='form-label'> País </label>
                <input
                  type='text'
                  placeholder='País'
                  {...formik.getFieldProps('pais')}
                  className='form-control'
                />
              </div>
            </div>

            <div className='mb-6 row'>
              <div
                className='mb-3 form-label-container'
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <h4>Contato</h4>
                <div
                  style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px' }}
                ></div>
              </div>
              <div className='row mb-5'>
                <div className='col-sm-4'>
                  <label className='form-label'>Telefone</label>
                  <InputMask
                    mask='(99)9999-9999'
                    placeholder='Insira o telefone'
                    {...formik.getFieldProps('telefone')}
                    className='form-control'
                  />
                </div>
                <div className='col-sm-4' style={{ display: isPF ? 'none' : '' }}>
                  <label className='form-label'>Telefone Adicional</label>
                  <InputMask
                    mask='(99)9999-9999'
                    {...formik.getFieldProps('telefoneadicional')}
                    className='form-control'
                  />
                </div>
                <div className='col-sm-4'>
                  <label className='form-label'>Celular</label>
                  <InputMask
                    mask='(99)99999-9999'
                    {...formik.getFieldProps('celular')}
                    className='form-control'
                  />
                </div>
              </div>
              <div className='row mb-5'>
                <div className='col-sm-4' style={{ display: isPF ? 'none' : '' }}>
                  <label className='form-label'>Website</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('website')}
                    className='form-control'
                  />
                </div>
                <div className='col-sm-4'>
                  <label className='form-label'>Email</label>
                  <input type='text' {...formik.getFieldProps('email')} className='form-control' />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.email}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className='col-sm-4'>
                  <label className='form-label'> Email para envio de NFE </label>
                  <input
                    type='text'
                    {...formik.getFieldProps('emailnfe')}
                    className='form-control'
                  />
                  {formik.touched.emailnfe && formik.errors.emailnfe && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.emailnfe}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12'>
                  <label className='form-label'>Observações do Contato</label>
                  <input
                    type='text'
                    {...formik.getFieldProps('obsContato')}
                    className='form-control'
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {activeSection === 'dadosComplementares' && (
          <>
            {isPF || isEstrangeiroNoBrasil ? (
              <>
                <div className='mt-5'>
                  <div className='row mb-5'>
                    <div className='col-sm-5'>
                      <label className='form-label'>Estado Civil</label>
                      <select
                        className='form-select'
                        aria-label='Select example'
                        onChange={(event) => handleSelectChange(event, 'estadoCivil')}
                        value={formik.values.estadoCivil}
                      >
                        <option value='0'>Selecione</option>
                        <option value='1'>Casado</option>
                        <option value='2'>Solteiro</option>
                        <option value='3'>Viúvo</option>
                        <option value='4'>Separado</option>
                        <option value='5'>Desquitado</option>
                      </select>
                    </div>
                    <div className='col-sm-3'>
                      <label className='form-label'>Profissão</label>
                      <input
                        type='text'
                        placeholder=''
                        {...formik.getFieldProps('profissao')}
                        className='form-control'
                        onChange={(event) => {
                          formik.setFieldValue('profissao', event.target.value); // Remove caracteres não numéricos
                        }}
                      />
                    </div>
                    <div className='col-sm-4'>
                      <label className='form-label'>Sexo</label>
                      <select
                        className='form-select'
                        aria-label='Select example'
                        onChange={(event) => handleSelectChange(event, 'sexoCliente')}
                        value={formik.values.sexoCliente}
                      >
                        <option value='0'>Selecione</option>
                        <option value='1'>Masculino</option>
                        <option value='2'>Feminino</option>
                        <option value='3'>Outros</option>
                      </select>
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-5'>
                      <label className='form-label'> Data de Nascimento </label>
                      <input
                        type='date'
                        placeholder=''
                        {...formik.getFieldProps('nascimento')}
                        className='form-control'
                      />
                    </div>
                    <div className='col-sm-3'>
                      <label className='form-label'>Naturalidade</label>
                      <input
                        type='text'
                        placeholder=''
                        {...formik.getFieldProps('naturalidade')}
                        className='form-control'
                        onChange={(event) => {
                          formik.setFieldValue('naturalidade', event.target.value); // Remove caracteres não numéricos
                        }}
                      />
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-5'>
                      <label className='form-label'>Nome do Pai</label>
                      <input
                        type='text'
                        placeholder=''
                        {...formik.getFieldProps('nomePai')}
                        className='form-control'
                        onChange={(event) => {
                          formik.setFieldValue('nomePai', event.target.value); // Remove caracteres não numéricos
                        }}
                      />
                    </div>
                    <div className='col-sm-3'>
                      <label className='form-label'>CPF do Pai</label>
                      <InputMask
                        mask='999.999.999-99' // Aplicando a máscara de CNPJ
                        {...formik.getFieldProps('cpfPai')}
                        className='form-control'
                      />
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-5'>
                      <label className='form-label'>Nome da Mãe</label>
                      <input
                        type='text'
                        placeholder=''
                        {...formik.getFieldProps('nomeMae')}
                        className='form-control'
                        onChange={(event) => {
                          formik.setFieldValue('nomeMae', event.target.value); // Remove caracteres não numéricos
                        }}
                      />
                    </div>
                    <div className='col-sm-3'>
                      <label className='form-label'>CPF da Mãe</label>
                      <InputMask
                        mask='999.999.999-99' // Aplicando a máscara de CNPJ
                        {...formik.getFieldProps('cpfMae')}
                        className='form-control'
                      />
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-5'>
                      <label className='form-label'>Status</label>
                      <select
                        className='form-select'
                        aria-label='Select example'
                        onChange={(event) => handleSelectChange(event, 'status')}
                        value={formik.values.status}
                      >
                        <option value='1'>Ativo</option>
                        <option value='0'>Inativo</option>
                      </select>
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-5' style={{ display: 'none' }}>
                      <label className='form-label'> Vendedor </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('vendedorPadrao')}
                        className='form-control'
                      />
                      <span style={{ color: 'black', marginTop: '10px' }}>
                        Vendedor Padrão para este fornecedor
                      </span>
                    </div>
                    <div className='col-sm-5'>
                      <label htmlFor='' className='form-label'>
                        Selecione os Vendedores para esse fornecedor
                      </label>
                      <Select
                        className='react-select-styled react-select-solid'
                        classNamePrefix='react-select'
                        options={optionsFuncionariosClientes}
                        value={selectedVendedor}
                        placeholder='Selecione uma opção'
                        styles={customStyles}
                        isMulti
                        onChange={handleVendedores}
                      />
                    </div>
                    <span style={{ color: 'black', marginTop: '10px' }}>
                      Vendedor Padrão para este fornecedor
                    </span>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-2'>
                      <label className='form-label'>Data de criação</label>
                      <input
                        type='Date'
                        placeholder=''
                        {...formik.getFieldProps('creationdatecliente')}
                        className='form-control'
                        readOnly
                        disabled
                      />
                    </div>
                  </div>

                  {/* <div className='row mb-5'>
                    <div
                      className='mt-8 mb-3 form-label-container'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <h4>Lista de Preço</h4>
                      <div
                        style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px' }}
                      ></div>
                    </div>
                    <div className='col-sm-2'>
                      <select
                        className='form-select'
                        aria-label='Select example'
                      //onChange={(event) => handleSelectChange(event, 'statuscrm')}
                      >
                        <option value=''>Selecione</option>
                      </select>
                    </div>
                  </div> */}

                  <div className='row mb-5'>
                    <div
                      className='mt-8 mb-3 form-label-container'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <h4>Financeiro</h4>
                      <div
                        style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px' }}
                      ></div>
                    </div>
                    <div className='col-sm-5'>
                      <label className='form-label'>Limite de Crédito</label>
                      <input
                        type='text'
                        placeholder='0,00' // Aplicando a máscara de CNPJ
                        {...formik.getFieldProps('limiteCredito')}
                        className='form-control'
                        disabled={true}
                        readOnly
                      />
                      <span style={{ color: 'black', marginTop: '10px' }}>
                        Para não limitar o crédito deste fornecedor, deixe este campo zerado
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ) : isEstrangeiro ? (
              <>
                <div className='mt-5'>
                  <div className='row mb-5'>
                    <div className='col-sm-5'>
                      <label className='form-label'>Status</label>
                      <select
                        className='form-select'
                        aria-label='Select example'
                        onChange={(event) => handleSelectChange(event, 'status')}
                        value={formik.values.status}
                      >
                        <option value='1'>Ativo</option>
                        <option value='0'>Inativo</option>
                      </select>
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-5' style={{ display: 'none' }}>
                      <label className='form-label'> Vendedor </label>
                      <input
                        type='text'
                        {...formik.getFieldProps('vendedorPadrao')}
                        className='form-control'
                      />
                      <span style={{ color: 'black', marginTop: '10px' }}>
                        Vendedor Padrão para este fornecedor
                      </span>
                    </div>
                    <div className='col-sm-5'>
                      <label htmlFor='' className='form-label'>
                        Selecione os Vendedores para esse fornecedor
                      </label>
                      <Select
                        className='react-select-styled react-select-solid'
                        classNamePrefix='react-select'
                        options={optionsFuncionariosClientes}
                        value={selectedVendedor}
                        placeholder='Selecione uma opção'
                        styles={customStyles}
                        isMulti
                        onChange={handleVendedores}
                      />
                    </div>
                    <span style={{ color: 'black', marginTop: '10px' }}>
                      Vendedor Padrão para este fornecedor
                    </span>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-2'>
                      <label className='form-label'>Data de criação</label>
                      <input
                        type='Date'
                        placeholder=''
                        {...formik.getFieldProps('creationdatecliente')}
                        className='form-control'
                        readOnly
                        disabled
                      />
                    </div>
                  </div>

                  <div
                    className='mb-3 mt-8 form-label-container'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '' }}
                    ></div>
                  </div>
                  {/* <div className='row mb-5'>
                    <div className='col-sm-2'>
                      <h4>Lista de Preço</h4>
                      <select className='form-select' aria-label='Select example'>
                        <option value=''>Selecione</option>
                      </select>
                    </div>
                  </div>
                  <div
                    className='mb-3 mt-8 form-label-container'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '' }}
                    ></div>
                  </div> */}

                  <div className='row mb-5'>
                    <div className='col-sm-5'>
                      <h4>Financeiro</h4>
                      <label className='form-label'>Limite de Crédito</label>
                      <input
                        type='text'
                        placeholder='0,00' // Aplicando a máscara de CNPJ
                        {...formik.getFieldProps('limiteCredito')}
                        className='form-control'
                        disabled={true}
                        readOnly
                      />
                      <span style={{ color: 'black', marginTop: '10px' }}>
                        Para não limitar o crédito deste fornecedor, deixe este campo zerado
                      </span>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className='mt-5'>
                  <div className='row mb-5'>
                    <div className='col-sm-5'>
                      <label className='form-label'>Codigo de regime tributário</label>
                      <select
                        className='form-select'
                        aria-label='Select example'
                        onChange={(event) => handleSelectChange(event, 'codregimetributario')}
                        value={formik.values.codregimetributario}
                      >
                        <option value='0'>Não informado</option>
                        <option value='1'>Simples Nacional</option>
                        <option value='2'>Regime Normal</option>
                        <option value='3'>Viúvo</option>
                      </select>
                    </div>
                    <div className='col-sm-5'>
                      <label className='form-label'>Inscric. SUFRAMA</label>
                      <input
                        type='text'
                        placeholder=''
                        {...formik.getFieldProps('inscricaosuframa')}
                        className='form-control'
                        onChange={(event) => {
                          formik.setFieldValue(
                            'inscricaosuframa',
                            event.target.value.replace(/\D/g, '')
                          ); // Remove caracteres não numéricos
                        }}
                      />
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-5'>
                      <label className='form-label'>Contribuinte</label>
                      <select
                        className='form-select'
                        onChange={(event) => handleSelectChange(event, 'contribuinteIcms')}
                        id=''
                        value={formik.values.contribuinteIcms}
                      >
                        <option value='0'> 0 - Não informado</option>
                        <option value='1'> 1 - Contribuinte ICMS</option>
                        <option value='2'>
                          {' '}
                          2 - Contribuinte isento de Inscrição no cadastro de Contribuintes do ICMS
                        </option>
                        <option value='9'>
                          {' '}
                          9 - Não Contribuinte, que pode ou não possuir Inscrição Estadual no
                          Cadastro de Contribuintes do ICMS
                        </option>
                      </select>
                    </div>
                    <div className='col-sm-5'>
                      <label className='form-label'>Inscrição Municipal</label>
                      <input
                        type='text'
                        placeholder=''
                        {...formik.getFieldProps('inscricaomunicipal')}
                        className='form-control'
                        onChange={(event) => {
                          formik.setFieldValue(
                            'inscricaomunicipal',
                            event.target.value.replace(/\D/g, '')
                          ); // Remove caracteres não numéricos
                        }}
                      />
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-5'>
                      <label className='form-label'>Status</label>
                      <select
                        className='form-select'
                        aria-label='Select example'
                        onChange={(event) => handleSelectChange(event, 'status')}
                        value={formik.values.status}
                      >
                        <option value='1'>Ativo</option>
                        <option value='0'>Inativo</option>
                      </select>
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='row mb-5'>
                      <div className='col-sm-5' style={{ display: 'none' }}>
                        <label className='form-label'> Vendedor </label>
                        <input
                          type='text'
                          {...formik.getFieldProps('vendedorPadrao')}
                          className='form-control'
                        />
                        <span style={{ color: 'black', marginTop: '10px' }}>
                          Vendedor Padrão para este fornecedor
                        </span>
                      </div>
                      <div className='col-sm-5'>
                        <label htmlFor='' className='form-label'>
                          Selecione os Vendedores para esse fornecedor
                        </label>
                        <Select
                          className='react-select-styled react-select-solid'
                          classNamePrefix='react-select'
                          options={optionsFuncionariosClientes}
                          value={selectedVendedor}
                          placeholder='Selecione uma opção'
                          styles={customStyles}
                          isMulti
                          onChange={handleVendedores}
                        />
                      </div>
                      <span style={{ color: 'black', marginTop: '10px' }}>
                        Vendedor Padrão para este fornecedor
                      </span>
                    </div>
                  </div>
                  <div className='row mb-5'>
                    <div className='col-sm-2'>
                      <label className='form-label'>Data de criação</label>
                      <input
                        type='Date'
                        placeholder=''
                        {...formik.getFieldProps('creationdatecliente')}
                        className='form-control'
                        readOnly
                        disabled
                      />
                    </div>
                  </div>
                  <div
                    className='mb-3 mt-8 form-label-container'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '' }}
                    ></div>
                  </div>
                  {/* <div className='row mb-5'>
                    <div className='col-sm-2'>
                      <h4>Lista de Preço</h4>
                      <select
                        className='form-select'
                        aria-label='Select example'
                      //onChange={(event) => handleSelectChange(event, 'statuscrm')}
                      >
                        <option value=''>Selecione</option>
                      </select>
                    </div>
                  </div>
                  <div
                    className='mb-3 mt-8 form-label-container'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <div
                      style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '' }}
                    ></div>
                  </div> */}

                  <div className='row mb-5'>
                    <div className='col-sm-5'>
                      <h4>Financeiro</h4>
                      <label className='form-label'>Limite de Crédito</label>
                      <input
                        type='text'
                        placeholder='0,00' // Aplicando a máscara de CNPJ
                        {...formik.getFieldProps('limiteCredito')}
                        className='form-control'
                        disabled={true}
                        readOnly
                      />
                      <span style={{ color: 'black', marginTop: '10px' }}>
                        Para não limitar o crédito deste fornecedor, deixe este campo zerado
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}
          </>
        )}

        {activeSection === 'fiscal' && (
          <>
            <div
              className='mb-3 mt-8 form-label-container'
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <h5>Informações Fiscais</h5>
              <div style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '' }}></div>
            </div>
            <div className='mb-5'>
              <div className='mb-5'>
                <div className='row'>
                  <div className='col-sm-4'>
                    <div className='form-label'>Usuário</div>
                    <input
                      type='text'
                      className='form-control bg-transparent'
                      readOnly
                      value={currentUser?.login}
                    />
                  </div>
                  <div className='col-sm-2'>
                    <label className='form-label'>Tabela de Preços</label>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      onChange={(event) => handleSelectChange(event, 'tabelaprecos1')}
                      value={formik.values.tabelaprecos1}
                    >
                      <option value=''>Selecione</option>
                      {optionsTabelaDePreco.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='col-sm-2'>
                    <label className='form-label'>Indicador de IE</label>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      onChange={(event) => handleSelectChange(event, 'indicadorie')}
                      value={formik.values.indicadorie}
                    >
                      <option value='0'>Não contribuinte</option>
                      <option value='1'>Contribuinte</option>
                    </select>
                  </div>
                  <div className='col-sm-2'>
                    <label className='form-label'>Conta corrente</label>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      onChange={(event) => handleSelectChange(event, 'contacorrente')}
                      value={formik.values.contacorrente}
                    >
                      <option value='1'>Sim</option>
                      <option value='0'>Não</option>
                    </select>
                  </div>
                </div>
                <div className='row mt-6'>
                  <div className='col-sm-3'>
                    <label className='form-label'> % Desconto</label>
                    <input
                      type='text'
                      placeholder='0,000%'
                      {...formik.getFieldProps('desconto')}
                      className='form-control'
                      onChange={(event) => {
                        let value = event.target.value.replace(/[^0-9.]/g, '');
                        value = value.replace(/(\..*)\./g, '$1');
                        formik.setFieldValue('desconto', value);
                      }}
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label className='form-label'> % Desconto Boleto</label>
                    <input
                      type='text'
                      placeholder='0,0%'
                      {...formik.getFieldProps('descontoboleto')}
                      className='form-control'
                      onChange={(event) => {
                        let value = event.target.value.replace(/[^0-9.]/g, '');
                        value = value.replace(/(\..*)\./g, '$1');
                        formik.setFieldValue('descontoboleto', value);
                      }}
                    />
                  </div>
                  <div className='col-sm-3'>
                    <label className='form-label'>Região Tributária - ICMS</label>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      onChange={(event) => handleSelectChange(event, 'regiaotributaria')}
                      value={formik.values.regiaotributaria}
                    >
                      <option value='0'>Selecione</option>
                      <option value='1'>PJ - FORA DO ESTADO</option>
                      <option value='4'>PJ - DENTRO DO ESTADO</option>
                      <option value='5'>PF - DENTRO DO ESTADO</option>
                      <option value='6'>PF - FORA DO ESTADO</option>
                      <option value='7'>DEVOLUÇÃO</option>
                      <option value='8'>PJ - NORTE/NORDESTE/C.OESTE</option>
                      <option value='9'>PJ - SUL/SUDESTE</option>
                      <option value='10'>PJ - ZONA FRANCA</option>
                    </select>
                  </div>
                  <div className='col-sm-3'>
                    <label className='form-label'>Class. Trib. - PIS/COFINS</label>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      onChange={(event) => handleSelectChange(event, 'piscofins')}
                      value={formik.values.piscofins}
                    >
                      <option value='0'>ISENTO PIS COFINS</option>
                      <option value='1'>CLIENTE COM PIS E COFINS</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className='mb-6 row'>
                <div className='col-sm-6'>
                  <div className='form-label'>Obs. p/ Nota Fiscal</div>
                  <input
                    type='text'
                    placeholder=''
                    {...formik.getFieldProps('obsnotafiscal')}
                    className='form-control'
                  />
                </div>
                <div className='col-sm-2'>
                  <label className='form-label'>Pode comprar?</label>
                  <select
                    className='form-select'
                    aria-label='Select example'
                    onChange={(event) => handleSelectChange(event, 'podecomprar')}
                    value={formik.values.podecomprar}
                  >
                    <option value='3'>Selecione</option>
                    <option value='0'>Não</option>
                    <option value='1'>Sim</option>
                  </select>
                </div>
                <div className='col-sm-2'>
                  <label className='form-label'>Protestar?</label>
                  <select
                    className='form-select'
                    aria-label='Select example'
                    onChange={(event) => handleSelectChange(event, 'podeprotestar')}
                    value={formik.values.podeprotestar}
                  >
                    <option value='3'>Selecione</option>
                    <option value='0'>Não</option>
                    <option value='1'>Sim</option>
                  </select>
                </div>
                <div className='col-sm-2'>
                  <label className='form-label'>Reter ISS?</label>
                  <select
                    className='form-select'
                    aria-label='Select example'
                    onChange={(event) => handleSelectChange(event, 'podereter')}
                    value={formik.values.podereter}
                  >
                    <option value='3'>Selecione</option>
                    <option value='0'>Não</option>
                    <option value='1'>Sim</option>
                  </select>
                </div>
              </div>
              <div className='mb-5 row'>
                <div className='col-sm-6'>
                  <div
                    className='form-label-container'
                    style={{ display: 'flex', alignItems: 'center' }}
                  >
                    <h6>Endereço NF-E</h6>
                    <div
                      style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '' }}
                    ></div>
                  </div>
                  <label className='form-label'>País</label>
                  <div className='mb-1 row'>
                    <div className='col-sm-3'>
                      <input
                        type='text'
                        id='nfePaisValue'
                        className='form-control'
                        {...formik.getFieldProps('paisid')}
                      />
                    </div>
                    <div className='col-sm-9'>
                      <input type='text' id='nfePaisLabel' className='form-control' readOnly />
                    </div>
                  </div>
                  <label className='form-label'>UF</label>
                  <div className='mb-1 row'>
                    <div className='col-sm-3'>
                      <input
                        type='text'
                        id='nfeUfValue'
                        className='form-control'
                        {...formik.getFieldProps('estadoid')}
                      />
                    </div>
                    <div className='col-sm-9'>
                      <input type='text' id='nfeUfLabel' className='form-control' readOnly />
                    </div>
                  </div>
                  <label className='form-label'>Cidade</label>
                  <div className='mb-4 row'>
                    <div className='col-sm-3'>
                      <input
                        type='text'
                        id='nfeCidadeValue'
                        className='form-control'
                        {...formik.getFieldProps('cidadeid')}
                      />
                    </div>
                    <div className='col-sm-9'>
                      <input type='text' id='nfeCidadeLabel' className='form-control' readOnly />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        {activeSection === 'observacoes' && (
          <>
            <div className='col-sm-12 mt-5 mb-10'>
              <h3>Observações</h3>
              <textarea
                className='form-control'
                {...formik.getFieldProps('obscliente')}
                maxLength={500}
                style={{ height: '200px' }}
              />
              <div className='char-counter' style={{ color: 'black' }}>
                {500 - formik.values.obscliente.length} caracteres restantes
              </div>
            </div>
          </>
        )}
        {activeSection === 'general' && (
          <>
            {isPF ? (
              <>
                <div className=''></div>
              </>
            ) : (
              <>
                <div
                  className='mt-8 mb-3 form-label-container'
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <h4>Pessoa de Contato</h4>
                  <div
                    style={{ flexGrow: 1, borderBottom: '2px solid #000', marginLeft: '10px' }}
                  ></div>
                </div>
                <Table>
                  <TabContainer>
                    <TablePessoasContato
                      className=''
                      infosPessoaContato={infosPessoaContato}
                      onRemovePessoa={handleRemovePessoa}
                      onEditPessoa={handleEditPessoa}
                      showInputRow={showInputRow}
                      onInputChange={handleInputChange}
                      onAddPessoa={handleAddPessoa}
                      onCancel={handleCancel}
                      newPessoa={newPessoa}
                    />
                  </TabContainer>
                </Table>
                {!showInputRow && (
                  <button
                    type='button'
                    className='btn btn-sm btn-success mt-3 mb-8'
                    onClick={handleShowInputRow}
                  >
                    Adicionar Pessoa de Contato
                  </button>
                )}
              </>
            )}
          </>
        )}
        {/* {activeSection === 'historico' && (
          <>
            {historicoCompras.length > 0 ? (
              renderizarHistoricoCompras(historicoCompras)
            ) : (
              <>
                <div className='mt-5 mb-10'>
                  <h4 style={{ marginLeft: '10px' }}>
                    Ainda não há histórico de compras disponível para este fornecedor.
                  </h4>
                </div>
              </>
            )}
          </>
        )} */}

        <div className='d-flex gap-8'>
          <button type='submit' className=' btn-cadastro btn-bg-success' id='post_user_submit'>
            Cadastrar
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Aguarde...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>

          <button
            type='button'
            className='btn-cadastro btn-danger'
            id='back'
            onClick={() => navigate('/listagem-fornecedor')}
          >
            Voltar
            {loading && (
              <span className='indicator-progress' style={{ display: 'block' }}>
                Aguarde...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
}
