import {useParams} from 'react-router-dom';
import PageTitulo from '../../components/Pagetitulo';
import {useEffect, useState} from 'react';
import {useProduto} from './hooks/useProduto';
import DadosGerais from './components/DadosGerais';
import DadosComplementares from './components/DadosComplementares';
import Precos from './components/Precos';
import Custos from './components/Custo';
import Outros from './components/Outros';
import CodigoBarras from './components/CodigoBarras';
import ProdutosSimilares from './components/ProdutosSimilares';

const CadastrarProduto = () => {
  const {id} = useParams();

  const [activeSection, setActiveSection] = useState('geral');
  const {formik, loading, navigate, setIdDoFormulario} = useProduto();

  useEffect(() => {
    setIdDoFormulario(id);
  }, [id]);

  return (
    <form
      className='form-control-solid'
      onSubmit={formik.handleSubmit}
      noValidate
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      }}
    >
      <div className=' mb-10'>
        <PageTitulo id={id} tipoFormulario='Produto' />
        <div style={{marginBottom: '8px'}}>
          <div style={{borderBottom: '2px solid #878787'}}></div>
        </div>
        <div className=' mt-8'>
          <div className='col-sm-12'>
            <div className='btn-group custom-nav-tabs' role='group' style={{width: '100%'}}>
              <button
                type='button'
                className={`btn btn-outline-secondary ${activeSection === 'geral' ? 'active' : ''}`}
                onClick={() => setActiveSection('geral')}
              >
                Dados gerais
              </button>
              <button
                type='button'
                className={`btn btn-outline-secondary ${
                  activeSection === 'complementares' ? 'active' : ''
                }`}
                onClick={() => setActiveSection('complementares')}
              >
                Dados complementares
              </button>

              {id && (
                <button
                  type='button'
                  className={`btn btn-outline-secondary ${
                    activeSection === 'cod_barras' ? 'active' : ''
                  }`}
                  onClick={() => setActiveSection('cod_barras')}
                >
                  Código de Barras
                </button>
              )}
              {id && (
                <button
                  type='button'
                  className={`btn btn-outline-secondary ${
                    activeSection === 'produtos-similares' ? 'active' : ''
                  }`}
                  onClick={() => setActiveSection('produtos-similares')}
                >
                  Produtos Similares
                </button>
              )}
              <button
                type='button'
                className={`btn btn-outline-secondary ${
                  activeSection === 'precos' ? 'active' : ''
                }`}
                onClick={() => setActiveSection('precos')}
              >
                Preços
              </button>
              <button
                type='button'
                className={`btn btn-outline-secondary ${
                  activeSection === 'custos' ? 'active' : ''
                }`}
                onClick={() => setActiveSection('custos')}
              >
                Custos
              </button>
              <button
                type='button'
                className={`btn btn-outline-secondary ${
                  activeSection === 'outros' ? 'active' : ''
                }`}
                onClick={() => setActiveSection('outros')}
              >
                Outros
              </button>
            </div>
          </div>
        </div>
        {activeSection === 'geral' && <DadosGerais idParam={id} />}
        {activeSection === 'complementares' && <DadosComplementares />}
        {/* {activeSection === 'fichatecnica' && <FichaTecnica />} */}
        {/* {activeSection === 'anuncio' && <Anuncios />} */}
        {activeSection === 'precos' && <Precos />}
        {activeSection === 'produtos-similares' && <ProdutosSimilares />}
        {activeSection === 'custos' && <Custos />}
        {activeSection === 'outros' && <Outros />}
        {activeSection === 'cod_barras' && <CodigoBarras />}
      </div>
      <div>
        <div className='d-flex gap-8'>
          <button type='submit' className='btn-cadastro btn-bg-success' id='post_user_submit'>
            {id ? 'Atualizar' : 'Cadastrar'}

            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Aguarde...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>

          <button
            type='button'
            className='btn-cadastro btn-danger'
            id='back'
            onClick={() => navigate('/produto-listagem')}
          >
            Voltar
            {loading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                Aguarde...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};

export default CadastrarProduto;
