import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";
import { AsyncSelectEmpresa, AsyncSelectFornecedor, Modal } from "../../../../../shared"
import { useEffect, useState } from "react";
import { ClienteOption } from "../../../../cadastros/proposta-pedido/types/interface";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Swal from "sweetalert2";
import { number } from "yup";
import { set } from "date-fns";
import NotaFiscalDetails from "../../../../cadastros/cadastro-pedido-compras/components/nota-fiscal-details/nota-fiscal-details";
import { is } from "date-fns/locale";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const ModalEstoqueEntradas = ({ isOpen, onHide, isEdit, dataLine, reload, clearFiltros }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [selectedEmpresa, setSelectedEmpresa] = useState<ClienteOption | null>(null)
    const [selectedFornecedor, setSelectedFornecedor] = useState<ClienteOption | null>(null)
    const [selectedTipoEntidade, setSelectedTipoEntidade] = useState<ClienteOption | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [chaveNotaFiscal, setChaveNotaFiscal] = useState<number | null>(null)
    const [numeroDocumento, setNumeroDocumento] = useState<number | null>(null)
    const [dataEntrada, setDataEntrada] = useState<string | null>(null)

    const cadastrarEstoqueEntrada = async () => {
        try {
            if(selectedEmpresa && selectedFornecedor && selectedTipoEntidade && dataEntrada && chaveNotaFiscal && numeroDocumento){
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoque-entradas`;

            const response = await axios.post(url, {
                "idEmpresa": selectedEmpresa?.value ? selectedEmpresa?.value : null,
                'idFornecedor': selectedFornecedor?.value ? selectedFornecedor?.value : null,
                'numeroDocumento': numeroDocumento ? numeroDocumento : null,
                'chaveNotaFiscal': chaveNotaFiscal ? chaveNotaFiscal : null,
                'idTipoEntidadeDocumento':  selectedTipoEntidade?.value ? selectedTipoEntidade?.value : null,
                'dataEntrada': dataEntrada ? dataEntrada : null,
                'status': status
            });

            Swal.fire({
                icon: 'success',
                title: `Entrada cadastrada com sucesso`,
                timer: 3000,
                timerProgressBar: true,
            }).then(() => {
                clearForm()
                onClose()
            })
        }}
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro no cadastro `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
    }
    const editarEstoqueEntrada = async () => {
        try {
            if(selectedEmpresa && selectedFornecedor && selectedTipoEntidade && dataEntrada && chaveNotaFiscal && numeroDocumento){
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoque-entradas/${dataLine[0]}`;
            const response = await axios.put(url, {
                "idEmpresa": selectedEmpresa?.value ? selectedEmpresa?.value : null,
                'idFornecedor': selectedFornecedor?.value ? selectedFornecedor?.value : null,
                'numeroDocumento': numeroDocumento? numeroDocumento : null,
                'chaveNotaFiscal': chaveNotaFiscal? chaveNotaFiscal : null,
                'idTipoEntidadeDocumento':  selectedTipoEntidade?.value ? selectedTipoEntidade?.value : null,
                'dataEntrada': dataEntrada ? dataEntrada : null,
                'status': status
            });
            Swal.fire({
                icon: 'success',
                title: `Entrada editada com sucesso`,
                timer: 3000,
                timerProgressBar: true,

            }).then(() => {
                clearForm()
                onClose()
                reload()

            });

        }}
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro na edicao `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
    }
    const onClose = () => {
        clearForm()
        clearFiltros()
        reload!()
        onHide!();
    }
    const clearForm = () => {
        setStatus('ATIVO')
        setSelectedEmpresa(null)
        setSelectedFornecedor(null)
        setSelectedTipoEntidade(null)
        setNumeroDocumento(null)
        setChaveNotaFiscal(null)
        setDataEntrada(null)
    }
    const submitButton = (
        <Button
            type='submit'
            variant='success'
            onClick={() => isEdit ? editarEstoqueEntrada() : cadastrarEstoqueEntrada()}
        >
            {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? "Editar" : "Cadastrar"}
        </Button>
    );
    const loadOptionsTipoEntidade = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/entidade-documentos/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000,
                    status: null
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: `${item.titulo}`,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }

    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };
    function formatDate(inputDate: string): string {
        const date = new Date(inputDate);
      
        if (isNaN(date.getTime())) {
          throw new Error("Data inválida");
        }
      
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0'); 
      
        return `${year}-${month}-${day}`;
      }

    useEffect(() => {
        if (isEdit) {
            setSelectedEmpresa({
                value: dataLine[1],
                label: `[${dataLine[1]}] ${dataLine[2]}`,
            })
            setSelectedFornecedor({
                value: dataLine[3],
                label: `[${dataLine[3]}] ${dataLine[4]}`,
            })
            setSelectedTipoEntidade({
                value: dataLine[5],
                label: `${dataLine[6]}`,
            })
            setNumeroDocumento(dataLine[7])
            setChaveNotaFiscal(dataLine[8])
            setDataEntrada(formatDate(dataLine[9]))
            setStatus(dataLine[10])
        }
    }, [dataLine])

    return (
        <Modal {...modalConfigs} >
            <div className='form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    {isEdit ? 'Editar' : 'Cadastrar'} Entrada
                </h1>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            <div>
                <form action="" >
                    <Row className="my-5">
                        <Col>
                            <label htmlFor="" className="form-label">Empresa:</label>
                            <AsyncSelectEmpresa inputOption={selectedEmpresa} handleOptions={(selectedOption) => setSelectedEmpresa(selectedOption)} ></AsyncSelectEmpresa>
                            {( selectedEmpresa === null) && <span className={` form-label text-danger`}>*Por favor, informe a Empresa</span>}

                        </Col>
                    </Row>
                    <Row className='my-4'>
                        <Col>
                            <label htmlFor="" className="form-label"  >Fornecedor:</label>
                            <AsyncSelectFornecedor inputOption={selectedFornecedor} handleOptions={(selectedOption) => setSelectedFornecedor(selectedOption)}></AsyncSelectFornecedor>
                            {( selectedFornecedor === null) && <span className={` form-label text-danger`}>*Por favor, informe o Fornecedor</span>}

                        </Col>
                    </Row>
                    <Row className="my-5">
                        {/* Tipo entidade */}
                        <Col>

                            <label htmlFor="" className="form-label">Tipo Entidade Documento :</label>
                            <AsyncSelect
                                defaultOptions
                                value={selectedTipoEntidade}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedTipoEntidade(null);
                                    }
                                }} onChange={(selectedOption: ClienteOption | null) => {
                                    if (selectedOption) {
                                        const idValue = isNaN(Number(selectedOption.value))
                                            ? selectedOption.value // Caso seja texto, mantêm como string.
                                            : Number(selectedOption.value);
                                        console.log(selectedOption.value)
                                    }
                                    setSelectedTipoEntidade(selectedOption);
                                    console.log(selectedOption)
                                }}
                                loadOptions={loadOptionsTipoEntidade}
                                placeholder="Selecione o Tipo da Entidade" className="mt-1"></AsyncSelect>
                                {( selectedTipoEntidade === null) && <span className={` form-label text-danger`}>*Por favor, informe o Tipo Entidade do Documneto</span>}

                        </Col>
                        {/* Status */}
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select"
                                onChange={(e) => { setStatus(e.target.value) }}
                                value={status ? status : ""}
                                name="" id="">
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                        {/* Numero Documento */}
                        <Col>
                            <label htmlFor="" className="form-label"  >Numero do documento:</label>
                            <input type="number"
                                value={numeroDocumento ? numeroDocumento : ""}
                                className="form-control"
                                onChange={(e) => {
                                    setNumeroDocumento(Number(e.target.value.replace(/\D/g, "")))
                                }} placeholder="Digite o numero do documento" />
                                {( numeroDocumento === null || numeroDocumento===0) && <span className={` form-label text-danger`}>*Por favor, informe o numero do documento</span>}

                        </Col>
                    </Row>
                    <Row className="my-5  mb-4 row">
                        <Col>
                            <label htmlFor="" className="form-label">Chave nota fiscal:</label>
                            <input type="text"
                            maxLength={44}
                            value={chaveNotaFiscal ? chaveNotaFiscal : ""}
                                onChange={(e) => {
                                    setChaveNotaFiscal(Number(e.target.value.replace(/\D/g, "")))
                                    }}    
                                     className="form-control" placeholder="Digite a chave da nota fiscal" 
                                    />
                            {( chaveNotaFiscal === null || chaveNotaFiscal === 0  ) && <span className={` form-label text-danger`}>*Por favor, informe a chave da nota fiscal</span>}
                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Data Entrada:</label>
                            <input type="date" value={dataEntrada ? dataEntrada : ""}
                                onChange={(e) => {
                                    setDataEntrada(e.target.value)}} className="form-control"></input>
                            {( dataEntrada === null || dataEntrada==='') && <span className={` form-label text-danger`}>*Por favor, informe a Data da Entrada</span>}

                        </Col>
                    </Row>
                </form>
            </div>
        </Modal >
    )
}

export default ModalEstoqueEntradas