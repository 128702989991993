import {useContext, useEffect, useMemo, useState} from 'react';
import {
  AsyncSelectFornecedor,
  DescriptionComponent,
  FooterForm,
  formatarData,
  formatDateTimeWithHours,
  INotaFiscal,
  ISelectOption,
  pedidoCompraNotaFiscalSchema,
  useModal,
} from '../../../../../shared';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {CustomRow} from '../../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import Swal from 'sweetalert2';
import {useFormik} from 'formik';
import {
  editPedidoCompraNotaFiscal,
  importarNotaFiscal,
  sendPedidoCompraNotaFiscal,
  vincularNotaFiscal,
} from '../../../cadastro_requests/cadastro_requests';
import {useParams} from 'react-router-dom';
import {
  getNotaFiscalByPedidoCompraId,
  getPedidoCompraNotaFiscal,
  getPedidoCompraNotaFiscalById,
} from '../../../../listagem/listagem_requests/listagem_requests';

import {Col, OverlayTrigger, Row as RowForm, Spinner, Tooltip} from 'react-bootstrap';
import axios from 'axios';
import {DatePicker} from 'rsuite';
import Skeleton from 'react-loading-skeleton';
import PedidoCompraHookContext from '../../context/pedido-compra-context';
import NotaFiscalDetailsModal from './components/nota-fiscal-modal-details';

export const NotaFiscalDetails: React.FC = () => {
  const context = useContext(PedidoCompraHookContext);

  const renderCellValue = (value: any) => (value ? value : '...');

  const pedidoCompraNotaFiscalColumns: Column<INotaFiscal>[] = [
    {
      Header: 'Detalhes',
      accessor: 'id',
      Cell: ({row}) => (
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={`tooltip-edit-${row.original.id}`}>
              Observe os fretes associados à nota fiscal
            </Tooltip>
          }
        >
          <button
            className='btn btn-primary btn-sm'
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleModalFreteDetails(row.original.id!)}
          >
            <i style={{padding: 0}} className='bi bi-eye'></i>
          </button>
        </OverlayTrigger>
      ),
    },
    {
      Header: 'Id do Pedido de Compra',
      accessor: 'idPedidoCompra',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Fornecedor',
      accessor: 'fantasiaFornecedor',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Número da Nota',
      accessor: 'numeroNota',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Chave',
      accessor: 'chave',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Importado',
      accessor: 'isImportado',
      Cell: ({value, row}) => (
        <div className='text-start'>{row.original.isImportado ? 'SIM' : 'NÃO'}</div>
      ),
    },
    {
      Header: 'Data de Emissão',
      accessor: 'dataEmissaonota',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Valor',
      accessor: 'valor',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },
    {
      Header: 'Volume Total',
      accessor: 'volumeTotal',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Usuário Criação',
      accessor: 'nomeUsuarioCriacao',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Data de Criação',
      accessor: 'dataCriacao',
      Cell: ({value}) => (
        <div className='text-end'>{renderCellValue(formatDateTimeWithHours(value))}</div>
      ),
    },
    {
      Header: 'Usuário de Alteração',
      accessor: 'nomeUsuarioAlteracao',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Data de alteração',
      accessor: 'dataAlteracao',
      Cell: ({value}) => (
        <div className='text-end'>
          {renderCellValue(value ? formatDateTimeWithHours(value) : '...')}
        </div>
      ),
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <div style={{display: 'flex', gap: '5px'}}>
          <button
            onClick={() => handleOnEdit(row.original.id!)}
            className='btn btn-success btn-sm bi bi-pencil'
            data-toggle='tooltip'
            data-placement='top'
            title='Editar'
          />

          {row.original.isImportado ? (
            <></>
          ) : (
            <button
              className='btn btn-info btn-sm'
              onClick={() => confirmAction(row.original.id!)}
              style={{
                marginLeft: 10,
                cursor: 'pointer',
              }}
            >
              {isLoadingImport ? (
                <Spinner size='sm' animation='border' />
              ) : (
                <i style={{padding: 0}} className='bi bi-arrow-bar-up'></i>
              )}
            </button>
          )}
        </div>
      ),
    },
  ];

  const confirmAction = async (idNfe: number | string) => {
    const result = await Swal.fire({
      title: 'Confirmação:',
      text: 'Deseja realmente importar a nota fiscal?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sim, importar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      importNotaFiscal(idNfe);
    }
  };

  const [isLoadingImport, setIsLoadingImport] = useState<boolean>(false);

  const importNotaFiscal = async (idNfe: number | string) => {
    setIsLoadingImport(true);
    try {
      await importarNotaFiscal(idNfe);

      Swal.fire({
        icon: 'success',
        title: 'Nota Fiscal Importada com Sucesso!',
        showCancelButton: false,
        timer: 3000,
        timerProgressBar: false,
      });

      loadNotaFiscalByPedidoCompraId()

      setIsLoadingImport(false);
    } catch (errors: any) {
      const {data, status} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoadingImport(false);
    }
  };

  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [isPedidoNotaFiscalData, setIsPedidoNotaFiscalData] = useState<INotaFiscal[]>([]);

  const data = useMemo(() => isPedidoNotaFiscalData, [isPedidoNotaFiscalData]);
  const columns = useMemo(() => pedidoCompraNotaFiscalColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const initialValues: INotaFiscal = {
    chave: undefined,
    idFornecedor: undefined,
    idPedidoCompra: undefined,
    numeroNota: undefined,
    valor: undefined,
    dataEmissaonota: undefined,
    volumeTotal: 0,
  };

  const [isLoadingNotaFiscal, setIsLoadingNotaFiscal] = useState<boolean>(false);
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState<boolean>(false);
  const [isNotaFiscalAction, setIsNotaFiscalAction] = useState<'add' | 'edit' | null>(null);
  const [isAsyncSelectFornecedor, setIsAsyncSelectFornecedor] = useState<ISelectOption | null>(
    null
  );
  const [isIdNotaFiscal, setIsIdNotaFiscal] = useState<number>();

  const [isDataEmissaoNota, setIsDataEmissaoNota] = useState<Date | null>(null);

  const {id} = useParams();

  const [isIdItem, setIsIdItem] = useState<number>();
  const [isDetailsFreteModal, openDetailsFreteModal, closeDetailsFreteModal] = useModal();

  const handleModalFreteDetails = (id: number) => {
    openDetailsFreteModal();

    setIsIdItem(id);
  };

  const formik = useFormik({
    initialValues,
    // Validação condicional de schemas, add para a ação de adição, e edit para a ação de add
    validationSchema: pedidoCompraNotaFiscalSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoadingNotaFiscal(true);
      if (isNotaFiscalAction === 'add') {
        if (!isAsyncSelectFornecedor || !isDataEmissaoNota) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          let title;
          switch (true) {
            case !isAsyncSelectFornecedor:
              title = 'A inserção do Fornecedor é obrigatória';
              break;
            case !isDataEmissaoNota:
              title = 'A inserção da Data de Emissão é obrigatória';
              break;

            default:
              title = 'Erro desconhecido';
          }

          Toast.fire({
            icon: 'info',
            title,
          });

          setSubmitting(false);
          setIsLoadingNotaFiscal(false);
          return;
        }
        try {
          await sendPedidoCompraNotaFiscal({
            ...values,
            numeroNota: values.numeroNota ? Number(values.numeroNota) : undefined,
            idPedidoCompra: Number(id),
            idFornecedor: isAsyncSelectFornecedor?.value,
            dataEmissaonota: isDataEmissaoNota!,
          });

          Swal.fire({
            icon: 'success',
            title: 'Nota Fiscal Cadastrada com Sucesso!',
            showCancelButton: false,
            timer: 3000,
            timerProgressBar: false,
          });

          loadNotaFiscalByPedidoCompraId();
          setIsNotaFiscalAction(null);

          setSubmitting(false);
          setIsLoadingNotaFiscal(false);

          setIsAsyncSelectFornecedor(null);
        } catch (errors: any) {
          const {data, status} = errors.response;

          if (status === 409) {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
              confirmButtonText: 'Ok',
            });
            setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
          } else if (status === 401) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          }

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoadingNotaFiscal(false);
        }
      } else {
        try {
          if (!isAsyncSelectFornecedor || !isDataEmissaoNota) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'center',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            let title;
            switch (true) {
              case !isAsyncSelectFornecedor:
                title = 'A inserção do Fornecedor é Obrigatória';
                break;
              case !isDataEmissaoNota:
                title = 'A inserção da Data de Emissão é Obrigatória';
                break;

              default:
                title = 'Erro desconhecido';
            }

            Toast.fire({
              icon: 'info',
              title,
            });

            setSubmitting(false);
            setIsLoadingNotaFiscal(false);
            return;
          }
          const result = await Swal.fire({
            title: 'Confirmação:',
            text: 'Deseja realmente editar a nota fiscal do pedido de compra?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, editar',
            cancelButtonText: 'Cancelar',
          });

          if (result.isConfirmed) {
            await editPedidoCompraNotaFiscal(isIdNotaFiscal!, {
              ...values,
              numeroNota: values.numeroNota ? Number(values.numeroNota) : undefined,
              idPedidoCompra: Number(id),
              idFornecedor: isAsyncSelectFornecedor?.value,
              dataEmissaonota: isDataEmissaoNota!,
            });

            Swal.fire({
              icon: 'success',
              title: 'Nota Fiscal Editada Com Sucesso!',
              showCancelButton: false,
              timer: 3000,
              timerProgressBar: false,
            });

            loadNotaFiscalByPedidoCompraId();

            setIsNotaFiscalAction(null);
          }

          setSubmitting(false);
          setIsLoadingNotaFiscal(false);
        } catch (errors: any) {
          const {error} = errors;

          if (error.response) {
            const status = error.response.status;

            const {data} = errors.response;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            }

            Swal.fire({
              icon: 'error',
              title: data.map((item) => item.mensagem),
              showCancelButton: false,
            });
          }

          setSubmitting(false);
          setIsLoadingNotaFiscal(false);
        }
      }
    },
  });

  const handleOnAdd = () => {
    setIsNotaFiscalAction('add');
    setIsAsyncSelectFornecedor(null);
    setIsDataEmissaoNota(null);
    setIsLoadingNotaFiscal(false);
    formik.setValues({
      chave: '',
      modelo: 1,
      valor: 0,
      numeroNota: 0,
      status: 'ATIVO',
      volumeTotal: undefined,
    });
  };

  const handleOnEdit = (id: number) => {
    setIsNotaFiscalAction('edit');
    setIsIdNotaFiscal(id);
    loadNotaFiscalById(id);
  };

  const loadFornecedorById = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedores/${id}?id=${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setIsAsyncSelectFornecedor({
        value: data.id,
        label: ``,
      });

      return [
        {
          value: data.id,
          label: `${data.id} - ${data.descricao}`,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadNotaFiscalById = async (idFrete: string | number) => {
    setIsLoadingNotaFiscal(true);
    if (id) {
      try {
        const response = await getPedidoCompraNotaFiscalById(idFrete);

        const {data} = response;

        formik.setValues({
          chave: data.chave,
          modelo: data.modelo,
          status: data.status,
          valor: data.valor,
          numeroNota: data.numeroNota,
          volumeTotal: data.volumeTotal,
        });

        setIsAsyncSelectFornecedor({
          label: '',
          value: isAsyncSelectFornecedor?.value!,
        });

        setIsDataEmissaoNota(data.dataEmissaonota ? new Date(data.dataEmissaonota) : null);

        loadFornecedorById(data.idFornecedor);

        setIsLoadingNotaFiscal(false);
      } catch (errors) {
        setIsLoadingNotaFiscal(false);
      }
    }
  };

  const loadNotaFiscalByPedidoCompraId = async () => {
    if (id) {
      setIsLoadingSkeleton(true);
      try {
        const response = await getNotaFiscalByPedidoCompraId(id);

        const {content} = response.data;

        setIsPedidoNotaFiscalData(content);
        setIsLoadingSkeleton(false);
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });

        setIsLoadingSkeleton(false);
      }
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (context?.actionTap) {
        context.setActionTap(() => loadNotaFiscalByPedidoCompraId);
      }
    }, 300); // Aguarda 300ms antes de disparar
  
    return () => clearTimeout(timeout); // Limpa o timeout em cada render
  }, [context?.actionTap]);

  useEffect(() => {
    if (id) {
      loadNotaFiscalByPedidoCompraId();
    }
  }, []);

  const handleAsyncSelectFornecedor = (option: ISelectOption) => {
    setIsAsyncSelectFornecedor(option);
  };

  return (
    <>
      {isNotaFiscalAction && (
        <>
          <DescriptionComponent
            description={
              isNotaFiscalAction === 'edit' ? 'Editar Nota Fiscal' : 'Cadastrar Nota Fiscal'
            }
            isButtonAction
            variantButton='danger'
            buttonTitle={
              isNotaFiscalAction === 'edit'
                ? 'Cancelar Edição de Nota Fiscal'
                : 'Cancelar Cadastro de Nota Fiscal'
            }
            handleAction={() => setIsNotaFiscalAction(null)}
          />

          <form action='form-control-solid' onSubmit={formik.handleSubmit}>
            <RowForm className='mb-6 mt-6'>
              <Col className='col-sm-12'>
                <label htmlFor='' className='form-label'>
                  Fornecedor:
                </label>

                <AsyncSelectFornecedor
                  handleOptions={(option) => handleAsyncSelectFornecedor(option!)}
                  inputOption={isAsyncSelectFornecedor}
                />
              </Col>
            </RowForm>

            <RowForm>
              <Col sm='4' className='col-12'>
                <label className='form-label'>Chave da Nota:</label>
                <input
                  type='text' // Usamos "text" para suportar maxLength
                  placeholder='Digite o Número da Chave da Nota'
                  maxLength={44} // Limita a entrada para no máximo 44 caracteres
                  {...formik.getFieldProps('chave')}
                  className={`form-control ${
                    formik.touched.chave && formik.errors.chave ? 'is-invalid' : ''
                  }`}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replace(/[^0-9]/g, ''); // Remove caracteres não numéricos
                    e.target.value = value; // Atualiza o valor exibido no input
                    formik.setFieldValue('chave', value ? Number(value) : ''); // Converte para BigInt para suportar grandes números
                  }}
                />
                {formik.touched.chave && formik.errors.chave ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.chave}
                  </div>
                ) : null}
              </Col>

              <Col sm='4' className='col-12'>
                <label className='form-label'>Número da Nota:</label>
                <input
                  type='text' // Mantemos "text" para suportar maxLength
                  placeholder='Digite o Número da Nota'
                  maxLength={9} // Limita a entrada para no máximo 9 caracteres
                  {...formik.getFieldProps('numeroNota')}
                  className={`form-control ${
                    formik.touched.numeroNota && formik.errors.numeroNota ? 'is-invalid' : ''
                  }`}
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value.replace(/[^0-9]/g, ''); // Remove caracteres não numéricos
                    e.target.value = value; // Atualiza o valor exibido no input
                    formik.setFieldValue('numeroNota', value); // Converte para número e atualiza o formik
                  }}
                />
                {formik.touched.numeroNota && formik.errors.numeroNota ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.numeroNota}
                  </div>
                ) : null}
              </Col>

              <Col sm='4' className='col-12'>
                <label className='form-label'>Volume Total:</label>
                <input
                  readOnly
                  disabled
                  className='form-control'
                  type='number'
                  placeholder='0'
                  {...formik.getFieldProps('volumeTotal')}
                />

                <small id='' className='form-text text-muted'>
                  Campo somente de leitura
                </small>
              </Col>
            </RowForm>

            <RowForm className='mt-4'>
              <Col md='4' className='col-sm-3'>
                <label htmlFor='' className='form-label w-100'>
                  Data de Emissão:
                </label>

                <DatePicker
                  className='w-100'
                  format='dd-MM-yyyy'
                  placeholder='Data de Emissão da Nota'
                  onChange={(date) => setIsDataEmissaoNota(date)}
                  value={isDataEmissaoNota}
                />
              </Col>

              <Col md={4} className='col-12'>
                <label className='form-label'>Status:</label>
                <select
                  {...formik.getFieldProps('status')}
                  className={`form-control ${
                    formik.touched.status && formik.errors.status ? 'is-invalid' : ''
                  }`}
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </Col>

              <Col md={4} className='col-12'>
                <label htmlFor='' className='form-label'>
                  Valor:
                </label>
                <input
                  placeholder='Insira o valor do seguro:'
                  type='text'
                  readOnly
                  disabled
                  id=''
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(formik.values.valor || 0)}
                  className={`form-control ${
                    formik.touched.valor && formik.errors.valor ? 'is-invalid' : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    inputValue = inputValue.replace(/\D/g, '');

                    if (inputValue === '') {
                      inputValue = '0,00';
                    }

                    formik.setFieldValue('valor', parseFloat(inputValue) / 100);
                  }}
                />
                <small id='' className='form-text text-muted'>
                  Campo somente de leitura
                </small>
              </Col>
            </RowForm>
            <FooterForm
              isLoading={isLoadingNotaFiscal}
              textActionSubmit={
                isNotaFiscalAction === 'edit' ? 'Editar Nota Fiscal' : 'Cadastrar Nota Fiscal'
              }
              redirectTo='pedido-compras-listagem'
              isBack={false}
            />
          </form>
        </>
      )}

      {isNotaFiscalAction === null && (
        <DescriptionComponent
          description='Notas Fiscais'
          buttonTitle='+ Adicionar'
          isButtonAction
          handleAction={() => handleOnAdd()}
          variantButton='warning'
        />
      )}

      {isLoadingSkeleton ? (
        <Skeleton count={25} height={20} />
      ) : (
        <div className='table-responsive mt-4' style={{maxHeight: 500}}>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border px-5'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<INotaFiscal>, index: number) => (
                  <th
                    key={index}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<INotaFiscal>, i) => {
                  prepareRow(row);
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={(id) => handleOnEdit(id)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      SEM NOTAS FISCAIS PARA O PEDIDO DE COMPRA
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      <NotaFiscalDetailsModal
        isOpen={isDetailsFreteModal}
        onHide={closeDetailsFreteModal}
        idItem={isIdItem}
      />
    </>
  );
};

export default NotaFiscalDetails;
