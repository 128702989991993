import { Col,  Row, Spinner } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import {AsyncSelectProductByIdSysled, EstoqueTransferenciasProduto, smoothScrollToBottom, TipoLocalizacao, useModal } from "../../../../shared";
import { ClienteOption } from "../../../cadastros/proposta-pedido/types/interface";
import { Link, useParams } from "react-router-dom";
import ModalEstoqueTransferenciaProduto from "./components/ModalTransferenciasProduto";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const ListagemEstoqueTransferenciasProduto = () => {
    const {id} = useParams()
    const [tranferenciaProduto, setTranferenciaProduto] = useState<EstoqueTransferenciasProduto[]>([])
    const [dataLine, setDataLine] = useState<TipoLocalizacao[]>([])

    const [selectedEstoque, setSelectedEstoque] = useState<ClienteOption | null>(null)
    const [selectedLocalOrigem, setSelectedLocalOrigem] = useState<ClienteOption | null>(null)
    const [selectedLocalDestino, setSelectedLocalDestino] = useState<ClienteOption | null>(null)
    const [selectedProduto, setSelectedProduto] = useState<ClienteOption | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [quantidade, setQuantidade] = useState<number | null>(null)
    const [page, setPage] = useState(0)

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [hasMore, setHasMore] = useState<boolean>(false)


    const [isEstoqueTransferenciaModal, openEstoqueTransferenciaModal, closeEstoqueTransferenciaModal] = useModal();

    const loadMore = () => {
        setPage((prevPage) => prevPage + 1);
        smoothScrollToBottom()
    }

    const fetchTransferenciasProduto = async (page = 0) => {
        setIsLoading(true)
        try {

            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/transferencias-itens/listar`,
                {
                    params: {
                        status: status,
                        idEstoqueTransferencia: id,
                        idEstoque: selectedEstoque?.value,
                        idProduto: selectedProduto?.value,
                        idEstoqueTransferencias: selectedEstoque?selectedEstoque.value:null,
                        idLocalizacaoOrigem : selectedLocalOrigem?.value,
                        idLocalizacaoDestino : selectedLocalDestino?.value,
                        quantidade: quantidade?quantidade:null,
                        page: page,
                    },
                }

            );

            console.log(response.data.content)
            const { content, totalPages } = response.data;

            if (response.status === 200) {
                setTranferenciaProduto((prev) => (page === 0 ? content : [...prev, ...content]));
                setHasMore(page < totalPages - 1);
                setIsLoading(false);

            }
            if (content.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de Produtos da transferência para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setIsLoading(false);

        }
    };

    const loadOptionsEstoques = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: item.titulo,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }

    const loadOptionsLocalizacao = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/localizacao/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: item.titulo,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }

    const clearFiltros = () => {
        setSelectedLocalDestino(null)
        setSelectedLocalOrigem(null)
        setQuantidade(0)
        setSelectedEstoque(null)
        setSelectedProduto(null)
        setStatus('ATIVO')
        setPage(0)
    }

    useEffect(() => {
        fetchTransferenciasProduto(page)
    }, [page]);

    const handleSearch = (event) => {
        event.preventDefault();
        setPage(0)
        fetchTransferenciasProduto(0);
    };

    const openModal = (isEdit, ...data) => {
        if (data) {
            setDataLine(data)
        }
        setIsEdit(isEdit)
        openEstoqueTransferenciaModal();
    }

    return (
        <div>
            {/* TITULO E CADASTRO */}
            <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    Transferências de Produto
                </h1>
                <button className='btn btn-success ' style={{ fontSize: '14px' }}
                    onClick={() => {
                        setIsEdit(false)
                        openModal(false)
                    }}
                >
                    Cadastrar Transferência
                </button>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            {/* FILTROS */}
            <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
            <div>
                <form action="">
                    <Row className="mt-4 col-12 mb-4 row">
                        <Col>
                            <label htmlFor="" className="form-label"  >Produto:</label>
                            <AsyncSelectProductByIdSysled onKeyDown={()=>{setSelectedProduto(null)}} inputOption={selectedProduto} handleOptions={(product) => setSelectedProduto(product)}  ></AsyncSelectProductByIdSysled>
                        </Col>
                    </Row>
                    <Row className="mt-5 row col-12">

                        <Col>
                            <label htmlFor="" className="form-label">Estoque de Transferencia:</label>
                            <AsyncSelect placeholder='Selecione o Estoque de Transferencia'
                                loadOptions={loadOptionsEstoques}
                                defaultOptions
                                value={selectedEstoque}
                                onChange={(selectedOption: ClienteOption | null) => {
                                    setSelectedEstoque(selectedOption);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedEstoque(null);
                                    }
                                }}
                                 ></AsyncSelect>
                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Localização de Origem:</label>
                            <AsyncSelect placeholder='Selecione a localização de origem'
                                value={selectedLocalOrigem}
                                defaultOptions
                                onChange={(selectedOption: ClienteOption | null) => {
                                    setSelectedLocalOrigem(selectedOption);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedLocalOrigem(null);
                                    }
                                }}
                                loadOptions={loadOptionsLocalizacao} ></AsyncSelect>
                        </Col><Col>
                            <label htmlFor="" className="form-label">Localização de Destino:</label>
                            <AsyncSelect placeholder='Selecione a localização de destino'
                                value={selectedLocalDestino}
                                defaultOptions
                                onChange={(selectedOption: ClienteOption | null) => {
                                    setSelectedLocalDestino(selectedOption);
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedLocalDestino(null);
                                    }
                                }}
                                loadOptions={loadOptionsLocalizacao} ></AsyncSelect>
                        </Col>
                    </Row>
                    <Row className="mt-5 col-12 mb-4 row">

                    <Col className="col-4">
                            <label htmlFor="" className="form-label">Transferência:</label>
                            <input type="number"  value={Number(id)} disabled  className="form-control" placeholder={`${id}`} />

                        </Col>

                    <Col className="col-4">
                            <label htmlFor="" className="form-label">Quantidade:</label>
                            <input type="number"  value={quantidade?quantidade : ""} onChange={(e) => { setQuantidade(Number(e.target.value)) }} className="form-control" placeholder="0" />

                        </Col>
                        <Col className="col-4">
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select" value={status ? status : ""}
                                onChange={(e) => { setStatus(e.target.value) }}>
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                    </Row>
                    <div className='col-12 my-8 mt-9' style={{ display: 'flex', justifyContent: 'end' }}>
                        <div className='col-sm-4 mx-5' style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10' }}>
                        <Link className="btn mx-5 px-10 btn-info " to="/consultas/estoques/listagem-estoque-transferencias">
                                    Voltar
                                </Link>
                            <button
                                type='button'
                                style={{ width: '40%' }}
                                onClick={() => clearFiltros()}
                                className='btn btn-danger form-control'
                            >
                                Limpar
                            </button>
                            <button
                                onClick={handleSearch}
                                type='submit'
                                style={{ width: '40%', marginLeft: '10px' }}
                                className='btn btn-primary d-flex align-items-center justify-content-center'

                            >
                                {isLoading ? <Spinner animation='border' size='sm' /> : (
                                    <>
                                        <i className="bi bi-search mx-0"></i>
                                        Pesquisar
                                    </>)}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {isLoading ? (
                <Skeleton count={40} height={25} />
            ) : (
                <>
                    <div>
                        <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800'>
                                    <th className='text-center'>Estoque de Transferência</th>
                                    <th className='text-center'>Produto</th>
                                    <th className='text-center'>Localização de origem</th>
                                    <th className='text-center'>Localização de destino</th>
                                    <th className='text-center'>Quantidade</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Criado por</th>
                                    <th className='text-center'>Data de Criação</th>
                                    <th className='text-center'>Alterado por</th>
                                    <th className='text-center'>Data de alteracao</th>
                                    <th className='text-center'>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tranferenciaProduto?.map((produto) => (
                                    <tr className="text-center">
                                        {/* <td>{produto.idEstoqueTransferencia}</td> */}
                                        <td>{produto.estoque}</td>
                                        <td>{produto. produto}</td>
                                        <td>{produto.localizacaoOrigem}</td>
                                        <td>{produto.localizacaoDestino}</td>
                                        <td>{produto.quantidade}</td>
                                        <td>{produto.status}</td>
                                        <td>{produto.usuarioCriacaoNome}</td>
                                        <td>{produto.dataCriacao}</td>
                                        <td>{produto.usuarioAlteracaoNome ? produto.usuarioAlteracaoNome : "..."}</td>
                                        <td>{produto.dataAlteracao}</td>
                                            <td>
                                            <div className='text-center'>
                                                <button
                                                    onClick={() => { 
                                                        openModal(true,produto.id,produto.idProduto, produto.produto,produto.idEstoque,produto.estoque ,produto.idLocalizacaoOrigem, produto.localizacaoOrigem, produto.idLocalizacaoDestino, produto.localizacaoDestino,produto.quantidade,produto.status )
                                                        setIsEdit(true)
                                                    }}
                                                    className='btn btn-success btn-sm bi bi-pencil'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title='Editar'
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                        {hasMore && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-primary m-5' onClick={loadMore} >
                                    Carregar Mais
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}
            <ModalEstoqueTransferenciaProduto clearFiltros={clearFiltros} dataLine={dataLine} reload={fetchTransferenciasProduto} isEdit={isEdit} isOpen={isEstoqueTransferenciaModal} onHide={closeEstoqueTransferenciaModal}></ModalEstoqueTransferenciaProduto>
        </div>
    )
}

export default ListagemEstoqueTransferenciasProduto