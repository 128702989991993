import { useState } from "react";

const FormArquivoUpload = ({setIsOk,isOk}) => {
    const [arquivoSalvo, setArquivoSalvo] = useState<string>();

    const SalvarArquivo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const arquivo = event.target.files?.[0]; 
        // setArquivoSalvo(arquivo)
        if (arquivo?.type=='application/pdf'){
            setArquivoSalvo(arquivo?.name)
            setIsOk(true)
        }
        else{
            setIsOk(false)
            setArquivoSalvo("Tipo de arquivo nao suportado(somente pdf)")
        }
    }

    return (<>
        <div className='position-relative mt-5  mx-auto'>

            <input type="file" name='inputFile' onChange={SalvarArquivo} id='inputFile' className='w-100  ' />

            <label htmlFor="inputFile" className=' d-flex justify-content-between'>
                <label htmlFor="inputFile" className={`'d-flex align-items-center  ${arquivoSalvo?isOk?"text-success":'text-danger':''}  form-control rounded-end-0'`}>{ arquivoSalvo ? arquivoSalvo : 'Anexe um arquivo'} </label>
                <label htmlFor="inputFile" className={`d-flex border-1 border   h-100 border-secondary rounded-end-3 align-items-center justify-content-center text-center w-25  h-100  fs-2`}>
                    <i className="fs-1 bi bi-upload mx-5 my-5 text-black "></i> Upload
                </label>
            </label>
        </div>
    </>)
}
export default FormArquivoUpload