import { Button, Col, ModalProps, Row } from "react-bootstrap";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { Modal } from "../../../../../shared";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
const ModalTipoMovimento = ({isOpen, onHide,isEdit=false,reload,dataLine={},clearFiltros}) => {
    const [operacao, setOperacao] = useState<string | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [titulo, setTitulo] = useState<string | null>(null)
    
    const onClose = () => {
        // clearFiltros()
        clearForm()
        onHide!();
        reload()
    }

    const clearForm = () => {
        setStatus('ATIVO')
        setDescricao(null)
        setOperacao(null)
        setTitulo(null)
    }

    const cadastrarMovimentoTipo = async () => {
        try {
            if((titulo !== null && titulo !== ''&& titulo !== undefined && titulo !== ' ') && operacao !== null){
                let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/tipo-movimento`;
            
                const response = await axios.post(url, {
                    "titulo": titulo?titulo:null,
                    "descricao": descricao?descricao:null,
                    "status": status?status:'ATIVO',
                    "operacao": operacao?operacao:null
                });   
                
            Swal.fire({
                icon: 'success',
                  title: `Tipo de Movimento cadastrado com sucesso`,
                  timer: 3000,
                  timerProgressBar: true,
                }).then(() => {
                    clearForm()
                })
        }    
            }
            
        catch (errors: any) {
              Swal.fire({
                icon: 'error',
                  title: `Erro na requisicao`,
                  timer: 3000,
                  timerProgressBar: true,
                });
                              
            console.log(errors)}
    }

    const editarMovimentoTipo = async () => {
        try {
                    let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/tipo-movimento/${dataLine[0]}`;
                    if((titulo !== null && titulo !== ''&& titulo !== undefined && titulo !== ' ') && operacao !== null){
                    const response = await axios.put(url, {
                        "titulo": titulo?titulo:'',
                        "descricao": descricao?descricao:'',
                        "status": status,
                        "operacao": operacao?operacao:null
                    });
                    Swal.fire({
                        icon: 'success',
                          title: `Tipo de Movimento editado com sucesso`,
                          timer: 3000,
                          timerProgressBar: true,

                        }).then(() => {
                            clearForm()
                            onClose()
                            reload()

                        });
    
                } }
                catch (errors: any) {
                      Swal.fire({
                        icon: 'info',
                          title: `Erro na requisicao`,
                          timer: 3000,
                          timerProgressBar: true,
                        });
                                      
                    console.log(errors)}}

     const submitButton = (
            <Button
              type='submit'
              variant='success'
              onClick={() => isEdit?editarMovimentoTipo():cadastrarMovimentoTipo()}
            >
              {isEdit?"Editar":"Cadastrar"}
            </Button>
          );

    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };

    useEffect(() => {
            setTitulo(dataLine[1])
            setDescricao(dataLine[2])
            setStatus(dataLine[3])
            setOperacao(dataLine[4])
        if(dataLine[0] === undefined){
            setTitulo(null)
            setOperacao(null)
        }
    }, [dataLine])

    return (
        <div>
            <Modal {...modalConfigs}>
                {/* TITULO E CADASTRO */}
            <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    {isEdit?"Editar":"Cadastrar"} Tipo de Movimento
                </h1>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            {/* FILTROS */}
            <div className="my-4">
                <form action="" className="mt-4">
                    <Row className="mt-8 col-12 mb-4 row">
                        <Col>
                            <label htmlFor="" className="form-label"  >Titulo:</label>
                            <input type="text" 
                             className="form-control"
                            value={titulo ? titulo : ""}
                                onChange={(e) => {
                                    setTitulo(e.target.value)
                                }}
                                 placeholder="Digite o titulo" />
                            {(titulo === null || titulo === '')  && <span className={` form-label text-danger`}>*Por favor, informe o Titulo</span>}

                        </Col>
                        
                        <Col>
                            <label htmlFor="" className="form-label">Operação:</label>
                            <select className="form-select" value={operacao ? operacao : ""}
                                onChange={(e) => {setOperacao(e.target.value)}}>
                                <option value=''disabled selected >Selecione a Operação</option>
                                <option value="POSITIVAR" >POSITIVAR</option>
                                <option value="NEGATIVAR">NEGATIVAR</option>
                            </select>
                            {( operacao === null || operacao === '') && <span className={` form-label text-danger`}>*Por favor, informe a Operação</span>}

                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select" value={status ? status : ""}
                                onChange={(e) => {setStatus(e.target.value)}}>
                                <option  value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                    </Row>
                    <Row className="mt-4 col-12 mb-4 row">
                        <label htmlFor="" className="form-label">Descrição:</label>
                        <Col className="">
                            <textarea name="" 
                            value={descricao ? descricao : ""} onChange={(e) => {
                                setDescricao(e.target.value)
                            }}
                             placeholder="Digite a descrição" className="form-control" id=""></textarea>
                        </Col>
                    </Row>
                </form>
            </div>
            </Modal>
        </div>
    )
}  

export default ModalTipoMovimento