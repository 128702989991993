import { Column, ColumnInstance, Row, useTable } from 'react-table';
import { DescriptionComponent, ILogisticaSeparacao, PDFView } from '../../../shared';
import { useNavigate } from 'react-router-dom';
import { CustomRow } from '../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import { useEffect, useMemo, useState } from 'react';
import {
  getLogisticaSeparacao,
  imprimirPDFSeparacao,
  postSeparacaoItemSync,
  visualizarPDFSeparacao,
} from '../listagem_requests/listagem_requests';
import Swal from 'sweetalert2';
import { Badge, Button, Modal, OverlayTrigger, Spinner, Tooltip } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

const LogisticaSeparacaoListagemPage: React.FC = () => {
  const navigate = useNavigate();

  const [isLoadingSyncSeparacao, setIsLoadingSyncSeparacao] = useState<boolean>(false);
  const [isLogisticaSeparacaoData, setIsLogisticaSeparacaoData] = useState<ILogisticaSeparacao[]>(
    []
  );

  const [isIdSeparacao, setIsIdSeparacao] = useState<string | number>();
  const [isPdfUrl, setIsPdfUrl] = useState<string>();
  const [modalShowPDFViewer, setModalShowPDFViewer] = useState(false);

  const separacaoLogisticaColumns: Column<ILogisticaSeparacao>[] = [
    {
      Header: 'Id Pedido',
      accessor: 'idPrevenda',
      Cell: ({ value }) => <div className='text-end'>{value}</div>,
    },
    {
      Header: 'Sequência',
      accessor: 'seqEntrega',
      Cell: ({ value }) => <div className='text-start'>{value ? value : '...'}</div>,
    },

    {
      Header: 'Fluxo',
      accessor: 'fluxoDescricao',
      Cell: ({ value }) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Tipo de Entrega',
      accessor: 'tipoEntrega',
      Cell: ({ value }) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Quantidade de itens',
      accessor: 'qtdItens',
      Cell: ({ value }) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row, value }) => {
        let statusText;
        switch (value) {
          case 'CANCELADO':
            statusText = 'CANCELADO';
            break;
          case 'ABERTO':
            statusText = 'ABERTO';
            break;
          case 'CONCLUIDO':
            statusText = 'CONCLUÍDO';
            break;
          default:
            statusText = 'DESCONHECIDO';
        }

        return (
          <div
            style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}
            className='text-start'
          >
            {statusText}

            {(row.original.seqEntrega != null && row.original.isSincronizado == null) ||
              row.original.isSincronizado == 0 ? (
              <Badge style={{ color: '#fff', marginTop: '5px' }} bg='info'>
                Aguardando Integração com WMS
              </Badge>
            ) : (
              <></>
            )}

            {row.original.isSincronizado == 1 ? (
              <Badge style={{ color: '#fff', marginTop: '5px' }} bg='info'>
                Integrado com WMS
              </Badge>
            ) : (
              <></>
            )}
          </div>
        );
      },
    },
    {
      Header: 'Ações',
      Cell: ({ row }) => (
        <div
          className='text-end'
          style={{ display: 'flex', gap: '1', alignItems: 'center', justifyContent: 'flex-start' }}
        >
          {row.original.seqEntrega == null ? (
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id={`tooltip-edit-${row.original.id}`}>
                  {row.original.seqEntrega
                    ? 'Não é possível realizar esta ação porque os items foram separados para logística.'
                    : 'Editar'}
                </Tooltip>
              }
            >
              <button
                onClick={() => handleOnEdit(row.original.id!)}
                className='btn btn-success btn-sm bi bi-pencil'
                data-toggle='tooltip'
                data-placement='top'
                title='Editar'
              />
            </OverlayTrigger>
          ) : (
            <></>
          )}
          
          {row.original.seqEntrega ? (
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id={`tooltip-edit-${row.original.id}`}>Visualizar Separação</Tooltip>
              }
            >
              <button
                className='btn btn-primary btn-sm'
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleOnEdit(row.original.id!)}
              >
                {isLoadingSyncSeparacao ? (
                  <Spinner size='sm' animation='border' />
                ) : (
                  <i style={{ padding: 0 }} className='bi bi-eye'></i>
                )}
              </button>
            </OverlayTrigger>
          ) : (
            <></>
          )}

          {row.original.seqEntrega == null ? (
            <OverlayTrigger
              placement='top'
              overlay={
                <Tooltip id={`tooltip-edit-${row.original.id}`}>Separar para logística</Tooltip>
              }
            >
              <button
                className='btn btn-info btn-sm'
                style={{
                  marginLeft: 10,
                  cursor: 'pointer',
                }}
                onClick={() => onHandleModal(row.original.id!)}
              >
                {isLoadingSyncSeparacao ? (
                  <Spinner size='sm' animation='border' />
                ) : (
                  <i style={{ padding: 0 }} className='bi bi-arrow-bar-up'></i>
                )}
              </button>
            </OverlayTrigger>
          ) : (
            <></>
          )}

          <div className='dropdown' style={{ marginLeft: '7px' }}>
            <button className='btn btn-sm btn-danger dropdown-toggle' data-bs-toggle='dropdown'>
              <i className='bi bi-file-earmark-arrow-down'>PDF</i>
            </button>
            <ul className='dropdown-menu'>
              <li>
                <button
                  className='dropdown-item'
                  style={{ color: 'red' }}
                  type='button'
                  onClick={() =>
                    visualizarPDFSeparacao(
                      setModalShowPDFViewer,
                      row.original.id,
                      setIsLoading,
                      setIsPdfUrl,
                      setIsIdSeparacao
                    )
                  }
                >
                  VISUALIZAR PDF
                </button>
              </li>
            </ul>
          </div>
        </div>
      ),
    },
  ];

  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const data = useMemo(() => isLogisticaSeparacaoData, [isLogisticaSeparacaoData]);
  const columns = useMemo(() => separacaoLogisticaColumns, []);

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const handleOnEdit = (id: number) => {
    navigate(`/logistica-separacao/${id}`);
  };

  const onHandleModal = (id: number) => {
    Swal.fire({
      title: 'Você tem certeza?',
      text: 'Deseja realizar a separação para logística?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim, confirmar!',
      cancelButtonText: 'Cancelar',
    }).then((result) => {
      if (result.isConfirmed) {
        handleSyncSeparacao(Number(id));
      }
    });
  };

  const handleSyncSeparacao = async (id: number) => {
    setIsLoadingSyncSeparacao(true);

    try {
      await postSeparacaoItemSync(id);

      Swal.fire({
        icon: 'success',
        title: 'Separação em Logística com sucesso!',
        showCancelButton: false,
        timer: 3000,
        timerProgressBar: false,
      });

      setIsLoadingSyncSeparacao(false);
      getExpedicaoSeparacaoData(isPage);
    } catch (errors: any) {
      const { data, status } = errors.response;

      if (status === 409) {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
          confirmButtonText: 'Ok',
        });
      } else if (status === 401) {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          window.open('/auth', '_blank');
        });
      }

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoadingSyncSeparacao(false);
    }
  };

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  const getExpedicaoSeparacaoData = async (page = 0) => {
    setIsLoading(true);

    try {
      const response = await getLogisticaSeparacao(page);

      const { content, totalPages } = response.data;

      setIsLogisticaSeparacaoData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoading(false);
    } catch (errors: any) {
      const { error } = errors;

      const status = error.response.status;

      if (status === 401 || 403) {
        Swal.fire({
          icon: 'error',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        });
      }

      Swal.fire({
        icon: 'error',
        title: error,
        confirmButtonText: 'Ok',
      });
    }
  };

  useEffect(() => {
    getExpedicaoSeparacaoData(isPage);
  }, [isPage]);

  return (
    <>
      <DescriptionComponent
        description={'Listagem de Separação Logística'}
        buttonTitle='+ Cadastro de Logística de Seperação'
        redirectTo='/logistica-separacao'
        withLink
      />

      {isLoading ? (
        <Skeleton height={30} count={15} />
      ) : (
        <div className='table-responsive'>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<ILogisticaSeparacao>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<ILogisticaSeparacao>, i) => {
                  prepareRow(row);
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={(id) => handleOnEdit(id)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      Sem Dados
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {isHasMore && (
            <div className='d-flex justify-content-center align-items-center'>
              <button className='btn btn-primary m-5' onClick={loadMore}>
                {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
              </button>
            </div>
          )}
        </div>
      )}

      <Modal
        size={'xl'}
        show={modalShowPDFViewer}
        onHide={() => setModalShowPDFViewer(false)}
        style={{ width: '100%' }}
      >
        <Modal.Header style={{ justifyContent: 'flex-end', gap: 10 }}>
          <Button
            variant='primary'
            onClick={() => imprimirPDFSeparacao(isIdSeparacao, setIsLoading)}
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</Modal.Body>
      </Modal>
    </>
  );
};

export default LogisticaSeparacaoListagemPage;
