import styled from 'styled-components';

export const ContainerCard = styled.div`
  width: 100%;
  background-color: #3e97ff;
  border-radius: 7px;
  padding: 20px;
  flex-direction: column;
  justify-content: space-between;

  display: flex;
`;

export const CardItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;

  color: #fff;

  p {
    color: #fff;
    font-weight: bold;
    margin: 0px;
  }

  span {
    margin-left: 10px;
    text-align: end;
  }
`;

export const ContainerImage = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 5px;

  .box-left {
    width: 50%;
    height: 100%;
  }

  .box-right {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: column;
    text-align: end;

    span {
      font-weight: bold;
    }

    P {
      color: #fff;
    }
  }
`;

export const InfoBage = styled.div`
  width: 100%;
  height: 30px;
  border-radius: 5px;
  margin-top: 5px;

  display: flex;
  align-items: center;
  justify-content: center;

  margin-bottom: 5px;

  background-color: rgb(0, 119, 255);
  color: #fff;

  p {
    margin: 0px;
    font-weight: 600;
  }
`;


export const InfoBagdeWithButton = styled.div`
  width: 100%;
  height: 30px;
  border-radius: 5px;

  display: flex;

  background-color: rgb(0, 119, 255);
  color: #fff;

  .box-left {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;  
  }

  .box-right {
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      border: none;
      border-radius: 3px;
      background-color: #3e97ff;

      color: #fff;
      transition: 0.3s;
      
      &:hover {
        filter: brightness(0.95);
      }
      
    }
  }

  p {
    margin: 0px;
    font-weight: 600;
  }
`;

type IProps = {
  isView: boolean
}

export const InfoEntregaContainer = styled.div<IProps>`
  width: 100%;
  display: ${(props) => props.isView ? 'block' : 'none' };
`;
