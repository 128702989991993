import styled from 'styled-components';

export const MeiosPagamentoContainer = styled.div`
  margin-top: 20px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  border-radius: .4375rem;

  box-shadow: 0rem .125rem .5rem rgb(228, 228, 228);

  padding: 20px 20px;

  h2 {
    margin-bottom: 1.25rem;
  }

  table {
    margin-top: .625rem;
    padding: 0rem 12.5rem;

    max-height: 3.125rem;

    margin-top: 20px;

    background-color: rgb(250, 250, 250);

    @media (max-width: 50rem) {
      padding: 0rem 1.25rem;
    }
  }

  button {
    @media (max-width: 50rem) {
      width: 100%;
    }
  }
`;

export const FooterContainer = styled.footer`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

`
