import {Route, Routes, BrowserRouter, Navigate, useLocation} from 'react-router-dom';
import {MasterLayout} from '../../_sysled/layout/MasterLayout';
import {HomePage} from '../pages/home/HomePage';
import {RelatorioVendasWrapper} from '../pages/dashboard/comercial/RelatorioVendas';
import {CadastroPromocao} from '../pages/campanha-promocional/criacao-promocao/CadastroPromocao';
import {ListagemPromocao} from '../pages/campanha-promocional/pesquisa-promocao/PesquisaListagemPromocao';
import {EdicaoPromocao} from '../pages/campanha-promocional/criacao-promocao/EdicaoPromocao';
import {ConsultaEstoqueWMS} from '../pages/consultas/estoques/ConsultaEstoqueWMS';
import {CadastroClientes} from '../pages/cadastros/clientes/CadastroCliente';
import {CadastroUsuarios} from '../pages/cadastros/usuarios/CadastroUsuario';
import {CadastroEmpresas} from '../pages/cadastros/empresas/CadastroEmpresa';
import {FormCadEmpresa} from '../pages/cadastros/empresas/indexEmpresas';
import {CadastroConsultores} from '../pages/cadastros/consultores/CadastroConsultores';
import {CadastroVendedores} from '../pages/cadastros/vendedores/CadastroVendedor';
import {CadastroPerfis} from '../pages/cadastros/permissoes/CadastroPerfis';
import {EditarUsuario} from '../pages/cadastros/usuarios/EditarUsuario';
import {EditarEmpresas} from '../pages/cadastros/empresas/EditarEmpresa';
import {EditarClientes} from '../pages/cadastros/clientes/EditarClientes';
import {ListagemClientes} from '../pages/listagem/clientes/ListagemClientes';
import {ListagemUsuariosTable} from '../pages/listagem/usuarios/ListagemUsuariosTable';
import {ListagemEmpresa} from '../pages/listagem/empresas/ListagemEmpresas';
import {ListagemPerfis} from '../pages/listagem/perfis/ListagemPerfis';
import {FormComponentIndex} from '../pages/cadastros/test-form-comp/TestFormComp';
import {useAuth} from '../modules/auth';
import CadastroParceiros from '../pages/cadastros/parceiros/CadastroParceiros';
import ListagemParceiros from '../pages/listagem/parceiros/ListagemParceiros';
import CadastroTipoVenda from '../pages/cadastros/tipo-venda/CadastroTipoVenda';
import ListagemTipoVenda from '../pages/listagem/tipo-venda/ListagemTipoVenda';
import {ListagemPropostaPedido} from '../pages/listagem/proposta-pedido/ListagemPropostaPedido';
import {CadastrarPrevenda} from '../pages/cadastros/proposta-pedido/CadastrarPropostaPedido';
import WorkflowAprovacao from '../pages/workflow/workflow_aprovacao/WorkflowAprovacao';
import ListagemWorkflowAprovacao from '../pages/workflow/workflow_aprovacao/ListagemWorkFlowAprovação';
import WorkflowComponentes from '../pages/workflow/workflow_componente/WorkflowComponentes';
import ListagemWorkFlowComponentes from '../pages/workflow/workflow_componente/ListagemWorkFlowComponentes';
import WorkflowSequencia from '../pages/workflow/workflow_sequencia/WorkflowSequencia';
import ListagemWorkFlowSequencia from '../pages/workflow/workflow_sequencia/ListagemWorkFlowSequencia';
import WorkflowPendencia from '../pages/workflow/workflow_pendencia/WorkflowPendencia';
import CadastrarProduto from '../pages/cadastros/produto/CadastroProduto';
import {ListagemProduto} from '../pages/listagem/produtos/ListagemProduto';
import ListagemPendenciaCompra from '../pages/listagem/pendencia-compra/ListagemPendenciaCompra';
import {
  CategorizacaoProdutosCadastroPage,
  CategorizacaoProdutosListagemPage,
  LogisticaExpedicaoPage,
  ConfiguracoesRotinasListagemPage,
  UnidadeMedidaCadastroPage,
  UnidadeMedidaListagemPage,
  PedidoComprasListagemPage,
  PedidoComprasCadastroPage,
  CadastroSuprimentoPage,
  SuprimentoListagemPage,
  EnderecoListagemPage,
  LogListagemPage,
  SysledExpedicaoPage,
} from '../pages';
import {FluxoLogisticoPage, FluxoLogisticoCadastroPage} from '../pages';
import LogisticaSeparacaoCadastroPage from '../pages/cadastros/separacao-logistica/SeparacaoLogisticaPage';
import LogisticaSeparacaoListagemPage from '../pages/listagem/seperacao-logistica/SeperacaoLogisticaListagem';
import {FormularioCadastroPage, FormulariosListagemPage} from '../pages';
import CadastroSuprimento from '../pages/cadastros/cadastro-suprimento/CadastroSuprimento';
import EnderecoPage from '../pages/cadastros/endereco/EnderecoPage';
import CadastroFormasdePagamento from '../pages/cadastros/formas_de_pagamento/CadastroFormasDePagamento';
import ListagemFormasDePagamento from '../pages/listagem/formas-pagamento/ListagemFormasDePagamento';
import CadastroCondicaoPagamento from '../pages/cadastros/condicoes_pagamento/CadastroCondicoesDePagamento';
import ListagemCondicoesPagamento from '../pages/listagem/condicoes-pagamento/ListagemCondicoesPagamento';
import {CadastroFornecedor} from '../pages/cadastros/fornecedor/CadastroFornecedor';
import {ListagemFornecedor} from '../pages/listagem/fornecedor/ListagemFornecedor';
import {EditarFornecedor} from '../pages/cadastros/fornecedor/EditarFornecedor';
import {CadastroColaborador} from '../pages/cadastros/colaboradores/CadastroColaborador';
import {ListagemColaborador} from '../pages/listagem/colaboradores/ListagemColaborador';
import {EditarColaborador} from '../pages/cadastros/colaboradores/EditarColaborador';
import { ListagemEstoqueEntradasPage, ListagemEstoqueEntradasProdutosPage, ListagemEstoqueInventariMotivoPage, ListagemEstoqueInventarioPage, ListagemEstoqueInventarioProdutoPage, ListagemEstoqueLocalizacaoPage, ListagemEstoquesPage, ListagemEstoqueTiposPage, ListagemEstoqueTransferenciasPage, ListagemEstoqueTransferenciasProdutoPage, ListagemTipoLocalizacaoPage, ListagemTipoMovimentoPage } from '../pages/modulo-estoques/estoque';
import ListagemEstoqueMovimento from '../pages/modulo-estoques/estoque/estoque-movimento/ListagemEstoqueMovimento';
import ListagemEstoqueMovimentoProdutos from '../pages/modulo-estoques/estoque/estoque-movimento-produtos/ListagemEstoqueMovimentoProdutos';

const PrivateRoutes = () => {
  const {currentUser} = useAuth();

  function organizaPermissoes(permissions) {
    const permissionMap = {};
    permissions.forEach((permission) => {
      permissionMap[permission.arquivo] = true;
    });
    return permissionMap;
  }

  const permissoes = currentUser?.usuariosPermissoes;
  const permissionMap = organizaPermissoes(permissoes);

  function renderRouteIfPermission(permissionKey, routeComponent) {
    return permissionMap[permissionKey] ? routeComponent : null;
  }

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/inicio' />} />
        <Route path='inicio' element={<HomePage />} />
        {currentUser?.tipouser === 'ADMIN' ? (
          <>
            {/* Pages */}
            {/* Consultores */}
            <Route path='/consultor' element={<CadastroConsultores />} />
            <Route path='/consultor/:id' element={<CadastroConsultores />} />
            {/* Perfis */}
            <Route path='/perfis' element={<CadastroPerfis />} />
            <Route path='/perfis/:id' element={<CadastroPerfis />} />
            <Route path='/perfis-listagem' element={<ListagemPerfis />} />
            {/* Propostas Comerciais e/ou Pedido*/}
            <Route path='/propostas-comerciais' element={<CadastrarPrevenda tipo='propostas' />} />
            <Route
              path='/editar-proposta-comercial/:id'
              element={<CadastrarPrevenda tipo='propostas' />}
            />
            <Route
              path='/listagem-propostas-comerciais'
              element={<ListagemPropostaPedido tipo='propostas' />}
            />

            <Route path='/listagem-logs' element={<LogListagemPage></LogListagemPage>} />
            {/* Pedidos */}
            {/* <Route path='/pedidos' element={<CadastrarPrevenda tipo="pedidos" />} /> */}
            <Route path='/editar-pedidos/:id' element={<CadastrarPrevenda tipo='pedidos' />} />
            <Route path='/listagem-pedidos' element={<ListagemPropostaPedido tipo='pedidos' />} />
            {/* Clientes */}
            <Route path='/cadastro-clientes' element={<CadastroClientes />} />
            <Route path='/listagem-clientes' element={<ListagemClientes />} />
            <Route path='/editar-cliente/:id' element={<EditarClientes />} />
            {/* Empresas */}
            <Route path='/cadastro-empresas' element={<FormCadEmpresa />} />
            <Route path='/listagem-empresas' element={<ListagemEmpresa />} />
            <Route path='/editar-empresa/:id' element={<EditarEmpresas />} />
            {/* Tipos de venda */}
            <Route path='/cadastro-tipo-venda' element={<CadastroTipoVenda />}></Route>
            <Route path='/listagem-tipo-venda' element={<ListagemTipoVenda />}></Route>
            <Route path='/editar-tipo-venda/:id' element={<CadastroTipoVenda />}></Route>
            {/* Pendência de venda */}
            <Route path='/listagem-pendencia-compra' element={<ListagemPendenciaCompra />}></Route>
            {/* Usuarios */}
            <Route path='/cadastro-usuarios' element={<CadastroUsuarios />} />
            <Route path='/listagem-usuarios' element={<ListagemUsuariosTable />} />
            <Route path='/editar-usuario/:id' element={<EditarUsuario />} />
            {/* Parceiros */}
            <Route path='/cadastro-parceiros' element={<CadastroParceiros />} />
            <Route path='/editar-parceiro/:id' element={<CadastroParceiros />} />
            <Route path='/listagem-parceiros' element={<ListagemParceiros />} />
            {/* Vendedores */}
            <Route path='/cadastro-vendedores' element={<CadastroVendedores />} />
            {/* Promoções */}
            <Route path='/promocao-cadastro' element={<CadastroPromocao />} />
            <Route path='/promocao-listagem' element={<ListagemPromocao />} />
            <Route path='/promocao-edicao/:id' element={<EdicaoPromocao />} />

            {/* Produtos */}
            <Route path='/produto-cadastro' element={<CadastrarProduto />} />
            <Route path='/produto-listagem' element={<ListagemProduto />} />
            <Route path='/produto-edicao/:id' element={<CadastrarProduto />} />

            <Route path='/produto-categorizacao' element={<CategorizacaoProdutosCadastroPage />} />
            <Route
              path='/produto-categorizacao/:id'
              element={<CategorizacaoProdutosCadastroPage />}
            />
            <Route
              path='/produto-categorizacao-listagem'
              element={<CategorizacaoProdutosListagemPage />}
            />

            <Route path='/produto-unidade-medida' element={<UnidadeMedidaCadastroPage />} />

            <Route path='/produto-unidade-medida/:id' element={<UnidadeMedidaCadastroPage />} />

            <Route
              path='/produto-unidade-medida-listagem'
              element={<UnidadeMedidaListagemPage />}
            />

            {/* Financeiro/ Formas de Pagamento */}
            <Route
              path='/cadastro-formas-de-pagamento'
              element={<CadastroFormasdePagamento></CadastroFormasdePagamento>}
            />
            <Route
              path='/listagem-formas-de-pagamento'
              element={<ListagemFormasDePagamento></ListagemFormasDePagamento>}
            />
            <Route
              path='/cadastro-formas-de-pagamento/:id'
              element={<CadastroFormasdePagamento></CadastroFormasdePagamento>}
            />

            {/* Financeiro/ Condicoes de Pagamento */}
            <Route
              path='/cadastro-condicoes-de-pagamento'
              element={<CadastroCondicaoPagamento></CadastroCondicaoPagamento>}
            />
            <Route
              path='/listagem-condicoes-de-pagamento'
              element={<ListagemCondicoesPagamento></ListagemCondicoesPagamento>}
            />
            <Route
              path='/cadastro-condicoes-de-pagamento/:id'
              element={<CadastroCondicaoPagamento></CadastroCondicaoPagamento>}
            />

            {/* Workflow-Aprovação */}
            <Route path='/workflow-aprovacao' element={<WorkflowAprovacao />} />
            <Route path='/workflow-aprovacao/:id' element={<WorkflowAprovacao />} />
            <Route path='/listagem-workflow-aprovacao' element={<ListagemWorkflowAprovacao />} />
            {/* Workflow-Componente */}
            <Route path='/workflow-componente' element={<WorkflowComponentes />} />
            <Route path='/workflow-componente/:id' element={<WorkflowComponentes />} />
            <Route path='/listagem-workflow-componente' element={<ListagemWorkFlowComponentes />} />
            {/* Workflow-Sequencia */}
            <Route path='/workflow-sequencia' element={<WorkflowSequencia />} />
            <Route path='/workflow-sequencia/:id' element={<WorkflowSequencia />} />
            <Route path='/listagem-workflow-sequencia' element={<ListagemWorkFlowSequencia />} />
            {/* Workflow Pendência */}
            <Route path='/workflow-pendencia-listagem' element={<WorkflowPendencia />} />

            {/* Logística Expedição */}
            <Route path='/logistica-expedicao-listagem' element={<LogisticaExpedicaoPage />} />

            { /* Logística Expedição Sysled */ }
            <Route path='/logistica-expedicao-sysled-listagem' element={<SysledExpedicaoPage />} />

            {/* Fluxo Logistico Listagem */}
            <Route path='/logistica-fluxo-listagem' element={<FluxoLogisticoPage />} />
            {/* Fluxo Logistico Edição */}
            <Route path='/logistica-fluxo-edicao/:id' element={<FluxoLogisticoCadastroPage />} />
            {/* Fluxo Logistico Cadastro */}
            <Route path='/logistica-fluxo-cadastro' element={<FluxoLogisticoCadastroPage />} />

            {/* Suprimento Pedido Status Controler */}
            <Route
              path='/suprimentos-cadastro'
              element={<CadastroSuprimentoPage></CadastroSuprimentoPage>}
            />
            <Route
              path='/suprimentos-listagem'
              element={<SuprimentoListagemPage></SuprimentoListagemPage>}
            />
            <Route
              path='/editar-suprimentos/:id'
              element={<CadastroSuprimentoPage></CadastroSuprimentoPage>}
            />

            {/* Fornecedores */}

            <Route path='/cadastro-fornecedor' element={<CadastroFornecedor />} />
            <Route path='/listagem-fornecedor' element={<ListagemFornecedor />} />
            <Route path='/editar-fornecedor/:id' element={<EditarFornecedor />} />

            {/* Colaborador */}

            <Route path='/cadastro-colaborador' element={<CadastroColaborador />} />
            <Route path='/listagem-colaborador' element={<ListagemColaborador />} />
            <Route path='/editar-colaborador/:id' element={<EditarColaborador />} />

            {/* Modules */}
            <Route
              path='relatorios/dashboards/comercial/relatorio-vendas'
              element={<RelatorioVendasWrapper />}
            />
            <Route path='consultas/estoques/consulta-estoque' element={<ConsultaEstoqueWMS />} />
            <Route path='/consultas/estoques/listagem-estoques' element={<ListagemEstoquesPage></ListagemEstoquesPage>} />
            <Route path='/consultas/estoques/listagem-estoque-tipos' element={<ListagemEstoqueTiposPage />} />
            <Route path='/consultas/estoques/listagem-estoque-localizacao' element={<ListagemEstoqueLocalizacaoPage />} />
            <Route path='/consultas/estoques/listagem-estoque-tipo-localizacao' element={<ListagemTipoLocalizacaoPage />} />
            <Route path='/consultas/estoques/listagem-estoque-tipo-movimento' element={<ListagemTipoMovimentoPage />} />
            <Route path='/consultas/estoques/listagem-estoque-movimento' element={<ListagemEstoqueMovimento></ListagemEstoqueMovimento>} />
            <Route path='/consultas/estoques/listagem-estoque-movimento-produtos' element={<ListagemEstoqueMovimentoProdutos />}/>
            <Route path='/consultas/estoques/listagem-estoque-entradas' element={<ListagemEstoqueEntradasPage></ListagemEstoqueEntradasPage>}/>
            <Route path='/consultas/estoques/listagem-estoque-entradas-produtos/:id' element={<ListagemEstoqueEntradasProdutosPage></ListagemEstoqueEntradasProdutosPage>}/>
            <Route path='/consultas/estoques/listagem-estoque-transferencias' element={<ListagemEstoqueTransferenciasPage></ListagemEstoqueTransferenciasPage>}/>
            <Route path='/consultas/estoques/listagem-estoque-transferencias-produtos/:id' element={<ListagemEstoqueTransferenciasProdutoPage/>}/>
            <Route path='/consultas/estoques/listagem-estoque-inventario' element={<ListagemEstoqueInventarioPage />}/>
            <Route path='/consultas/estoques/listagem-estoque-inventario-produtos/:id' element={<ListagemEstoqueInventarioProdutoPage></ListagemEstoqueInventarioProdutoPage>}/>
            <Route path='/consultas/estoques/listagem-estoque-motivo-inventario' element={<ListagemEstoqueInventariMotivoPage />}/>

            {/* Formulários */}
            <Route path='/formularios-listagem' element={<FormulariosListagemPage />} />

            <Route path='/formularios-cadastro' element={<FormularioCadastroPage />} />

            <Route path='/editar-formularios/:id' element={<FormularioCadastroPage />} />

            {/* { Logistica Separação } */}
            <Route path='/logistica-separacao' element={<LogisticaSeparacaoCadastroPage />} />

            <Route path='/logistica-separacao/:id' element={<LogisticaSeparacaoCadastroPage />} />

            <Route
              path='/logistica-separacao-listagem'
              element={<LogisticaSeparacaoListagemPage />}
            />

            {/* { Logistica Endereço } */}
            <Route path='/logistica-endereco' element={<EnderecoPage />} />
            <Route path='/logistica-endereco/:id' element={<EnderecoPage />} />

            <Route path='/logistica-endereco-listagem' element={<EnderecoListagemPage />} />

            {/* Configurações e Rotinas SQL */}
            <Route
              path='/configuracoes-rotinas-listagem'
              element={<ConfiguracoesRotinasListagemPage />}
            />

            {/* Pedido Compras */}

            <Route path='/pedido-compras-listagem' element={<PedidoComprasListagemPage />} />

            <Route path='/pedido-compras' element={<PedidoComprasCadastroPage />} />

            <Route path='/pedido-compras/:id' element={<PedidoComprasCadastroPage />} />
          </>
        ) : (
          <>
            {/* Produtos */}
            <Route
              path='/produto-cadastro'
              element={renderRouteIfPermission('produtos', <CadastrarProduto />)}
            />
            <Route
              path='/produto-listagem'
              element={renderRouteIfPermission('produtos', <ListagemProduto />)}
            />
            <Route
              path='/produto-edicao/:id'
              element={renderRouteIfPermission('produtos', <CadastrarProduto />)}
            />

            <Route
              path='/produto-categorizacao'
              element={renderRouteIfPermission('produtos', <CategorizacaoProdutosCadastroPage />)}
            />
            <Route
              path='/produto-categorizacao-listagem'
              element={renderRouteIfPermission('produtos', <CategorizacaoProdutosListagemPage />)}
            />

            <Route
              path='/produto-unidade-medida'
              element={renderRouteIfPermission('produtos', <UnidadeMedidaCadastroPage />)}
            />

            <Route
              path='/produto-unidade-medida/:id'
              element={renderRouteIfPermission('produtos', <UnidadeMedidaCadastroPage />)}
            />
            <Route
              path='/produto-unidade-medida-listagem'
              element={renderRouteIfPermission('produtos', <UnidadeMedidaListagemPage />)}
            />

            {/* Logística Expedição */}
            <Route
              path='/logistica-expedicao-listagem'
              element={renderRouteIfPermission('logisticaExpedicao', <LogisticaExpedicaoPage />)}
            />

              {/* Logística Expedição */}
              <Route
              path='/logistica-expedicao-sysled-listagem'
              element={renderRouteIfPermission('logisticaExpedicao', <SysledExpedicaoPage/>)}
            />

            {/* Fluxo Logistico */}
            <Route
              path='/logistica-fluxo-listagem'
              element={renderRouteIfPermission('logisticaFluxo', <FluxoLogisticoPage />)}
            />

            <Route
              path='/logistica-fluxo-edicao/:id'
              element={renderRouteIfPermission('logisticaFluxo', <FluxoLogisticoCadastroPage />)}
            />

            <Route
              path='/logistica-fluxo-cadastro'
              element={renderRouteIfPermission('logisticaFluxo', <FluxoLogisticoCadastroPage />)}
            />

            {/* Configurações e Rotinas SQL */}
            <Route
              path='/configuracoes-rotinas-listagem'
              element={renderRouteIfPermission(
                'configRotinaSQL',
                <ConfiguracoesRotinasListagemPage />
              )}
            />

            {/* { Logistica Separação } */}
            <Route
              path='/logistica-separacao'
              element={renderRouteIfPermission(
                'logisticaExpedicao',
                <LogisticaSeparacaoCadastroPage />
              )}
            />

            <Route
              path='/logistica-separacao/:id'
              element={renderRouteIfPermission(
                'logisticaExpedicao',
                <LogisticaSeparacaoCadastroPage />
              )}
            />

            <Route
              path='/logistica-separacao-listagem'
              element={renderRouteIfPermission(
                'logisticaExpedicao',
                <LogisticaSeparacaoListagemPage />
              )}
            />

            {/* { Logistica Endereço } */}
            <Route path='/logistica-endereco' element={<EnderecoPage />} />
            <Route path='/logistica-endereco:id' element={<EnderecoPage />} />

            <Route path='/logistica-endereco-listagem' element={<EnderecoListagemPage />} />

            {/* Workflow Pendência */}
            <Route path='/workflow-pendencia-listagem' element={<WorkflowPendencia />} />

            {/* Propostas Comerciais*/}
            <Route
              path='/propostas-comerciais'
              element={renderRouteIfPermission('preVendas', <CadastrarPrevenda tipo='propostas' />)}
            />
            <Route
              path='/editar-proposta-comercial/:id'
              element={renderRouteIfPermission('preVendas', <CadastrarPrevenda tipo='propostas' />)}
            />
            <Route
              path='/listagem-propostas-comerciais'
              element={renderRouteIfPermission(
                'preVendas',
                <ListagemPropostaPedido tipo='propostas' />
              )}
            />
            {/* Pedidos */}
            <Route
              path='/pedidos'
              element={renderRouteIfPermission('preVendas', <CadastrarPrevenda tipo='pedidos' />)}
            />
            <Route
              path='/editar-pedidos/:id'
              element={renderRouteIfPermission('preVendas', <CadastrarPrevenda tipo='pedidos' />)}
            />
            <Route
              path='/listagem-pedidos'
              element={renderRouteIfPermission(
                'preVendas',
                <ListagemPropostaPedido tipo='pedidos' />
              )}
            />
            {/* Promoções */}
            <Route
              path='/promocao-cadastro'
              element={renderRouteIfPermission('encartePromocional', <CadastroPromocao />)}
            />
            <Route
              path='/promocao-listagem'
              element={renderRouteIfPermission('encartePromocional', <ListagemPromocao />)}
            />
            <Route
              path='/promocao-edicao/:id'
              element={renderRouteIfPermission('encartePromocional', <EdicaoPromocao />)}
            />
            {/* Empresas */}
            <Route
              path='/cadastro-empresas'
              element={renderRouteIfPermission('empresas', <FormCadEmpresa />)}
            />
            <Route
              path='/listagem-empresas'
              element={renderRouteIfPermission('empresas', <ListagemEmpresa />)}
            />
            <Route
              path='/editar-empresa/:id'
              element={renderRouteIfPermission('empresas', <EditarEmpresas />)}
            />
            {/* Clientes */}
            <Route
              path='/cadastro-clientes'
              element={renderRouteIfPermission('clientes', <CadastroClientes />)}
            />
            <Route
              path='/listagem-clientes'
              element={renderRouteIfPermission('clientes', <ListagemClientes />)}
            />
            <Route
              path='/editar-cliente/:id'
              element={renderRouteIfPermission('clientes', <EditarClientes />)}
            />
            {/* Usuarios */}
            <Route
              path='/cadastro-usuarios'
              element={renderRouteIfPermission('usuarios', <CadastroUsuarios />)}
            />
            <Route
              path='/listagem-usuarios'
              element={renderRouteIfPermission('usuarios', <ListagemUsuariosTable />)}
            />
            <Route
              path='/editar-usuario/:id'
              element={renderRouteIfPermission('usuarios', <EditarUsuario />)}
            />
            {/*Parceiros*/}
            <Route
              path='/cadastro-parceiros'
              element={renderRouteIfPermission('parceiros', <CadastroParceiros />)}
            />
            <Route
              path='/editar-parceiro/:id'
              element={renderRouteIfPermission('parceiros', <CadastroParceiros />)}
            />
            <Route
              path='/listagem-parceiros'
              element={renderRouteIfPermission('parceiros', <ListagemParceiros />)}
            />

            {/* Perfis */}
            <Route path='/perfis' element={renderRouteIfPermission('perfis', <CadastroPerfis />)} />
            <Route
              path='/perfis/:id'
              element={renderRouteIfPermission('perfis', <CadastroPerfis />)}
            />
            <Route path='/perfis-listagem' element={<ListagemPerfis />} />

            {/* Modules */}
            <Route
              path='relatorios/dashboards/comercial/relatorio-vendas'
              element={renderRouteIfPermission('dashboardGerencial', <RelatorioVendasWrapper />)}
            />
            <Route
              path='consultas/estoques/consulta-estoque'
              element={renderRouteIfPermission('estoqueConsulta', <ConsultaEstoqueWMS />)}
            />
              <Route path='/consultas/estoques/listagem-estoques' element={renderRouteIfPermission('estoques',<ListagemEstoquesPage></ListagemEstoquesPage>)} />
            <Route path='/consultas/estoques/listagem-estoque-tipos' element={renderRouteIfPermission('estoqueTipos',<ListagemEstoqueTiposPage />)} />
            <Route path='/consultas/estoques/listagem-estoque-localizacao' element={renderRouteIfPermission('estoqueLocalizacao',<ListagemEstoqueLocalizacaoPage />)} />
            <Route path='/consultas/estoques/listagem-estoque-tipo-localizacao' element={renderRouteIfPermission('tiposLocalizacao',<ListagemTipoLocalizacaoPage />)} />
            <Route path='/consultas/estoques/listagem-estoque-tipo-movimento' element={renderRouteIfPermission('tiposMovimento',<ListagemTipoMovimentoPage />)} />
            <Route path='/consultas/estoques/listagem-estoque-entradas' element={renderRouteIfPermission('estoqueEntrada',<ListagemEstoqueEntradasPage></ListagemEstoqueEntradasPage>)}/>
            <Route path='/consultas/estoques/listagem-estoque-entradas-produtos/:id' element={renderRouteIfPermission('estoqueEntrada',<ListagemEstoqueEntradasProdutosPage></ListagemEstoqueEntradasProdutosPage>)}/>
            <Route path='/consultas/estoques/listagem-estoque-transferencias' element={renderRouteIfPermission('estoqueTransferencia',<ListagemEstoqueTransferenciasPage></ListagemEstoqueTransferenciasPage>)}/>
            <Route path='/consultas/estoques/listagem-estoque-transferencias-produtos/:id' element={renderRouteIfPermission('estoqueTransferencia',<ListagemEstoqueTransferenciasProdutoPage/>)}/>
            <Route path='/consultas/estoques/listagem-estoque-inventario' element={renderRouteIfPermission('estoqueInventario',<ListagemEstoqueInventarioPage />)}/>
            <Route path='/consultas/estoques/listagem-estoque-inventario-produtos/:id' element={renderRouteIfPermission('estoqueInventario',<ListagemEstoqueInventarioProdutoPage></ListagemEstoqueInventarioProdutoPage>)}/>
            <Route path='/consultas/estoques/listagem-estoque-motivo-inventario' element={renderRouteIfPermission('inventarioMotivo',<ListagemEstoqueInventariMotivoPage />)}/>


            {/* Pedido Compras */}

            <Route
              path='/pedido-compras-listagem'
              element={renderRouteIfPermission('pedido_compras', <PedidoComprasListagemPage />)}
            />

            <Route
              path='/pedido-compras'
              element={renderRouteIfPermission('pedido_compras', <PedidoComprasCadastroPage />)}
            />

            <Route
              path='/pedido-compras/:id'
              element={renderRouteIfPermission('pedido_compras', <PedidoComprasCadastroPage />)}
            />

            {/* Pendência de Compra */}

            <Route
              path='/listagem-pendencia-compra'
              element={renderRouteIfPermission('pendencia_venda', <ListagemPendenciaCompra />)}
            ></Route>

            {/* Suprimento Pedido Status Controler */}
            <Route
              path='/suprimentos-cadastro'
              element={renderRouteIfPermission('pedido_compras', <CadastroSuprimentoPage />)}
            />
            <Route
              path='/suprimentos-listagem'
              element={renderRouteIfPermission('pedido_compras', <SuprimentoListagemPage />)}
            />
            <Route
              path='/editar-suprimentos/:id'
              element={renderRouteIfPermission('pedido_compras', <CadastroSuprimentoPage />)}
            />

            {/* { Logistica Endereço } */}
            <Route path='/listagem-logs' element={<LogListagemPage></LogListagemPage>} />
            <Route
              path='/logistica-endereco'
              element={renderRouteIfPermission('pedido_compras', <EnderecoPage />)}
            />

            <Route
              path='/logistica-endereco/:id'
              element={renderRouteIfPermission('pedido_compras', <EnderecoPage />)}
            />

            <Route
              path='/logistica-endereco-listagem'
              element={renderRouteIfPermission('', <EnderecoListagemPage />)}
            />

            {/* Financeiro/ Formas de Pagamento */}
            <Route
              path='/cadastro-formas-de-pagamento'
              element={renderRouteIfPermission('financeiro', <CadastroFormasdePagamento />)}
            />
            <Route
              path='/listagem-formas-de-pagamento'
              element={renderRouteIfPermission('financeiro', <ListagemFormasDePagamento />)}
            />
            <Route
              path='/cadastro-formas-de-pagamento/:id'
              element={renderRouteIfPermission('financeiro', <CadastroFormasdePagamento />)}
            />

            {/* Financeiro/ Condicoes de Pagamento */}
            <Route
              path='/cadastro-condicoes-de-pagamento'
              element={renderRouteIfPermission(
                ['financeiro', 'pagamento'],
                <CadastroCondicaoPagamento />
              )}
            />
            <Route
              path='/listagem-condicoes-de-pagamento'
              element={renderRouteIfPermission(
                ['financeiro', 'pagamento'],
                <ListagemCondicoesPagamento />
              )}
            />
            <Route
              path='/cadastro-condicoes-de-pagamento/:id'
              element={renderRouteIfPermission(
                ['financeiro', 'pagamento'],
                <CadastroCondicaoPagamento />
              )}
            />

            {/* Fornecedores */}

            <Route
              path='/cadastro-fornecedor'
              element={renderRouteIfPermission('fornecedores', <CadastroFornecedor />)}
            />
            <Route
              path='/listagem-fornecedor'
              element={renderRouteIfPermission('fornecedores', <ListagemFornecedor />)}
            />
            <Route
              path='/editar-fornecedor/:id'
              element={renderRouteIfPermission('fornecedores', <EditarFornecedor />)}
            />

            {/* Colaborador */}

            <Route
              path='/cadastro-colaborador'
              element={renderRouteIfPermission('colaboradores', <CadastroColaborador />)}
            />
            <Route
              path='/listagem-colaborador'
              element={renderRouteIfPermission('colaboradores', <ListagemColaborador />)}
            />
            <Route
              path='/editar-colaborador/:id'
              element={renderRouteIfPermission('colaboradores', <EditarColaborador />)}
            />
          </>
        )}
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  );
};

export {PrivateRoutes};
