import {ChangeEvent, useEffect, useState} from 'react';
import useBuscaCep from '../../useUtils/useBuscaCep';
import clsx from 'clsx';
import {editEndereco, sendEndereco} from '../cadastro_requests/cadastro_requests';
import {useFormik} from 'formik';
import {DescriptionComponent, FooterForm} from '../../../shared';
import enderecoSchema from '../../../shared/domain-types/validators/endereco';
import Swal from 'sweetalert2';
import {Col, Row} from 'react-bootstrap';
import {useNavigate, useParams} from 'react-router-dom';
import {getEnderecoById} from '../../listagem/listagem_requests/listagem_requests';

const CadastroEndereco = () => {
  const navigate = useNavigate();
  const {id} = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const initialValues = {
    rua: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    cep: '',
    numero: '',
    referencia: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: enderecoSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true);

      if (id) {
        try {
          await editEndereco(id, values);
          setSubmitting(false);
          setIsLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Endereço editado com sucesso!',
            showCancelButton: false,
            confirmButtonText: 'Voltar à listagem Endereços',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => {
              navigate('/logistica-endereco-listagem');
            },
          });
        } catch (error: any) {
          console.error(error);

          if (error.response) {
            const status = error.response.status;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar a requisição, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar a requisição. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              confirmButtonText: 'Ok',
            });
          }

          setSubmitting(false);
          setIsLoading(false);
        }
      } else {
        try {
          const response = await sendEndereco(values);

          if (response.status === 201) {
            setSubmitting(false);
            setIsLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Endereço cadastrado com sucesso!',
              showCancelButton: false,
              confirmButtonText: 'Voltar à listagem de Endereços ',
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate('/logistica-endereco-listagem');
              },
            });
          }
        } catch (error: any) {
          console.error(error);

          if (error.response) {
            const status = error.response.status;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar a requisição, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar a requisição. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              confirmButtonText: 'Ok',
            });
          }

          setSubmitting(false);
          setIsLoading(false);
        }
      }
    },
  });

  useEffect(() => {
    getForms();
  }, [id]);

  const getForms = async () => {
    if (id) {
      try {
        const response = await getEnderecoById(id);

        const {data} = response;

        formik.setValues({
          rua: data.rua || '',
          complemento: data.complemento || '',
          bairro: data.bairro || '',
          cidade: data.cidade || '',
          uf: data.uf || '',
          cep: data.cep || '',
          numero: data.numero || '',
          referencia: data.referencia || '',
        });
      } catch (errors) {
        console.log(errors);
      }
    }
  };

  useBuscaCep(formik.values.cep, formik);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    formik.setFieldValue(name, value);
  };

  const isDisabled = formik.values.cep.length === 9;

  return (
    <>
      <DescriptionComponent
        description={id ? 'Edição de Endereço ' : 'Cadastro de Endereço '}
        withLink={true}
        buttonTitle='Listar endereços'
        redirectTo='/logistica-endereco-listagem'
      />
      <form className='form-control-solid' onSubmit={formik.handleSubmit} noValidate>
        <Row className='mb-3 mt-2 form-inputs'>
          <Col className='col-md-4 mt-4'>
            <label className='form-label'>CEP *</label>
            <input
              placeholder='Informe o CEP'
              {...formik.getFieldProps('cep')}
              onChange={(e) => {
                const value = e.target.value.replace(/\D/g, '');
                const maskedValue =
                  value.length > 5 ? `${value.slice(0, 5)}-${value.slice(5, 8)}` : value;

                formik.setFieldValue('cep', maskedValue);

                if (maskedValue.length < 9) {
                  formik.setFieldValue('rua', '');
                  formik.setFieldValue('bairro', '');
                  formik.setFieldValue('cidade', '');
                  formik.setFieldValue('uf', '');
                }
              }}
              maxLength={9}
              className='form-control bg-transparent'
            />
            {formik.touched.cep && formik.errors.cep && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.cep}</span>
                </div>
              </div>
            )}
          </Col>
          <Col className='col-md-8 mt-4'>
            <label className='form-label'> Endereço *</label>
            <input
              type='text'
              placeholder='Logradouro'
              disabled = {isDisabled}
              {...formik.getFieldProps('rua')}
              className={`form-control ${
                formik.touched.rua && formik.errors.rua ? 'is-invalid' : ''
              }`}
            />
            {formik.touched.rua && formik.errors.rua ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.rua}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row className='mb-5 form-inputs'>
          <Col className='col-md-5 mt-4'>
            <label className='form-label'> Município *</label>
            <input
              type='text'
              placeholder='Cidade'
              disabled = {isDisabled}
              {...formik.getFieldProps('cidade')}
              className={`form-control ${
                formik.touched.cidade && formik.errors.cidade ? 'is-invalid' : ''
              }`}
            />
            {formik.touched.cidade && formik.errors.cidade ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.cidade}
              </div>
            ) : null}
          </Col>
          <Col className='col-md-5 mt-4'>
            <label className='form-label'>Bairro *</label>
            <input
              type='text'
              placeholder='Bairro'
              disabled = {isDisabled}
              {...formik.getFieldProps('bairro')}
              className={`form-control ${
                formik.touched.bairro && formik.errors.bairro ? 'is-invalid' : ''
              }`}
            />
            {formik.touched.bairro && formik.errors.bairro ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.bairro}
              </div>
            ) : null}
          </Col>
          <Col className='col-md-2 mt-4'>
            <label className='form-label'> UF *</label>
            <input
              type='text'
              placeholder='Estado'
              disabled = {isDisabled}
              {...formik.getFieldProps('uf')}
              className={`form-control ${
                formik.touched.uf && formik.errors.uf ? 'is-invalid' : ''
              }`}
            />
            {formik.touched.uf && formik.errors.uf ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.uf}
              </div>
            ) : null}
          </Col>
        </Row>
        <Row className='mb-5 form-inputs'>
          <Col className='col-md-2 mt-2'>
            <label className='form-label'>Número</label>
            <input
              type='text'
              placeholder='Número'
              {...formik.getFieldProps('numero')}
              onChange={(e) => {
                formik.setFieldValue('numero', e.target.value.replace(/[^0-9snSN]/g, ''));
              }}
              className={clsx('form-control bg-transparent', {
                'is-invalid': formik.touched.numero && formik.errors.numero,
                'is-valid': formik.touched.numero && !formik.errors.numero,
              })}
            />
             <small className='form-text text-muted'>Se não houver número, insira "SN"</small>
          </Col>
          <Col className='col-md-5 mt-2'>
            <label className='form-label'>Complemento</label>
            <input
              placeholder='Complemento'
              type='text'
              {...formik.getFieldProps('complemento')}
              onChange={handleChange}
              className='form-control bg-transparent'
            />
          </Col>
          <Col className='col-md-5 mt-2'>
            <label className='form-label'>Ponto de Referência</label>
            <input
              placeholder='Ponto de referência'
              type='text'
              {...formik.getFieldProps('referencia')}
              onChange={handleChange}
              className='form-control bg-transparent'
            />
          </Col>
        </Row>
        <FooterForm
          isLoading={isLoading}
          textActionSubmit={id ? 'Editar Endereço' : 'Cadastrar Endereço'}
          redirectTo='logistica-endereco-listagem'
        />
      </form>
    </>
  );
};

export default CadastroEndereco;
