import {useEffect, useMemo, useState} from 'react';
import {Button, ModalProps, Spinner} from 'react-bootstrap';
import {
  DescriptionComponent,
  formatarData,
  IFrete,
  IFreteDetails,
  INotaFiscal,
  Modal,
} from '../../../../../../shared';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {useParams} from 'react-router-dom';
import {
  getNotaFiscalItemDetails,
  getPedidoCompraItemDetails,
} from '../../../../../listagem/listagem_requests/listagem_requests';
import Swal from 'sweetalert2';
import {CustomRow} from '../../../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {FaTruck} from 'react-icons/fa';

type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
  selectItens?: number[];
  handleRequest?: () => void;
  idItem?: number | string;
};

const NotaFiscalDetailsModal: React.FC<Props> = ({isOpen, onHide, idItem}) => {
  const renderCellValue = (value: any) => (value ? value : '...');

  const pedidoCompraFreteColumns: Column<IFreteDetails>[] = [
    {
      Header: 'Id do Frete',
      accessor: 'idFrete',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Endereço de Origem',
      accessor: 'enderecoOrigem',
      Cell: ({value}) => (
        <div className='text-start' style={{minWidth: 200}}>
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Endereço de Destino',
      accessor: 'enderecoDestino',
      Cell: ({value}) => (
        <div className='text-start' style={{minWidth: 200}}>
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Transportadora Fantasia',
      accessor: 'transportadoraFantasia',
      Cell: ({value}) => (
        <div className='text-start' style={{minWidth: 200}}>
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Transportadora Razão Social',
      accessor: 'transportadoraRazao',
      Cell: ({value}) => (
        <div className='text-start' style={{minWidth: 200}}>
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Data Prevista',
      accessor: 'freteDataPrevisao',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Data de Saída',
      accessor: 'freteDataSaida',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Data de Entrega',
      accessor: 'freteDataEntrega',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
  ];

  const [isFreteData, setIsFreteData] = useState<IFreteDetails[]>([]);

  const data = useMemo(() => isFreteData, [isFreteData]);
  const columns = useMemo(() => pedidoCompraFreteColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const onClose = () => {
    onHide!();
  };

  const loadDetailsItem = async () => {
    if (idItem) {
      try {
        const response = await getNotaFiscalItemDetails(idItem!);

        const {data} = response;

        console.log('response: ', response);

        setIsFreteData(data ? data : []);
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }
    }
  };

  useEffect(() => {
    loadDetailsItem();
  }, [isOpen]);

  const modalConfigs: ModalProps = {
    isOpen,
    title: 'Detalhes do Item',
    actions: [],
    onHide: onClose,
    size: 'xl',
  };

  return (
    <>
      <Modal {...modalConfigs}>
        <DescriptionComponent description='Fretes Associados à Nota Fiscal' isSub icon={FaTruck} />
        <div className='table-responsive' style={{maxHeight: 350}}>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border px-4'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IFreteDetails>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IFreteDetails>, i) => {
                  prepareRow(row);
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      SEM FRETES ASSOCIADOS À NOTA FISCAL
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default NotaFiscalDetailsModal;
