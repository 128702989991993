import {Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {LayoutProvider, LayoutSplashScreen} from '../_sysled/layout/core';
import {MasterInit} from '../_sysled/layout/MasterInit';
import {AuthInit} from './modules/auth';
import {ThemeModeProvider} from '../_sysled/partials';
import './style.css';
import {ProdutoContextProvider} from './pages/cadastros/produto/context/ProdutoContext';
import {MessagesProvider} from './modules/auth/core/MessagesContextProvider';
import {SkeletonTheme} from 'react-loading-skeleton';
import {TabProvider} from './pages/listagem/produtos-categorizacao/context/CategorizacaoProdutosContext';
import {PedidoCompraProvider} from './pages/cadastros/cadastro-pedido-compras/context/pedido-compra-context';

const App = () => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <SkeletonTheme>
        <LayoutProvider>
          <ThemeModeProvider>
            <AuthInit>
              <MessagesProvider>
                <ProdutoContextProvider>
                  <TabProvider>
                    <PedidoCompraProvider>
                      <Outlet />
                      <MasterInit />
                    </PedidoCompraProvider>
                  </TabProvider>
                </ProdutoContextProvider>
              </MessagesProvider>
            </AuthInit>
          </ThemeModeProvider>
        </LayoutProvider>
      </SkeletonTheme>
    </Suspense>
  );
};

export {App};
