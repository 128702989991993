import React, {useState} from 'react';
import Divider from './Divider';
import {useProduto} from '../hooks/useProduto';
import useOptionsLinhaDeProduto from '../../../selectOptions/useOptionsLinhaDeProduto';

const Outros = () => {
  const {
    formik,
    setSelectedLinhaProduto,
    selectedLinhaProduto,
    handleSelectChange,
    handleChangeNumber,
  } = useProduto();
  const [inputs, setInputs] = useState<string[]>(['']);
  const {optionsLinhaDeProduto} = useOptionsLinhaDeProduto();

  // const addInput = () => {
  //   setInputs([...inputs, '']);
  // };

  return (
    <div className='mb-5 mt-8 '>
      <div className='mb-5 row'>
        <div className='col-md-3 mt-4'>
          <label className='form-label'>Unidade por Caixa:</label>
          <input
            className='form-control bg-transparent'
            type='number'
            placeholder='Itens por embalagem'
            {...formik.getFieldProps('uni_por_caixas')}
          />
        </div>
        <div className='col-md-3 mt-4'>
          <label className='form-label'>Linha de Produto:</label>
          <select
            defaultValue={''}
            required
            className='form-select bg-transparent'
            onChange={(e) => handleSelectChange(e, setSelectedLinhaProduto)}
            value={selectedLinhaProduto?.id}
            style={{marginTop: '0px'}}
          >
            <option value='' disabled selected>
              Selecione...
            </option>
            {optionsLinhaDeProduto.map((option) => (
              <option key={option.id} value={option.id}>
                {option.desc}
              </option>
            ))}
          </select>
        </div>
        <div className='col-sm-1'> </div>
        <div className='col-md-4 mt-4'>
          <label className='form-label'>Garantia:</label>
          <input
            className='form-control bg-transparent'
            type='text'
            placeholder='Exemplo: 1 ano'
            {...formik.getFieldProps('garantia')}
          />
        </div>
        {/* {!id && (
          <div className='mb-5 row'>
            <div className='col-md-3 mt-4'>
              <label className='form-label'>Data de criação</label>
              <input
                type='date'
                className='form-control bg-transparent'
                placeholder=''
                {...formik.getFieldProps('data_criacao')}
                readOnly
                //onChange={handleChange}
              />
            </div>
          </div>
        )} */}
        <Divider />
      </div>
      <div className=' mb-5 row'>
        <div className='mb-form-label-container' style={{display: 'flex', alignItems: 'center'}}>
          <h4 style={{color: '#3f3f3f'}}>Informações Tributárias Adicionais</h4>
        </div>
        <div className='mb-5 row'>
          <div className='col-md-3 mt-4'>
            <label className='form-label'>Unidade Tributável:</label>
            <input
              className='form-control bg-transparent'
              type='number'
              placeholder='Unidade Tributável'
              {...formik.getFieldProps('unidade_tributavel')}
            />
          </div>
        </div>
        <div className='mb-5 row'>
          <div className='col-md-3 mt-4'>
            <label className='form-label'>Código de Enquadramento IPI:</label>
            <input
              className='form-control bg-transparent'
              type='text'
              placeholder='Código de barras de comercial'
              {...formik.getFieldProps('codigo_enquadramento_ipi')}
              onChange={handleChangeNumber}
            />

            <small id='' className='form-text text-muted'>
              Código de Enquadramento Legal do IPI
            </small>
          </div>
        </div>
        <Divider />
        <div className='mb-5 row'>
          <div className='col-md-8 mt-4'>
            <label className='form-label'>Observações Gerais Sobre o Produto:</label>
            <textarea
              placeholder='Observações Gerais'
              className='form-control bg-transparent'
              {...formik.getFieldProps('obs_geral_produto')}
              maxLength={500}
              onChange={(e) => {
                const value = e.target.value;
                formik.setFieldValue('obs_geral_produto', value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Outros;
