import {useEffect, useMemo, useState} from 'react';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import {formatarData} from '../../core';
import {ProductOption, ProdutoInfo} from '../../../pages/cadastros/proposta-pedido/types/interface';
import {IProduto, ISelectOption} from '../../domain-types';
import icon_img_not_found from '../../../pages/../assets/icon_img_not_found (1).jpg';
import './async-select-produto-styles.scss';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isImg?: boolean;
  isDisabled?: boolean;
  isLoad?: boolean;
};
const AsyncSelectProductInput: React.FC<IProps> = ({
  handleOptions,
  inputOption,
  isImg = true,
  isDisabled,
  isLoad = false,
}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectedProductAsync, setSelectedProductAsync] = useState<ISelectOption | null>(null);
  const [productDetails, setProductDetails] = useState<IProduto[]>();

  const loadOptionsProdutosByName = async (inputValue: string | number) => {
    let ref = false;
    if (
      (typeof inputValue === 'string' && inputValue.trim() !== '' && !isNaN(Number(inputValue))) ||
      typeof inputValue === 'number'
    ) {
      ref = true;
    } else {
      if (inputValue.length >= 3) {
        ref = true;
      }
    }

    if (ref) {
      try {
        const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/buscaProdutoInfoPrevenda/${inputValue}`;
        const response = await axios.get(url);
        const data = await response.data;

        setProductDetails(data);

        return data.map((item) => ({
          value: item.idProduto,
          label: item,
        }));
      } catch (error) {
        console.error('Erro ao carregar opções de produtos:', error);
        return [];
      }
    }
  };

  const loadOptionsProdutosById = async (inputValue: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/buscaProdutoInfoPrevenda/${inputValue}`;
      const response = await axios.get(url);
      const data = await response.data;

      // setEstoque(data[0].estoque);
      // setMarca(data[0].marca);

      // setUnidade(data[0].unidade);
      // setTipoProduto(data[0].tipoProduto);
      // setPrecoCusto(data[0].precoCusto);

      // setSelectedProductDetails(data[0]);

      // // console.log("setSelectedProductDetails", data[0])
      // setPrecoPadrao(data[0].precoUnitario);

      // console.log('response products by id in coponente: ', data)

      setProductDetails(data);

      const selectedOption = {
        value: data[0].idProduto,
        label: data[0].idProduto + ' - ' + data[0].produto,
      };

      setSelectedProductAsync(selectedOption);

      return data.map((item) => ({
        value: item.idProduto,
        label: item.idProduto,
      }));
    } catch (error) {
      console.error('Erro ao carregar opções de produtos:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    const product = productDetails?.find((prod) => prod.idProduto === String(option.value));

    if (product) {
      const regex = /\[Estoque: (\d+)\]\s(.+?)\s\/\sR\$\s([\d,.]+)/;
      const match = product.produto.match(regex);

      if (match) {
        const estoque = match[1];
        const nomeProduto = match[2];
        const preco = match[3];

        return (
          <div className='container-message' style={{height: '125px'}}>
            <div className='box-left'>
              {isImg ? (
                <img
                  className='image-product'
                  style={{width: '115px', height: '115px'}}
                  src={
                    product.urlImagem && product.urlImagem !== 'null'
                      ? product.urlImagem
                      : icon_img_not_found
                  }
                  alt='Product Image'
                />
              ) : null}
              <div className='info-container' style={{marginLeft: '10px'}}>
                <p>
                  <span className='product-name'>
                    {' '}
                    {nomeProduto
                      ? nomeProduto.length > 80
                        ? `${nomeProduto.slice(0, 80)} ...`
                        : nomeProduto
                      : ' - '}
                  </span>
                </p>
                <p>
                  Código: <span>{product.idProduto !== 'null' ? product.idProduto : ' - '}</span>
                </p>
                <p>
                  Preço: <span>R$ {preco !== 'null' ? preco : ' - '}</span>
                </p>
                <p>
                  Marca: <span>{product.marca !== 'null' ? product.marca : ' - '}</span>
                </p>
                <p>
                  Unidade: <span>{product.unidade !== 'null' ? product.unidade : ' - '}</span>
                </p>
              </div>
            </div>
            <div className='box-right'>
              <p>
                Em Encarte: <span>{product.isEncarte === 'true' ? 'SIM' : 'NÃO'}</span>
              </p>
              <p>
                Estoque Total: <span>{estoque || ' - '}</span>
              </p>
              <p>
                Quantidade Prevista:{' '}
                <span>{product.qtdPrevisto !== 'null' ? product.qtdPrevisto : ' - '}</span>
              </p>
              <p>
                Data Prevista:{' '}
                <span>{product.dataPrevisao ? formatarData(product.dataPrevisao) : ' - '}</span>
              </p>
            </div>
          </div>
        );
      } else {
        return (
          <div className='container-message' style={{height: '125px'}}>
            <div className='box-left'>
              {isImg ? (
                <img
                  style={{width: '115px', height: '115px'}}
                  src={
                    product.urlImagem && product.urlImagem !== 'null'
                      ? product.urlImagem
                      : icon_img_not_found
                  }
                  alt='Product Image'
                />
              ) : null}
              <div className='info-container'>
                <p>
                  {' '}
                  Nome:{' '}
                  <span>
                    {product.produto
                      ? product.produto.length > 80
                        ? `${product.produto.slice(0, 80)} ...`
                        : product.produto
                      : ' - '}
                  </span>
                </p>
                <p>
                  Código: <span>{product.idProduto !== 'null' ? product.idProduto : ' - '}</span>
                </p>
                <p>
                  Preço:{' '}
                  <span>R$ {product.precoUnitario !== 'null' ? product.precoUnitario : ' - '}</span>
                </p>
                <p>
                  Marca: <span>{product.marca !== 'null' ? product.marca : ' - '}</span>
                </p>
                <p>
                  Unidade: <span>{product.unidade !== 'null' ? product.unidade : ' - '}</span>
                </p>
              </div>
            </div>
            <div className='box-right'>
              <p>
                Em Encarte: <span>{product.isEncarte === 'true' ? 'SIM' : 'NÃO'}</span>
              </p>
              <p>
                Estoque Total: <span>{product.estoqueTotal || ' - '}</span>
              </p>
              <p>
                Quantidade Prevista:{' '}
                <span>{product.qtdPrevisto !== 'null' ? product.qtdPrevisto : ' - '}</span>
              </p>
              <p>
                Data Prevista:{' '}
                <span>{product.dataPrevisao ? formatarData(product.dataPrevisao) : ' - '}</span>
              </p>
            </div>
          </div>
        );
      }
    }

    return null;
  };

  useEffect(() => {
    if (inputOption) {
      loadOptionsProdutosById(inputOption.value);
    } else {
      setSelectedProductAsync(null);
    }
  }, [inputOption]);

  return (
    <AsyncSelect
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise o produto'
      value={selectedProductAsync}
      onInputChange={(a) => {}}
      loadOptions={loadOptionsProdutosByName}
      onChange={(selectedOption: ProductOption | null) => {
        if (selectedOption) {
          setSelectedProductAsync(selectedOption);
          handleOptions?.(selectedOption);
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
    />
  );
};

export default AsyncSelectProductInput;
