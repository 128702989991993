export const validarCep = async (cep) => {
    const cepFormatado = cep.replace(/[^\d]/g, '');
    if (cepFormatado.length !== 8) {
      return false; 
    }
  
    try {
      const response = await fetch(`https://viacep.com.br/ws/${cepFormatado}/json/`);
      const data = await response.json();
  
      if (response.status === 200 && !data.erro) {
        return true; 
      }
    } catch (error) {
      console.error('Erro ao validar o CEP:', error);
    }
  
    return false; 
  };
  