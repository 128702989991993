import * as Yup from 'yup';

export const cadastroCategorizacaoValidationSchema = Yup.object().shape({
  titulo: Yup.string()
    .min(3, 'O titulo deve conter pelo menos 3 caracteres')
    .max(255, 'O nome do arquivo deve conter no máximo 255 caracteres')
    .required('Por favor, informe um titulo'),
  status: Yup.string().required('Por favor, adicione um status'),
  modelo: Yup.number().required('Por favor, informe uma descrição'),
});

export const cadastroUnidadeMedidaValidationSchema = Yup.object().shape({
  titulo: Yup.string()
    .max(255, 'O nome do arquivo deve conter no máximo 255 caracteres')
    .required('Por favor, informe um titulo'),
  status: Yup.string().required('Por favor, adicione um status'),
  descricao: Yup.string().max(255, 'A descrição deve conter no máximo 255 caracteres'),
});

export const codigoBarrasValidatorSchema = Yup.object().shape({
    codigo: Yup.string()
    .max(255, 'O código EAN deve conter no máximo 255 caracteres')
    .required('Por favor, informe o código EAN'),
    codProdFornecedor: Yup.string()
    .max(255, 'O código Fornecedor deve conter no máximo 255 caracteres'),
    status: Yup.string().required('Por favor, informe o status do código EAN'),
});


export const produtoSimilarValidatorSchema = Yup.object().shape({
  observacao: Yup.string()
  .max(255, 'A descricao deve conter no máximo 255 caracteres'),
  status: Yup.string().required('Por favor, informe o status do código EAN'),
});

