import { useContext } from 'react';
import { CategorizacaoProdutosContext } from '../context/CategorizacaoProdutosContext';

export const useTabContext = () => {
  const context = useContext(CategorizacaoProdutosContext);

  if (context === undefined) {
    throw new Error('useTabContext deve ser usado dentro de um TabProvider');
  }

  return context;
};
