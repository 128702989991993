import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {
  ICondicoesPagamento,
  IFormaDePagamento,
  IMeiosPagamento,
  ISelectOption,
} from '../../../../../shared';
import {useEffect, useMemo, useState} from 'react';
import {CustomRow} from '../../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {Alert, Col, OverlayTrigger, Row as RowForm, Spinner, Tooltip} from 'react-bootstrap';
import AsyncSelect from 'react-select/async';
import axios from 'axios';
import Swal from 'sweetalert2';

import * as S from './table-formas-pagamento-styles';
import {getPagamentosByIdPedidoCompra} from '../../../../listagem/listagem_requests/listagem_requests';
import {useParams} from 'react-router-dom';
import {sendPedidoCompraPagamentos} from '../../../cadastro_requests/cadastro_requests';

export const TableFormasPagamento: React.FC = () => {
  const listagemMeiosPagamento: Column<IMeiosPagamento>[] = [
    {
      Header: 'Forma de Pagamento',
      accessor: 'formaPagamento',
      Cell: ({value}) => <div className='text-start'>{value}</div>,
    },
    {
      Header: 'Condição de Pagamento',
      accessor: 'condicaoPagamento',
      Cell: ({value}) => (
        <div style={{marginLeft: '5px'}} className='text-start'>
          {value}
        </div>
      ),
    },
    {
      Header: 'Valor a Pagar',
      accessor: 'valorRecebido',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{value ? value : '...'}</div>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <div style={{textAlign: 'left', marginRight: 20, marginLeft: 0}}>
          <button
            type='button'
            onClick={() => handleOnRemove(row.original.id!)}
            className='btn btn-danger btn-sm bi bi-x'
          />
        </div>
      ),
    },
  ];


  const [isMeioPagamentoData, setIsMeioPagamentoData] = useState<IMeiosPagamento[]>([]);
  const [isFormaPagamento, setIsFormaPagamento] = useState<ISelectOption | null>(null);
  const [isCondicaoPagamento, setIsCondicaoPagamento] = useState<ISelectOption | null>(null);
  const [isStatus, setIsStatus] = useState<string>('ATIVO');
  
  const data = useMemo(() => isMeioPagamentoData, [isMeioPagamentoData]);
  const columns = useMemo(() => listagemMeiosPagamento, []);

  const {id} = useParams();

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [valorRecebido, setValorRecebido] = useState<number>(0); // Armazena como inteiro (em centavos)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;

    // Remove caracteres não numéricos
    inputValue = inputValue.replace(/\D/g, '');

    // Converte o valor para inteiro (centavos)
    const valorEmCentavos = parseInt(inputValue, 10) || 0;

    // Atualiza o estado com o valor numérico em centavos
    setValorRecebido(valorEmCentavos);
  };

  // Formata o valor para exibição no formato BRL
  const valorFormatado = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  }).format(valorRecebido / 100);

  const handleOnRemove = (id: string | number) => {
    setIsMeioPagamentoData((prev) => prev.filter((item) => item.id !== id));
  };

  const handleOnAdd = () => {
    if (isFormaPagamento && isCondicaoPagamento) {
      const isDuplicated = isMeioPagamentoData.some(
        (item) => item.formaPagamento === isFormaPagamento.label
      );

      if (isDuplicated) {
        const Toast = Swal.mixin({
          toast: true,
          position: 'center',
          showConfirmButton: false,
          timer: 2000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: 'info',
          title: 'A forma de pagamento já está em uso',
        });
        return;
      }

      // Cria um novo objeto baseado nos estados atuais
      const novoMeioPagamento: IMeiosPagamento = {
        id: new Date().getTime(), // Gera um ID único (ou use uma lógica mais apropriada)
        idPedidoCompra: '',
        idFormaPagamento: isFormaPagamento.value,
        idCondicaoPagamento: isCondicaoPagamento.value,
        status: isStatus,
        valorRecebido: valorRecebido / 100,
        condicaoPagamento: isCondicaoPagamento.label,
        formaPagamento: isFormaPagamento.label,
      };

      // Adiciona o novo objeto à tabela
      setIsMeioPagamentoData((prev) => [...prev, novoMeioPagamento]);

      // Limpa os estados do formulário após adicionar
      setIsFormaPagamento(null);
      setIsCondicaoPagamento(null);
      setValorRecebido(0)
      setIsStatus('ATIVO');
    } else {
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'info',
        title: !isFormaPagamento
          ? 'A inserção da forma de pagamento é obrigatória'
          : 'A iserção da condição de pagamento é obrigatória',
      });
    }
  };

  const loadFormaPagamento = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formaspagamento/buscaFormasPagamento`;

      const response = await axios.get(url);
      const {data} = response;

      // Mapeia os dados recebidos para o formato de options
      const options = data.map((item: IFormaDePagamento) => ({
        value: Number(item.id),
        label: item.formaPagamento,
      }));

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadCondicoesPagamento = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/condicoespagamento/buscaCondicoesPagamento`;

      const response = await axios.get(url);
      const {data} = response;

      // Mapeia os dados recebidos para o formato de options
      const options = data.map((item: ICondicoesPagamento) => ({
        value: Number(item.id),
        label: item.condicaoPagamento,
      }));

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const submitMeiosPagamento = async () => {
    setIsLoading(true);

    try {
      const payload = isMeioPagamentoData.map((item: any) => ({
        idPedidoCompra: id,
        idFormaPagamento: item.idFormaPagamento || 0,
        idCondicaoPagamento: item.idCondicaoPagamento || 0,
        status: item.status || 'INATIVO',
        valorRecebido: item.valorRecebido

      }));

      await sendPedidoCompraPagamentos(id!, payload);

      Swal.fire({
        icon: 'success',
        title: 'Forma (s) de pagamento salvas com sucesso!',
        showCancelButton: false,
        timer: 3000,
        timerProgressBar: false,
      });

      loadMeiosPagamento();

      setIsMeioPagamentoData([]);

      setIsLoading(false);
    } catch (errors: any) {
      const {error} = errors;

      if (error.response) {
        const status = error.response.status;

        const {data} = errors.response;

        if (status === 409) {
          Swal.fire({
            icon: 'error',
            title:
              'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
            confirmButtonText: 'Ok',
          });
        } else if (status === 401) {
          Swal.fire({
            icon: 'info',
            title: 'Por questões de segurança, por favor faça login novamente',
            confirmButtonText: 'Ok',
          }).then(() => {
            window.open('/auth', '_blank');
          });
        }

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }

      setIsLoading(false);
    }
  };

  const loadMeiosPagamento = async () => {
    try {
      let response = await getPagamentosByIdPedidoCompra(id!);

      const {content} = response.data;

      setIsMeioPagamentoData(content ? content : []);
    } catch (errors: any) {
      const {data, status} = errors.response;

      if (status === 409) {
        Swal.fire({
          icon: 'error',
          title: 'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
          confirmButtonText: 'Ok',
        });
      } else if (status === 401) {
        Swal.fire({
          icon: 'info',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        }).then(() => {
          window.open('/auth', '_blank');
        });
      }

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
    }
  };

  useEffect(() => {
    if (id) {
      loadMeiosPagamento();
    }
  }, []);

  return (
    <>
      <S.MeiosPagamentoContainer>
        <h2 className='card-label fs-3'>Inserção de Meios de Pagamento</h2>

        <form>
          <RowForm>
            <Col md='4' className='col-12 mt-4'>
              <label htmlFor='' className='form-label'>
                Selecione a Forma de Pagamento:
              </label>
              <AsyncSelect
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  option: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Selecione a Forma de Pagamento'
                loadOptions={loadFormaPagamento}
                isClearable
                value={isFormaPagamento}
                onChange={(selectedOption: ISelectOption | null) => {
                  if (selectedOption) {
                    setIsFormaPagamento(selectedOption);
                  } else {
                    setIsFormaPagamento(null);
                  }
                }}
                cacheOptions
                defaultOptions
              />
            </Col>
            <Col md='4' className='col-12 mt-4'>
              <label htmlFor='' className='form-label'>
                Selecione a Condição de Pagamento:
              </label>
              <AsyncSelect
                styles={{
                  menu: (provided) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    zIndex: 9999,
                  }),
                }}
                className='react-select-styled react-select-solid'
                classNamePrefix='react-select'
                placeholder='Selecione a Condição de Pagamento'
                isClearable
                loadOptions={loadCondicoesPagamento}
                value={isCondicaoPagamento}
                onChange={(selectedOption: ISelectOption | null) => {
                  if (selectedOption) {
                    setIsCondicaoPagamento(selectedOption);
                  } else {
                    setIsCondicaoPagamento(null);
                  }
                }}
                cacheOptions
                defaultOptions
              />
            </Col>

            <Col md='3' className='col-12 mt-4'>
              <label className='form-label'>Valor a Pagar:</label>
              <input
                type='text'
                placeholder='Digite o valor do meio de pagamento'
                value={valorFormatado}
                className='form-control'
                onChange={handleChange}
              />
            </Col>

            <Col md='1' className='col-12 d-flex align-items-end justify-content-start mt-4'>
              <button
                type='button'
                className='btn btn-success bi bi-plus-lg'
                onClick={handleOnAdd}
              />
            </Col>
          </RowForm>
        </form>

        {isMeioPagamentoData.length > 0 && (
          <Alert variant='warning' className='mt-4'>
            Obs: Ao incluir ou excluir um meio de pagamento, lembre-se de salvar novamente os meios de
            pagamento para garantir que as alterações sejam aplicadas corretamente
          </Alert>
        )}

        <table
          style={{height: '50px', border: '2px solid yellow'}}
          id='kt_table_formularios'
          className='table table-hover table-striped table-rounded table-row-bordered border px-5'
          {...getTableProps()}
        >
          <thead className='thead-dark'>
            <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<IMeiosPagamento>, index: number) => (
                <th key={column.id}>{column.render('Header')}</th>
              ))}
            </tr>
          </thead>

          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<IMeiosPagamento>, i) => {
                prepareRow(row);
                return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
              })
            ) : (
              <tr>
                <td colSpan={4}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    <p> Sem meios de pagamento </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        <S.FooterContainer>
          <button className='btn btn-success' onClick={() => submitMeiosPagamento()}>
            {isLoading ? <Spinner animation='border' size='sm' /> : 'Salvar Meio (s) de Pagamento'}
          </button>
        </S.FooterContainer>
      </S.MeiosPagamentoContainer>
    </>
  );
};

export default TableFormasPagamento;
