import React, {useState, useEffect} from 'react';
import icon_img_not_found from '../../../pages/../../assets/icon_img_not_found (1).jpg';
const REACT_APP_IMG_URL = process.env.REACT_APP_IMG_URL;

function checkImageExists(url) {
  return new Promise((resolve) => {
    const img = new Image();
    img.src = url;
    img.onload = () => resolve(true);
    img.onerror = () => resolve(false);
  });
}

const AvatarConsultor = ({idFuncionario, funcionario, style = {}}) => {
  const [imageExists, setImageExists] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const consultorId = idFuncionario;
    const imgUrl = `${REACT_APP_IMG_URL}/avatars/${consultorId}.jpg`;

    checkImageExists(imgUrl).then((exists) => {
      setImageExists(exists as boolean);
      setLoading(false);
    });
  }, [idFuncionario]);

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <img
      src={
        imageExists
          ? `${REACT_APP_IMG_URL}/avatars/${idFuncionario}.jpg`
          : `${icon_img_not_found}`
      }
      title={funcionario}
      alt='Avatar Consultor'
      style={{width: '50px', height: '50px', ...style}}
    />
  );
};

export default AvatarConsultor;
