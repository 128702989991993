import {useNavigate, useParams} from 'react-router-dom';
import {
  AsyncSelectEmpresa,
  AsyncSelectFornecedor,
  DescriptionComponent,
  FooterForm,
  ICondicoesPagamento,
  IFormaDePagamento,
  IMarca,
  ISelectOption,
  ISuprimentoStatus,
  pedidoCompraValidationSchema,
  smoothScrollToBottom,
} from '../../../shared';
import {useFormik} from 'formik';
import {useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import {editPedidoCompra, sendPedidoCompra} from '../cadastro_requests/cadastro_requests';
import {Col, Row} from 'react-bootstrap';
import {DatePicker} from 'rsuite';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {
  getPedidoCompraById,
  getSumarizacao,
} from '../../listagem/listagem_requests/listagem_requests';
import PedidoCompraDetails from './components/pedido-compra-details/pedido-de-compra-details';
import TableFormasPagamento from './components/table-formas-pagamento/table-formas-pagamento';
import * as S from './cadastro-pedido-compras-styles';

const PedidoComprasCadastroPage: React.FC = () => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const {id} = useParams();

  const navigate = useNavigate();

  const initialValues = {
    valorPedido: 0,
    volumeTotal: 0,
    pedidoFornecedor: '',
    acordoPercentual: 0,
    status: 'ATIVO',
    observacoes: '',
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSelectAsyncEmpresa, setIsSelectAsyncEmpresa] = useState<ISelectOption | null>(null);
  const [isSelectAsyncEmpresaOrigem, setIsSelectAsyncEmpresaOrigem] =
    useState<ISelectOption | null>(null);
  const [isSelectAsyncEmpresaFaturamento, setIsSelectAsyncEmpresaFaturamento] =
    useState<ISelectOption | null>(null);

  const [isStatusSuprimento, setIsStatusSuprimento] = useState<ISelectOption | null>(null);

  const [isDataEmissao, setIsDataEmissao] = useState<Date | null>(null);
  const [isDataCancelamento, setIsDataCancelamento] = useState<Date | null>(null);
  const [isDataLiberacao, setIsDataLiberacao] = useState<Date | null>(null);
  const [isDataFaturamento, setIsDataFaturamento] = useState<Date | null>(null);

  const handleAsyncSelectEmpresa = (option: ISelectOption) => {
    setIsSelectAsyncEmpresa(option);
  };

  const handleAsyncSelectEmpresaOrigem = (option: ISelectOption) => {
    setIsSelectAsyncEmpresaOrigem(option);
  };

  const handleAsyncEmpresaFaturamento = (option: ISelectOption) => {
    setIsSelectAsyncEmpresaFaturamento(option);
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setSubmitting}) => {
      setIsLoading(true);
      if (id) {
        try {
          if (
            !isSelectAsyncEmpresa ||
            !isSelectAsyncEmpresaOrigem ||
            !isSelectAsyncEmpresaFaturamento ||
            !isSelectAsyncFornecedor
          ) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'center',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            let title;
            switch (true) {
              case !isSelectAsyncEmpresa:
                title = 'A inserção da Empresa é obrigatória';
                break;
              case !isSelectAsyncEmpresaOrigem:
                title = 'A inserção da Empresa Origem é obrigatória';
                break;
              case !isSelectAsyncEmpresaFaturamento:
                title = 'A inserção da Empresa Faturamento é obrigatória';
                break;

              case !isSelectAsyncFornecedor:
                title = 'A Inserção do Fornecedor é obrigatória';
                break;

              default:
                title = 'Erro desconhecido';
            }

            Toast.fire({
              icon: 'info',
              title,
            });

            setSubmitting(false);
            setIsLoading(false);
            return;
          }
          await editPedidoCompra(id, {
            ...values,
            dataCancelamento: isDataCancelamento,
            dataFaturamento: isDataFaturamento,
            dataLiberacao: isDataLiberacao,
            idEmpresa: isSelectAsyncEmpresa?.value,
            idEmpresaOrigem: isSelectAsyncEmpresaOrigem?.value,
            idEmpresaFaturamento: isSelectAsyncEmpresaFaturamento?.value,
            idFornecedor: isSelectAsyncFornecedor?.value,
            idStatus: isStatusSuprimento?.value,
          });
          Swal.fire({
            icon: 'success',
            title: 'Pedido de compra editado com sucesso!',
            showCancelButton: false,
            confirmButtonText: 'Voltar à listagem de pedido de compra',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => {
              navigate('/pedido-compras-listagem');
            },
          });
          setSubmitting(false);
          setIsLoading(false);
        } catch (errors: any) {
          const {data} = errors.response;

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoading(false);
        }
      } else {
        try {
          if (
            !isSelectAsyncEmpresa ||
            !isSelectAsyncEmpresaOrigem ||
            !isSelectAsyncEmpresaFaturamento ||
            !isSelectAsyncFornecedor
          ) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'center',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            let title;
            switch (true) {
              case !isSelectAsyncEmpresa:
                title = 'A inserção da Empresa é obrigatória';
                break;
              case !isSelectAsyncEmpresaOrigem:
                title = 'A inserção da Empresa Origem é obrigatória';
                break;
              case !isSelectAsyncEmpresaFaturamento:
                title = 'A inserção da Empresa Faturamento é obrigatória';
                break;

              case !isSelectAsyncFornecedor:
                title = 'A Inserção do Fornecedor é obrigatória';
                break;

              default:
                title = 'Erro desconhecido';
            }

            Toast.fire({
              icon: 'info',
              title,
            });

            setSubmitting(false);
            setIsLoading(false);
            return;
          }
          const response = await sendPedidoCompra({
            ...values,
            dataCancelamento: isDataCancelamento,
            dataLiberacao: isDataLiberacao,
            dataFaturamento: isDataFaturamento,
            idEmpresa: isSelectAsyncEmpresa?.value,
            idEmpresaOrigem: isSelectAsyncEmpresaOrigem?.value,
            idEmpresaFaturamento: isSelectAsyncEmpresaFaturamento?.value,
            idFornecedor: isSelectAsyncFornecedor?.value,
            idStatus: isStatusSuprimento?.value,
          });

          const {data} = response;

          if (response.status === 201) {
            setSubmitting(false);
            setIsLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Pedido de compra cadastrado com sucesso!',
              showCancelButton: false,
              confirmButtonText: 'Ir para o pedido de compra',
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate(`/pedido-compras/${data.id}`);
              },
            });
          }
        } catch (errors: any) {
          const {data} = errors.response;

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoading(false);
        }
      }
    },
  });

  const loadSuprimentoStatus = async () => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-status/listar`;

      const response = await axios.get(url);
      const {content} = response.data;

      // Mapeia os dados recebidos para o formato de options
      const options = content.map((item: ISuprimentoStatus) => ({
        value: item.id,
        label: `${item.titulo} ${item.descricao ? ` - ${item.descricao}` : ''}`,
      }));

      return options;
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadSuprimentoStatusById = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-status/${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setIsStatusSuprimento({
        value: data.id,
        label: `${data.titulo} ${data.descricao ? ` - ${data.descricao}` : ''}`,
      });

      return [
        {
          value: data.id,
          label: `${data.id} - ${data.descricao}`,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const getPedidoCompraDataById = async () => {
    if (id) {
      try {
        const response = await getPedidoCompraById(id);

        const {data} = response;

        formik.setValues({
          volumeTotal: data.volumeTotal || '',
          valorPedido: data.valorPedido || '',
          pedidoFornecedor: data.pedidoFornecedor || '',
          acordoPercentual: data.acordoPercentual || '',
          status: data.status,
          observacoes: data.observacoes,
        });

        if (data.idStatus) {
          loadSuprimentoStatusById(data.idStatus);
        }

        setIsSelectAsyncFornecedor({
          label: '',
          value: data.idFornecedor,
        });

        setIsSelectAsyncEmpresa({
          label: '',
          value: data.idEmpresa,
        });
        setIsSelectAsyncEmpresaOrigem({
          label: '',
          value: data.idEmpresaOrigem,
        });
        setIsSelectAsyncEmpresaFaturamento({
          label: '',
          value: data.idEmpresaFaturamento,
        });
        setIsDataEmissao(data.dataEmissaoPedido ? new Date(data.dataEmissaoPedido) : null);
        setIsDataCancelamento(data.dataCancelamento ? new Date(data.dataCancelamento) : null);
        setIsDataLiberacao(data.dataLiberacao ? new Date(data.dataLiberacao) : null);
        setIsDataFaturamento(data.dataFaturamento ? new Date(data.dataFaturamento) : null);

        console.log(response);
      } catch (errors) {
        console.log(errors);
      }
    }
  };

  const getSumarizacaoData = async () => {
    try {
      const response = await getSumarizacao(id!);

      const {data} = response;

      formik.setFieldValue('valorPedido', data.valorPedido);
      formik.setFieldValue('volumeTotal', data.volumeTotal);
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
    }
  };

  const handleTapPedidoCompra = () => {
    getSumarizacaoData();
  };

  useEffect(() => {
    getPedidoCompraDataById();

    if (id) {
      setTimeout(() => {
        smoothScrollToBottom(700);
      }, 300);
    }
  }, [id]);

  const [isSelectAsyncFornecedor, setIsSelectAsyncFornecedor] = useState<ISelectOption | null>(
    null
  );

  const handleAsyncSelectFornecedor = (option: ISelectOption) => {
    setIsSelectAsyncFornecedor(option);
  };

  return (
    <>
      {id && (
        <S.ContainerBadge>
          <S.BadgeEnviroment>
            <p>
              Número do Pedido de Compra: <span>{id}</span>
            </p>
          </S.BadgeEnviroment>
        </S.ContainerBadge>
      )}

      <DescriptionComponent
        description={id ? 'Edição de Pedido de Compra' : 'Cadastro de Pedido de Compra'}
        withLink={true}
        buttonTitle='Listar Pedido de Compras'
        redirectTo='/pedido-compras-listagem'
      />

      <form className='form-control-solid' onSubmit={formik.handleSubmit} noValidate>
        <Row>
          <Col className='col-sm-12'>
            <label htmlFor='' className='form-label'>
              Empresa Estoque:
            </label>

            <AsyncSelectEmpresa
              handleOptions={(option) => handleAsyncSelectEmpresa(option!)}
              inputOption={isSelectAsyncEmpresa}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setIsSelectAsyncEmpresa(null);
                }
              }}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col className='col-sm-6'>
            <label htmlFor='' className='form-label'>
              Empresa de Solicitante:
            </label>
            <AsyncSelectEmpresa
              handleOptions={(option) => handleAsyncSelectEmpresaOrigem(option!)}
              inputOption={isSelectAsyncEmpresaOrigem}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setIsSelectAsyncEmpresaOrigem(null);
                }
              }}
            />
          </Col>
          <Col className='col-sm-6'>
            <label htmlFor='' className='form-label'>
              Empresa Faturamento:
            </label>

            <AsyncSelectEmpresa
              handleOptions={(option) => handleAsyncEmpresaFaturamento(option!)}
              inputOption={isSelectAsyncEmpresaFaturamento}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setIsSelectAsyncEmpresaFaturamento(null);
                }
              }}
            />
          </Col>
        </Row>
        <Row className='mt-6'>
          <Col className='col-sm-3'>
            <label className='form-label'>Código do Pedido do Fornecedor:</label>
            <input
              type='text'
              className='form-control'
              placeholder='Digite o Código do Pedido do Fornecedor'
              {...formik.getFieldProps('pedidoFornecedor')}
            />
          </Col>
          <Col className='col-3'>
            <label className='form-label'>Acordo Percentual (%):</label>
            <input
              type='number'
              className='form-control'
              placeholder='Digite o Código do Pedido do Fornecedor'
              {...formik.getFieldProps('acordoPercentual')}
            />
          </Col>
          <Col className='col-sm-3'>
            <label className='form-label'>Volume Total:</label>
            <input
              type='number'
              readOnly
              disabled
              placeholder='Digite o Volume Total'
              {...formik.getFieldProps('volumeTotal')}
              className={`form-control`}
            />
          
            <small id='' className='form-text text-muted'>
              Campo somente de leitura
            </small>
          </Col>
          <Col className='col-sm-3'>
            <label className='form-label'>Valor do Pedido:</label>
            <input
              type='text'
              placeholder='Valor do Pedido'
              readOnly
              disabled
              value={new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(formik.values.valorPedido || 0)}
              className={`form-control`}
              onChange={(e) => {
                let inputValue = e.target.value;

                // Remove caracteres não numéricos
                inputValue = inputValue.replace(/\D/g, '');

                if (inputValue === '') {
                  inputValue = '0,00';
                }

                // Converte o valor em número e atualiza o Formik com o valor numérico
                formik.setFieldValue('valorPedido', parseFloat(inputValue) / 100);
              }}
            />
            <small id='' className='form-text text-muted'>
              Campo somente de leitura
            </small>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col className='col-sm-3'>
            <label htmlFor='' className='form-label'>
              Data de Emissão de Pedido:
            </label>

            <DatePicker
              style={{filter: 'brightness(0.95)'}}
              format='dd-MM-yyyy'
              placeholder='Data de emissão'
              readOnly
              className=' w-100'
              onChange={(date) => setIsDataEmissao(date)}
              value={isDataEmissao}
            />

            <small id='' className='form-text text-muted'>
              Campo somente de leitura
            </small>
          </Col>
          <Col className='col-sm-3'>
            <label htmlFor='' className='form-label'>
              Data de Cancelamento:
            </label>

            <DatePicker
              format='dd-MM-yyyy'
              className='w-100'
              placeholder='Data cancelamento'
              onChange={(date) => setIsDataCancelamento(date)}
              value={isDataCancelamento}
            />
          </Col>
          <Col className='col-sm-3'>
            <label htmlFor='' className='form-label'>
              Data de Liberação:
            </label>

            <DatePicker
              className='w-100'
              format='dd-MM-yyyy'
              placeholder='Data Liberação'
              onChange={(date) => setIsDataLiberacao(date)}
              value={isDataLiberacao}
            />
          </Col>
          <Col className='col-sm-3'>
            <label htmlFor='' className='form-label'>
              Data de Faturamento:
            </label>

            <DatePicker
              className='w-100'
              format='dd-MM-yyyy'
              placeholder='Data de Faturamento'
              onChange={(date) => setIsDataFaturamento(date)}
              value={isDataFaturamento}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col md='6' className='col-12'>
            <label htmlFor='' className='form-label'>
              Selecione o Status:
            </label>
            <AsyncSelect
              styles={{
                menu: (provided) => ({
                  ...provided,
                  zIndex: 9999,
                }),
                option: (provided, state) => ({
                  ...provided,
                  zIndex: 9999,
                }),
              }}
              className='react-select-styled react-select-solid'
              classNamePrefix='react-select'
              placeholder='Selecione o Status'
              loadOptions={loadSuprimentoStatus}
              value={isStatusSuprimento}
              onChange={(selectedOption: ISelectOption | null) => {
                // Verifica se selectedOption não é null antes de definir o estado
                if (selectedOption) {
                  setIsStatusSuprimento(selectedOption);
                } else {
                  setIsStatusSuprimento(null); // Define como null caso a opção seja anulada
                }
              }}
              cacheOptions
              defaultOptions
            />
          </Col>
          <Col md='6' className='col-12'>
            <label className='form-label'>Status ATIVO/INATIVO:</label>
            <select
              {...formik.getFieldProps('status')}
              className={`form-control ${
                formik.touched.status && formik.errors.status ? 'is-invalid' : ''
              }`}
            >
              <option value='ATIVO'>ATIVO</option>
              <option value='INATIVO'>INATIVO</option>
            </select>
            <small id='' className='form-text text-muted'>
              Ative ou Desative o Pedido de Compra
            </small>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col md='12' className='col-12'>
            <label htmlFor='' className='form-label'>
              Selecione o Fornecedor:
            </label>

            <AsyncSelectFornecedor
              handleOptions={(option) => handleAsyncSelectFornecedor(option!)}
              inputOption={isSelectAsyncFornecedor}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setIsSelectAsyncFornecedor(null);
                }
              }}
            />
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col className='col-12'>
            <label className='form-label'>Observação:</label>
            <textarea
              style={{ height: '100px' }}
              placeholder='Digite uma Observação'
              {...formik.getFieldProps('observacoes')}
              className={`form-control`}
            />
          </Col>
        </Row>

        <FooterForm
          isLoading={isLoading}
          textActionSubmit={id ? 'Editar Pedido de Compra' : 'Cadastrar Pedido de Compra'}
          redirectTo='pedido-compras-listagem'
        />
      </form>

      {id && <TableFormasPagamento />}

      {id ? <PedidoCompraDetails handleTapAction={() => handleTapPedidoCompra()} /> : <></>}
    </>
  );
};

export default PedidoComprasCadastroPage;
