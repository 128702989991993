import {useEffect, useState} from 'react';
import axios from 'axios';
import AsyncSelect from 'react-select/async';
import {IPrevenda, ISelectOption} from '../../domain-types';
import './async-select-prevenda-styles.scss';

type IProps = {
  handleOptions?: (product: ISelectOption | null) => void;
  inputOption?: ISelectOption | null;
  isDisabled?: boolean;
  onKeyDown?: (e: React.KeyboardEvent) => void;
};

const AsyncSelectPrevenda: React.FC<IProps> = ({handleOptions, inputOption, isDisabled, onKeyDown}) => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [selectedPrevendaAsync, setSelectPrevendaAsync] = useState<ISelectOption | null>(null);
  const [prevendaDetails, setPrevendasDetails] = useState<IPrevenda[]>();

  const loadOptionsPrevenda = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/buscar/pedido/${id}?isPedido=${true}`;

      const response = await axios.get(url);
      const data = await response.data;

      setPrevendasDetails([data]);

      return [
        {
          value: data.id,
          label: `${data.nomeCliente} - ${data.nomeConsultor}`,
        },
      ];
    } catch (error) {
      console.error('Erro ao carregar opções de consultor:', error);
      return [];
    }
  };

  const formatOptionLabel = (option: ISelectOption) => {
    const prevenda = prevendaDetails?.find((prevenda) => prevenda.id === Number(option.value));

    if (prevenda) {
      return (
        <div className='container-message-prevenda'>
          <div className='box-left-prevenda'>
            <div className='info-container-prevenda'>
              <p>
                ID: <span>{prevenda.id ? prevenda.id : '-'}</span>
              </p>
              <p>
                ID G2:
                <span>
                  <span> {prevenda.idPrevendag2 ? prevenda.idPrevendag2 : '-'} </span>
                </span>
              </p>
              <p>
                Cliente:{' '}
                <span>
                  {prevenda.nomeCliente
                    ? prevenda.nomeCliente.length > 300
                      ? `${prevenda.nomeCliente.slice(0, 300)}...`
                      : prevenda.nomeCliente
                    : '-'}
                </span>
              </p>
            </div>
          </div>
          <div className='box-right-prevenda'>
            <p>
              {' '}
              Ambientado: <span> {prevenda.isAmbientado ? 'SIM' : 'NÃO'} </span>{' '}
            </p>
            <p>
              {' '}
              Frete: <span> {prevenda.fretestatus ? 'SIM' : 'NÃO'} </span>{' '}
            </p>
            <p>
              {' '}
              Telefone do cliente:{' '}
              <span>
                {prevenda.produtosPrevenda.length > 0 &&
                prevenda.produtosPrevenda[0].telefoneCliente
                  ? prevenda.produtosPrevenda[0].telefoneCliente
                  : '-'}
              </span>
            </p>
          </div>
        </div>
      );
    }

    return null;
  };

  useEffect(() => {
    if (selectedPrevendaAsync && handleOptions) {
      handleOptions(selectedPrevendaAsync);
    }
  }, [selectedPrevendaAsync]);

  useEffect(() => {
    if (inputOption) {
      loadOptionsPrevenda(inputOption.value);
      setSelectPrevendaAsync(inputOption);
    } else if (inputOption == null) {
      setSelectPrevendaAsync(null);
    }

  }, [inputOption]);

  return (
    <AsyncSelect
      className='react-select-styled react-select-solid'
      classNamePrefix='react-select'
      placeholder='Pesquise o Pedido'
      loadOptions={loadOptionsPrevenda}
      value={selectedPrevendaAsync}
      onChange={(selectedOption: ISelectOption | null) => {
        if (selectedOption) {
          setSelectPrevendaAsync(selectedOption);
        }
      }}
      formatOptionLabel={formatOptionLabel}
      isDisabled={isDisabled}
      onKeyDown={onKeyDown}
    />
  );
};

export default AsyncSelectPrevenda;
