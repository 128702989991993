import {useEffect, useMemo, useState} from 'react';
import {
  AsyncSelectEndereco,
  AsyncSelectFornecedor,
  DescriptionComponent,
  FooterForm,
  formatarData,
  formatDateTimeWithHours,
  IFrete,
  ISelectOption,
  useModal,
} from '../../../../../shared';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import Swal from 'sweetalert2';
import {useFormik} from 'formik';
import {
  editPedidoCompraFrete,
  sendPedidoCompraFrete,
} from '../../../cadastro_requests/cadastro_requests';
import {useParams} from 'react-router-dom';
import {
  getFretesByPedidoCompraId,
  getPedidoCompraFreteById,
} from '../../../../listagem/listagem_requests/listagem_requests';
import {CustomRow} from '../../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {Col, OverlayTrigger, Row as RowForm, Tooltip} from 'react-bootstrap';
import {DatePicker} from 'rsuite';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton';
import FreteDetailsModal from './components/frete-details-modal';

export const FreteDetails: React.FC = () => {
  const renderCellValue = (value: any) => (value ? value : '...');

  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const pedidoCompraItemsColumns: Column<IFrete>[] = [
    {
      Header: 'Detalhes',
      accessor: 'id',
      Cell: ({row}) => (
        <OverlayTrigger
          placement='top'
          overlay={
            <Tooltip id={`tooltip-edit-${row.original.id}`}>
              Observe as notas fiscais associadas ao endereço
            </Tooltip>
          }
        >
          <button
            className='btn btn-primary btn-sm'
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleModalFreteDetails(row.original.id!)}
          >
            <i style={{padding: 0}} className='bi bi-eye'></i>
          </button>
        </OverlayTrigger>
      ),
    },
    {
      Header: 'Tipo de Frete',
      accessor: 'tipoFrete',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Modelo',
      accessor: 'modelo',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Endereço de Origem',
      accessor: 'enderecoOrigem',
      Cell: ({value}) => (
        <div className='text-end' style={{minWidth: 200}}>
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Endereço de Destino',
      accessor: 'enderecoDestino',
      Cell: ({value}) => (
        <div className='text-end' style={{minWidth: 200}}>
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Data de Entrega',
      accessor: 'dataEntrega',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Data Prevista',
      accessor: 'dataPrevisao',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Data de Saída',
      accessor: 'dataSaida',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Valor do Frete',
      accessor: 'valorFrete',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },
    {
      Header: 'Valor do Seguro',
      accessor: 'valorSeguro',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },
    {
      Header: 'Transportadora',
      accessor: 'fantasiaFornecedor',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Usuário Criação',
      accessor: 'nomeUsuarioCriacao',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Data de Criação',
      accessor: 'dataCriacao',
      Cell: ({value}) => (
        <div className='text-end'>{renderCellValue(formatDateTimeWithHours(value))}</div>
      ),
    },
    {
      Header: 'Usuário de Alteração',
      accessor: 'nomeUsuarioAlteracao',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Data de alteração',
      accessor: 'dataAlteracao',
      Cell: ({value}) => (
        <div className='text-end'>
          {renderCellValue(value ? formatDateTimeWithHours(value) : '...')}
        </div>
      ),
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <button
          onClick={() => handleOnEdit(row.original.id!)}
          className='btn btn-success btn-sm bi bi-pencil'
          data-toggle='tooltip'
          data-placement='top'
          title='Editar'
        />
      ),
    },
  ];

  const [isSelectAsyncFornecedor, setIsSelectAsyncFornecedor] = useState<ISelectOption | null>(
    null
  );
  const [isSelectAsyncEnderecoOrigem, setIsSelectAsyncEnderecoOrigem] =
    useState<ISelectOption | null>(null);
  const [isSelectAsyncEnderecoDestino, setIsSelectAsyncEnderecoDestino] =
    useState<ISelectOption | null>(null);
  const [isPedidoCompraItemsData, setIsPedidoCompraItemsData] = useState<IFrete[]>([]);
  const [isLoadingFretePedidoCompra, setIsLoadingFretePedidoCompra] = useState<boolean>(false);
  const [isLoadingSkeleton, setIsLoadingSkeleton] = useState<boolean>(false);

  const [isActionPedidoCompraFrete, setIsActionPedidoCompraFrete] = useState<'add' | 'edit' | null>(
    null
  );
  const [isIdFrete, setIsIdFrete] = useState<number | string>();

  const [isIdItem, setIsIdItem] = useState<number>();

  const [isDataPrevisao, setIsDataPrevisao] = useState<Date | null>(null);
  const [isDataSaida, setIsDataSaida] = useState<Date | null>(null);
  const [isDataEntrega, setIsDataEntrega] = useState<Date | null>(null);

  const [isDetailsFreteModal, openDetailsFreteModal, closeDetailsFreteModal] = useModal();

  const handleModalFreteDetails = (id: number) => {
    openDetailsFreteModal();

    setIsIdItem(id);
  };

  const data = useMemo(() => isPedidoCompraItemsData, [isPedidoCompraItemsData]);
  const columns = useMemo(() => pedidoCompraItemsColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const {id} = useParams();

  const handleOnAdd = () => {
    setIsActionPedidoCompraFrete('add');

    setIsSelectAsyncFornecedor(null);
    setIsSelectAsyncEnderecoOrigem(null);
    setIsSelectAsyncEnderecoDestino(null);

    setIsDataEntrega(null);
    setIsDataPrevisao(null);
    setIsDataSaida(null);

    formik.setValues({
      valorFrete: 0,
      valorSeguro: 0,
      status: 'ATIVO',
    });
  };

  const handleOnEdit = (id: string | number) => {
    setIsActionPedidoCompraFrete('edit');
    setIsIdFrete(id);
    loadFreteById(id);
  };

  const initialValues: IFrete = {
    id: undefined,
    idPedidoCompra: undefined,
    valorSeguro: undefined,
    valorFrete: undefined,
    tipoFrete: undefined,
    status: 'ATIVO',
    dataEntrega: new Date(),
    dataPrevisao: new Date(),
    dataSaida: new Date(),
    modelo: undefined,
    idEnderecoDestino: undefined,
    idEnderecoOrigem: undefined,
    idFornecedor: undefined,
  };

  const formik = useFormik({
    initialValues,
    // Validação condicional de schemas, add para a ação de adição, e edit para a ação de add
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoadingFretePedidoCompra(true);
      if (isActionPedidoCompraFrete === 'add') {
        if (
          !isSelectAsyncFornecedor ||
          !isSelectAsyncEnderecoOrigem ||
          !isSelectAsyncEnderecoDestino
        ) {
          const Toast = Swal.mixin({
            toast: true,
            position: 'center',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          let title;
          switch (true) {
            case !isSelectAsyncFornecedor:
              title = 'A inserção do Fornecedor é obrigatório';
              break;
            case !isSelectAsyncEnderecoOrigem:
              title = 'A inserção do Endereço Origem é obrigatória';
              break;
            case !isSelectAsyncEnderecoDestino:
              title = 'A inserção da Empresa de Destino é obrigatória';
              break;
            default:
              title = 'Erro desconhecido';
          }

          Toast.fire({
            icon: 'info',
            title,
          });

          setSubmitting(false);
          setIsLoadingFretePedidoCompra(false);
          return;
        }
        try {
          await sendPedidoCompraFrete({
            ...values,
            idPedidoCompra: id,
            dataEntrega: isDataEntrega!,
            dataPrevisao: isDataPrevisao!,
            dataSaida: isDataSaida!,
            idFornecedor: isSelectAsyncFornecedor?.value,
            idEnderecoOrigem: isSelectAsyncEnderecoOrigem?.value,
            idEnderecoDestino: isSelectAsyncEnderecoDestino?.value,
          });

          Swal.fire({
            icon: 'success',
            title: 'Frete cadastrado com sucesso!',
            showCancelButton: false,
            timer: 3000,
            timerProgressBar: false,
          });

          loadFretesByPedidoCompraId(id!);
          setIsActionPedidoCompraFrete(null);

          setSubmitting(false);
          setIsLoadingFretePedidoCompra(false);
        } catch (errors: any) {
          const {data, status} = errors.response;

          if (status === 409) {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
              confirmButtonText: 'Ok',
            });
            setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
          } else if (status === 401) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          }

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoadingFretePedidoCompra(false);
        }
      } else {
        try {
          if (
            !isSelectAsyncFornecedor ||
            !isSelectAsyncEnderecoOrigem ||
            !isSelectAsyncEnderecoDestino
          ) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'center',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            let title;
            switch (true) {
              case !isSelectAsyncFornecedor:
                title = 'A inserção do Fornecedor é obrigatório';
                break;
              case !isSelectAsyncEnderecoOrigem:
                title = 'A inserção do Endereço Origem é obrigatória';
                break;
              case !isSelectAsyncEnderecoDestino:
                title = 'A inserção da Empresa de Destino é obrigatória';
                break;
              default:
                title = 'Erro desconhecido';
            }

            Toast.fire({
              icon: 'info',
              title,
            });

            setSubmitting(false);
            setIsLoadingFretePedidoCompra(false);
            return;
          }

          const result = await Swal.fire({
            title: 'Confirmação:',
            text: 'Deseja realmente editar o frete do pedido de compra?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, editar',
            cancelButtonText: 'Cancelar',
          });

          if (result.isConfirmed) {
            await editPedidoCompraFrete(isIdFrete!, {
              ...values,
              idPedidoCompra: id,
              dataEntrega: isDataEntrega!,
              dataPrevisao: isDataPrevisao!,
              dataSaida: isDataSaida!,
              idFornecedor: isSelectAsyncFornecedor?.value,
              idEnderecoOrigem: isSelectAsyncEnderecoOrigem?.value,
              idEnderecoDestino: isSelectAsyncEnderecoDestino?.value,
            });

            Swal.fire({
              icon: 'success',
              title: 'Frete do pedido de compra editado com sucesso!',
              showCancelButton: false,
              timer: 3000,
              timerProgressBar: false,
            });

            loadFretesByPedidoCompraId(id!);

            setIsActionPedidoCompraFrete(null);
          }

          setSubmitting(false);
          setIsLoadingFretePedidoCompra(false);
        } catch (errors: any) {
          const {error} = errors;

          if (error.response) {
            const status = error.response.status;

            const {data} = errors.response;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            }

            Swal.fire({
              icon: 'error',
              title: data.map((item) => item.mensagem),
              showCancelButton: false,
            });
          }

          setSubmitting(false);
          setIsLoadingFretePedidoCompra(false);
        }
      }
    },
  });

  const loadFornecedorById = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedores/${id}?id=${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setIsSelectAsyncFornecedor({
        value: data.id,
        label: ``,
      });

      return [
        {
          value: data.id,
          label: `${data.id} - ${data.descricao}`,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadEnderecoOrigemById = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica/frete-enderecos/${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setIsSelectAsyncEnderecoOrigem({
        value: data.id,
        label: `${data.rua}`,
      });

      return [
        {
          value: data.id,
          label: `${data.id} - ${data.descricao}`,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadEnderecoDestinoById = async (id: string | number) => {
    try {
      const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica/frete-enderecos/${id}`;

      const response = await axios.get(url);
      const {data} = response;

      setIsSelectAsyncEnderecoDestino({
        value: data.id,
        label: `${data.rua}`,
      });

      return [
        {
          value: data.id,
          label: `${data.id} - ${data.descricao}`,
        },
      ];
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });
      return [];
    }
  };

  const loadFreteById = async (idFrete: string | number) => {
    setIsLoadingFretePedidoCompra(true);
    if (id) {
      try {
        const response = await getPedidoCompraFreteById(idFrete);

        const {data} = response;

        formik.setValues({
          valorFrete: data.valorFrete,
          valorSeguro: data.valorSeguro,
          status: data.status,
          tipoFrete: data.tipoFrete,
        });

        setIsDataEntrega(data.dataEntrega ? new Date(data.dataEntrega) : null);
        setIsDataPrevisao(data.dataPrevisao ? new Date(data.dataPrevisao) : null);
        setIsDataSaida(data.dataSaida ? new Date(data.dataSaida) : null);

        loadFornecedorById(data.idFornecedor);

        loadEnderecoOrigemById(data.idEnderecoOrigem);
        loadEnderecoDestinoById(data.idEnderecoDestino);

        setIsLoadingFretePedidoCompra(false);
      } catch (errors) {}
    }
  };

  const handleAsyncSelectFornecedor = (option: ISelectOption) => {
    setIsSelectAsyncFornecedor(option);
  };

  const handleAsyncSelectEnderecoOrigem = (option: ISelectOption) => {
    setIsSelectAsyncEnderecoOrigem(option);
  };

  const handleAsyncSelectEnderecoDestino = (option: ISelectOption) => {
    setIsSelectAsyncEnderecoDestino(option);
  };

  const loadFretesByPedidoCompraId = async (idPedidoCompra: string | number) => {
    setIsLoadingFretePedidoCompra(true);
    setIsLoadingSkeleton(true);
    if (id) {
      try {
        const response = await getFretesByPedidoCompraId(id);

        const {content} = response.data;

        setIsPedidoCompraItemsData(content);

        setIsLoadingFretePedidoCompra(false);

        setIsLoadingSkeleton(false);
      } catch (errors: any) {
        const {data, status} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });

        setIsLoadingSkeleton(false);
      }
    }
  };

  useEffect(() => {
    if (id) {
      loadFretesByPedidoCompraId(id);
    }
  }, []);

  return (
    <>
      <form action='form-control-solid' onSubmit={formik.handleSubmit}>
        {isActionPedidoCompraFrete && (
          <>
            <DescriptionComponent
              description={
                isActionPedidoCompraFrete === 'edit' ? 'Editar Frete' : 'Cadastrar Frete'
              }
              isButtonAction
              variantButton='danger'
              buttonTitle={
                isActionPedidoCompraFrete === 'edit' ? 'Edição de Frete' : 'Cadastro de Frete'
              }
              handleAction={() => setIsActionPedidoCompraFrete(null)}
            />

            <RowForm className='mb-6 mt-6'>
              <Col className='col-sm-12'>
                <label htmlFor='' className='form-label'>
                  Transportadora:
                </label>

                <AsyncSelectFornecedor
                  handleOptions={(option) => handleAsyncSelectFornecedor(option!)}
                  inputOption={isSelectAsyncFornecedor}
                  placeholder='Transportadora'
                />
              </Col>
            </RowForm>
            <RowForm>
              <Col md='6' className='col-sm-12'>
                <label htmlFor='' className='form-label'>
                  Endereço de Origem:
                </label>

                <AsyncSelectEndereco
                  handleOptions={(option) => handleAsyncSelectEnderecoOrigem(option!)}
                  inputOption={isSelectAsyncEnderecoOrigem}
                />
              </Col>
              <Col md='6' className='col-sm-12'>
                <label htmlFor='' className='form-label'>
                  Endereço de Destino:
                </label>

                <AsyncSelectEndereco
                  handleOptions={(option) => handleAsyncSelectEnderecoDestino(option!)}
                  inputOption={isSelectAsyncEnderecoDestino}
                />
              </Col>
            </RowForm>
            <RowForm>
              <Col md={3} className='col-12 mt-4'>
                <label htmlFor='' className='form-label'>
                  Valor do Frete:
                </label>
                <input
                  placeholder='Insira o preço médio:'
                  type='text'
                  id=''
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(formik.values.valorFrete || 0)}
                  className={`form-control ${
                    formik.touched.valorFrete && formik.errors.valorFrete ? 'is-invalid' : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    if (inputValue === '') {
                      inputValue = '0,00';
                    }

                    // Converte o valor em número e atualiza o Formik com o valor numérico
                    formik.setFieldValue('valorFrete', parseFloat(inputValue) / 100);
                  }}
                />
              </Col>
              <Col md={3} className='col-12 mt-4'>
                <label htmlFor='' className='form-label'>
                  Valor do Seguro:
                </label>
                <input
                  placeholder='Insira o valor do seguro:'
                  type='text'
                  id=''
                  value={new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                  }).format(formik.values.valorSeguro || 0)}
                  className={`form-control ${
                    formik.touched.valorSeguro && formik.errors.valorSeguro ? 'is-invalid' : ''
                  }`}
                  onChange={(e) => {
                    let inputValue = e.target.value;

                    // Remove caracteres não numéricos
                    inputValue = inputValue.replace(/\D/g, '');

                    if (inputValue === '') {
                      inputValue = '0,00';
                    }

                    // Converte o valor em número e atualiza o Formik com o valor numérico
                    formik.setFieldValue('valorSeguro', parseFloat(inputValue) / 100);
                  }}
                />
              </Col>
              <Col md={3} className='col-12 mt-4'>
                <label className='form-label'>Status:</label>
                <select
                  {...formik.getFieldProps('status')}
                  className={`form-control ${
                    formik.touched.status && formik.errors.status ? 'is-invalid' : ''
                  }`}
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>

                {formik.touched.status && formik.errors.status ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.status}
                  </div>
                ) : null}
              </Col>

              <Col md={3} className='col-12 mt-4'>
                <label className='form-label'>Tipo de Frete:</label>
                <select
                  {...formik.getFieldProps('tipoFrete')}
                  className={`form-control ${
                    formik.touched.tipoFrete && formik.errors.tipoFrete ? 'is-invalid' : ''
                  }`}
                >
                  <option value='CIF'>CIF</option>
                  <option value='FOB'>FOB</option>
                </select>

                {formik.touched.tipoFrete && formik.errors.tipoFrete ? (
                  <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                    {formik.errors.tipoFrete}
                  </div>
                ) : null}
              </Col>
            </RowForm>
            <RowForm className='mt-4'>
              <Col md='4' className='col-sm-12'>
                <label htmlFor='' className='form-label'>
                  Data de Saída:
                </label>

                <DatePicker
                  className='w-100'
                  format='dd-MM-yyyy'
                  placeholder='Data de Saída'
                  onChange={(date) => setIsDataSaida(date)}
                  value={isDataSaida}
                />
              </Col>
              <Col md='4' className='col-sm-12'>
                <label htmlFor='' className='form-label'>
                  Data de Entrega:
                </label>

                <DatePicker
                  className='w-100'
                  format='dd-MM-yyyy'
                  placeholder='Data de Entrega'
                  onChange={(date) => setIsDataEntrega(date)}
                  value={isDataEntrega}
                />
              </Col>

              <Col md='4' className='col-sm-12'>
                <label htmlFor='' className='form-label'>
                  Data de Previsão:
                </label>

                <DatePicker
                  className='w-100'
                  format='dd-MM-yyyy'
                  placeholder='Data de Previsão'
                  onChange={(date) => setIsDataPrevisao(date)}
                  value={isDataPrevisao}
                />
              </Col>
            </RowForm>
            <FooterForm
              isLoading={isLoadingFretePedidoCompra}
              textActionSubmit={
                isActionPedidoCompraFrete === 'edit'
                  ? 'Editar Frete do Pedido'
                  : 'Cadastrar Frete do Pedido'
              }
              redirectTo='pedido-compras-listagem'
              isBack={false}
            />
          </>
        )}
      </form>

      {isActionPedidoCompraFrete === null && (
        <DescriptionComponent
          description='Fretes'
          buttonTitle='+ Adicionar'
          isButtonAction
          handleAction={() => handleOnAdd()}
          variantButton='warning'
        />
      )}

      {isLoadingSkeleton ? (
        <Skeleton count={20} height={25} />
      ) : (
        <div className='table-responsive mt-4' style={{maxHeight: 500}}>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border px-4'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IFrete>, index: number) => (
                  <th
                    key={index}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IFrete>, i) => {
                  prepareRow(row);
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      showEditButton
                      onEdit={(id) => handleOnEdit(id)}
                    />
                  );
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      SEM FRETES PARA O PEDIDO DE COMPRA
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}

      <FreteDetailsModal
        isOpen={isDetailsFreteModal}
        onHide={closeDetailsFreteModal}
        idItem={isIdItem}
      />
    </>
  );
};

export default FreteDetails;
