import axios from 'axios';
import Swal from 'sweetalert2';
import {
  IHeaders,
  ICondicoesPagamento,
  IFormasPagamento,
  IItemsSeparacao,
  IPedidoCompraParams,
  IRequestFormulariosParams,
  IMeiosPagamento,
  IFornecedor,
} from '../../../shared/domain-types/models';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

export const GET_USUARIOS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/usuarios`;
export const GET_EMPRESAS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/empresas`;
export const API_PREVENDA = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas`;
export const API_CLIENTES = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/clientes`;
export const GET_TIPO_VENDA_BY_ID = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/tipoVenda`;
export const GET_TIPO_VENDA = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/tipoVenda/listar`;
export const API_PRODUTOS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/listar`;
export const API_PARCEIROS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/parceiros`;
export const GET_FORMULARIOS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formularios`;
export const GET_FLUXO_LOGISTICO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-fluxo`;
export const GET_LOGISTICA_SEPARACAO_BY_ID = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-separacao`;
export const GET_LOGISTICA_SEPARACAO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-separacao/listar`;
export const GET_LOGISTICA_SEPARACAO_ITEMS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-separacao-itens`;
export const SEPARACAO_SYNC = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-separacao/sincronizar`;
export const GET_ENDERECO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica/frete-enderecos`;
export const GET_RESUMOSKU = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/resumoSku`;
export const GET_PRODUTOS_PRAZO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos-prazos/listar`;
export const GET_CATEGORIZACAO_PRODUTO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/categorizacao-produto`;
export const GET_TIPO_ENTREGA = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/todosTipoEntrega`;
export const GET_CONFIGURACAO_ROTINAS_SQL = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/configuracao-rotinas-sql`;
export const GET_CONFIGURACAO_ROTINAS_SQL_PARAMETROS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/configuracao-rotinas-sql-parametros`;
export const GET_FORMAS_PAGAMENTO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/formaspagamento`;
export const GET_CONDICAO_PAGAMENTO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/condicoespagamento`;

export const GET_SEPARACAO_PDF = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica-separacao/gerarPdfSeparacao`;
export const GET_PRODUTO_PDF = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/gerarPdfDetalhesProduto`;
export const GET_CLIENTE_PDF = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/clientes/gerarPdfCliente`;
export const GET_PEDIDO_COMPRA_PDF = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-compra/gerarPdf`;
export const GET_UNIDADE_MEDIDA = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/unidade-medida`;

export const GET_EXPEDICAO_SYSLED_PDF = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica/expedicao/sysled`;

export const GET_SUPRIMENTOS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-status`;

export const API_COLABORADOR = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedores`;
export const API_FORNECEDOR = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedores`;
export const GET_EAN = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos-ean`;
export const GET_PEDIDO_COMPRA = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-compra`;
export const GET_PEDIDO_COMPRA_ITEMS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-compra-itens`;
export const GET_PEDIDO_COMPRA_FRETE = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica/frete`;
export const GET_PEDIDO_COMPRA_NOTA_FISCAL = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fiscal/notas-fiscais`;
export const GET_PRODUTO_SIMILAR = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos-similares`;
export const GET_PRODUTO = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos-similares`;
export const GET_PEDIDO_COMPRA_PAGAMENTOS = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pedido-compra-pagamentos`;
export const GET_PENDENCIA_VENDA = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/suprimento/pendencia-compra/consolidado/listar`;
export const GET_FORNECEDOR = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/fornecedor/listar`;
export const GET_COLABORADOR = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/colaboradores/listar`;export const GET_EXPEDICAO_SYSLED = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/logistica/expedicao/sysled`;
export const GET_PDF_LIGHTKORP = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/gerarPdfPrevendaLightKorp`;

export function clonarPrevenda(idPrevenda) {
  return axios.post(`${API_PREVENDA}/clonarPrevenda?idPrevenda=${idPrevenda}`);
}

export function clonarProduto(idProduto) {
  return axios.post(
    `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/clonarProduto?idProduto=${idProduto}`
  );
}

export function receberPagamento(idPrevenda) {
  return axios.post(`${API_PREVENDA}/receberPagamento?idPrevenda=${idPrevenda}`);
}

export function sincronizarPrevenda(idPrevenda) {
  return axios.post(`${API_PREVENDA}/sincronizarPrevenda?idPrevenda=${idPrevenda}`);
}

export function buscaHistoricoCompras(codigoCliente, idEmpresa) {
  return axios.get(
    `${API_CLIENTES}/buscaHistoricoCompra?codigoCliente=${codigoCliente}&idEmpresa=${idEmpresa}`
  );
}

export function sincronizarCliente(idCliente) {
  return axios.post(`${API_CLIENTES}/sincronizarCliente?idCliente=${idCliente}`);
}

type IFornecedores = {
  razaoSocial: string
  fantasia: string
  cpf: string
  cnpj: string
  page: string | number
}

export const getFornecedores = ({
  cnpj,
  cpf,
  fantasia,
  razaoSocial,
  page
}: IFornecedores) => {
  return axios.get(
    `${GET_FORNECEDOR}?razaoSocial=${razaoSocial}&fantasia=${fantasia}&cpf=${cpf}&cnpj=${cnpj}`
  );
};

export function sincronizarFornecedor(idFornecedor) {
  return axios.post(`${API_FORNECEDOR}/sincronizarFornecedor?idFornecedor=${idFornecedor}`);
}

type IColaboradores = {
  razaoSocial: string
  fantasia: string
  cpf: string
  cnpj: string
  page: string | number
}

export const getColaboradores = ({
  cnpj,
  cpf,
  fantasia,
  razaoSocial,
  page
}: IColaboradores) => {
  return axios.get(
    `${GET_COLABORADOR}?razaoSocial=${razaoSocial}&fantasia=${fantasia}&cpf=${cpf}&cnpj=${cnpj}`
  );
};

export function sincronizarColaborador(idColaborador) {
  return axios.post(`${API_COLABORADOR}/sincronizarColaborador?idColaborador=${idColaborador}`);
}
export function getEan() {
  return axios.get(`${GET_EAN}/listar`);
}

export function getEanById(id: string | number) {
  return axios.get(`${GET_EAN}/${id}`);
}

export function getProdutoSimilarById(id: string | number) {
  return axios.get(`${GET_PRODUTO_SIMILAR}/${id}`);
}

export function getEanByIdProduto(id: string | number) {
  return axios.get(`${GET_EAN}/listar?idProduto=${id}&status=${'ATIVO'}`);
}

export function getProdutoSimilarByIdProduto(id: string | number) {
  return axios.get(`${GET_PRODUTO_SIMILAR}/listar?idProduto=${id}`);
}
export function getProdutoSimilar(id: string | number) {
  return axios.get(`${GET_PRODUTO_SIMILAR}/listar?idProduto=${id}`);
}

export function getPagamentosByIdPedidoCompra(idPedidoCompra: string | number) {
  return axios.get(
    `${GET_PEDIDO_COMPRA_PAGAMENTOS}/listar?idPedidoCompra=${idPedidoCompra}&size=${1000}`
  );
}

type IProductsSearch = {
  page?: string | number;
  size?: string | number;
  idProduto?: string | number;
  sku?: string | number;
  descricao?: string;
  marca?: string;
};

export function getProducts({page, descricao, idProduto, marca, sku, size}: IProductsSearch) {
  return axios.get(
    `${API_PRODUTOS}?page=${page}&descricao=${descricao}&idProduto=${idProduto}&marca=${marca}&sku=${sku}&size=${size}`
  );
}

export function sincronizarProduto(idProduto) {
  return axios.post(`${API_PRODUTOS}/sincronizarProduto?idProduto=${idProduto}`);
}

export function sincronizarParceiro(idParceiro, parceiro?: string) {
  return axios.post(`${API_PARCEIROS}/sincronizarParceiro?idParceiro=${idParceiro}`);
}

export const getConfiguracaoRotinaSql = ({
  page,
  titulo,
  descricao,
  storedProcedure,
  status,
  hasReturn,
}) => {
  return axios.get(
    `${GET_CONFIGURACAO_ROTINAS_SQL}/listar?page=${page}&titulo=${titulo}&descricao=${descricao}&storedProcedure=${storedProcedure}&status=${status}&hasReturn=${hasReturn}`
  );
};

export const getConfiguracaoRotinaSqlId = (id) => {
  return axios.get(`${GET_CONFIGURACAO_ROTINAS_SQL}/${id}`);
};

export const getFormularios = ({
  descricao,
  nomeArquivo,
  status,
  page,
  size,
}: IRequestFormulariosParams) => {
  return axios.get(
    `${GET_FORMULARIOS}?descricao=${descricao}&nomeArquivo=${nomeArquivo}&status=${status}&page=${page}`
  );
};

export const getTiposEntrega = () => {
  return axios.get(`${GET_TIPO_ENTREGA}`);
};

export const getResumoSku = (id: number) => {
  return axios.get(`${GET_RESUMOSKU}/${id}`);
};

export const getProdutosPrazo = (id: number) => {
  return axios.get(`${GET_PRODUTOS_PRAZO}`);
};

export const getUnidadeMedidaById = (id: number | string) => {
  return axios.get(`${GET_UNIDADE_MEDIDA}/${id}`);
};

export const getPendenciaCompra = (
  idCliente?: string | number | null,
  idProduto?: string | number| null,
  idFornecedor?: string | number | null,
  dataPedido?: string | null,
  dataPendencia?: string | null,
  idPedidoG2?: string| number | null,
) => {
  const params: Record<string, string | number | null | undefined | Date> = {
    idCliente,
    idProduto,
    idFornecedor,
    dataPedido,
    dataPendencia,
    idPedidoG2,
  };
  const filteredParams = Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return axios.get(`${GET_PENDENCIA_VENDA}?${filteredParams}`);
};

export const getPedidoCompra = (
  {page}: IPedidoCompraParams,
  idFornecedor?: string | number,
  idEmpresa?: string | number,
  idEmpresaOrigem?: string | number,
  idEmpresaFaturamento?: string | number,
  idStatus?: string | number,
  dataEmissao?: string | null,
  dataFaturamento?: string | null,
  id?: number | null
) => {
  const params: Record<string, string | number | null | undefined> = {
    page,
    idFornecedor,
    idEmpresa,
    idEmpresaOrigem,
    idEmpresaFaturamento,
    idStatus,
    dataEmissao,
    dataFaturamento,
    id
  };

  // Filtra os parâmetros removendo undefined e null
  const filteredParams = Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null)
    .map(([key, value]) => `${key}=${value}`)
    .join('&');

  return axios.get(`${GET_PEDIDO_COMPRA}/listar?${filteredParams}`);
};

type IProps = {
  idPedido?: number;
  idCliente?: number;
  seqEntrega?: number;
  idFluxoLogistido?: number;
  idTipoEntrega?: number;
  idConsultor?: number;
  pedidoDataEmissaoInicio?: Date | string | null;
  pedidoDataEmissaoFim?: Date | string | null;
  pedidoDataAcordadoInicio?: Date | string | null;
  pedidoDataAcordadoFim?: Date | string | null;
  page?: number | null;
  size?: number | null;
};

export const getExpedicaoSysled = (params: IProps) => {
  console.log('params: ', params);
  // Remove parâmetros com valores `null` ou `undefined`
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([_, value]) => value != null)
  );

  return axios.get(`${GET_EXPEDICAO_SYSLED}/listar`, {params: filteredParams});
};

export const getExpedicaoSysledItens = (idSeqEntrega: number | string) => {
  return axios.get(`${GET_EXPEDICAO_SYSLED}/listar/itens?seqEntrega=${idSeqEntrega}`);
};

export const getPedidoCompraById = (id: string | number) => {
  return axios.get(`${GET_PEDIDO_COMPRA}/${id}`);
};

export const getPedidoItemCompraById = (id: string | number) => {
  return axios.get(`${GET_PEDIDO_COMPRA_ITEMS}/${id}`);
};

export const getItemsByPedidoCompraId = (id: string | number) => {
  return axios.get(`${GET_PEDIDO_COMPRA_ITEMS}/listar?idPedidoCompra=${id}&size=1000&status=ATIVO`);
};

export const getSuprimentoById = (id: number | string) => {
  return axios.get(`${GET_SUPRIMENTOS}/${id}`);
};

export const getFormasPagamentoById = (id: number | string) => {
  return axios.get(`${GET_FORMAS_PAGAMENTO}/${id}`);
};

export const getCondicoesPagamentoById = (id: number | string) => {
  return axios.get(`${GET_CONDICAO_PAGAMENTO}/${id}`);
};

type IUnidadeMedidasParams = {
  titulo: string;
  descricao: string;
  page: string | number;
  status: string;
};

export const getUnidadesMedidas = ({descricao, page, status, titulo}: IUnidadeMedidasParams) => {
  return axios.get(
    `${GET_UNIDADE_MEDIDA}/listar?page=${page}&titulo=${titulo}&descricao=${descricao}&status=${status}`
  );
};

export const getListagemSuprimentos = ({descricao, page, status, titulo}: IHeaders) => {
  return axios.get(
    `${GET_SUPRIMENTOS}/listar?page=${page}&titulo=${titulo}&descricao=${descricao}&status=${status}`
  );
};

export const getFormasPagamento = ({icone, page, formaPagamento}: IFormasPagamento) => {
  return axios.get(
    `${GET_FORMAS_PAGAMENTO}/listar?page=${page}&icons=${icone}&formapagamento=${formaPagamento}`
  );
};

export const getCondicoesPagamento = ({page, condicaoPagamento}: ICondicoesPagamento) => {
  return axios.get(
    `${GET_CONDICAO_PAGAMENTO}/listar?page=${page}&condicaoPagamento=${condicaoPagamento}`
  );
};

export const getCategorizacaoProdutoById = (id: number) => {
  return axios.get(`${GET_CATEGORIZACAO_PRODUTO}/${id}`);
};

export const getCategorizacaoProduto = (
  model: number,
  titulo?: string,
  status?: string,
  page?: number
) => {
  return axios.get(
    `${GET_CATEGORIZACAO_PRODUTO}/listar?modelo=${model}&titulo=${titulo}&status=${status}&page=${page}`
  );
};

//Tipo de Venda

export const getTipoVenda = ({page}) => {
  return axios.get(`${GET_TIPO_VENDA}?page=${page}`);
};

export const getTipoVendaById = (id: number | string) => {
  return axios.get(`${GET_TIPO_VENDA_BY_ID}/${id}`);
};

export const getFluxoLogistico = ({page}) => {
  return axios.get(`${GET_FLUXO_LOGISTICO}/listar?page=${page}`);
};

export const getLogisticaSeparacao = async (page: number) => {
  return axios.get(`${GET_LOGISTICA_SEPARACAO}?page=${page}`);
};

export const getEnderecoById = (id: string | number) => {
  return axios.get(`${GET_ENDERECO}/${id}`, {});
};
export const getEndereco = (page) => {
  return axios.get(`${GET_ENDERECO}/listar?page=${page}`);
};

export const getItemsBySeparacaoId = async (id: number | string) => {
  return axios.get(`${GET_LOGISTICA_SEPARACAO_BY_ID}/${id}`);
};

export const getItemSeparacaoById = async (id: number | string) => {
  return axios.get(`${GET_LOGISTICA_SEPARACAO_ITEMS}/${id}`);
};

export const postSeparacaoItemSync = async (id: number | string) => {
  return axios.post(`${SEPARACAO_SYNC}?idSeparacao=${id}`);
};

export const putLogisticaSeparacaoItem = async (id: number | string, data: IItemsSeparacao) => {
  return axios.put(`${GET_LOGISTICA_SEPARACAO_ITEMS}/${id}`, data);
};

export function imprimirPrevenda(idPrevenda, setLoading, tipoPDF) {
  let endpoint = tipoPDF === 'revenda' ? 'gerarPdfPrevendaRevenda' : 'gerarPdfPrevenda';
  const url = `${API_PREVENDA}/${endpoint}/${idPrevenda}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `prevenda_${idPrevenda}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export const getPedidoCompraNotaFiscal = async (page: number) => {
  return axios.get(`${GET_PEDIDO_COMPRA_NOTA_FISCAL}/listar?page=${page}`);
};

export const getPedidoCompraNotaFiscalById = async (id: string | number) => {
  return axios.get(`${GET_PEDIDO_COMPRA_NOTA_FISCAL}/${id}`);
};

export const getNotaFiscalByPedidoCompraId = async (id: string | number) => {
  return axios.get(
    `${GET_PEDIDO_COMPRA_NOTA_FISCAL}/listar?idPedidoCompra=${id}&size=1000&status=ATIVO`
  );
};

export const getConsultaByNotaFiscal = (
  idNfe: number | string | BigInt,
  data: number[],
  idPedidoCompra: string | number
) => {
  const params = new URLSearchParams();
  params.append('idPedidoCompra', idPedidoCompra.toString());
  params.append('idNotaFiscal', idNfe.toString());
  data.forEach((item) => params.append('idPedidoCompraItem', item.toString()));

  return axios.put(`${GET_PEDIDO_COMPRA_NOTA_FISCAL}/consultar-nfe-entrada-produto?${params}`);
};

export const getPedidoCompraFrete = async (page: number) => {
  return axios.get(`${GET_PEDIDO_COMPRA_FRETE}/listar?page=${page}`);
};

export const getFretesByPedidoCompraId = async (id: string | number) => {
  return axios.get(`${GET_PEDIDO_COMPRA_FRETE}/listar?idPedidoCompra=${id}&size=1000&status=ATIVO`);
};

export const getPedidoCompraFreteById = async (id: string | number) => {
  return axios.get(`${GET_PEDIDO_COMPRA_FRETE}/${id}`);
};

export const getPedidoCompraItemDetails = async (id: string | number) => {
  return axios.get(`${GET_PEDIDO_COMPRA_ITEMS}/detalhes/${id}`);
};

export const getNotaFiscalItemDetails = async (id: string | number) => {
  return axios.get(`${GET_PEDIDO_COMPRA_NOTA_FISCAL}/frete-por-nota?idNotaFiscal=${id}`);
};

export const getFreteItemDetails = async (id: string | number) => {
  return axios.get(`${GET_PEDIDO_COMPRA_FRETE}/nota-por-frete?idFrete=${id}`);
};

export const getSumarizacao = async (id: string | number) => {
  return axios.get(`${GET_PEDIDO_COMPRA}/ver-sumarizacao/${id}`);
};

export function visualizarPDFPrevenda(
  setModalShowPDFViewer,
  idPrevenda,
  setLoading,
  setPdfUrl,
  setIdPrevenda,
  setTipoPDF,
  tipoPDF
) {
  let endpoint = tipoPDF === 'revenda' ? 'gerarPdfPrevendaRevenda' : 'gerarPdfPrevenda';
  setModalShowPDFViewer(true);
  setTipoPDF(tipoPDF);
  const url = `${API_PREVENDA}/${endpoint}/${idPrevenda}`;
  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }
      setIdPrevenda(idPrevenda);
      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function visualizarPDFExpedicao(
  setModalShowPDFViewer,
  idPedido,
  setLoading,
  setPdfUrl,
  setIdPedido
) {
  setModalShowPDFViewer(true);

  const url = `${GET_EXPEDICAO_SYSLED_PDF}/${idPedido}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }
      setIdPedido(idPedido);
      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function visualizarPDFExpedicaoAmbientadoByArray(
  setModalShowPDFViewer,
  dataSeqEntrega: ICheck[],
  setLoading,
  setPdfUrl,

) {
  setModalShowPDFViewer(true);

  const params = new URLSearchParams();
  dataSeqEntrega.forEach((item) => params.append('seqEntrega', item.seqEntrega.toString()));
  const url = `${GET_EXPEDICAO_SYSLED_PDF}/gerarPdfRomaneioAmbiente?${params}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function imprimirPDFExpedicaoAmbientadoByArray(dataSeqEntrega: ICheck[], setLoading) {
  const params = new URLSearchParams();
  dataSeqEntrega.forEach((item) => params.append('seqEntrega', item.seqEntrega.toString()));
  const url = `${GET_EXPEDICAO_SYSLED_PDF}/gerarPdfRomaneioAmbiente?${params}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `separacao_${dataSeqEntrega}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function visualizarPDFExpedicaoAmbientadoByIdPedido(
  setModalShowPDFViewer,
  idPedido,
  setLoading,
  setPdfUrl,
  setIdPedido
) {
  setModalShowPDFViewer(true);

  const url = `${GET_EXPEDICAO_SYSLED_PDF}/gerarPdfRomaneioAmbiente?idPedido=${idPedido}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }
      setIdPedido(idPedido);
      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function imprimirPDFExpedicaoAmbientadoById(idPedido, setLoading) {
  const url = `${GET_EXPEDICAO_SYSLED_PDF}/gerarPdfRomaneioAmbiente?idPedido=${idPedido}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `separacao_${idPedido}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function visualizarPDFExpedicaoRomaneioByArray(
  setModalShowPDFViewer,
  dataSeqEntrega : ICheck[],
  setLoading,
  setPdfUrl,

) {
  setModalShowPDFViewer(true);

  const params = new URLSearchParams();
  dataSeqEntrega.forEach((item) => params.append('seqEntrega', item.seqEntrega.toString()));
  const url = `${GET_EXPEDICAO_SYSLED_PDF}/gerarPdfRomaneio?${params}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

type ICheck = {
  seqEntrega: number
  idPedido: number
}

export function imprimirPDFExpedicaoRomaneioByArray(dataSeqEntrega: ICheck[], setLoading) {
  const params = new URLSearchParams();
  dataSeqEntrega.forEach((item) => params.append('seqEntrega', item.seqEntrega.toString()));
  const url = `${GET_EXPEDICAO_SYSLED_PDF}/gerarPdfRomaneio?${params}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `separacao_${dataSeqEntrega}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function visualizarPDFExpedicaoRomaneioByIdPedido(
  setModalShowPDFViewer,
  idPedido,
  setLoading,
  setPdfUrl,
  setIdPedido
) {
  setModalShowPDFViewer(true);

  const url = `${GET_EXPEDICAO_SYSLED_PDF}/gerarPdfRomaneio?idPedido=${idPedido}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }
      setIdPedido(idPedido);
      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function imprimirPDFExpedicaoRomaneioByIdPedido(idPedido, setLoading) {
  const url = `${GET_EXPEDICAO_SYSLED_PDF}/gerarPdfRomaneioAmbiente?idPedido=${idPedido}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `separacao_${idPedido}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function visualizarPDFSeparacao(
  setModalShowPDFViewer,
  idSeparacao,
  setLoading,
  setPdfUrl,
  setIdSeparacao
) {
  setModalShowPDFViewer(true);

  const url = `${GET_SEPARACAO_PDF}/${idSeparacao}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }
      setIdSeparacao(idSeparacao);
      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function visualizarPDFProduto(
  setModalShowPDFViewer,
  idSeparacao,
  setLoading,
  setPdfUrl,
  setIdSeparacao
) {
  setModalShowPDFViewer(true);

  const url = `${GET_PRODUTO_PDF}/${idSeparacao}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }
      setIdSeparacao(idSeparacao);
      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function visualizarPDFCliente(
  setModalShowPDFViewer,
  idSeparacao,
  setLoading,
  setPdfUrl,
  setIdSeparacao
) {
  setModalShowPDFViewer(true);

  const url = `${GET_CLIENTE_PDF}/${idSeparacao}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }
      setIdSeparacao(idSeparacao);
      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function visualizarPDFPedidoCompra(
  setModalShowPDFViewer,
  idPedidoCompra,
  setLoading,
  setPdfUrl,
  setIdSeparacao
) {
  setModalShowPDFViewer(true);

  const url = `${GET_PEDIDO_COMPRA_PDF}/${idPedidoCompra}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }
      setIdSeparacao(idPedidoCompra);
      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function imprimirPDFSeparacao(idSeparacao, setLoading) {
  const url = `${GET_SEPARACAO_PDF}/${idSeparacao}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `separacao_${idSeparacao}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function imprimirPDFProduto(idSeparacao, setLoading) {
  const url = `${GET_PRODUTO_PDF}/${idSeparacao}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `produto_${idSeparacao}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}
export function imprimirPDFCliente(idSeparacao, setLoading) {
  const url = `${GET_CLIENTE_PDF}/${idSeparacao}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `cliente_${idSeparacao}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function imprimirPDFPedidoCompra(idSeparacao, setLoading) {
  const url = `${GET_PEDIDO_COMPRA}/${idSeparacao}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `separacao_${idSeparacao}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function imprimirPDFLightkorp(idPedido, setLoading) {
  const url = `${GET_PDF_LIGHTKORP}/${idPedido}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }

      const blob = response.data;
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = `separacao_${idPedido}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}

export function visualizarPDFLightkorp(
  setModalShowPDFViewer,
  idPedido,
  setLoading,
  setPdfUrl,
  setIdSeparacao
) {
  setModalShowPDFViewer(true);

  const url = `${GET_PDF_LIGHTKORP}/${idPedido}`;

  axios
    .get(url, {responseType: 'blob'})
    .then((response) => {
      if (response.status !== 200) {
        throw new Error('Erro ao baixar o PDF');
      }
      setIdSeparacao(idPedido);
      const blob = response.data;
      const pdfUrl = URL.createObjectURL(blob);
      setPdfUrl(pdfUrl);
    })
    .catch((error) => {
      console.error('Erro ao baixar o PDF:', error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao baixar o PDF',
        text: 'Ocorreu um erro ao tentar baixar o PDF. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    })
    .finally(() => {
      setLoading(false);
    });
}
