import { useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { ColumnInstance, useTable, Row, Column } from 'react-table';
import { Col, Row as RowBootstrap, Spinner } from 'react-bootstrap';
import { DescriptionComponent } from '../../../shared/components';
import { IRotinasSQL, IParametroRotinaSQL } from '../../../shared/domain-types/models/index';
import { CustomRow } from '../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import { CustomHeaderColumn } from '../../../modules/apps/user-management/users-list/table/columns/CustomHeaderColumn';
import {
  getConfiguracaoRotinaSql,
  getConfiguracaoRotinaSqlId,
} from '../listagem_requests/listagem_requests';
import { cadastroRotinaSQLValidationSchema } from '../../../shared/domain-types/validators/rotinas-sql';
import {
  addParametroRotinaSQL,
  createRotinaSQL,
  editParametroRotinaSQL,
  editRotinaSQL,
  executarRotinaSQL,
} from '../../cadastros/cadastro_requests/cadastro_requests';
import {
  cadastroParametroRotinaSQLValidationSchema,
  ModalList,
  ModalAction,
} from '../../../shared/index';

const ConfiguracoesRotinasListagem: React.FC = () => {
  const rotinasColumns: Column<IRotinasSQL>[] = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Titulo',
      accessor: 'titulo',
    },
    {
      Header: 'Descrição',
      accessor: 'descricao',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: { value: string }) => <span>{value === 'ATIVO' ? 'Ativo' : 'Inativo'}</span>,
    },
    {
      Header: 'Tem Retorno',
      accessor: 'hasReturn',
      Cell: ({ value }: { value: string | boolean }) => <span>{value === true ? 'Sim' : 'Não'}</span>,
    },
    {
      Header: 'Stored Procedure',
      accessor: 'storedProcedure',
    },
    {
      Header: 'Ações',
      Cell: ({ row }) => (
        <>
          <div className='dropdown position-static'>
            <button
              className='btn btn-primary dropdown-toggle'
              type='button'
              data-bs-toggle='dropdown'
              aria-expanded='false'
              style={{
                height: '40px',
              }}
            >
              Ações
            </button>
            <ul
              className='dropdown-menu'
              aria-labelledby='dropdownMenuButton'
              style={{
                position: 'absolute',
                zIndex: 1050,
                top: '100%',
                left: '0',
              }}
            >
              <li>
                <button
                  onClick={() => handleOnEdit(row.original)}
                  className='dropdown-item d-flex align-items-center'
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Editar'
                >
                  <i className='bi bi-pencil me-2'></i> Editar
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setId(String(row.original.id));
                    setIsToggleModalListagem(true);
                    formik.resetForm();
                    console.log('ID da rotina:', id);
                  }}
                  className='dropdown-item d-flex align-items-center'
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Listar/Adicionar Parâmetros'
                >
                  <i className='bi bi-table me-2'></i>Listar/Adicionar Parâmetros
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    setId(String(row.original.id));
                    setIsToggleModalExecutar(true);
                  }}
                  className='dropdown-item d-flex align-items-center'
                  data-toggle='tooltip'
                  data-placement='top'
                  title='Executar a Rotina'
                >
                  <i className='bi bi-play-circle me-2'></i>Executar a Rotina
                </button>
              </li>
            </ul>
          </div>
        </>
      ),
    },
  ];

  const [isRotinasData, setIsRotinasData] = useState<IRotinasSQL[]>([]);
  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isEditingParametro, setIsEditingParametro] = useState<boolean>(false);

  const [isToggleModalListagem, setIsToggleModalListagem] = useState<boolean>(false);
  const toggleModalListagem = () => setIsToggleModalListagem(!isToggleModalListagem);

  const [isToggleModalExecutar, setIsToggleModalExecutar] = useState<boolean>(false);
  const toggleModalExecutar = () =>

    setIsToggleModalExecutar(!isToggleModalExecutar);

  const [id, setId] = useState<string>('');
  const [idParametro, setIdParametro] = useState<string>('');

  const data = useMemo(() => isRotinasData, [isRotinasData]);
  const columns = useMemo(() => rotinasColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const initialValues = {
    titulo: '',
    descricao: '',
    storedProcedure: '',
    status: '',
    hasReturn: '',
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: cadastroRotinaSQLValidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setIsLoading(true);

      if (isEditing) {
        try {
          await editRotinaSQL(id, values);
          setSubmitting(false);
          setIsLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Rotina editada com sucesso!',
            showCancelButton: false,
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => {
              getRotinasData(0);
            },
          });
        } catch (error: any) {
          console.error(error);

          if (error.response) {
            const status = error.response.status;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar a rotina, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar a rotina. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              confirmButtonText: 'Ok',
            });
          }
          setSubmitting(false);
          setIsLoading(false);
        } finally {
          setIsEditing(false);
          formik.resetForm();
        }
      } else {
        try {
          await createRotinaSQL(values);
          setSubmitting(false);
          setIsLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Rotina cadastrada com sucesso!',
            showCancelButton: false,
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => {
              getRotinasData(0);
            },
          });
        } catch (error: any) {
          console.error(error);

          if (error.response) {
            const status = error.response.status;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar a rotina, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar a rotina. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              confirmButtonText: 'Ok',
            });
          }
          setSubmitting(false);
          setIsLoading(false);
        }
        formik.resetForm();
      }
    },
  });

  const formikModalListagemListagem = useFormik({
    initialValues: {
      idConfigRotinaSQL: 0,
      titulo: '',
      descricao: '',
      parameter: '',
      status: '',
    },
    validationSchema: cadastroParametroRotinaSQLValidationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setIsLoading(true)

      if (isEditingParametro) {
        try {
          await editParametroRotinaSQL(idParametro, values);
          setSubmitting(false);
          setIsLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Parâmetro editado com sucesso!',
            showCancelButton: false,
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => { fetchParametersFromAPI(id) },
          });
        } catch (error: any) {
          console.error(error);

          if (error.response) {
            const status = error.response.status;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar parâmetro, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar parâmetro. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              confirmButtonText: 'Ok',
            });
          }
          setSubmitting(false);
          setIsLoading(false);
        } finally {
          setIsEditing(false);
          formikModalListagemListagem.resetForm();
        }
      } else {
        try {
          values.idConfigRotinaSQL = Number(id);
          await addParametroRotinaSQL(values);
          setSubmitting(false);
          setIsLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Parâmetro cadastrado com sucesso!',
            showCancelButton: false,
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => { },
          });
        } catch (error: any) {
          console.error(error);

          if (error.response) {
            const status = error.response.status;

            if (status === 409) {
              Swal.fire({
                icon: 'error',
                title:
                  'Erro ao salvar parâmetro, verifique as informações preenchidas e tente novamente',
                confirmButtonText: 'Ok',
              });
              setStatus('Ocorreu um erro ao salvar parâmetro. Por favor, tente novamente.');
            } else if (status === 401) {
              Swal.fire({
                icon: 'info',
                title: 'Por questões de segurança, por favor faça login novamente',
                confirmButtonText: 'Ok',
              }).then(() => {
                window.open('/auth', '_blank');
              });
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              confirmButtonText: 'Ok',
            });
          }
          setSubmitting(false);
          setIsLoading(false);
        }
        formikModalListagemListagem.resetForm();
      }
    },
  });


  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };



  const [isParamRotinaData, setIsParamRotinaData] = useState<IParametroRotinaSQL[]>([]);

  const fetchParametersFromAPI = async (
    idRotinaSql: string | number
  ): Promise<IParametroRotinaSQL[]> => {
    try {
      const response = await getConfiguracaoRotinaSqlId(idRotinaSql);
      const { parametros } = response.data;


      setIsParamRotinaData(parametros);

      return parametros;
    } catch (errors: any) {
      const { error } = errors;

      const status = error.response.status;

      if (status === 401 || 403) {
        Swal.fire({
          icon: 'error',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        });
      }

      Swal.fire({
        icon: 'error',
        title: error,
        confirmButtonText: 'Ok',
      });

      return [];
    }
  };

  const fetchRotinaFromAPI = async (): Promise<IRotinasSQL> => {
    try {
      const response = await getConfiguracaoRotinaSqlId(id);
      const data = response.data;

      return data as IRotinasSQL;
    } catch (errors: any) {
      const { error } = errors;
      const status = error.response.status;

      if (status === 401 || 403) {
        Swal.fire({
          icon: 'error',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        });
      }

      Swal.fire({
        icon: 'error',
        title: error,
        confirmButtonText: 'Ok',
      });

      return {} as IRotinasSQL;
    }
  };

  const formikModalExecutar = useFormik({
    initialValues: {
      parametros: '',
    },
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setIsLoading(true);

      try {
        const response = await executarRotinaSQL(id, values);
        setSubmitting(false);
        setIsLoading(false);

        if (response.data.return !== undefined) {
          Swal.fire({
            icon: 'info',
            title: 'Retorno da Rotina:',
            text: response.data.return[0] === 0 ? 'false' : (response.data.return[0] !== null ? response.data.return[0].toString() : ''),
            showCancelButton: false,
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => { },
          });
        } else {
          Swal.fire({
            icon: 'success',
            title: 'Rotina executada com sucesso!',
            showCancelButton: false,
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            didClose: () => { },
          });
        }
      } catch (error: any) {
        console.error(error);

        if (error.response) {
          const status = error.response.status;

          if (status === 409) {
            Swal.fire({
              icon: 'error',
              title:
                'Erro ao executar a rotina, verifique as informações preenchidas e tente novamente',
              confirmButtonText: 'Ok',
            });
            setStatus('Ocorreu um erro ao executar a rotina. Por favor, tente novamente.');
          } else if (status === 401) {
            Swal.fire({
              icon: 'info',
              title: 'Por questões de segurança, por favor faça login novamente',
              confirmButtonText: 'Ok',
            }).then(() => {
              window.open('/auth', '_blank');
            });
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
              confirmButtonText: 'Ok',
            });
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: error.message || 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
            confirmButtonText: 'Ok',
          });
        }
        setSubmitting(false);
        setIsLoading(false);
      }
      formikModalExecutar.resetForm();
    },
  });

  const renderJsonContent = (item: IRotinasSQL) => {
    if (!item || Object.keys(item).length === 0) return <p>Sem dados para exibir.</p>;

    const handleSubmitModalAction = () => {
      const parametrosErrors = formikModalExecutar.errors.parametros;

      if (item.parametros) {
        formikModalExecutar.validateField('parametros');
        if (parametrosErrors) {
          Swal.fire({
            icon: 'error',
            title: 'Erro ao executar a rotina',
            text: 'Por favor, preencha os parâmetros corretamente.',
            confirmButtonText: 'Ok',
          });
          return;
        }
      }

      formikModalExecutar.handleSubmit();
    };

    const parametrosAtivos =
      item.parametros?.filter((param) => param.status === 'ATIVO') || [];
    const containerStyle = { fontFamily: 'Arial, sans-serif', marginLeft: '10px' };
    const headerStyle = { fontSize: '1.5rem' };
    const textStyle = { fontSize: '1rem' };

    return (
      <div style={containerStyle}>
        <h4 style={headerStyle}>{item.titulo}</h4>

        {item.descricao && (
          <p style={textStyle}>
            <strong>Descrição:</strong> {item.descricao}
          </p>
        )}

        <p style={textStyle}>
          <strong>Stored Procedure:</strong> {item.storedProcedure}
        </p>

        <p style={textStyle}>
          <strong>Tem Retorno:</strong> {item.hasReturn ? 'Sim' : 'Não'}
        </p>

        {parametrosAtivos.length > 0 && (
          <>
            <div style={{ marginTop: '20px' }}>
              <h5 style={{ fontSize: '1.2rem', marginBottom: '10px' }}>
                Parâmetros:
              </h5>
              <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '20px' }}>
                {parametrosAtivos.map((param) => (
                  <div key={param.id} style={{ marginTop: '10px', ...textStyle }}>
                    <p>
                      <strong>Título:</strong> {param.titulo}
                    </p>
                    {param.descricao && (
                      <p>
                        <strong>Descrição:</strong> {param.descricao}
                      </p>
                    )}
                    <p>
                      <strong>Parameter:</strong> {param.parameter}
                    </p>
                  </div>
                ))}
              </div>
            </div>

            <div style={{ marginTop: '20px' }}>
              <h5 style={{ fontSize: '1.2rem' }}>Digite os parâmetros:</h5>
              <input
                type="text"
                placeholder="Digite os parâmetros separados por vírgula"
                {...formikModalExecutar.getFieldProps('parametros')}
                className="form-control"
                style={{ padding: '10px', fontSize: '1rem', width: '100%' }}
              />
              <p style={{ fontSize: '0.9rem', marginTop: '10px' }}>
                <strong>Importante:</strong> Os parâmetros devem ser separados por vírgula.
              </p>
            </div>
          </>
        )}

        <div style={{ marginTop: '10px' }}>
          <button onClick={handleSubmitModalAction} className="btn btn-info">
            Executar Rotina
          </button>
        </div>
      </div>
    );
  };


  const columnsParameter: {
    Header: string;
    accessor?: keyof IParametroRotinaSQL;
    Cell?: (parametro: IParametroRotinaSQL) => JSX.Element;
  }[] = [
      { Header: 'Titulo', accessor: 'titulo' },
      { Header: 'Descrição', accessor: 'descricao' },
      { Header: 'Parâmetro', accessor: 'parameter' },
      { Header: 'Status', accessor: 'status' },
      {
        Header: 'Ações',
        Cell: (parametro: IParametroRotinaSQL) => (
          <div className="d-flex justify-content-center">
            <button
              onClick={() => handleOnEditParametro(parametro)}
              className='btn btn-primary d-flex align-items-center small'
              data-toggle='tooltip'
              data-placement='top'
              title='Editar'
            >
              <i className='bi bi-pencil me-2'></i> Editar
            </button>
          </div>
        ),
      },
    ];




  const handleOnEditParametro = (parametro: IParametroRotinaSQL) => {
    setIsEditingParametro(true);
    setIdParametro(String(parametro.id));

    formikModalListagemListagem.setValues({
      idConfigRotinaSQL: parametro.idConfigRotinaSQL,
      titulo: parametro.titulo,
      descricao: parametro.descricao,
      parameter: parametro.parameter,
      status: parametro.status,
    });
  };

  const handleOnEdit = (rowOriginal: Object) => {
    setIsEditing(true);

    setId(String(rowOriginal['id']));
    formik.setValues({
      titulo: rowOriginal['titulo'],
      descricao: rowOriginal['descricao'],
      storedProcedure: rowOriginal['storedProcedure'],
      status: rowOriginal['status'],
      hasReturn: rowOriginal['hasReturn'],
    });
  };

  const handleSearch = async () => {
    setIsLoadingSearch(true);
    try {
      const response = await getConfiguracaoRotinaSql({
        page: 0,
        titulo: formik.values.titulo || '',
        descricao: formik.values.descricao || '',
        storedProcedure: formik.values.storedProcedure || '',
        status: formik.values.status || '',
        hasReturn: formik.values.hasReturn || '',
      });

      const { content } = response.data;
      setIsRotinasData(content);
      setIsHasMore(false);
      setIsPage(0);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar dados',
        text: 'Não foi possível realizar a pesquisa. Tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    } finally {
      setIsLoadingSearch(false);
    }
  };

  const getRotinasData = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await getConfiguracaoRotinaSql({
        page,
        titulo: '',
        descricao: '',
        storedProcedure: '',
        status: '',
        hasReturn: '',
      });

      const { content, totalPages } = response.data;

      setIsRotinasData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1);

      setIsLoading(false);
    } catch (errors: any) {
      const { error } = errors;

      const status = error.response.status;

      if (status === 401 || 403) {
        Swal.fire({
          icon: 'error',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        });
      }

      Swal.fire({
        icon: 'error',
        title: error,
        confirmButtonText: 'Ok',
      });

      setIsLoading(false);
    }
  };

  const resetForm2 = () => {
    formikModalExecutar.resetForm()
  }

  useEffect(() => {
    getRotinasData(isPage);
  }, [isPage]);

  const handleSubmitWithTimeout = async (event: React.FormEvent) => {
    event.preventDefault();

    setTimeout(() => {
      formik.setErrors({});
    }, 3000);

    formik.handleSubmit();
  };

  useEffect(() => {
    if (id) {
      fetchParametersFromAPI(id);
    }
  }, [isToggleModalListagem]);

  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const resetFormik = () => {
    formikModalListagemListagem.resetForm();
  };

  return (
    <>
      <DescriptionComponent description='Rotinas SQL' />

      <div className='mt-10'>
        <h4 className='text-dark'>{isEditing ? 'EDIÇÃO' : 'FILTROS DE PESQUISA'}</h4>
        <form onSubmit={handleSubmitWithTimeout} className='form-control-solid row mb-5'>
          <RowBootstrap className='col-12 mb-4'>
            <Col className='col-sm-4'>
              <label className='form-label'>Titulo:</label>
              <input
                type='text'
                placeholder='Digite o titulo da Rotina'
                {...formik.getFieldProps('titulo')}
                className={`form-control ${formik.errors.titulo && formik.touched.titulo ? 'is-invalid' : ''
                  }`}
              />
              {formik.errors.titulo && formik.touched.titulo && (
                <div className='invalid-feedback'>{formik.errors.titulo}</div>
              )}
            </Col>
            <Col className='col-sm-6'>
              <label className='form-label'>Descrição:</label>
              <textarea
                rows={1}
                placeholder='Digite a descrição da rotina'
                {...formik.getFieldProps('descricao')}
                className={`form-control ${formik.errors.descricao && formik.touched.descricao ? 'is-invalid' : ''
                  }`}
              />
              {formik.errors.descricao && formik.touched.descricao && (
                <div className='invalid-feedback'>{formik.errors.descricao}</div>
              )}
            </Col>
            <Col className='col-sm-2'>
              <div>
                <label className='form-label'>Status:</label>
                <select
                  className={`form-select ${formik.errors.status && formik.touched.status ? 'is-invalid' : ''
                    }`}
                  {...formik.getFieldProps('status')}
                >
                  <option value=''>Selecione</option>
                  <option value='ATIVO'>Ativo</option>
                  <option value='INATIVO'>Inativo</option>
                </select>
              </div>
              {formik.errors.status && formik.touched.status && (
                <div className='invalid-feedback'>{formik.errors.status}</div>
              )}
            </Col>
          </RowBootstrap>
          <RowBootstrap className='col-12'>
            <Col className='col-sm-5'>
              <label className='form-label'>Stored Procedure:</label>
              <input
                type='text'
                placeholder='Digite a Rotina conforme banco de dados'
                {...formik.getFieldProps('storedProcedure')}
                className={`form-control ${formik.errors.storedProcedure && formik.touched.storedProcedure
                  ? 'is-invalid'
                  : ''
                  }`}
              />
              {formik.errors.storedProcedure && formik.touched.storedProcedure && (
                <div className='invalid-feedback'>{formik.errors.storedProcedure}</div>
              )}
            </Col>
            <Col className='col-sm-2'>
              <div>
                <label className='form-label'>Tem Retorno:</label>
                <select
                  className={`form-select ${formik.errors.hasReturn && formik.touched.hasReturn ? 'is-invalid' : ''
                    }`}
                  {...formik.getFieldProps('hasReturn')}
                >
                  <option value=''>Selecione</option>
                  <option value='true'>Sim</option>
                  <option value='false'>Não</option>
                </select>
              </div>
              {formik.errors.status && formik.touched.status && (
                <div className='invalid-feedback'>{formik.errors.status}</div>
              )}
            </Col>

            <Col className='col-sm-2'>
              <label className='form-label'>&nbsp;</label>

              <button
                type='button'
                onClick={handleSearch}
                disabled={isLoadingSearch}
                className='btn btn-success form-control d-flex align-items-center justify-content-center'
              >
                {isLoadingSearch ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-search me-2'></i>
                    Pesquisar
                  </>
                )}
              </button>
            </Col>
            <Col className='col-sm-2'>
              <label className='form-label'>&nbsp;</label>
              <button
                type='submit'
                className='btn btn-primary form-control d-flex align-items-center justify-content-center'
                disabled={formik.isSubmitting}
              >
                {isLoadingSearch ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className={isEditing ? 'bi bi-pencil-square me-2' : 'bi bi-plus me-2'}></i>
                    {isEditing ? 'Editar' : 'Cadastrar'}
                  </>
                )}
              </button>
            </Col>
            <Col className='col-sm-1'>
              <label className='form-label'>&nbsp;</label>

              <button
                type='button'
                className='btn btn-danger form-control d-flex align-items-center justify-content-center'
                onClick={() => {
                  formik.resetForm();
                  setIsEditing(false);
                  getRotinasData(0);
                }}
              >
                {isLoadingSearch ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <>
                    <i className='bi bi-x-circle me-2'></i>
                    Limpar
                  </>
                )}
              </button>
            </Col>
          </RowBootstrap>
          <div className='row mt-6'>
            <div className='col-sm-2 mb-6'></div>
          </div>
        </form>
      </div>

      <div className='table-responsive'>
        <table
          id='kt_table_formularios'
          className='table table-hover table-striped table-rounded table-row-bordered border'
          {...getTableProps()}
        >
          <thead className='thead-dark'>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<IRotinasSQL>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<IRotinasSQL>, i) => {
                prepareRow(row);
                return (
                  <CustomRow
                    row={row}
                    key={`row-${i}-${row.id}`}
                    showEditButton
                    onEdit={(id) => handleOnEdit(id)}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Sem Dados
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isHasMore && (
          <div className='d-flex justify-content-center align-items-center'>
            <button className='btn btn-primary m-5' onClick={loadMore}>
              {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
            </button>
          </div>
        )}
      </div>

      <ModalList
        title='Listar/Adicionar Parâmetros'
        isVisible={isToggleModalListagem}
        toggleModal={toggleModalListagem}
        columns={columnsParameter}
        isForm={true}
        resetFormik={() => resetFormik()}
        onFormSubmit={isSubmit}
        isParamRotinaData={isParamRotinaData}
        form={
          <div className="d-flex justify-content-center align-items-center">
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                await formikModalListagemListagem.handleSubmit();
                setIsEditingParametro(false);
                formikModalListagemListagem.resetForm();
                setIsSubmit(true);
              }}
              className="form-control-solid row m-5"
            >
              <RowBootstrap className="col-12 mb-4">
                <Col className="col-sm-4">
                  <label className="form-label">Titulo:</label>
                  <input
                    type="text"
                    placeholder="Digite o titulo da Rotina"
                    {...formikModalListagemListagem.getFieldProps("titulo")}
                    className={`form-control ${formikModalListagemListagem.errors.titulo &&
                      formikModalListagemListagem.touched.titulo
                      ? "is-invalid"
                      : ""
                      }`}
                  />
                  {formikModalListagemListagem.errors.titulo &&
                    formikModalListagemListagem.touched.titulo && (
                      <div className="invalid-feedback">
                        {formikModalListagemListagem.errors.titulo}
                      </div>
                    )}
                </Col>
                <Col className="col-sm-6">
                  <label className="form-label">Descrição:</label>
                  <textarea
                    rows={1}
                    placeholder="Digite a descrição da rotina"
                    {...formikModalListagemListagem.getFieldProps("descricao")}
                    className={`form-control ${formikModalListagemListagem.errors.descricao &&
                      formikModalListagemListagem.touched.descricao
                      ? "is-invalid"
                      : ""
                      }`}
                  />
                  {formikModalListagemListagem.errors.descricao &&
                    formikModalListagemListagem.touched.descricao && (
                      <div className="invalid-feedback">
                        {formikModalListagemListagem.errors.descricao}
                      </div>
                    )}
                </Col>
                <Col className="col-sm-2">
                  <div>
                    <label className="form-label">Status:</label>
                    <select
                      className={`form-select ${formikModalListagemListagem.errors.status &&
                        formikModalListagemListagem.touched.status
                        ? "is-invalid"
                        : ""
                        }`}
                      {...formikModalListagemListagem.getFieldProps("status")}
                    >
                      <option value="">Selecione</option>
                      <option value="ATIVO">Ativo</option>
                      <option value="INATIVO">Inativo</option>
                    </select>
                  </div>
                  {formikModalListagemListagem.errors.status &&
                    formikModalListagemListagem.touched.status && (
                      <div className="invalid-feedback">
                        {formikModalListagemListagem.errors.status}
                      </div>
                    )}
                </Col>
              </RowBootstrap>
              <RowBootstrap className="col-12">
                <Col className="col-sm-5">
                  <label className="form-label">Parâmetro:</label>
                  <input
                    type="text"
                    placeholder="Digite o parâmetro conforme banco de dados"
                    {...formikModalListagemListagem.getFieldProps("parameter")}
                    className={`form-control ${formikModalListagemListagem.errors.parameter &&
                      formikModalListagemListagem.touched.parameter
                      ? "is-invalid"
                      : ""
                      }`}
                  />
                  {formikModalListagemListagem.errors.parameter &&
                    formikModalListagemListagem.touched.parameter && (
                      <div className="invalid-feedback">
                        {formikModalListagemListagem.errors.parameter}
                      </div>
                    )}
                </Col>
                <Col className="col-sm-4">
                  <label className="form-label">&nbsp;</label>
                  <button
                    type="button"
                    className="btn btn-primary form-control d-flex align-items-center justify-content-center"
                    disabled={formikModalListagemListagem.isSubmitting}
                    onClick={() => formikModalListagemListagem.handleSubmit()}
                  >
                    {isLoadingSearch ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <>
                        <i
                          className={
                            isEditingParametro
                              ? "bi bi-pencil-square me-2"
                              : "bi bi-plus me-2"
                          }
                        ></i>
                        {isEditingParametro ? "Editar" : "Cadastrar"}
                      </>
                    )}
                  </button>
                </Col>
                <Col className="col-sm-2">
                  <label className="form-label">&nbsp;</label>
                  <button
                    type="button"
                    className="btn btn-danger form-control d-flex align-items-center justify-content-center"
                    onClick={() => {
                      formikModalListagemListagem.resetForm();
                      setIsEditingParametro(false);
                    }}
                  >
                    {isLoadingSearch ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      <>
                        <i className="bi bi-x-circle me-2"></i>
                        Limpar
                      </>
                    )}
                  </button>
                </Col>
              </RowBootstrap>
            </form>
          </div>
        }
      />

      <ModalAction<IRotinasSQL>
        title='Executar Rotina'
        resetFormik={() => resetForm2()}
        isVisible={isToggleModalExecutar}
        toggleModal={toggleModalExecutar}
        fetchItem={fetchRotinaFromAPI}
        renderContent={renderJsonContent}
      />
    </>
  );


};

export default ConfiguracoesRotinasListagem;
