import { useEffect, useMemo, useState } from "react";
import { Row as Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { ColumnInstance, useTable, Row, Column } from "react-table";
import { DescriptionComponent } from "../../../shared/components";
import { CustomRow } from '../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import { CustomHeaderColumn } from '../../../modules/apps/user-management/users-list/table/columns/CustomHeaderColumn';
import { ITipoVenda } from "../../../shared";
import { getTipoVenda, getTipoVendaById } from "../listagem_requests/listagem_requests";

const TipoVendaListagem: React.FC = () => {
  const tipoVendaColumns: Column<ITipoVenda>[] = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Titulo',
      accessor: 'titulo',
    },
    {
      Header: 'Descrição',
      accessor: 'descricao',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ value }: { value: string }) => <span>{value === 'ATIVO' ? 'Ativo' : 'Inativo'}</span>,
    },
    {
      Header: 'Ações',
      Cell: ({ row }) => (
        <button
          onClick={() => handleOnEdit(row.original.id!)}
          className='btn btn-success btn-sm bi bi-pencil'
          data-toggle='tooltip'
          data-placement='top'
          title='Editar'
        />
      ),
    },
  ];

  const navigate = useNavigate();

  const [isTipoVendaData, setIsTipoVendaData] = useState<ITipoVenda[]>([])
  const [isPage, setIsPage] = useState<number>(0);
  const [isHasMore, setIsHasMore] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const data = useMemo(() => isTipoVendaData, [isTipoVendaData]);
  const columns = useMemo(() => tipoVendaColumns, []);
  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const loadMore = () => {
    if (isHasMore && !isLoading) {
      setIsPage((prevPage) => prevPage + 1);
    }
  };

  const handleOnEdit = (id: string | number) => {
    navigate(`/editar-tipo-venda/${id}`);
  };

  const getTipoVendaData = async (page = 0) => {
    setIsLoading(true);
    try {
      const response = await getTipoVenda({ page });

      const { content, totalPages } = response.data;

      setIsTipoVendaData((prev) => (isPage === 0 ? content : [...prev, ...content]));

      setIsHasMore(isPage < totalPages - 1)

      setIsLoading(false);
    } catch (errors: any) {
      const { error } = errors;

      const status = error.response.status.code;

      if (status === 401 || 403) {
        Swal.fire({
          icon: 'error',
          title: 'Por questões de segurança, por favor faça login novamente',
          confirmButtonText: 'Ok',
        });
      }

      Swal.fire({
        icon: 'error',
        title: error,
        confirmButtonText: 'Ok',
      });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    getTipoVendaData(isPage);
  }, [isPage]);

  return (
    <>
      <DescriptionComponent
        description='Listagem Tipo de Venda'
        withLink
        buttonTitle='+ Novo Tipo de Venda'
        redirectTo='/cadastro-tipo-venda'
      />

      <div className='table-responsive'>
        <table
          id='kt_table_tipo-venda'
          className='table table-hover table-striped table-rounded table-row-bordered border'
          {...getTableProps()}
        >
          <thead className='thead-dark'>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              {headers.map((column: ColumnInstance<ITipoVenda>) => (
                <CustomHeaderColumn key={column.id} column={column} />
              ))}
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
            {rows.length > 0 ? (
              rows.map((row: Row<ITipoVenda>, i) => {
                prepareRow(row);
                return (
                  <CustomRow
                    row={row}
                    key={`row-${i}-${row.id}`}
                    showEditButton
                    onEdit={(id) => handleOnEdit(id)}
                  />
                );
              })
            ) : (
              <tr>
                <td colSpan={4}>
                  <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                    Sem Dados
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {isHasMore && (
          <div className='d-flex justify-content-center align-items-center'>
            <button className='btn btn-primary m-5' onClick={loadMore}>
              {isLoading ? <Spinner size='sm' animation='border' /> : 'Carregar Mais'}
            </button>
          </div>
        )}
      </div>
    </>
  );
}

export default TipoVendaListagem;