import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";
import { Modal } from "../../../../../shared"
import { useEffect, useState } from "react";
import { ClienteOption } from "../../../../cadastros/proposta-pedido/types/interface";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Swal from "sweetalert2";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const ModalEntradaInventario = ({ isOpen, onHide, isEdit, dataLine, reload, clearFiltros }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [selectedMotivo, setSelectedMotivo] = useState<ClienteOption | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [dataMovimento, setDataMovimento] = useState<string | null>(null)

    const cadastrarInventario = async () => {
        try {
            if(selectedMotivo &&  dataMovimento){
                let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/inventario`;

                const response = await axios.post(url, {
                    "idMotivo": selectedMotivo ? selectedMotivo.value : null,
                    "dataMovimento": dataMovimento ? `${dataMovimento}T21:14:41.462Z` : null,
                    "status": status
                });
    
                Swal.fire({
                    icon: 'success',
                    title: `Inventário cadastrado com sucesso`,
                    timer: 3000,
                    timerProgressBar: true,
                }).then(() => {
                    clearForm()
                    onClose()
                })    
            }
            
        }
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro no cadastro `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
    }
    const editarInventario = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/inventario/${dataLine[0]}`;

            const response = await axios.put(url, {
                "idMotivo": selectedMotivo ? selectedMotivo.value : null,
                "dataMovimento": `${dataMovimento}T21:14:41.462Z`,
                "status": status
            });
            Swal.fire({
                icon: 'success',
                title: `Inventário  editado com sucesso`,
                timer: 3000,
                timerProgressBar: true,

            }).then(() => {
                clearForm()
                onClose()
                reload()

            });

        }
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro na edicao `,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
    }
    const onClose = () => {
        clearForm()
        clearFiltros()
        reload!()
        onHide!();
    }
    const clearForm = () => {
        setStatus('ATIVO')
        setDataMovimento(null)
        setSelectedMotivo(null)
    }
    const submitButton = (
        <Button
            type='submit'
            variant='success'
            onClick={() => isEdit ? editarInventario() : cadastrarInventario()}
        >
            {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? "Editar" : "Cadastrar"}
        </Button>
    );
    const loadOptionsMotivo = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/inventario-motivos/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 1000,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: item.titulo,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }

    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };
    function formatDate(inputDate: string): string {
        // Converte o inputDate (que pode ser no formato ISO ou no formato 'yyyy-mm-dd') para um objeto Date
        const date = new Date(inputDate);
      
        // Verifica se a data é válida
        if (isNaN(date.getTime())) {
          throw new Error("Data inválida");
        }
      
        // Obtém o ano, mês e dia
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mês é zero-indexed, por isso somamos 1
        const day = date.getDate().toString().padStart(2, '0'); // Garante que o dia tenha 2 dígitos
      
        // Retorna a data no formato 'yyyy-mm-dd'
        return `${year}-${month}-${day}`;
      }

    useEffect(() => {
        if (isEdit) {
            setSelectedMotivo({
                value: dataLine[3],
                label: ` ${dataLine[4]}`,
            })
            setDataMovimento(formatDate(dataLine[5]))
            setStatus(dataLine[6])
        }
    }, [dataLine])

    return (
        <Modal {...modalConfigs} >
            <div className='form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    {isEdit ? 'Editar' : 'Cadastrar'}  Inventário 
                </h1>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            <div>
                <form action="" >
                    <Row className='my-4 mt-8'>
                        <Col>
                            <label htmlFor="" className="form-label"  >Motivo:</label>
                            <AsyncSelect 
                                defaultOptions
                             placeholder='Selecione o motivo' onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedMotivo(null);
                                    }
                                }} value={selectedMotivo} onChange={(e) => { setSelectedMotivo(e) }} loadOptions={loadOptionsMotivo}></AsyncSelect>
                        {(selectedMotivo === null ) && <span className={` form-label text-danger`}>*Por favor, informe o Motivo</span>}
                        </Col>

                        <Col>
                            <label htmlFor="" className="form-label"  >Data do Movimento:</label>   
                            <input value={dataMovimento ? dataMovimento : ""} onChange={(e) => { setDataMovimento((e.target.value)) }} className="form-control" type="date" placeholder="" />
                            {(dataMovimento === null || dataMovimento === '' ) && <span className={` form-label text-danger`}>*Por favor, informe a Data do Movimento</span>}

                        </Col>
                        
                    </Row>
                    <Row>
                    <Col className="col-6">
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select"
                                onChange={(e) => { setStatus(e.target.value) }}
                                value={status ? status : ""}


                                name="" id="">
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                    </Row>
                </form>
            </div>
        </Modal >
    )
}

export default ModalEntradaInventario