import { useEffect, useState } from "react";
import axios from "axios";

interface OptionType {
  value: number;
  label: string;
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT

const useOptionsTipoVenda = () => {
  const [optionsTipoVenda, setOptionsTipoVenda] = useState<OptionType[]>([]);

  useEffect(() => {
    async function fetchOptions() {
      try {
        const response = await axios.get(`https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/tipoVenda/optionsTipoVenda`);
        const data = await response.data;
        const formattedOptions = data.map(item => ({
          value: item.id,
          label: item.titulo
        }));
        setOptionsTipoVenda(formattedOptions);
      } catch (error) {
        console.error("Erro ao buscar opções:", error);
      }
    }
    fetchOptions();
  }, []);

  return { optionsTipoVenda };
};

export default useOptionsTipoVenda;