import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import { useAuth } from '../../../modules/auth';
import { sincronizarCliente, buscaHistoricoCompras, imprimirPDFProduto, visualizarPDFProduto, visualizarPDFCliente, imprimirPDFCliente } from '../listagem_requests/listagem_requests';
import { Modal, Button } from 'react-bootstrap';
import ReactDOMServer from 'react-dom/server';
import { PageTitle } from '../../../../_sysled/layout/core';
import ModalHistorico from './components/ModalHistorico';
import moment from 'moment';
import './styles/tableHistorico.css';
import InputMask from 'react-input-mask';
import { PDFView } from '../../../shared';

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

interface Cliente {
  id: number;
  codigo: number;
  nome: string;
  razaosocial: string;
  tipocliente: number;
  telefone: string;
  status: number;
  cpf: string;
  cnpj: string;
  cep: string;
  cidade: string;
  estado: string;
  endereco: string;
  bairro: string;
  numero: string;
}

export function ListagemClientes() {
  const [clientes, setClientes] = useState<Cliente[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchFiltro, setSearchFiltro] = useState('todos');
  const [modalShow, setModalShow] = useState(false); // Estado para controlar a exibição da modal
  const [historicoCompras, setHistoricoCompras] = useState([]);
  const [clienteSelecionado, setClienteSelecionado] = useState<Cliente | null>(null); // Estado para armazenar o cliente selecionado
  const { currentUser } = useAuth();
  const [filtroHistorico, setFiltroHistorico] = useState('');

  const [modalShowPDFViewer, setModalShowPDFViewer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isIdSeparacao, setIsIdSeparacao] = useState<string | number>();
  const [isPdfUrl, setIsPdfUrl] = useState<string>();


  useEffect(() => {
    setLoading(true);
    fetchClientes();
  }, []);

  useEffect(() => {
    setSearchTerm('');
  }, [searchFiltro]);

  const fetchClientes = async (search = '', filtro = '') => {
    try {
      setLoading(true);
      const response = await axios.get(
        `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/clientes`,
        {
          params: {
            search: search,
            filtro: filtro,
          },
        }
      );

      if (response.status === 200) {
        setLoading(false);
        setClientes(response.data.content);
      } else if (response.status === 204) {
        setLoading(false);
        const Toast = Swal.mixin({
          toast: true,
          position: 'center-end',
          iconColor: 'blue',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.onmouseenter = Swal.stopTimer;
            toast.onmouseleave = Swal.resumeTimer;
          },
        });
        Toast.fire({
          icon: 'info',
          title: 'Não existe registros de Clientes para essa pesquisa',
        });
        setClientes([]);
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao carregar clientes',
        text: 'Ocorreu um erro ao carregar as clientes. Por favor, tente novamente mais tarde.',
        confirmButtonText: 'Ok',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    fetchClientes(searchTerm, searchFiltro);
  };

  const formatStatus = (status) => {
    switch (status) {
      case 1:
        return <Badge bg='success'>ATIVO</Badge>;
      case 0:
        return <Badge bg='danger'>INATIVO</Badge>;
      case 9:
        return <Badge bg='warning'>SINCRONIZADO</Badge>;
      default:
        return <Badge bg='light'>N/A</Badge>;
    }
  };

  const formatTipoCliente = (tipocliente) => {
    switch (tipocliente) {
      case 1:
        return <Badge bg='warning'>Pessoa Física</Badge>;
      case 0:
        return <Badge bg='primary'>Pessoa Jurídica</Badge>;
      default:
        return <Badge bg='light'>N/A</Badge>;
    }
  };

  const handleClickCompras = async (cliente: Cliente) => {
    setClienteSelecionado(cliente);

    try {
      setLoading(true);
      const codigosEmpresas = currentUser?.empresasVinculadas?.map((empresa) => empresa.codigo);
      const codigosEmpresasString = codigosEmpresas?.join(',');

      if (cliente.codigo === null || cliente.codigo === 0) {
        setLoading(false);
        setHistoricoCompras([]);
        setModalShow(true);
      } else {
        const response = await buscaHistoricoCompras(cliente.codigo, codigosEmpresasString || '');

        if (response.status === 200) {
          setLoading(false);
          setHistoricoCompras(response.data);
          setModalShow(true);
        } else {
          setLoading(false);
          console.error('Erro ao buscar histórico de compras');
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const renderizarHistoricoCompras = (historicoCompras) => {
    const filtrarHistoricoCompras = () => {
      return historicoCompras.filter((item) =>
        item.idProduto.toLowerCase().includes(filtroHistorico.toLowerCase())
      );
    };

    const handleFiltrar = () => {
      // Atualizar os dados da tabela após filtragem
      if (filtroHistorico.trim() === '') {
        // Se o filtro estiver vazio, exibir todos os itens da tabela
        setHistoricoCompras(historicoCompras);
      } else {
        // Caso contrário, aplicar o filtro
        setHistoricoCompras(filtrarHistoricoCompras());
      }
    };

    return (
      <div className='text-center table-container table-responsive'>
        <table className=' table-hover tablehist table-rounded table-row-bordered'>
          <thead className='thead-dark'>
            <tr className='fw-bold fs-6 text-gray-800'>
              <th className='text-center' style={{ width: '5%' }}>
                Id. Prevenda
              </th>
              <th className='text-center' style={{ width: '10%' }}>
                Consultor
              </th>
              <th className='text-center' style={{ width: '5%' }}>
                Id. Produto
              </th>
              <th className='text-center' style={{ width: '40%' }}>
                Produto
              </th>
              <th className='text-center' style={{ width: '5%' }}>
                Quant.
              </th>
              <th className='text-center' style={{ width: '5%' }}>
                Preço Venda
              </th>
              <th className='text-center' style={{ width: '5%' }}>
                Tipo Produto
              </th>
              <th className='text-center' style={{ width: '5%' }}>
                Prod. Filho
              </th>
              <th className='text-center' style={{ width: '5%' }}>
                Grupo
              </th>
              <th className='text-center' style={{ width: '5%', marginRight: '10px' }}>
                Data
              </th>
            </tr>
          </thead>
          <tbody>
            {historicoCompras.map((item, index) => (
              <tr key={index}>
                <td className='text-center'>{item.idPrevenda}</td>
                <td className='text-center' style={{ width: '30px', height: '30px' }}>
                  {item.idConsultor} - {item.consultor}
                </td>
                <td className='text-center'>{item.idProduto}</td>
                <td className='text-center' style={{ width: '50px', height: '50px' }}>
                  {item.produto}
                </td>
                <td className='text-center'>{item.produtoQuantidade}</td>
                <td className='text-center'>{`R$${parseFloat(item.precoVenda).toLocaleString(
                  'pt-BR',
                  { minimumFractionDigits: 2, maximumFractionDigits: 2 }
                )}`}</td>
                <td className='text-center'>{item.tipoProduto}</td>
                <td className='text-center'>{item.produtoFilho}</td>
                <td className='text-center'>{item.grupo}</td>
                <td className='text-center'>
                  {moment(item.dataFinalizacaoPrevenda).format('DD/MM/YYYY')}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    if (searchFiltro === 'id') {
      // Permite apenas números
      if (/^\d*$/.test(value)) {
        setSearchTerm(value);
      }
    } else {
      setSearchTerm(value);
    }
  };

  const renderInput = () => {
    if (searchFiltro === 'cpf') {
      return (
        <InputMask
          mask='999.999.999-99'
          type='text'
          placeholder='Pesquisar clientes'
          className='form-control'
          value={searchTerm}
          onChange={handleInputChange}
        />
      );
    } else if (searchFiltro === 'cnpj') {
      return (
        <InputMask
          mask='99.999.999/9999-99'
          type='text'
          placeholder='Pesquisar clientes'
          className='form-control'
          value={searchTerm}
          onChange={handleInputChange}
        />
      );
    } else if (searchFiltro === 'id') {
      return (
        <input
          type='text'
          placeholder='Pesquisar clientes'
          className='form-control'
          value={searchTerm}
          onChange={handleInputChange}
        />
      );
    } else {
      return (
        <input
          type='text'
          placeholder='Pesquisar clientes'
          className='form-control'
          value={searchTerm}
          onChange={handleInputChange}
        />
      );
    }
  };

  return (
    <div>
      <div
        className='mb-3 form-label-container'
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
      >
        <h1 className='text-dark fw-bolder'>Listagem de Clientes</h1>
        <Link
          to='/cadastro-clientes'
          className='btn btn-success btn-sm '
          style={{ fontSize: '14px' }}
        >
          + Novo Cliente
        </Link>
      </div>
      <div style={{ marginBottom: '8px' }}>
        <div style={{ borderBottom: '2px solid #000' }}></div>
      </div>
      <div className='mt-10'>
        <h4 className='text-dark'>FILTROS DE PESQUISA </h4>

        <form onSubmit={handleSearch} className='form-control-solid row mb-5'>
          <div className='row mt-8 gap-4'>
            <div className='col-sm-3 '>
              <select
                className='form-select'
                value={searchFiltro}
                onChange={(e) => setSearchFiltro(e.target.value)}
              >
                <option value='todos'>Selecione</option>
                <option value='id'>Id Cliente</option>
                <option value='nome'>Nome/Fantasia</option>
                <option value='cpf'>CPF</option>
                <option value='cnpj'>CNPJ</option>
              </select>
            </div>
            <div className='col-sm-3'>{renderInput()}</div>
          </div>
          <div className='row mt-6'>
            <div className='col-sm-2  mb-6'>
              <button type='submit' className='btn btn-success form-control'>
                Pesquisar
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className='table-responsive'>
        {loading ? (
          <div
            className='d-flex justify-content-center align-items-center'
            style={{ height: '100%' }}
          >
            <div
              className='spinner-border text-success m-5'
              style={{ width: '3rem', height: '3rem' }}
            >
              <span className='sr-only'>Loading...</span>
            </div>
          </div>
        ) : (
          <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7'>
            <thead className='thead-dark'>
              <tr className='fw-bold fs-6 text-gray-800'>
                <th className='text-center'>Id</th>
                <th className='text-center'>Nome</th>
                <th className='text-center'>Tipo</th>
                <th className='text-center'>Histórico</th>
                <th className='text-center'>Editar</th>
                <th className='text-center'>Sincronizar</th>
                <th className='text-center'>Pdf</th>
              </tr>
            </thead>
            <tbody>
              {clientes.map((cliente) => (
                <tr key={cliente.id}>
                  <td className='text-center'>{cliente.id}</td>
                  <td className='text-center'>
                    <div>{cliente.nome || 'N/A'}</div>
                    <div>{formatStatus(cliente.status)}</div>
                  </td>
                  <td className='text-center'>{formatTipoCliente(cliente.tipocliente) || 'N/A'}</td>
                  <td className='text-center'>
                    <button
                      onClick={() => handleClickCompras(cliente)}
                      className='btn btn-info btn-sm'
                    >
                      <i className='bi bi-search'> Compras</i>
                    </button>
                  </td>
                  <td className='text-center'>
                    <Link to={`/editar-cliente/${cliente.id}`} className='btn btn-success btn-sm'>
                      Editar
                    </Link>
                  </td>
                  <td className='text-center'>
                    {cliente.status === 9 || cliente.status === 0 ? (
                      <button className='btn btn-primary btn-sm disabled' style={{ display: 'none' }}>
                        Sincronizar
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          if (cliente.tipocliente === 0 && (cliente.cnpj === '' || !cliente.cnpj)) {
                            Swal.fire({
                              title:
                                'Cliente(Pessoa Jurídica) sem CNPJ cadastrado. Por favor, atualize o cadastro do cliente e tente novamente.',
                              icon: 'info',
                              confirmButtonText: 'Ok',
                            });
                            return;
                          }

                          if (cliente.tipocliente === 1 && (cliente.cpf === '' || !cliente.cpf)) {
                            Swal.fire({
                              title:
                                'Cliente(Pessoa Física) sem CPF cadastrado. Por favor, atualize o cadastro do cliente e tente novamente.',
                              icon: 'info',
                              confirmButtonText: 'Ok',
                            });
                            return;
                          }

                          if (
                            cliente.cep === '' ||
                            cliente.cidade === '' ||
                            cliente.estado === '' ||
                            cliente.endereco === '' ||
                            cliente.bairro === '' ||
                            cliente.numero === ''
                          ) {
                            Swal.fire({
                              title:
                                'Cliente com endereço incompleto. Por favor, complete os campos de ENDEREÇO do Cliente.',
                              text: '',
                              icon: 'info',
                              confirmButtonText: 'Ok',
                            });
                            return;
                          }

                          Swal.fire({
                            title:
                              'Você tem certeza que deseja sincronizar o cliente com id: ' +
                              cliente.id +
                              '?',
                            icon: 'info',
                            showCancelButton: true,
                            confirmButtonText: 'Sim',
                            cancelButtonText: 'Não',
                            reverseButtons: true,
                          }).then((result) => {
                            if (result.isConfirmed) {
                              setLoading(true);
                              sincronizarCliente(cliente.id)
                                .then((response) => {
                                  let responseinfo = response.data;
                                  setLoading(false);
                                  if (response.status === 200) {
                                    Swal.fire({
                                      icon: 'success',
                                      title: 'Cliente sincronizada com sucesso!',
                                      confirmButtonText: 'Ok',
                                      reverseButtons: true,
                                    });
                                    fetchClientes(); // Recarrega a lista de prevendas após a clonagem
                                  } else if (response.status === 400) {
                                    setLoading(false);
                                    const errorMessage = responseinfo.message;
                                    Swal.fire({
                                      icon: 'info',
                                      title: 'Verifique as informações',
                                      text:
                                        errorMessage ||
                                        'Ocorreu um erro ao tentar sincronizar o cliente. Por favor, tente novamente mais tarde.',
                                      confirmButtonText: 'Ok',
                                    });
                                  }
                                })
                                .catch((error) => {
                                  setLoading(false);
                                  console.error(error);
                                  Swal.fire({
                                    icon: 'error',
                                    title: 'Erro ao sincronizar prevenda',
                                    text:
                                      error.response?.data?.message ||
                                      'Ocorreu um erro ao tentar sincronizar o cliente. Por favor, tente novamente mais tarde.',
                                    confirmButtonText: 'Ok',
                                  });
                                });
                            }
                          });
                        }}
                        className='btn btn-success btn-sm'
                        style={{ backgroundColor: '#3fd975', borderColor: '#0099ff' }}
                      >
                        Sincronizar
                      </button>
                    )}
                  </td>
                  <td>
                    <div className='dropdown text-center' style={{ marginLeft: '7px' }}>
                      <button className='btn btn-sm btn-danger dropdown-toggle' data-bs-toggle='dropdown'>
                        <i className='bi bi-file-earmark-arrow-down'>PDF</i>
                      </button>
                      <ul className='dropdown-menu'>
                        <li>
                          <button
                            className='dropdown-item'
                            style={{ color: 'red' }}
                            type='button'
                            onClick={() =>
                              visualizarPDFCliente(
                                setModalShowPDFViewer,
                                cliente.id,
                                setIsLoading,
                                setIsPdfUrl,
                                setIsIdSeparacao
                              )
                            }
                          >
                            VISUALIZAR PDF
                          </button>
                        </li>
                      </ul>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Modal size={'xl'} show={modalShow} centered={true} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Histórico de Compras</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {clienteSelecionado && (
            <div className=''>
              {' '}
              {/* Defina a altura máxima e adicione rolagem vertical */}
              <h3>{clienteSelecionado.nome}</h3>
              <hr />
              <div>
                {historicoCompras.length > 0 ? (
                  renderizarHistoricoCompras(historicoCompras)
                ) : (
                  <p>Não há histórico de compras disponível para este cliente.</p>
                )}
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={() => setModalShow(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size={'xl'}
        show={modalShowPDFViewer}
        onHide={() => setModalShowPDFViewer(false)}
        style={{ width: '100%' }}
      >
       <Modal.Header style={{ justifyContent: 'flex-end', gap: 10 }}>
        <Button
            variant='primary'
            onClick={() => imprimirPDFCliente(isIdSeparacao, setIsLoading)}
          >
            Download
          </Button>
          <Button variant='secondary' onClick={() => setModalShowPDFViewer(false)}>
            Fechar
          </Button>
        </Modal.Header>
        <Modal.Body>{isPdfUrl && <PDFView source={isPdfUrl} />}</Modal.Body>
       
      </Modal>
    </div>
  );
}
