import styled from "styled-components";

export const BagdeId = styled.div`
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 5px;

    background-color: rgb(0, 119, 255);

    p {
        margin: 0px;
        padding: 0px;

        font-size: x-small;
        color: #fff;
    }
`