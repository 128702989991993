// export {}; 
import { Col, Row, Spinner } from "react-bootstrap";
// import { AsyncSelectEmpresa, DescriptionComponent, smoothScrollToBottom, useModal } from "../../../shared";
import AsyncSelect from "react-select/async";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
// import FormEstoque from "./components/modal-estoque/FormEstoque";
// import ModalEstoques from "../proposta-pedido/components/ModalEstoques";
// import { Estoques } from "./interface";
import Swal from "sweetalert2";
import axios from "axios";
// import ModalEstoques from "../../proposta-pedido/components/ModalEstoques";
// import { AsyncSelectEmpresa, Estoques, smoothScrollToBottom, useModal } from "../../../../shared";
import { ClienteOption } from "../../../cadastros/proposta-pedido/types/interface";
import ModalEstoques from "./components/ModalEstoques";
import { AsyncSelectEmpresa, Estoques, smoothScrollToBottom, useModal } from "../../../../shared";
// import ModalEstoques from "./components/ModalEstoques";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
const ListagemEstoques = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isLoadingSearch, setIsLoadingSearch] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [countSkeleton, setCountSkeleton] = useState(40)
    const [hasMore, setHasMore] = useState<boolean>(false)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [dataLine, setDataLine] = useState<(string | number | null)[] | null>(null)

    const [page, setPage] = useState(0)
    const [estoques, setEstoques] = useState<Estoques[]>([])

    const [selectedEmpresa, setSelectedEmpresa] = useState<ClienteOption | null>(null)
    const [selectedTipoEstoque, setSelectedTipoEstoque] = useState<ClienteOption | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [titulo, setTitulo] = useState<string | null>(null)


    const formDataState = {
        setTitulo: setTitulo,
        setDescricao: setDescricao,
        setStatus: setStatus,
        setSelectedEmpresa: setSelectedEmpresa,
        setSelectedTipoEstoque: setSelectedTipoEstoque,
        setEstoques: setEstoques,
        setPage: setPage
    };

    const [isEstoqueModal, openEstoqueModal, closeEstoqueModal] = useModal();

    const fetchEstoques = async (page=0) => {
        setLoading(true)
        setIsLoadingSearch(true)
        try {
            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/listar`,
                {
                    params: {
                        status: status,
                        page: page,
                        idEstoqueTipos: selectedTipoEstoque ? selectedTipoEstoque.value : null,
                        idEmpresa: selectedEmpresa ? selectedEmpresa.value : null,
                        titulo: titulo ? titulo : null,
                        descricao: descricao ? descricao : null,
                    },
                }

            );

            if (response.status === 200) {
                if (page === 0) {
                }
                const {content, totalPages} = response.data;
                setEstoques((prev) => (page === 0 ? content : [...prev, ...content]));
                setHasMore(page < totalPages - 1);
          
            }
            if (response.data.length === 0) {
                setHasMore(false);
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de estoques para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        } catch (error: any) {
            console.error(error);
            if (error.response && error.response.data && error.response.data.message === '401') {
                Swal.fire({
                    icon: 'info',
                    title: 'Por questões de segurança, por favor faça login novamente',
                    confirmButtonText: 'Ok',
                }).then(() => {
                    setLoading(false);
                    window.open('/auth', '_blank');
                });
            }
        } finally {
            setLoading(false);
            setIsLoadingSearch(false);

        }
    };
    const handleSearch = async (event) => {
        event.preventDefault();
        setPage(0);
        try {

            const response = await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/listar`,
                {
                    params: {
                        status: status,
                        idEstoqueTipos: selectedTipoEstoque ? selectedTipoEstoque.value : null,
                        idEmpresa: selectedEmpresa ? selectedEmpresa.value : null,
                        titulo: titulo ? titulo : null,
                        descricao: descricao ? descricao : null,
                        page: page,
                    },
                }

            );
            if (response.status === 200) {
                if (page === 0) {
                    setEstoques([]);
                }
                formDataState.setEstoques(response.data.content)
                const { content, totalPages } = response.data;
                setEstoques(content)
                setHasMore(page < totalPages - 1);
            }
            if (response.data.content.length === 0) {
                Swal.fire({
                    icon: 'info',
                    title: `Não existe registros de estoques para essa pesquisa`,
                    timer: 3000,
                    timerProgressBar: true,
                });
            }

        }

        catch (error) {
            console.error(error);
        }
    }
    const openModal = (isEdit, ...data: (string | number)[]) => {
        if (data) {
            setDataLine(data)
        }
        setIsEdit(isEdit)
        openEstoqueModal();
    }
    const clearFiltros = () => {
        setTitulo(null)
        setDescricao(null)
        setStatus('ATIVO')
        setSelectedEmpresa(null)
        setSelectedTipoEstoque(null)
        setPage(0)
    }

    const loadOptionsTipoEstoque = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/tipos/listar`;

            const response = await axios.get(url,{
                params: {
                    size: 100,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: `${item.titulo}`,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }
    const loadMore = () => {
        setLoading(true)
        setLoading(false)
        setPage((prevPage) => prevPage + 1);
        setCountSkeleton((count) => count + 40)
        smoothScrollToBottom()
    }

    useEffect(() => {
        if (
          descricao === null &&
          titulo === null &&
          status === 'ATIVO' &&
          selectedEmpresa === null &&
          page === 0 &&
          selectedTipoEstoque === null
        ) {
          setPage(0);
          fetchEstoques(0);
        }
      }, [titulo,descricao,status,selectedEmpresa,selectedTipoEstoque,page]);
    
    useEffect(() => {
        fetchEstoques(page)
    }, [page]);

    return (
        <div>
            {/* TITULO E CADASTRO */}
            <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    Listagem de Estoques
                </h1>
                <button className='btn btn-success ' style={{ fontSize: '14px' }} onClick={() => { openModal(false) }}>
                    Cadastrar Estoque
                </button>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            {/* FILTROS */}
            <h4 className='text-dark mt-10 mb-5'>FILTROS DE PESQUISA</h4>
            <div>
                <form action="">
                    <Row className='col-12 mb-4 row mt-8'>
                        <label htmlFor="" className="form-label">Empresa:</label>
                        <Col>
                                <AsyncSelectEmpresa handleOptions={(empresa)=>{setSelectedEmpresa(empresa)}} inputOption={selectedEmpresa} onKeyDown={()=>{setSelectedEmpresa(null)}} ></AsyncSelectEmpresa>
                        </Col>
                    </Row>
                    <Row className="mt-4 col-12 mb-4 row">
                        <Col>
                            <label htmlFor="" className="form-label"  >Titulo:</label>
                            <input type="text" value={titulo ? titulo : ""} className="form-control"
                                onChange={(e) => {
                                    setTitulo(e.target.value)
                                    formDataState.setTitulo ? (formDataState.setTitulo(e.target.value)) : console.log(e.target.value)
                                }} placeholder="Digite o titulo" />
                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select"
                                onChange={(e) => {
                                    // handleChangeStatus(e.target.value)
                                    formDataState.setStatus ? (formDataState.setStatus(e.target.value)) : console.log(e.target.value)
                                }}
                                value={status ? status : ""}


                                name="" id="">
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Tipo de Estoque:</label>
                            <AsyncSelect
                                defaultOptions
                                value={selectedTipoEstoque}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedTipoEstoque(null);
                                    }
                                }} onChange={(selectedOption: ClienteOption | null) => {
                                    if (selectedOption) {
                                        const idValue = isNaN(Number(selectedOption.value))
                                            ? selectedOption.value // Caso seja texto, mantêm como string.
                                            : Number(selectedOption.value);
                                        console.log(selectedOption.value)
                                    }
                                    setSelectedTipoEstoque(selectedOption);
                                    console.log(selectedOption)
                                }} loadOptions={loadOptionsTipoEstoque} placeholder="Selecione o tipo de estoque" className="mt-1"></AsyncSelect>

                        </Col>
                    </Row>
                    <Row className="mt-4 col-12 mb-4 row">
                        <label htmlFor="" className="form-label">Descrição:</label>
                        <Col className="">
                            <textarea name="" value={descricao ? descricao : ""} onChange={(e) => {
                                setDescricao(e.target.value)
                                formDataState.setDescricao ? (formDataState.setDescricao(e.target.value)) : console.log(e.target.value)
                            }} placeholder="Digite a descrição" className="form-control" id=""></textarea>
                        </Col>
                    </Row>
                    <div className='col-12 my-6' style={{ display: 'flex', justifyContent: 'end' }}>
                        <div className='col-sm-4 mx-5' style={{ display: 'flex', alignItems: 'end', justifyContent: 'flex-end', gap: '10' }}>
                            <button
                                type='button'
                                style={{ width: '40%' }}
                                onClick={() => clearFiltros()}
                                className='btn btn-danger form-control'
                            >
                                {isLoading ? <Spinner animation='border' size='sm' /> :
                                    'Limpar'
                                }
                            </button>
                            <button
                                onClick={handleSearch}
                                type='submit'
                                style={{ width: '40%', marginLeft: '10px' }}
                                className='btn btn-primary d-flex align-items-center justify-content-center'

                            >
                                {isLoadingSearch ? <Spinner animation='border' size='sm' /> : (
                                    <>
                                        <i className="bi bi-search mx-0"></i>
                                        Pesquisar
                                    </>
                                )}
                            </button>
                        </div>
                    </div>
                </form>
            </div>
            {loading ? (
                <Skeleton count={countSkeleton} height={25} />
            ) : (
                <>
                    <div>
                        <table className='table table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800'>
                                    <th className='text-center'>ID</th>
                                    <th className='text-center'>Titulo</th>
                                    <th className='text-center'>Descrição</th>
                                    <th className='text-center'>Empresa</th>
                                    <th className='text-center'>Tipo de Estoque</th>
                                    <th className='text-center'>Criado por</th>
                                    <th className='text-center'>Data de criação</th>
                                    <th className='text-center'>Alterado por</th>
                                    <th className='text-center'>Data de alteração</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Editar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {estoques?.map((estoque) => (
                                    <tr className="text-center">
                                        <td>{estoque.id}</td>
                                        <td>{estoque.titulo}</td>
                                        <td>{estoque.descricao?estoque.descricao:'...'}</td>
                                        <td>{estoque.empresaFantasia}</td>
                                        <td>{estoque.estoqueTipos}</td>
                                        <td>{estoque.usuarioCriacaoNome}</td>
                                        <td>{estoque.dataCriacao}</td>
                                        <td>{estoque.usuarioAlteracaoNome?estoque.usuarioAlteracaoNome:'...'}</td>
                                        <td>{estoque.dataAlteracao}</td>
                                        <td>{estoque.status}</td>
                                        <td>
                                            <div className='text-center'>
                                                <button
                                                    onClick={() => { openModal(true, estoque.titulo, estoque.id, estoque.descricao, estoque.idEmpresa, estoque.empresaFantasia, estoque.status, estoque.idEstoqueTipos, estoque.estoqueTipos) }}
                                                    className='btn btn-success btn-sm bi bi-pencil'
                                                    data-toggle='tooltip'
                                                    data-placement='top'
                                                    title='Editar'
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))
                                }
                            </tbody>
                        </table>
                        {hasMore && (
                            <div className='d-flex justify-content-center align-items-center'>
                                <button className='btn btn-primary m-5' onClick={loadMore} >
                                    Carregar Mais
                                </button>
                            </div>
                        )}
                    </div>
                </>
            )}
            <ModalEstoques
                isOpen={isEstoqueModal}
                onHide={closeEstoqueModal}
                isEdit={isEdit}
                dataLine={dataLine}
                loadEstoques={fetchEstoques}
            
            ></ModalEstoques>

        </div>
    )
}

export default ListagemEstoques