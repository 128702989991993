export const formatarData = (dataString: string) => {
  if (!dataString || dataString === 'null') {
    return ' - ';
  }

  const data = new Date(dataString);
  return data.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });
};

export const formatDateTimeWithHours = (isoDate) => {
  const date = new Date(isoDate);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).slice(-2);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${day}-${month}-${year} às ${hours}:${minutes}`;
};

export const formatDateByFilter = (date: Date | null): string | null => {
  if (!date) return null;
  return date.toISOString().split('T')[0];
};
