import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { validarCep } from './validarCep';


const useBuscaCep = (cep, formik) => {
  useEffect(() => {
      const buscaCep = async () => {
        const cepValido = await validarCep(cep);
        if (!cepValido) {
          Swal.fire({
            icon: 'error',
            title: 'Não foi possível localizar este CEP',
            timer: 3000,
            timerProgressBar: true,
          });
          return;
        }
       
       try {
         const cepFormatado = cep.replace(/[^\d]/g, '');
         const response = await fetch(`https://viacep.com.br/ws/${cepFormatado}/json/`);
         const data = await response.json();
 
         if (response.status === 200) {
           if (data.erro) {
            Swal.fire({
            icon: 'error',
            title: 'Não foi possível localizar este CEP',
            timer: 3000,
            timerProgressBar: true,
          });
          return;
           } else {
             formik.setValues({
               ...formik.values,
               rua: (data.logradouro || '').toUpperCase(),
               endereco: (data.logradouro || '').toUpperCase(),
               bairro: (data.bairro || '').toUpperCase(),
               cidade: (data.localidade || '').toUpperCase(),
               uf: (data.uf || '').toUpperCase(),
               estado: (data.uf || '').toUpperCase(),
               pais: 'BRASIL',
             });
           }
         }
       } catch (error) {
         console.error('Erro ao buscar endereço pelo CEP:', error);
       }
     };
     if (cep.replace(/[^\d]/g, '').length === 8) {
       buscaCep();
     }
  }, [cep, formik.values.cep]);
 };
 
 export default useBuscaCep;