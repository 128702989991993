import * as Yup from 'yup';

export const cadastroParametroRotinaSQLValidationSchema = Yup.object().shape({
  titulo: Yup.string()
    .min(4, 'O titulo deve conter pelo menos 4 caracteres')
    .max(255, 'O titulo deve conter no máximo 255 caracteres')
    .required('Por favor, adicione um titulo'),
  descricao: Yup.string()
    .min(4, 'A descrição deve conter no mínimo 4 caracteres')
    .max(255, 'A descrição deve conter no máximo 255 caracteres'),
  parameter: Yup.string()
    .min(4, 'o parâmetro deve conter no mínimo 4 caracteres')
    .max(255, 'o parâmetro deve conter no máximo 255 caracteres')
    .required('Por favor, adicione um parâmetro'),
  status: Yup.string().required('Por favor, adicione um status'),
});
