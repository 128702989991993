import {useState} from 'react';
import * as S from './card-styles';
import AvatarConsultor from '../../../../shared/core/adapters/avatar-consultor/avatar-consultor';
import {formatDateTimeWithHours} from '../../../../shared';
import {FaPlus} from 'react-icons/fa';
import {BsDash} from 'react-icons/bs';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';

export type IProps = {
  idPedido: number;
  clienteRazao: string;
  clienteNumero: string;
  clienteEmail: string;
  consultor: string;
  consultorContato: string;
  idConsultor: number;
  idG2?: string | number | null;
  cepEntrega: string;
  cidadeEntrega: string;
  bairroEntrega: string;
  numeroEntrega: string;
  enderecoEntrega: string;
  ufEntrega: string;
  pontoReferencia1Entrega: string;
  pontoReferencia2Entrega: string;
  responsavel1Entrega: string;
  responsavel2Entrega: string;
  clienteFantasia: string;
  clienteTelefone: string;
  clienteBairro: string;
  clienteCidade: string;
  clienteEndereco: string;
  clienteEstado: string;
  clienteComplemento: string;
  observacaoEntrega: string;
  pedidoDataEmissao?: string | null;
  pedidoDataAcordado?: string | null;
};

export const CardInfo: React.FC<IProps> = ({
  idPedido,
  clienteEmail,
  clienteNumero,
  clienteRazao,
  consultor,
  consultorContato,
  idConsultor,
  idG2,
  bairroEntrega,
  cepEntrega,
  cidadeEntrega,
  enderecoEntrega,
  numeroEntrega,
  pontoReferencia1Entrega,
  pontoReferencia2Entrega,
  responsavel1Entrega,
  responsavel2Entrega,
  ufEntrega,
  clienteTelefone,
  clienteFantasia,
  clienteBairro,
  clienteCidade,
  clienteComplemento,
  clienteEndereco,
  clienteEstado,
  observacaoEntrega,
  pedidoDataEmissao,
  pedidoDataAcordado,
}) => {
  const [isView, setIsView] = useState<boolean>(false);

  const handleView = () => {
    setIsView((prev) => !prev);
  };

  return (
    <>
      <S.ContainerCard>
        <div className='box-top'>
          <S.CardItem>
            <p>Id do Pedido:</p>

            <span>{idPedido}</span>
          </S.CardItem>

          <S.CardItem>
            <p>Id do G2:</p>

            <span>{idG2}</span>
          </S.CardItem>

          <S.CardItem>
            <p>Data de Emissão:</p>

            <span>{pedidoDataEmissao ? formatDateTimeWithHours(pedidoDataEmissao) : '-'}</span>
          </S.CardItem>

          <S.CardItem>
            <p>Data Acordada:</p>

            <span>{pedidoDataAcordado ? formatDateTimeWithHours(pedidoDataAcordado) : '-'}</span>
          </S.CardItem>

          <S.InfoBage>
            <p>Informações do Cliente</p>
          </S.InfoBage>

          <S.CardItem>
            <p>Cliente Razao:</p>

            <span>{clienteRazao}</span>
          </S.CardItem>

          <S.CardItem>
            <p>Cliente Fantasia:</p>

            <span>{clienteFantasia}</span>
          </S.CardItem>

          <S.CardItem>
            <p>Cliente Estado:</p>

            <span>{clienteEstado}</span>
          </S.CardItem>

          <S.CardItem>
            <p>Cliente Cidade:</p>

            <span>{clienteCidade}</span>
          </S.CardItem>

          <S.CardItem>
            <p>Cliente Bairro:</p>

            <span>{clienteBairro}</span>
          </S.CardItem>

          <S.CardItem>
            <p>Cliente Complemento:</p>

            <span>{clienteComplemento}</span>
          </S.CardItem>

          <S.CardItem>
            <p>Cliente Endereço:</p>

            <span>{clienteEndereco}</span>
          </S.CardItem>

          <S.CardItem>
            <p>Telefone do Cliente:</p>

            <span>{clienteTelefone}</span>
          </S.CardItem>

          <S.CardItem>
            <p>Email do Cliente:</p>

            <span>{clienteEmail}</span>
          </S.CardItem>

          <S.InfoBagdeWithButton>
            <div className='box-left'>
              <p>Informações de Entrega</p>
            </div>

            <div className='box-right'>
              <OverlayTrigger
                placement='top'
                overlay={<Tooltip>Visualize as Informações de Entrega</Tooltip>}
              >
                <button onClick={() => handleView()}>{isView ? <BsDash /> : <FaPlus />}</button>
              </OverlayTrigger>
            </div>
          </S.InfoBagdeWithButton>

          <S.InfoEntregaContainer isView={isView}>
            <S.CardItem>
              <p>CEP Entrega:</p>

              <span>{cepEntrega}</span>
            </S.CardItem>

            <S.CardItem>
              <p>Cidade de Entrega:</p>

              <span>{cidadeEntrega}</span>
            </S.CardItem>

            <S.CardItem>
              <p>Bairro de Entrega:</p>

              <span>{bairroEntrega}</span>
            </S.CardItem>

            <S.CardItem>
              <p>Número de Entrega:</p>

              <span>{numeroEntrega}</span>
            </S.CardItem>

            <S.CardItem>
              <p>Endereço de Entrega:</p>

              <span>{enderecoEntrega}</span>
            </S.CardItem>

            <S.CardItem>
              <p>UF Entrega:</p>

              <span>{ufEntrega}</span>
            </S.CardItem>

            <S.CardItem>
              <p>Ponto de Referência 1:</p>

              <span>{pontoReferencia1Entrega}</span>
            </S.CardItem>

            <S.CardItem>
              <p>Ponto de Referência 2:</p>

              <span>{pontoReferencia2Entrega}</span>
            </S.CardItem>

            <S.CardItem>
              <p>Responsável de Entrega 1:</p>

              <span>{responsavel1Entrega}</span>
            </S.CardItem>

            <S.CardItem>
              <p>Responsável de Entrega 2:</p>

              <span>{responsavel2Entrega}</span>
            </S.CardItem>

            <S.CardItem>
              <p>Observação de Entrega:</p>

              <span>{observacaoEntrega}</span>
            </S.CardItem>
          </S.InfoEntregaContainer>

          <S.InfoBage>
            <p>Consultor</p>
          </S.InfoBage>
        </div>

        <div className='box-bottom'>
          <S.ContainerImage>
            <div className='box-left'>
              <AvatarConsultor
                idFuncionario={idConsultor}
                funcionario={consultor}
                style={{
                  borderRadius: '5px',
                }}
              />
            </div>
            <div className='box-right'>
              <p>{consultor ? consultor : '-'}</p>
              <p>
                {' '}
                <span>Contato:</span> {consultorContato ? consultorContato : '-'}
              </p>
            </div>
          </S.ContainerImage>
        </div>
      </S.ContainerCard>
    </>
  );
};

export default CardInfo;
