import {useEffect, useState} from 'react';
import useOptionsTabelaDePreco from '../../../selectOptions/useOptionsTabelaDePreco';
import {Alert} from 'react-bootstrap';
import {useProduto} from '../hooks/useProduto';
import {TableListagemPreco} from './TableListagemPreco';
import {ListPrecoInfo} from '../Interface';
import Swal from 'sweetalert2';
import {number} from 'yup';

const Precos = () => {
  const {formik, tabelasDePrecosProduto, setTabelasDePrecosProduto} = useProduto();
  const {optionsTabelaDePreco} = useOptionsTabelaDePreco();

  const [selectedListPreco, setSelectedListPreco] = useState<ListPrecoInfo | null>(null);
  const [status, setStatus] = useState<number>(1);
  
  const [inputs, setInputs] = useState<string[]>(['']);
  const [editingProduct, setEditingProduct] = useState<boolean>(false);
  
  const [preco, setPreco] = useState<number | null>(null);
  const [precoPromocional, setPrecoPromocional] = useState<number | null>(null);
  const [formataPreco, setFormataPreco] = useState<string | null>('');
  const [formataPrecoPromocional, setFormataPrecoPromocional] = useState<string | null>('');

  const handleRemoveProduct = (index: number) => {
    setTabelasDePrecosProduto((prevInfosProd) => prevInfosProd.filter((_, i) => i !== index));
  };

  const handleEditClick = (index: number, product) => {
    console.log('produto: ', product);

    setEditingProduct(true);
    setSelectedListPreco(product);
    const opreco = Number(product.precovenda);
    // setPreco(opreco);
    setPrecoPromocional(opreco);
    setStatus(product.status);

    setFormataPreco(product.precovenda);
    setFormataPrecoPromocional(product.precopromocional);
  };

  useEffect(() => {
    formik.setFieldValue('preco', formataPreco);
  }, [formataPreco]);

  useEffect(() => {
    formik.setFieldValue('precoPromocional', formataPrecoPromocional);
  }, [formataPrecoPromocional]);

  useEffect(() => {
    // setFormataPreco({...formik.getFieldProps('preco')}.value)
  }, []);

  const handleAddListPreco = () => {
    if (!formataPreco || !formataPrecoPromocional || !selectedListPreco) {
      // alert('Os valores de preço não podem ser nulos ou zero.');
      const Toast = Swal.mixin({
        toast: true,
        position: 'center',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        },
      });
      Toast.fire({
        icon: 'info',
        title: 'Os valores de preço não podem ser nulos ou zero.',
      });
      // return;
      return;
    }

    if (selectedListPreco) {
      const exists = tabelasDePrecosProduto.some(
        (info) => info.idtabeladepreco === selectedListPreco!.idtabeladepreco
      );

      if (!exists) {
        const newInfo: ListPrecoInfo = {
          idtabeladepreco: selectedListPreco.idtabeladepreco,
          descricao: selectedListPreco.descricao,
          precovenda: preco,
          precopromocional: precoPromocional,
          status: status,
        };

        setTabelasDePrecosProduto((prevInfos) => [...prevInfos, newInfo]);
      } else {
        console.log('existe');

        setTabelasDePrecosProduto((prevInfos) =>
          prevInfos.map((info) =>
            info.idtabeladepreco === selectedListPreco.idtabeladepreco
              ? {
                  ...info,
                  precovenda: formataPreco,
                  precopromocional:formataPrecoPromocional,
                  status: status,
                }
              : info
          )
        );
      }
      setSelectedListPreco(null);
      setPreco(null);
      setPrecoPromocional(null);
      setStatus(1);
      setEditingProduct(false);
      setFormataPreco(null);
      setFormataPrecoPromocional(null);
    }
  };

  useEffect(() => {
    console.log(selectedListPreco);
  }, [selectedListPreco]);

  const handleCancelEdit = () => {
    setSelectedListPreco(null);
    setPreco(null);
    setPrecoPromocional(null);
    setStatus(1);
    setEditingProduct(false);
  };
  const handleChangePreco = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const cleanedValue = rawValue.replace(/[^\d]/g, '');
    const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;
    setFormataPreco(numericValue.toString());
    // const inputValue = Number(event.target.value);
    setPreco(Number(numericValue.toString()));
  };

  const handleChangePrecoPromocial = (event: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = event.target.value;
    const cleanedValue = rawValue.replace(/[^\d]/g, '');
    const numericValue = cleanedValue ? parseFloat(cleanedValue) / 100 : 0;
    setFormataPrecoPromocional(numericValue.toString());
    const inputValue = event.target.value;
    setPrecoPromocional(Number(numericValue.toString()));
    // const inputValue = Number(event.target.value);
    // setPrecoPromocional(inputValue);
  };

  const handleChangeStatus = (event) => {
    setStatus(event.target.value);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log('id do select: ', event.target.value);

    const id = Number(event.target.value);
    const descricao = event.target.options[event.target.selectedIndex].text;
    const selectedInfo: ListPrecoInfo = {
      idtabeladepreco: id,
      descricao: descricao,
      precovenda: preco,
      precopromocional: precoPromocional,
      status: status,
    };

    setSelectedListPreco(selectedInfo);
  };

  const addInput = () => {
    setInputs([...inputs, '']);
  };

  const idsEmUso = tabelasDePrecosProduto.map((item) => item.idtabeladepreco);

  return (
    <div className='mb-5 mt-8 '>
      {inputs.map((value, index) => (
        <div className='mb-5 row' key={index}>
          <div className='col-md-2 mt-4'>
            <label className='form-label'>Lista de preço:</label>
            <select
              required
              className='form-select bg-transparent'
              {...formik.getFieldProps('lista_preco')}
              value={selectedListPreco ? selectedListPreco.idtabeladepreco : ''}
              onChange={handleSelectChange}
              style={{marginTop: '0px'}}
              disabled={editingProduct}
            >
              <option value='' disabled>
                Selecione...
              </option>
              {optionsTabelaDePreco.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  disabled={idsEmUso.includes(Number(option.value))}
                  style={{color: idsEmUso.includes(Number(option.value)) ? '#d3d3d3' : ''}}
                >
                  {option.label}
                </option>
              ))}
            </select>

            {formik.touched.lista_preco && formik.errors.lista_preco && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.lista_preco}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-md-2  mt-4'>
            <label className='form-label'>Preço:</label>
            <input
              type='text'
              value={
                formataPreco
                  ? `R$ ${parseFloat(formataPreco).toLocaleString('pt-BR', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}`
                  : 'R$ 0,00'
              }
              className='form-control bg-transparent'
              onChange={(event) => {
                handleChangePreco(event);
              }}
            />

            {formik.touched.preco && formik.errors.preco && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.preco}</span>
                </div>
              </div>
            )}
          </div>
          <div
            className='col-md-2 mt-4'
            style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
          >
            <label className='form-label'>Preço Promocional:</label>
            <div style={{display: 'flex', flexDirection: 'row', gap: 4}}>
              <input
                type='text'
                placeholder='Preço Promocional'
                //{...formik.getFieldProps('preco_promocional_lista')}
                value={
                  formataPrecoPromocional
                    ? `R$ ${parseFloat(formataPrecoPromocional).toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                    : 'R$ 0,00'
                }
                className='form-control bg-transparent col-md-2'
                onChange={handleChangePrecoPromocial}
              />
            </div>
            {/* verificar se existe dentro das opções, alguma ja foi adicionada na lista, caso sim, ela fica inativa */}

            {formik.touched.preco_promocional_lista && formik.errors.preco_promocional_lista && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.preco_promocional_lista}</span>
                </div>
              </div>
            )}
          </div>
          <div className='col-md-2 mt-4'>
            <label className='form-label'>Status:</label>
            <select
              defaultValue={''}
              required
              className='form-select bg-transparent'
              value={status}
              onChange={handleChangeStatus}
              style={{marginTop: '0px'}}
            >
              <option value='' disabled selected>
                Selecione...
              </option>
              <option value='1'> Ativo </option>
              <option value='2'> Inativo </option>
            </select>
          </div>
          <div className='col-md-4 mt-13'>
            <button
              onClick={handleAddListPreco}
              className='btn btn-success btn-sm '
              data-toggle='tooltip'
              data-placement='top'
              title={'Adicionar'}
              type='button'
            >
              {editingProduct ? (
                <i className='bi bi-pencil' style={{margin: 0, padding: 0, fontSize: '18px'}}></i>
              ) : (
                <i className='bi bi-plus' style={{margin: 0, padding: 0, fontSize: '18px'}}></i>
              )}
            </button>

            <button
              className='btn btn-danger btn-sm '
              style={{
                marginLeft: 10,
                display: editingProduct ? '' : 'none',
              }}
              type='button'
              data-toggle='tooltip'
              data-placement='top'
              title={'Cancelar'}
              onClick={handleCancelEdit}
            >
              <i className='bi bi-x' style={{margin: 0, padding: 0, fontSize: '20px'}}></i>
            </button>
          </div>
        </div>
      ))}
      <TableListagemPreco
        onRemoveProduct={handleRemoveProduct}
        onEditProduct={handleEditClick}
        editingProduct={editingProduct}
      />
    </div>
  );
};

export default Precos;
