import { Button, Col, ModalProps, Row } from "react-bootstrap";
// import { Modal } from "../../../../shared"
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import axios from "axios";
import { da } from "date-fns/locale";
import { Modal } from "../../../../../shared";
import { ClienteOption } from "../../../../cadastros/proposta-pedido/types/interface";
import AsyncSelect from "react-select/async";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;
const ModalEstoqueTransferencia = ({isOpen, onHide,isEdit=false,reload,dataLine={},clearFiltros}) => {
    const [operacao, setOperacao] = useState<string | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [titulo, setTitulo] = useState<string | null>(null)
    const [dataMovimento, setDataMovimento] = useState<string | null>(null)

    const [selectedEstoque, setSelectedEstoque] = useState<ClienteOption | null>(null)
    const [selectedMotivo, setSelectedMotivo] = useState<ClienteOption | null>(null)
    const onClose = () => {
        clearFiltros()
        clearForm()
        reload()
        onHide!();
    }

    const clearForm = () => {
        setStatus('ATIVO')
        setSelectedEstoque(null)
        setSelectedMotivo(null)
        setDataMovimento(null)
    }

    const cadastrarMovimentoTipo = async () => {
        try {
            if(selectedEstoque && selectedMotivo && dataMovimento) {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/transferencias`;
            
                const response = await axios.post(url, {
                    // "titulo": titulo?titulo:null,
                    // "descricao": descricao?descricao:null,
                    "status": status?status:'ATIVO',
                    idMotivo: selectedMotivo?.value ? selectedMotivo?.value : null,
                    idEstoque: selectedEstoque?.value ? selectedEstoque?.value : null,
                    dataMovimento: dataMovimento ? `${dataMovimento}T14:55:35.403` : null
                });   
                
            Swal.fire({
                icon: 'success',
                  title: `Transferencia cadastrado com sucesso`,
                  timer: 3000,
                  timerProgressBar: true,
                }).then(() => {
                    clearForm()
                })
        } }
        catch (errors: any) {
              Swal.fire({
                icon: 'error',
                  title: `Erro na requisicao`,
                  timer: 3000,
                  timerProgressBar: true,
                });
                              
            console.log(errors)}
    }

    const editarMovimentoTipo = async () => {
        try {
            if(selectedEstoque && selectedMotivo && dataMovimento) {

                    let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/transferencias/${dataLine[0]}`;
                    const response = await axios.put(url, {
                        "status": status?status:'ATIVO',
                        idMotivo: selectedMotivo?.value ? selectedMotivo?.value : null,
                        idEstoque: selectedEstoque?.value ? selectedEstoque?.value : null,
                        dataMovimento: dataMovimento ? `${dataMovimento}T14:55:35.403` : null
                    });
                    Swal.fire({
                        icon: 'success',
                          title: `Transferencia editado com sucesso`,
                          timer: 3000,
                          timerProgressBar: true,

                        }).then(() => {
                            clearForm()
                            onClose()
                            reload()

                        });
    
                } }
                catch (errors: any) {
                      Swal.fire({
                        icon: 'info',
                          title: `Erro na requisicao`,
                          timer: 3000,
                          timerProgressBar: true,
                        });
                                      
                    console.log(errors)}}

                    const loadOptionsMotivos = async () => {
                        try {
                            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/inventario-motivos/listar`;
                
                            const response = await axios.get(url, {
                                params: {
                                    size: 1000,
                                    status: 'ATIVO'
                                }
                            });
                            const data = await response.data.content;
                
                            console.log(data)
                            return data.map((item) => ({
                                value: item.id,
                                label: item.titulo,
                            })
                            )
                        } catch (error) {
                            console.error('Erro ao buscar opções:', error);
                            return [];
                        };
                    }
                
                    const loadOptionsEstoque = async () => {
                        try {
                            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/listar`;
                
                            const response = await axios.get(url, {
                                params: {
                                    size: 1000,
                                    status: 'ATIVO'
                                }
                            });
                            const data = await response.data.content;
                
                            console.log(data)
                            return data.map((item) => ({
                                value: item.id,
                                label: item.titulo,
                            })
                            )
                        } catch (error) {
                            console.error('Erro ao buscar opções:', error);
                            return [];
                        };
                    }

     const submitButton = (
            <Button
              type='submit'
              variant='success'
              onClick={() => isEdit?editarMovimentoTipo():cadastrarMovimentoTipo()}
            >
              {/* {isLoading ? <Spinner animation='border' size='sm' /> : isEdit?"Editar":"Cadastrar"} */}
              {isEdit?"Editar":"Cadastrar"}
            </Button>
          );

    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };
    function formatDate(inputDate: string): string {
        // Converte o inputDate (que pode ser no formato ISO ou no formato 'yyyy-mm-dd') para um objeto Date
        const date = new Date(inputDate);
      
        // Verifica se a data é válida
        if (isNaN(date.getTime())) {
          throw new Error("Data inválida");
        }
      
        // Obtém o ano, mês e dia
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Mês é zero-indexed, por isso somamos 1
        const day = date.getDate().toString().padStart(2, '0'); // Garante que o dia tenha 2 dígitos
      
        // Retorna a data no formato 'yyyy-mm-dd'
        return `${year}-${month}-${day}`;
      }

    useEffect(() => {
        // console.log(dataLine[3],'aaaa')
            // setTitulo(dataLine[1])
            // setDescricao(dataLine[2])
            setStatus(dataLine[6])
            // setOperacao(dataLine[4])
            setSelectedMotivo({value: dataLine[1], label: dataLine[2]})
            setSelectedEstoque({value: dataLine[3], label: dataLine[4]})
            if(dataLine[5]){
                setDataMovimento(formatDate(dataLine[5]))
            }
        if(dataLine[0] === undefined){
            setSelectedEstoque(null)
            setSelectedMotivo(null)
        }
    }, [dataLine])

    return (
        <div>
            <Modal {...modalConfigs}>
                {/* TITULO E CADASTRO */}
            <div className='mb-3 form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    {isEdit?"Editar":"Cadastrar"} Transferencia
                </h1>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            {/* {isEdit&&(<h6 className='fw-bolder mt-4' style={{ color: '#3f3f3f' }}> Editando Movimento: <span className="fw-bold text-primary">{dataLine[1]}</span>  de id: <span className="fw-bold text-primary my-5">{dataLine[0]}</span></h6>)} */}
            {/* FILTROS */}
            <div className="my-4">
                <form action="" className="mt-4">
                <Row className="mt-8 col-12 mb-4 row">
                        <Col>
                            <label htmlFor="" className="form-label"  >Escolha o Motivo:</label>
                            <AsyncSelect 
                                defaultOptions
                            placeholder='Pesquise o Motivo'
                            // loadOptions={loadOptionsParceiro}
                            value={selectedMotivo}
                            onChange={(selectedOption: ClienteOption | null) => {
                              setSelectedMotivo(selectedOption);
                            //   setIdParceiro(selectedOption ? selectedOption.value.toString() : '');
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Backspace') {
                                setSelectedMotivo(null);
                                // setIdParceiro('');
                              }
                            }} loadOptions={loadOptionsMotivos} ></AsyncSelect>
                            {(selectedMotivo === null) && <span className={` form-label text-danger`}>*Por favor, informe o Motivo</span>}

                        </Col>
                        
                        <Col>
                            <label htmlFor="" className="form-label">Estoque:</label>
                            <AsyncSelect placeholder='Pesquise o Estoque'
                                defaultOptions
                            // loadOptions={loadOptionsParceiro}
                            value={selectedEstoque}
                            onChange={(selectedOption: ClienteOption | null) => {
                              setSelectedEstoque(selectedOption);
                            //   setIdParceiro(selectedOption ? selectedOption.value.toString() : '');
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Backspace') {
                                setSelectedEstoque(null);
                                // setIdParceiro('');
                              }
                            }}
                             loadOptions={loadOptionsEstoque
                                
                             } ></AsyncSelect>
                            {(selectedEstoque === null) && <span className={` form-label text-danger`}>*Por favor, informe o Estoque</span>}

                        </Col>
                    </Row>
                    <Row className="mt-9 col-12 mb-4 row">
                        <Col>
                        <label htmlFor="" className="form-label">Data Movimento:</label>
                                                 <input type="date" value={dataMovimento ? dataMovimento : ""}
                                onChange={(e) => {
                                    setDataMovimento(e.target.value)}} className="form-control"></input>
                    {(dataMovimento === null || dataMovimento === '') && <span className={` form-label text-danger`}>*Por favor, informe a Data do Movimneto</span>}

                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select" value={status ? status : ""}
                                onChange={(e) => {setStatus(e.target.value)}}>
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>  
                    </Row>
                </form>
            </div>
            </Modal>
        </div>
    )
}  

export default ModalEstoqueTransferencia