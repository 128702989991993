import {useEffect, useMemo, useState} from 'react';
import {Button, ModalProps, Spinner} from 'react-bootstrap';
import {
  DescriptionComponent,
  formatarData,
  IFrete,
  INotaFiscal,
  Modal,
} from '../../../../../../../shared';
import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {useParams} from 'react-router-dom';
import {getPedidoCompraItemDetails} from '../../../../../../listagem/listagem_requests/listagem_requests';
import Swal from 'sweetalert2';
import {CustomRow} from '../../../../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {FaFileInvoice, FaTruck} from 'react-icons/fa';

type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
  selectItens?: number[];
  handleRequest?: () => void;
  idItem?: number | string;
};

const ItemDetailsModal: React.FC<Props> = ({isOpen, onHide, idItem}) => {
  const renderCellValue = (value: any) => (value ? value : '...');

  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  const handleRadioChange = (id: number) => {
    setSelectedRow(id);
  };

  const pedidoCompraFreteColumns: Column<IFrete>[] = [
    {
      Header: 'Id do Pedido de Compra',
      accessor: 'idPedidoCompra',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Tipo de Frete',
      accessor: 'tipoFrete',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Modelo',
      accessor: 'modelo',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Endereço de Origem',
      accessor: 'enderecoOrigem',
      Cell: ({value}) => (
        <div className='text-end' style={{minWidth: 200}}>
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Endereço de Destino',
      accessor: 'enderecoDestino',
      Cell: ({value}) => (
        <div className='text-end' style={{minWidth: 200}}>
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Data de Entrega',
      accessor: 'dataEntrega',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Data Prevista',
      accessor: 'dataPrevisao',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Data de Saída',
      accessor: 'dataSaida',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Valor do Frete',
      accessor: 'valorFrete',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },
    {
      Header: 'Valor do Seguro',
      accessor: 'valorSeguro',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },
    {
      Header: 'Transportadora',
      accessor: 'fantasiaFornecedor',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
  ];

  const pedidoCompraNotaFiscalColumns: Column<INotaFiscal>[] = [
    {
      Header: 'Id do Pedido de Compra',
      accessor: 'idPedidoCompra',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Fornecedor',
      accessor: 'fantasiaFornecedor',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Número da Nota',
      accessor: 'numeroNota',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Chave',
      accessor: 'chave',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Data de Emissão',
      accessor: 'dataEmissaonota',
      Cell: ({value}) => (
        <div className='text-end'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },

    {
      Header: 'Valor',
      accessor: 'valor',
      Cell: ({value}) => (
        <div className='text-start'>
          {value
            ? `R$ ${Number(value).toLocaleString('pt-BR', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`
            : 'R$ 0,00'}
        </div>
      ),
    },
  ];

  const [isFreteData, setIsFreteData] = useState<IFrete[]>([]);
  const [isNotaFiscalData, setIsNotaFiscalData] = useState<INotaFiscal[]>([]);

  const data = useMemo(() => isFreteData, [isFreteData]);
  const columns = useMemo(() => pedidoCompraFreteColumns, []);

  const data2 = useMemo(() => isNotaFiscalData, [isNotaFiscalData]);
  const columns2 = useMemo(() => pedidoCompraNotaFiscalColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const {
    getTableProps: getTableProps2,
    getTableBodyProps: getTableBodyProps2,
    headers: headers2,
    rows: rows2,
    prepareRow: prepareRow2,
  } = useTable({
    columns: columns2,
    data: data2,
  });

  const {id} = useParams();

  const onClose = () => {
    onHide!();
  };

  const loadDetailsItem = async () => {
    if (idItem) {
      try {
        const response = await getPedidoCompraItemDetails(idItem!);

        const {data} = response;

        setIsFreteData(data.logisticaFrete ? [data.logisticaFrete] : []);

        setIsNotaFiscalData(data.notasFiscais ? [data.notasFiscais] : []);
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }
    }
  };

  useEffect(() => {
    loadDetailsItem();
  }, [isOpen]);

  const modalConfigs: ModalProps = {
    isOpen,
    title: 'Detalhes do Item',
    actions: [],
    onHide: onClose,
    size: 'xl',
  };

  return (
    <>
      <Modal {...modalConfigs}>
        <DescriptionComponent description='Frete Associado ao Item' isSub icon={FaTruck} />
        <div className='table-responsive' style={{maxHeight: 350}}>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border px-4'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<IFrete>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<IFrete>, i) => {
                  prepareRow(row);
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      SEM FRETE ASSOCIADO AO ITEM
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <DescriptionComponent
          description='Nota Fiscal Associada ao Item'
          isSub
          icon={FaFileInvoice}
        />
        <div className='table-responsive' style={{maxHeight: 350}}>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border'
            {...getTableProps2()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers2.map((column: ColumnInstance<INotaFiscal>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps2()}>
              {rows2.length > 0 ? (
                rows2.map((row: Row<INotaFiscal>, i) => {
                  prepareRow2(row);
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      SEM NOTA ASSOCIADA AO ITEM
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default ItemDetailsModal;
