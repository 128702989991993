import {useEffect, useMemo, useState} from 'react';
import {Button, ModalProps, Spinner} from 'react-bootstrap';

import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {useParams} from 'react-router-dom';
import Swal from 'sweetalert2';

import {FaFileInvoice, FaTruck} from 'react-icons/fa';
import {ISeparacaoExpedicaoItens} from '../../../../../shared/domain-types/models/expedicao-sysled';
import {DescriptionComponent, formatarData, Modal} from '../../../../../shared';
import {getExpedicaoSysledItens} from '../../../../listagem/listagem_requests/listagem_requests';
import {CustomRow} from '../../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {LiaSitemapSolid} from 'react-icons/lia';

type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
  selectItens?: number[];
  idSeqEntrega?: number | string;
};

const ItensDetailsModal: React.FC<Props> = ({isOpen, onHide, idSeqEntrega}) => {
  const renderCellValue = (value: any) => (value ? value : '...');

  const separacaoItensColumns: Column<ISeparacaoExpedicaoItens>[] = [
    {
      Header: 'Id da Separação',
      accessor: 'idSeparacao',
      Cell: ({value}) => <div className='text-end'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Sequencia de Entrega',
      accessor: 'seqEntrega',
      Cell: ({value}) => <div className='text-start'>{renderCellValue(value)}</div>,
    },
    {
      Header: 'Produto',
      accessor: 'idProduto',
      Cell: ({value, row}) => (
        <div className='text-end' style={{ width: '250px' }}>
          {row.original.codigoSku
            ? `${row.original.codigoSku} - ${row.original.produto}`
            : ` - ${row.original.produto}`}
        </div>
      ),
    },
    {
      Header: 'Status do Item',
      accessor: 'itemStatus',
      Cell: ({value}) => (
        <div className='text-start'>
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Quantidade Solicitada',
      accessor: 'qtdSolicitada',
      Cell: ({value}) => (
        <div className='text-start'>
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Quantidade Atendida',
      accessor: 'qtdAtendida',
      Cell: ({value}) => (
        <div className='text-start'>
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Saldo',
      accessor: 'saldo',
      Cell: ({value}) => (
        <div className='text-start' >
          {renderCellValue(value)}
        </div>
      ),
    },
    {
      Header: 'Data de Entrega',
      accessor: 'itemDataLancamento',
      Cell: ({value}) => (
        <div className='text-start'>{value ? formatarData(value.toString()) : '...'}</div>
      ),
    },
  ];

 
  const [isItensSeparacaoData, setIsItensSeparacaoData] = useState<ISeparacaoExpedicaoItens[]>([]);

  const data = useMemo(() => isItensSeparacaoData, [isItensSeparacaoData]);
  const columns = useMemo(() => separacaoItensColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const onClose = () => {
    onHide!();
  };

  const loadSeparacaoItens = async () => {
    if (idSeqEntrega) {
      try {
        const response = await getExpedicaoSysledItens(idSeqEntrega);

        const {data} = response;

        setIsItensSeparacaoData(data ? data : []);
      } catch (errors: any) {
        const {data} = errors.response;

        Swal.fire({
          icon: 'error',
          title: data.map((item) => item.mensagem),
          showCancelButton: false,
        });
      }
    }
  };

  useEffect(() => {
    loadSeparacaoItens();
  }, [isOpen]);

  const modalConfigs: ModalProps = {
    isOpen,
    title: 'Itens da Separação',
    actions: [],
    onHide: onClose,
    size: 'xl',
  };

  return (
    <>
      <Modal {...modalConfigs}>
        <DescriptionComponent description='Itens da Separação' isSub icon={LiaSitemapSolid} />
        <div className='table-responsive' style={{maxHeight: 350}}>
          <table
            id='kt_table_formularios'
            className='table table-hover table-striped table-rounded table-row-bordered border px-4'
            {...getTableProps()}
          >
            <thead className='thead-dark'>
              <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                {headers.map((column: ColumnInstance<ISeparacaoExpedicaoItens>, index: number) => (
                  <th
                    key={column.id}
                    className={index === 0 ? 'text-end' : 'text-start'} // Alinha a primeira coluna à direita, as demais à esquerda
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<ISeparacaoExpedicaoItens>, i) => {
                  prepareRow(row);
                  return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
                })
              ) : (
                <tr>
                  <td colSpan={4}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      SEM ITENS REFERENTE A SEPARAÇÃO
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default ItensDetailsModal;
