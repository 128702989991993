import React, {useState} from 'react';
import {AsyncSelectCliente, AsyncSelectProductByIdSysled, AsyncSelectFornecedor, DescriptionComponent} from '../../../../../../shared';
import {DatePicker} from 'rsuite';
import {Spinner} from 'react-bootstrap';

const FilterComponent = ({
  loading,
  isDataPendencia,
  setIsDataPendencia,
  idPedidoG2,
  setIdPedidoG2,
  isDataPedido,
  loadingSearch,
  setIsDataPedido,
  isSelectAsyncProduct,
  isSelectAsyncFornecedor,
  setIsSelectAsyncFornecedor,
  handleAsyncSelectFornecedor,
  setIsSelectAsyncProduct,
  isSelectAsyncClient,
  setIsSelectAsyncClient,
  handleAsyncSelectClient,
  handleAsyncSelect,
  handleSearch,
  clearFilter,
}) => {
  return (
    <div className='mt-10'>
      <DescriptionComponent
        description={'Pendências de Compra'}
        withLink={true}
        buttonTitle='Cadastro de Pedido de Compras'
        redirectTo='/pedido-compras'
      />
      <form onSubmit={handleSearch} className='form-control-solid row mb-4'>
        <div className='row mt-12'>
          <div className='col-sm-4'>
            <label>Cliente</label>
            <AsyncSelectCliente
              handleOptions={(option) => handleAsyncSelectClient(option!)}
              inputOption={isSelectAsyncClient}
              isSelectDOM={false}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setIsSelectAsyncClient(null);
                }
              }}
            />
          </div>
          <div className='col-sm-4'>
            <label>Produto</label>
            <AsyncSelectProductByIdSysled
              handleOptions={(option) => handleAsyncSelect(option!)}
              inputOption={isSelectAsyncProduct}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setIsSelectAsyncProduct(null);
                }
              }}
            />
          </div>
          <div className='col-sm-4'>
            <label>Fornecedor</label>
            <AsyncSelectFornecedor
              handleOptions={(option) => handleAsyncSelectFornecedor(option!)}
              inputOption={isSelectAsyncFornecedor}
              onKeyDown={(e) => {
                if (e.key === 'Backspace') {
                  setIsSelectAsyncFornecedor(null);
                }
              }}
            />
          </div>
        </div>
        <div className='row mt-12' style={{justifyContent: 'end'}}>
          <div className='col-sm-3'>
            <label>Data Pedido</label>
            <DatePicker
              format='dd-MM-yyyy'
              className=' w-100'
              placeholder='Data de pedido'
              onChange={(date) => setIsDataPedido(date)}
              value={isDataPedido}
            />
          </div>
          <div className='col-sm-3'>
            <label>Data Pendência</label>
            <DatePicker
              format='dd-MM-yyyy'
              placeholder='Data de pendência'
              className=' w-100'
              onChange={(date) => setIsDataPendencia(date)}
              value={isDataPendencia}
            />
          </div>
          <div className='col-sm-2'>
            <label>Id Pedido</label>
            <input
              id='idPedidoG2'
              type='text'
              className='form-control '
              value={idPedidoG2 || ''}
              onChange={(e) => {
                const value = e.target.value.replace(/[^0-9]/g, '');
                console.log(value);
                setIdPedidoG2(value);
              }}
            />
          </div>
          <div className='col-sm-2' style={{alignSelf: 'end'}}>
            <button
              type='button'
              onClick={() => clearFilter()}
              className='btn btn-success form-control'
            >
              {loading ? <Spinner animation='border' size='sm' /> : 'Limpar Filtros'}
            </button>
          </div>
          <div className='col-sm-2' style={{alignSelf: 'end'}}>
            <button type='submit' className='btn btn-success form-control'>
              {loadingSearch ? <Spinner animation='border' size='sm' /> : 'Pesquisar'}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FilterComponent;
