import * as Yup from 'yup';
import { validarCep } from '../../../pages/useUtils/validarCep';

const enderecoSchema = Yup.object().shape({
  numero: Yup.string()
    .matches(/^(SN|sn|\d+)$/,'Somente números ou as letras SN'),
  cep: Yup.string()
    .required('Informe um CEP')
    .min(9, 'O CEP deve conter 9 dígitos.')
    .test(
      'cep-valido',
      'CEP inválido.',
      async (value) => {
        if (!value) return false;
        return await validarCep(value);
      }
    ),
});

export default enderecoSchema;
