import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";
import { Modal } from "../../../../../shared"
import { useEffect, useState } from "react";
import { ClienteOption } from "../../../../cadastros/proposta-pedido/types/interface";
import AsyncSelect from "react-select/async";
import axios from "axios";
import Swal from "sweetalert2";


const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


const ModalEstoqueLocalizacao = ({ isOpen, onHide, isEdit, dataLine, reload,clearFiltros }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const [selectedTipoLocalizacao, setSelectedTipoLocalizacao] = useState<ClienteOption | null>(null)
    const [selectedTipoEstoque, setSelectedTipoEstoque] = useState<ClienteOption | null>(null)
    const [status, setStatus] = useState<string | null>('ATIVO')
    const [descricao, setDescricao] = useState<string | null>(null)
    const [titulo, setTitulo] = useState<string | null>(null)
    const [codigo, setCodigo] = useState<string | null>(null)

    const cadastrarEstoqueLocalizacao = async () => {
        try {
            if(selectedTipoEstoque !== null && selectedTipoLocalizacao !== null &&  codigo !== null && codigo !== '' && ( titulo !== null && titulo !== ''&& titulo !== undefined && titulo !== ' ') ){
                let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/localizacao`;

            const response = await axios.post(url, {
                "titulo": titulo ? titulo : null,
                "codigo": codigo ? codigo : null,
                "descricao": descricao ? descricao : null,
                "status": status ? status : 'ATIVO',
                "idEstoque": selectedTipoEstoque?.value ? selectedTipoEstoque?.value : null,
                "idTipoLocalizacao": selectedTipoLocalizacao?.value ? selectedTipoLocalizacao?.value : null,

            });

            Swal.fire({
                icon: 'success',
                title: `Localização cadastrado com sucesso`,
                timer: 3000,
                timerProgressBar: true,
            }).then(() => {
                reload!()
                clearForm()
            })
        }}
        catch (errors: any) {
            Swal.fire({
                icon: 'error',
                title: `Erro na requisicao`,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
    }

    const editarEstoqueTipo = async () => {
        try {
            if(selectedTipoEstoque !== null && selectedTipoLocalizacao !== null &&  codigo !== null && ( titulo !== null && titulo !== ''&& titulo !== undefined && titulo !== ' ')){
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/localizacao/${dataLine[1]}`;
            const response = await axios.put(url, {
                "titulo": titulo ? titulo : null,
                "descricao": descricao ? descricao : null,
                "status": status,
                "idEstoque": selectedTipoEstoque?.value ? selectedTipoEstoque?.value : null,
                "idTipoLocalizacao": selectedTipoLocalizacao?.value ? selectedTipoLocalizacao?.value : null,
                'codigo': codigo ? codigo : null
            });
            Swal.fire({
                icon: 'success',
                title: `Localização editado com sucesso`,
                timer: 3000,
                timerProgressBar: true,

            }).then(() => {
                clearForm()
                onClose()
                reload()

            });

        }}
        catch (errors: any) {
            Swal.fire({
                icon: 'info',
                title: `Erro na requisicao`,
                timer: 3000,
                timerProgressBar: true,
            });

            console.log(errors)
        }
    }
    const onClose = () => {
        clearForm()
        clearFiltros()
        // reload!()
        onHide!();
    }
    const loadOptionsTipoEstoque = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 100,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: ` ${item.titulo}`,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }
    const loadOptionsTipoLocalizacao = async () => {
        try {
            let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/tipo-localizacao/listar`;

            const response = await axios.get(url, {
                params: {
                    size: 100,
                    status: 'ATIVO'
                }
            });
            const data = await response.data.content;

            console.log(data)
            return data.map((item) => ({
                value: item.id,
                label: ` ${item.titulo}`,
            })
            )
        } catch (error) {
            console.error('Erro ao buscar opções:', error);
            return [];
        };
    }
    const clearForm = () => {
        setStatus('ATIVO')
        setDescricao(null)
        setTitulo(null)
        setCodigo(null)
        setSelectedTipoEstoque(null)
        setSelectedTipoLocalizacao(null)
    }
    const submitButton = (
        <Button
            type='submit'
            variant='success'
            onClick={() => isEdit ? editarEstoqueTipo() : cadastrarEstoqueLocalizacao()}
        >
            {isLoading ? <Spinner animation='border' size='sm' /> : isEdit ? "Editar" : "Cadastrar"}
        </Button>
    );

    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };
        useEffect(() => {
            if (isEdit) {
                setTitulo(dataLine[0])
                setSelectedTipoEstoque({
                    value: dataLine[2],
                    label: ` ${dataLine[3]}`,
                })
                setSelectedTipoLocalizacao({
                    value: dataLine[5],
                    label: `${dataLine[4]}`,
                })
                setCodigo(dataLine[6])
                setDescricao(dataLine[8])
                setStatus(dataLine[7])
            }
        }, [dataLine])

    return (
        <Modal {...modalConfigs} >
            <div className='form-label-container' style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}>
                    {isEdit ? 'Editar' : 'Cadastrar'} Localização
                </h1>
            </div>
            {/* BARRA HORRIZONTAL */}
            <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>
            <div>
                <form action="">
                    <Row className='col-12 mb-4 row mt-8'>
                        <Col>
                            <label htmlFor="" className="form-label">Estoque:</label>
                            <AsyncSelect
                                defaultOptions
                                value={selectedTipoEstoque}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedTipoEstoque(null);
                                    }
                                }} onChange={(selectedOption: ClienteOption | null) => {
                                    if (selectedOption) {
                                        const idValue = isNaN(Number(selectedOption.value))
                                            ? selectedOption.value // Caso seja texto, mantêm como string.
                                            : Number(selectedOption.value);
                                        console.log(selectedOption.value)
                                    }
                                    setSelectedTipoEstoque(selectedOption);
                                    console.log(selectedOption)
                                }}
                                loadOptions={loadOptionsTipoEstoque}
                                placeholder="Selecione o tipo de estoque" className="mt-1"></AsyncSelect>
                               {selectedTipoEstoque === null && <span className={` form-label text-danger`}>*Por favor, informe o Tipo de Estoque</span>}

                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label"  >Titulo:</label>
                            <input type="text"
                                value={titulo ? titulo : ""}
                                className="form-control"
                                onChange={(e) => {
                                    setTitulo(e.target.value)
                                }} placeholder="Digite o titulo" />
                            {(titulo === null || titulo === '') && <span className={` form-label text-danger`}>*Por favor, informe o Titulo</span>}

                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label"  >Codigo:</label>
                            <input type="text"
                                value={codigo ? codigo : ""}
                                className="form-control"
                                onChange={(e) => {
                                    setCodigo(e.target.value)
                                }} placeholder="Digite o Codigo" />
                            {(codigo === null ||  codigo === '') &&  <span className={` form-label text-danger`}>*Por favor, informe o Codigo</span>}

                        </Col>
                    </Row>
                    <Row className="mt-4 col-12 mb-4 row">
                        <Col>
                            <label htmlFor="" className="form-label">Tipo de Localização:</label>
                            <AsyncSelect
                                defaultOptions
                                value={selectedTipoLocalizacao}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setSelectedTipoLocalizacao(null);
                                    }
                                }} onChange={(selectedOption: ClienteOption | null) => {
                                    if (selectedOption) {
                                        const idValue = isNaN(Number(selectedOption.value))
                                            ? selectedOption.value // Caso seja texto, mantêm como string.
                                            : Number(selectedOption.value);
                                        console.log(selectedOption.value)
                                    }
                                    setSelectedTipoLocalizacao(selectedOption);
                                    console.log(selectedOption)
                                }}
                                loadOptions={loadOptionsTipoLocalizacao}
                                placeholder="Selecione o tipo de Localização" className="mt-1"></AsyncSelect>
                            {selectedTipoLocalizacao === null && <span className={` form-label text-danger`}>*Por favor, informe o Tipo de Localização</span>}

                        </Col>
                        <Col>
                            <label htmlFor="" className="form-label">Status:</label>
                            <select className="form-select"
                                onChange={(e) => { setStatus(e.target.value) }}
                                value={status ? status : ""}


                                name="" id="">
                                <option value="ATIVO" >ATIVO</option>
                                <option value="INATIVO">INATIVO</option>
                            </select>

                        </Col>

                    </Row>
                    <Row className="mt-4 col-12 mb-4 row">
                        <label htmlFor="" className="form-label">Descrição:</label>
                        <Col className="">
                            <textarea name=""
                                value={descricao ? descricao : ""}
                                onChange={(e) => {
                                    setDescricao(e.target.value)
                                }} placeholder="Digite a descrição" className="form-control" id=""></textarea>
                        </Col>
                    </Row>
                </form>
            </div>
        </Modal>
    )
}

export default ModalEstoqueLocalizacao