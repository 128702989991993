import {Column, ColumnInstance, Row, useTable} from 'react-table';
import {
  AsyncSelectCliente,
  AsyncSelectProduct,
  IProdutoSimilar,
  produtoSimilarValidatorSchema,
} from '../../../../shared';
import {useFormik} from 'formik';
import Swal from 'sweetalert2';
import {useEffect, useMemo, useState} from 'react';
import {
  sendEan,
  editProdutoSimilar,
  sendProdutoSimilar,
} from '../../cadastro_requests/cadastro_requests';
import {Col, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import {CustomHeaderColumn} from '../../../../modules/apps/user-management/users-list/table/columns/CustomHeaderColumn';
import {CustomRow} from '../../../../modules/apps/user-management/users-list/table/columns/CustomRow';
import {
  getEan,
  getEanById,
  getProdutoSimilarById,
  getProdutoSimilarByIdProduto,
} from '../../../listagem/listagem_requests/listagem_requests';
import {useParams} from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import {format} from 'date-fns';
import {ClienteOption} from '../../proposta-pedido/types/interface';
import {setPriority} from 'os';
import axios from 'axios';

const ProdutosSimilares: React.FC = () => {
  const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
  const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

  const [nomeProdutoId, setNomeProdutoId] = useState();

  const produtoSimilarColumns: Column<IProdutoSimilar>[] = [
    {
      Header: 'Produto',
      accessor: 'idProdutoSimilar',
      Cell: ({row}) => {
        const idProdutoSimilar = row.original.idProdutoSimilar;
        const idProduto = row.original.idProduto;

        const [nomeProduto, setNomeProduto] = useState<string | null>(null);

        useEffect(() => {
          const fetchProdutoInfo = async () => {
            try {
              const url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/produtos/buscaProdutoInfoPrevenda/${idProdutoSimilar}`;
              const response = await axios.get(url);
              const data = response.data;

              // Supondo que você quer pegar o primeiro nome de produto
              if (data.length > 0) {
                setNomeProduto(data[0].produto || 'Produto desconhecido');
              }
            } catch (error) {
              console.error('Erro ao carregar opções de produtos:', error);
              setNomeProduto('Erro ao carregar produto');
            }
          };

          fetchProdutoInfo();
        }, [idProdutoSimilar]);

        return (
          <div>
            <div>
              {idProdutoSimilar} - {nomeProduto || 'Carregando...'}
            </div>
          </div>
        );
      },
    },
    {
      Header: 'Cadastrado Por',
      accessor: 'nomeUsuarioCriacao',
    },
    {
      Header: 'Cadastrado em',
      accessor: 'dataCriacao',
      Cell: ({value}) => {
        if (value) {
          try {
            const [date, time] = value.split(' ');
            const [day, month, year] = date.split('-');
            const isoDate = `${year}-${month}-${day}T${time}`;

            const formattedDate = format(new Date(isoDate), "dd-MM-yyyy 'às' HH:mm");
            return <div>{formattedDate}</div>;
          } catch (error) {
            console.error('Erro ao formatar a data:', error);
            return <div>'Data inválida'</div>;
          }
        }
        return <div>'...'</div>;
      },
    },
    {
      Header: 'Atualizado Por',
      accessor: 'nomeUsuarioAlteracao',
      Cell: ({value}) => <span>{value ? value : '...'}</span>,
    },
    {
      Header: 'Atualizado em',
      accessor: 'dataAlteracao',
      Cell: ({value}) => {
        if (value) {
          try {
            const [date, time] = value.split(' ');
            const [day, month, year] = date.split('-');
            const isoDate = `${year}-${month}-${day}T${time}`;

            const formattedDate = format(new Date(isoDate), "dd-MM-yyyy 'às' HH:mm");
            return <div>{formattedDate}</div>;
          } catch (error) {
            console.error('Erro ao formatar a data:', error);
            return <div>'Data inválida'</div>;
          }
        }
        return <div>'...'</div>;
      },
    },
    {
      Header: 'Observaçao',
      accessor: 'observacao',
      Cell: ({value}) => <span>{value ? value : '...'}</span>,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({value}) => <span>{value}</span>,
    },
    {
      Header: 'Ações',
      Cell: ({row}) => (
        <button
          onClick={() => handleOnEdit(row.original.id!)}
          type='button'
          className='btn btn-success btn-sm bi bi-pencil'
          data-toggle='tooltip'
          data-placement='top'
          title='Editar'
        />
      ),
    },
  ];

  const [isProdutosSimilaresData, setIsProdutosSimilaresData] = useState<IProdutoSimilar[]>([]);

  const data = useMemo(() => isProdutosSimilaresData, [isProdutosSimilaresData]);
  const columns = useMemo(() => produtoSimilarColumns, []);

  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data,
  });

  const [isLoading, setIsLoading] = useState<boolean>();
  const [isLoadingEdit, setIsLoadingEdit] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [idProdutoSimilar, setIdProdutoSimilar] = useState('');
  const [idRow, setIdRow] = useState('');
  const [selectedCliente, setSelectedCliente] = useState<ClienteOption | null>(null);
  const [showFiltroCliente, setShowFiltroCliente] = useState(false);

  const initialValues = {
    // idProdutoSimilar:isIdProdutoSimilar,
    observacao: '',
    status: 'ATIVO',
  };

  const {id} = useParams();

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setIsLoading(true);
      if (isEditing) {
        setIsLoading(true);

        try {
          const result = await Swal.fire({
            title: 'Confirmação:',
            text: 'Deseja realmente editar o Produto?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, editar',
            cancelButtonText: 'Cancelar',
          });

          if (result.isConfirmed) {
            console.log('edit');
            let data = {idProdutoSimilar: idProdutoSimilar, ...values};
            console.log(idProdutoSimilar);
            let Row = idRow;
            await editProdutoSimilar(Row, {...data});
            setSubmitting(false);

            Swal.fire({
              icon: 'success',
              title: 'Produto editado com sucesso!',
              showCancelButton: false,
              confirmButtonText: 'Ok',
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
            getSimilarData(id!);

            handleCancelEdit();

            formik.resetForm();
          }
          setIsLoading(false);
        } catch (errors: any) {
          const {data} = errors.response;

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoading(false);
        }
      } else {
        try {
          if (!selectedCliente) {
            const Toast = Swal.mixin({
              toast: true,
              position: 'center',
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.onmouseenter = Swal.stopTimer;
                toast.onmouseleave = Swal.resumeTimer;
              },
            });
            Toast.fire({
              icon: 'info',
              title: 'A inserção do produto é obrigatória',
            });
            setSubmitting(false);
            setIsLoading(false);
            return;
          }

          let data = {idProdutoSimilar: idProdutoSimilar, ...values};
          await sendProdutoSimilar({
            idProduto: id!,
            ...data,
          });
          Swal.fire({
            icon: 'success',
            title: 'Produto cadastrado com sucesso!',
            showCancelButton: false,
            confirmButtonText: 'Ok',
            timer: 3000,
            timerProgressBar: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
          });

          getSimilarData(id!);

          formik.setValues({
            observacao: '',
            // idProdutoSimilar: '',
            status: 'ATIVO',
          });

          handleCancelEdit();

          formik.resetForm();

          setSubmitting(false);
          setIsLoading(false);
        } catch (errors: any) {
          const {data} = errors.response;

          Swal.fire({
            icon: 'error',
            title: data.map((item) => item.mensagem),
            showCancelButton: false,
          });

          setSubmitting(false);
          setIsLoading(false);
        }
      }
    },
  });

  const handleOnEdit = (idRows: string | number) => {
    setIsEditing(true);
    getSimilarData(id!);
    getSimilarDataById(idRows);
    // setIdRow('');
  };

  const handleCancelEdit = () => {
    setIsEditing(false);

    setIdProdutoSimilar('');
    formik.setValues({
      observacao: '',
      status: 'ATIVO',
    });

    formik.resetForm();

    setSelectedCliente(null);
  };

  const handleOptionsAsyncCliente = (selectedOption) => {
    if (selectedOption) {
      setSelectedCliente(selectedOption);
    }

    setIdProdutoSimilar(selectedOption?.value);
  };

  const getSimilarDataById = async (oidProdutoSimilar: string | number) => {
    setIsLoadingEdit(true);
    // console.log(id)
    try {
      console.log(idProdutoSimilar);
      const response = await getProdutoSimilarById(oidProdutoSimilar);

      const data = response.data;
      formik.setValues({
        observacao: data.observacao,
        status: data.status,
      });
      setIdProdutoSimilar(data.idProdutoSimilar);
      setIdRow(data.id);

      setSelectedCliente({label: '', value: data.idProdutoSimilar});

      setIsLoadingEdit(false);
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoadingEdit(false);
    }
  };

  const getSimilarData = async (idProduto: string | number) => {
    setIsLoading(true);
    // console.log(id,idProduto)
    try {
      const response = await getProdutoSimilarByIdProduto(idProduto);

      const {content} = response.data;

      setIsProdutosSimilaresData(content);
      setIsLoading(false);
    } catch (errors: any) {
      const {data} = errors.response;

      Swal.fire({
        icon: 'error',
        title: data.map((item) => item.mensagem),
        showCancelButton: false,
      });

      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      getSimilarData(id);
    }
  }, []);

  return (
    <>
      {isLoadingEdit ? (
        <Skeleton className='mb-4 mt-4' count={1} height={'40px'} />
      ) : (
        <div className='form-control-solid mt-6'>
          <RowBootstrap>
            <Col className='col-sm-12'>
              <label className='form-label'>Produto:</label>

              <AsyncSelectProduct
                handleOptions={(optionSelected) => handleOptionsAsyncCliente(optionSelected)}
                inputOption={selectedCliente}
                isDisabled={showFiltroCliente}
              />
            </Col>
          </RowBootstrap>
          <RowBootstrap className='col-12 mb-6 mt-5'>
            <Col className='col-sm-6'>
              <label className='form-label'>Observaçao:</label>
              <input
                type='text'
                placeholder='Digite uma Observaçao'
                {...formik.getFieldProps('observacao')}
                className={`form-control  ${
                  formik.touched.observacao && formik.errors.observacao ? 'is-invalid' : ''
                }`}
              ></input>
              {formik.touched.observacao && formik.errors.observacao ? (
                <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                  {formik.errors.observacao}
                </div>
              ) : null}
            </Col>
            <Col className='col-sm-2'>
              <div>
                <label className='form-label'>Status:</label>
                <select
                  {...formik.getFieldProps('status')}
                  className={`form-control ${
                    formik.touched.status && formik.errors.status ? 'is-invalid' : ''
                  }`}
                >
                  <option value='ATIVO'>ATIVO</option>
                  <option value='INATIVO'>INATIVO</option>
                </select>
              </div>
              {formik.touched.status && formik.errors.status ? (
                <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                  {formik.errors.status}
                </div>
              ) : null}
            </Col>
            <Col
              className='text-center  col-sm-1 '
              style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start'}}
            >
              <button
                className='btn btn-success btn-sm '
                type='button'
                onClick={() => formik.handleSubmit()}
                style={{height: '42px'}}
              >
                {isEditing ? (
                  isLoading ? (
                    <Spinner animation='border' size='sm' />
                  ) : (
                    <i
                      className='bi bi-pencil'
                      style={{margin: 0, padding: 0, fontSize: '18px'}}
                    ></i>
                  )
                ) : isLoading ? (
                  <Spinner animation='border' size='sm' />
                ) : (
                  <i className='bi bi-plus' style={{margin: 0, padding: 0, fontSize: '18px'}}></i>
                )}
              </button>

              {isEditing ? (
                <Col className='col-sm-1  d-flex text-end align-align-content-end align-items-end'>
                  <button
                    className='btn  btn-danger btn '
                    type='button'
                    style={{marginLeft: '5px', height: '42px'}}
                    onClick={handleCancelEdit}
                  >
                    <i className='bi bi-x-lg' style={{padding: '0px'}}></i>
                  </button>
                </Col>
              ) : (
                <></>
              )}
            </Col>
          </RowBootstrap>
        </div>
      )}

      <div className='card card-flush mb-4'>
        <div className='card-body' style={{padding: '0px', alignItems: 'stretch'}}>
          <div className='table-responsive'>
            <table
              id='kt_table_formularios'
              className='table table-row-dashed gs-0 gy-3 my-0'
              {...getTableProps()}
            >
              <thead>
                <tr className='fs-7 fw-bold border-bottom-0'>
                  {headers.map((column: ColumnInstance<IProdutoSimilar>) => (
                    <CustomHeaderColumn key={column.id} column={column} />
                  ))}
                </tr>
              </thead>
              <tbody className='' {...getTableBodyProps()}>
                {rows.length > 0 ? (
                  rows.map((row: Row<IProdutoSimilar>, i) => {
                    prepareRow(row);
                    return (
                      <CustomRow
                        row={row}
                        key={`row-${i}-${row.id}`}
                        showEditButton
                        onEdit={(id) => handleOnEdit(id)}
                      />
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                        Sem Produto Similar
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProdutosSimilares;
