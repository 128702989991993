import React, {createContext, useContext, useEffect, useState} from 'react';
import axios from 'axios';
import {useAuth} from '../../../../app/modules/auth';

interface Message {
  text: 'string';
  // Defina a estrutura das suas mensagens aqui
}

interface MessagesContextType {
  messages: Message[] | null;
  fetchMessages: () => void;
}

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const MessagesContext = createContext<MessagesContextType | undefined>(undefined);

export const MessagesProvider: React.FC<{children: React.ReactNode}> = ({children}) => {
  const {currentUser} = useAuth();
  const [messages, setMessages] = useState<Message[] | null>(null);

  const fetchMessages = async () => {
    if (currentUser) {
      try {
        const response = await axios.get(
          `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/mensagens/nao-lidas?idUsuario=${
            currentUser.id
          }&size=${1000}`
        );
        const newMessages = response.data;
        localStorage.setItem('unreadMessages', JSON.stringify(newMessages));
        setMessages(newMessages);
      } catch (error) {
        localStorage.removeItem('unreadMessages');
      }
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchMessages();
      const intervalId = setInterval(fetchMessages, 300000); // 5 minutos
      return () => clearInterval(intervalId);
    }
  }, [currentUser]);

  return (
    <MessagesContext.Provider value={{messages, fetchMessages}}>
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessages = () => {
  const context = useContext(MessagesContext);
  if (!context) {
    throw new Error('useMessages must be used within a MessagesProvider');
  }
  return context;
};
