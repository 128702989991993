import {useEffect, useMemo, useState} from 'react';
import {IPendenciaCompra} from '../../../../shared';
import Swal from 'sweetalert2';
const ResumoFornecedor = () => {
  // type FornecedorPendenciasExtended = IFornecedorPendencias & {
  //   fornecedor: string;
  //   idFornecedor?: number | string;
  //   comprador: string;
  // };

  // const pendenciasConvertidas = pendenciasVenda.map((pendencia) => ({
  //   ...pendencia,
  //   idFornecedor: pendencia.idFornecedor?.toString() ?? '',
  //   selected: false,
  //   numeroPedidoVenda: pendencia.numeroPedidoVenda?.toString() ?? '',
  //   dataPedidoVenda: new Date(
  //     pendencia.dataPedidoVenda.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1T00:00:00')
  //   ),
  //   dataPendenciaVenda: new Date(
  //     pendencia.dataPendenciaVenda.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1T00:00:00')
  //   ),
  //   qtPendencia: pendencia.qtPendencia?.toString() ?? '',
  //   qtCompra: pendencia.qtCompra?.toString() ?? '',
  //   qtPedidosCompra: pendencia.qtPedidosCompra?.toString() ?? '',
  //   status: pendencia.status?.toString() ?? '',
  // }));

  // const [pendencias, setPendencias] = useState<IPendenciaVenda[]>(pendenciasConvertidas);
  // const [searchTerm, setSearchTerm] = useState('');
  // const [searchFiltro, setSearchFiltro] = useState('todos');
  // const [loading, setLoading] = useState<boolean>(false);

  // const columns = [
  //   {label: 'Id Fornecedor', accessor: 'idFornecedor'},
  //   {label: 'Fornecedor', accessor: 'fornecedor'},
  //   {label: 'Quantidade pendencia', accessor: 'totalPendencias'},
  //   {label: 'Comprador', accessor: 'comprador'},
  // ];

  // const pendenciasPorFornecedor = useMemo(() => {
  //   const result = pendencias.reduce((acc, pendencia) => {
  //     const {fornecedor, qtPendencia, idFornecedor, comprador} = pendencia;
  //     const key = idFornecedor?.toString() ?? '-';
  //     if (!acc[key]) {
  //       acc[key] = {fornecedor, idFornecedor, comprador, totalPendencias: 0, pendencias: []};
  //     }
  //     acc[key].totalPendencias += parseInt(qtPendencia) || 0;
  //     acc[key].pendencias.push(pendencia);
  //     return acc;
  //   }, {} as Record<string, FornecedorPendenciasExtended>);

  //   return Object.values(result).sort((a, b) => b.totalPendencias - a.totalPendencias);
  // }, [pendencias]);

  // const fetchPendencias = async (search = '', filtro = '') => {
  //   try {
  //     setLoading(true);
  //     const filteredData =
  //       filtro === 'todos'
  //         ? pendenciasVenda
  //         : pendenciasVenda.filter((pendencia) => {
  //             if (!search) return true;
  //             switch (filtro) {
  //               case 'idFornecedor':
  //                 return pendencia.idFornecedor.toString() === search;
  //               case 'fornecedor':
  //                 return pendencia.fornecedor.toLowerCase().includes(search.toLowerCase());
  //               case 'comprador':
  //                 return pendencia.produto.toLowerCase().includes(search.toLowerCase());
  //               default:
  //                 return true;
  //             }
  //           });
  //     const formattedData = filteredData.map((pendencia) => ({
  //       ...pendencia,
  //       selected: false,
  //       numeroPedidoVenda: pendencia.numeroPedidoVenda.toString(),
  //       dataPedidoVenda: new Date(
  //         pendencia.dataPedidoVenda.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1T00:00:00')
  //       ),
  //       dataPendenciaVenda: new Date(
  //         pendencia.dataPendenciaVenda.replace(/(\d{2})-(\d{2})-(\d{4})/, '$3-$2-$1T00:00:00')
  //       ),
  //       qtPendencia: pendencia.qtPendencia.toString(),
  //       qtCompra: pendencia.qtCompra?.toString() ?? '',
  //       qtPedidosCompra: pendencia.qtPedidosCompra.toString(),
  //       status: pendencia.status.toString(),
  //     }));
  //     if (filteredData.length === 0) {
  //       Swal.fire({
  //         icon: 'warning',
  //         title: 'Nenhum resultado encontrado',
  //         text: 'Sua busca não retornou nenhum resultado. Por favor, tente novamente com outros critérios.',
  //         confirmButtonText: 'Ok',
  //       });
  //     }
  //     setPendencias(formattedData as IPendenciaVenda[]);
  //   } catch (error) {
  //     console.error('Error fetching pendencias:', error);
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Erro ao carregar as pendências',
  //       text: 'Ocorreu um erro ao carregar as pendências. Por favor, tente novamente mais tarde.',
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  // useEffect(() => {
  //   setSearchTerm('');
  //   if (searchFiltro === 'todos') {
  //     fetchPendencias('', 'todos');
  //   }
  // }, [searchFiltro]);

  // const handleSearch = (event: React.FormEvent) => {
  //   event.preventDefault();
  //   fetchPendencias(searchTerm, searchFiltro);
  //   console.log(searchTerm);
  // };

  // const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const value = e.target.value;
  //   if (searchFiltro === 'idFornecedor') {
  //     if (/^\d*$/.test(value)) {
  //       setSearchTerm(value);
  //     }
  //   } else {
  //     setSearchTerm(value);
  //   }
  // };

  // const renderInput = () => {
  //   if (searchFiltro === 'todos') {
  //     return null;
  //   }

  //   switch (searchFiltro) {
  //     case 'idFornecedor':
  //     case 'fornecedor':
  //     case 'comprador':
  //       return (
  //         <input
  //           type='text'
  //           placeholder={`Pesquisar por ${searchFiltro}...`}
  //           className='form-control'
  //           value={searchTerm}
  //           onChange={handleInputChange}
  //         />
  //       );
  //   }
  // };

  return (
    <>
      {/* <div className='mt-20'>
        <div className='container-fluid mb-8 p-0'>
          <h1>Pendencias por fornecedor</h1>
        </div>
      </div>
      <div className='mt-10 mb-15'>
        <h4 className='text-dark'>Filtrar busca:</h4>
        <form onSubmit={handleSearch} className='form-control-solid row mb-4'>
          <div className='row mt-6'>
            <div className='col-sm-2'>
              <select
                className='form-select'
                value={searchFiltro}
                onChange={(e) => setSearchFiltro(e.target.value)}
              >
                <option value='todos'>Selecionar...</option>
                <option value='idFornecedor'>Id Fornecedor</option>
                <option value='fornecedor'>Fornecedor</option>
                <option value='comprador'>Comprador</option>
              </select>
            </div>
            {searchFiltro !== 'todos' && (
              <>
                <div className='col-sm-3'>{renderInput()}</div>
                <div className='col-sm-1'>
                  <button type='submit' className='btn btn-primary form-control'>
                    <i className='bi bi-search'></i>
                  </button>
                </div>
              </>
            )}
          </div>
        </form>
      </div>
      <div
        className='text-center table-container table-responsive'
        style={{height: '300px', overflowY: 'auto', overflowX: 'auto'}}
      >
        <table
          className='table-hover tablehist table-rounded table-row-bordered'
          style={{width: '100%'}}
        >
          <thead className='thead-dark' style={{position: 'sticky', top: '0', zIndex: '1'}}>
            <tr>
              {columns.map(({label, accessor}) => {
                return (
                  <th className='text-center' style={{height: '60px'}} key={accessor}>
                    {label}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody className='fw-bold fs-6 text-gray-600'>
            {pendenciasPorFornecedor.map((data) => {
              return (
                <tr key={data.fornecedor} style={{height: '60px'}}>
                  {columns.map(({accessor}) => {
                    const tData = data[accessor] ?? '—';
                    return (
                      <td className='text-center' key={accessor}>
                        {tData}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div> */}
    </>
  );
};

export default ResumoFornecedor;
