import { useNavigate, useParams } from "react-router-dom";
import { cadastroCondicaoPagamento, DescriptionComponent, FooterForm } from "../../../shared";
import {Col, Row, Row as RowBootstrap, Spinner} from 'react-bootstrap';
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { putCondicoesPagamento, sendCondicoesDePagamento} from "../cadastro_requests/cadastro_requests";
import Swal from "sweetalert2";
import { getCondicoesPagamentoById } from "../../listagem/listagem_requests/listagem_requests";

const CadastroCondicaoPagamento: React.FC = ()=>{
    const navigate = useNavigate();
    const {id} = useParams();
  
    const [isLoading, setIsLoading] = useState<boolean>(false);
  
    const initialValues = {
      id: '',
      condicaoPagamento: '',
    };
  
    const formik = useFormik({
      initialValues,
    validationSchema: cadastroCondicaoPagamento,
      onSubmit: async (values, {setStatus, setSubmitting}) => {
        setIsLoading(true);
        if (id) {
          try {
            await putCondicoesPagamento(id, values);
            setSubmitting(false);
            setIsLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Condiçao de Pagamento editada com sucesso!',
              showCancelButton: false,
              confirmButtonText: 'Voltar à listagem de Condiçoess de Pagamento',
              timer: 3000,
              timerProgressBar: true,
              allowOutsideClick: false,
              allowEscapeKey: false,
              didClose: () => {
                navigate('/listagem-condicoes-de-pagamento')
              },
            });
          } catch (error: any) {
            console.error(error);
  
            if (error.response) {
              const status = error.response.status;
  
              if (status === 409) {
                Swal.fire({
                  icon: 'error',
                  title:
                    'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
                  confirmButtonText: 'Ok',
                });
                setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
              } else if (status === 401) {
                Swal.fire({
                  icon: 'info',
                  title: 'Por questões de segurança, por favor faça login novamente',
                  confirmButtonText: 'Ok',
                }).then(() => {
                  window.open('/auth', '_blank');
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                  confirmButtonText: 'Ok',
                });
              }
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
  
            setSubmitting(false);
            setIsLoading(false);
          }
        } else {
          try {
            const response = await sendCondicoesDePagamento(values)
  
            if (response.status === 201) {
              setSubmitting(false);
              setIsLoading(false);
              Swal.fire({
                icon: 'success',
                title: 'Condiçoes de pagamento cadastrada com sucesso!',
                showCancelButton: false,
                confirmButtonText: 'Voltar à listagem de condiçoes de pagamento',
                timer: 3000,
                timerProgressBar: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                didClose: () => {
                  navigate('/listagem-condicoes-de-pagamento');

                },
              });
            }
          } catch (error: any) {
            console.error(error);
  
            if (error.response) {
              const status = error.response.status;
  
              if (status === 409) {
                Swal.fire({
                  icon: 'error',
                  title:
                    'Erro ao salvar o cliente, verifique as informações preenchidas e tente novamente',
                  confirmButtonText: 'Ok',
                });
                setStatus('Ocorreu um erro ao salvar o cliente. Por favor, tente novamente.');
              } else if (status === 401) {
                Swal.fire({
                  icon: 'info',
                  title: 'Por questões de segurança, por favor faça login novamente',
                  confirmButtonText: 'Ok',
                }).then(() => {
                  window.open('/auth', '_blank');
                });
              } else {
                Swal.fire({
                  icon: 'error',
                  title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                  confirmButtonText: 'Ok',
                });
              }
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Ocorreu um erro inesperado. Tente novamente mais tarde.',
                confirmButtonText: 'Ok',
              });
            }
  
            setSubmitting(false);
            setIsLoading(false);
          }
        }
      },
    });
  
    useEffect(() => {
      getCondicoesPagamentByIdData();
    }, []);
  
    const getCondicoesPagamentByIdData = async () => {
      if (id) {
        try {
          const response = await getCondicoesPagamentoById(id);
  
          const {data} = response;
          formik.setValues({
            id: data.titulo || '',
            condicaoPagamento: data.condicaoPagamento || '',
          });
  
        } catch (errors) {
          console.log(errors);
        }
      }
    };
  
  
    return(
        <>
        <DescriptionComponent
          description={
            id ? 'Edição de Condiçao de pagamento' : 
            'Cadastro de Condiçao de pagamento'}
          withLink={true}
          buttonTitle='Listar Condiçoes de Pagamento'
          redirectTo='/listagem-condicoes-de-pagamento'
        />
        <form className='form-control-solid' 
        onSubmit={formik.handleSubmit} 
        noValidate>
          <Row className='col-12 mb-4'>
            <Col className='col-sm-12'>
              <label className='form-label'>Condiçao de Pagamento:</label>
              <input
                type='text'
                placeholder='Digite o título da Condiçao de pagamento'
                {...formik.getFieldProps('condicaoPagamento')}
              className={`form-control ${
                formik.touched.condicaoPagamento && formik.errors.condicaoPagamento ? 'is-invalid' : ''
              }`}
            />
            {formik.touched.condicaoPagamento && formik.errors.condicaoPagamento ? (
              <div className='invalid-feedback' style={{fontWeight: 'bold'}}>
                {formik.errors.condicaoPagamento}
              </div>
            ) : null}
            </Col>
          </Row>
          <FooterForm
            isLoading={isLoading}
            textActionSubmit={
                id ? 'Editar Condiçao de pagamento' : 
                'Cadastrar Condiçao de pagamento'}
            redirectTo='listagem-condicoes-de-pagamento'
          />
        </form>
      </>
      );
}
export default CadastroCondicaoPagamento