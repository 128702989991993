import Swal from 'sweetalert2';
import {sendPrevenda, updatePrevenda} from '../cadastros/cadastro_requests/cadastro_requests';
import {FormsPrevenda} from '../cadastros/proposta-pedido/types/interface';

export const submitFormPrevenda = async (
  id,
  values: FormsPrevenda,
  selectedCliente,
  selectedParceiro,
  selectedCloser,
  selectedConsultor,
  selectedOrcamentista,
  infosPagamento,
  infosProd,
  currentUser,
  totalGeral,
  setLoading,
  valorAReceber,
  obsStatus,
  // obsStatusPrd,
  freteStatus,
  projetoStatus,
  produtoNaoEncontradoStatus,
  setSubmitting,
  setStatus,
  navigate,
  tipo,
  ordenacao,
  margemProposta,
  updateProduto,
  fluxoLogistico,
  isEntregaParcial,
  idTipoVenda,
  dataAcordadoCliente
) => {
  setLoading(true);

  try {
    if (!selectedCliente || selectedCliente?.value === 0 || selectedCliente?.value === null) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, selecione pelo menos um cliente',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }

    if (!selectedConsultor || selectedConsultor?.value === 0 || selectedCliente?.value === null) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, selecione pelo menos um consultor',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }

    if (values.almoxarifadoid === 0) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, selecione um almoxarifado',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }

    if (idTipoVenda === 0) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, selecione um tipo de venda',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }

    const existePagamentoPrincipal = infosPagamento.some((info) => info.pagamentoPrincipal === 1);
    if (infosPagamento.length > 0 && !existePagamentoPrincipal) {
      Swal.fire({
        icon: 'info',
        title: 'Por favor, defina pelo menos uma forma de pagamento principal',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }

    const produtosPrevenda = infosProd;
    const pagamentosPrevenda = infosPagamento;

    if (produtosPrevenda.length <= 0) {
      Swal.fire({
        icon: 'info',
        title: `Por favor, selecione pelo menos um produto para esta ${
          tipo === 'propostas' ? 'Proposta Comercial' : 'Pedido'
        }`,
        confirmButtonText: 'Ok',
      });
      setLoading(false);
      return;
    }

    const usuarioinicio = currentUser?.login;
    const formattedDataEmissao = `${values.dataemissao}T00:00:00`;
    const formattedDataVencimento = `${values.vencimento}T00:00:00`;

    let formattedData1Entrega;
    let formattedData2Entrega;

    if (values.data1Entrega != null && values.data1Entrega !== '') {
      formattedData1Entrega = `${values.data1Entrega}T02:00:00`;
    }

    if (values.data2Entrega != null && values.data2Entrega !== '') {
      formattedData2Entrega = `${values.data1Entrega}T02:00:00`;
    }

    const total: number = totalGeral;
    let response;

    if (id) {
      if (valorAReceber !== 0) {
        const result = await Swal.fire({
          icon: 'info',
          title:
            'Este orçamento ainda possui valores a receber. Você tem certeza que deseja atualizar?',
          showCancelButton: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não',
        });

        if (!result.isConfirmed) {
          setLoading(false);
          return;
        }
      }

      if (Number(values.status) === 2 && infosPagamento.length === 0) {
        Swal.fire({
          icon: 'info',
          title: 'Para encerrar essa negociação é necessário informar uma forma de pagamento.',
          confirmButtonText: 'Ok',
        });
        setLoading(false);
        return;
      }

      if (idTipoVenda === 0) {
        Swal.fire({
          icon: 'info',
          title: 'Por favor, selecione um tipo de venda',
          confirmButtonText: 'Ok',
        });
        setLoading(false);
        return;
      }

      values.clienteid = selectedCliente ? selectedCliente.value : 0;
      values.parceiroid = selectedParceiro ? selectedParceiro.value : 0;
      values.closerid = selectedCloser ? selectedCloser.value : 0;
      values.consultorid = selectedConsultor ? selectedConsultor.value : 0;
      values.orcamentistaid = selectedOrcamentista ? selectedOrcamentista.value : 0;

      response = await updatePrevenda(
        id,
        values,
        selectedCliente ? selectedCliente.label : '',
        selectedParceiro ? selectedParceiro.label : '',
        selectedOrcamentista ? selectedOrcamentista.label : '',
        selectedConsultor ? selectedConsultor.label : '',
        total,
        produtosPrevenda,
        pagamentosPrevenda,
        usuarioinicio,
        projetoStatus,
        freteStatus,
        obsStatus,
        produtoNaoEncontradoStatus,
        ordenacao,
        margemProposta,
        updateProduto,
        fluxoLogistico,
        isEntregaParcial,
        idTipoVenda,
        dataAcordadoCliente
      );
    } else {
      values.clienteid = selectedCliente ? selectedCliente.value : 0;
      values.parceiroid = selectedParceiro ? selectedParceiro.value : 0;
      values.closerid = selectedCloser ? selectedCloser.value : 0;
      values.consultorid = selectedConsultor ? selectedConsultor.value : 0;
      values.orcamentistaid = selectedOrcamentista ? selectedOrcamentista.value : 0;

      response = await sendPrevenda(
        values,
        selectedCliente ? selectedCliente.label : '',
        selectedParceiro ? selectedParceiro.label : '',
        selectedCloser ? selectedCloser.label : '',
        selectedOrcamentista ? selectedOrcamentista.label : '',
        selectedConsultor ? selectedConsultor.label : '',
        total,
        produtosPrevenda,
        pagamentosPrevenda,
        usuarioinicio,
        projetoStatus,
        freteStatus,
        obsStatus,
        produtoNaoEncontradoStatus,
        ordenacao,
        margemProposta,
        fluxoLogistico,
        isEntregaParcial,
        idTipoVenda,
        dataAcordadoCliente
      );
    }

    if (response.status === 201 || response.status === 200) {
      const novoId = response.data.id;
      localStorage.setItem('idPrevenda', novoId);
      setSubmitting(false);
      setLoading(false);
      Swal.fire({
        icon: 'success',
        title: id ? 'Proposta atualizada com sucesso!' : 'Proposta cadastrada com sucesso!',
        confirmButtonText: 'Voltar a listagem',
        timer: 3000,
        timerProgressBar: true,
        allowOutsideClick: false,
        allowEscapeKey: false,
        didClose: () => {
          tipo === 'propostas'
            ? navigate('/listagem-propostas-comerciais', {state: {id: novoId}})
            : navigate('/listagem-pedidos', {state: {id: novoId}});
        },
      });
    } else if (response.status === 400) {
      Swal.fire({
        icon: 'error',
        title: `Erro ao criar a ${
          tipo === 'propostas' ? 'Proposta Comercial' : 'Pedido'
        }, verifique as informações preenchidas e tente novamente`,
        confirmButtonText: 'Ok',
      });
      setStatus(
        `Ocorreu um erro ao criar a ${
          tipo === 'propostas' ? 'Proposta Comercial' : 'Pedido'
        }. Por favor, tente novamente.`
      );
    } else {
      Swal.fire({
        icon: 'error',
        title: `Erro ao criar a ${
          tipo === 'propostas' ? 'Proposta Comercial' : 'Pedido'
        }, verifique as informações preenchidas e tente novamente`,
        confirmButtonText: 'Ok',
      });
      setStatus(
        `Ocorreu um erro ao criar a ${
          tipo === 'propostas' ? 'Proposta Comercial' : 'Pedido'
        }. Por favor, tente novamente.`
      );
    }
  } catch (errors: any) {
    const {data} = errors.response;

    
    Swal.fire({
      icon: 'error',
      title: data.map((item) => item.mensagemDesenvolvedor),
      showCancelButton: false,
    });

    setSubmitting(false);
    setLoading(false);

    if (errors.response && errors.response.data && errors.response.data.message === '401') {
      Swal.fire({
        icon: 'info',
        title: 'Por questões de segurança, por favor faça login novamente',
        confirmButtonText: 'Ok',
      }).then(() => {
        setSubmitting(false);
        setLoading(false);
        window.open('/auth', '_blank');
      });
    } else if (errors.response && errors.response.data && errors.response.data.message === '403') {
      Swal.fire({
        icon: 'info',
        title: 'Você não tem permissão para realizar esta ação, faça login novamente',
        confirmButtonText: 'Ok',
      });
      setSubmitting(false);
      setLoading(false);
    } 

    setStatus('Por favor, preencha as informações de usuário corretamente');
  }
};
