import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import AsyncSelect from "react-select/async";
import { ClienteOption } from "../../../../cadastros/proposta-pedido/types/interface";
import { AsyncSelectEmpresa, Modal } from "../../../../../shared";
import { Button, Col, ModalProps, Row, Spinner } from "react-bootstrap";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;

const ModalEstoques = ({ isOpen, onHide, dataLine,isEdit,loadEstoques }) => {

    const [titulo, setTitulo] = useState<string | null>(null)
    const [descricao, setDescricao] = useState<string | null>(null)
    const [status, setStatus] = useState<string>('ATIVO')
    const [selectedEmpresa, setSelectedEmpresa] = useState<ClienteOption | null>(null)
    const [selectedTipoEstoque, setSelectedTipoEstoque] = useState<ClienteOption | null>(null)

    const handleChangeStatus =(value)=>{
        setStatus(value)
    }
    
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const onClose = () => {
        onHide!();
        setTitulo(null)
        setDescricao(null)
        setStatus('ATIVO')
        setSelectedEmpresa(null)
        setSelectedTipoEstoque(null)
        onClear()
        loadEstoques()
        // setPage(0)
    };
    const onClear = () => {
        setTitulo(null)
        setDescricao(null)
        setStatus('ATIVO')
        setSelectedEmpresa(null)
        setSelectedTipoEstoque(null)
    };

    const cadastrarEstoque = async () => {
        // if (!selectedEmpresa) {
        setIsLoading(true)
        setIsLoading(false)
        // const handleSubmit = async () => {
            try {
                // console.log(2,valueData)
    
                let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques`;
                if(selectedEmpresa?.value && selectedTipoEstoque && titulo ){
                
                    const response = await axios.post(url, {
                        "idEmpresa": selectedEmpresa?.value,
                        "idEstoqueTipos": selectedTipoEstoque?.value,
                        "titulo": titulo?titulo:'',
                        "descricao": descricao?descricao:'',
                        "status": status
                    });   
                    
                Swal.fire({
                    icon: 'success',
                      title: `Estoque cadastrado com sucesso`,
                      timer: 3000,
                      timerProgressBar: true,
                    }).then(() => {
                        onClear()
                    })
                loadEstoques()
                } else{console.log(selectedEmpresa?.value,
                        selectedTipoEstoque?.value,
                         titulo?titulo:'',
                         descricao?descricao:'',
                        status)}

            } 
            catch (errors: any) {
                  Swal.fire({
                    icon: 'error',
                      title: `Erro na requisicao`,
                      timer: 3000,
                      timerProgressBar: true,
                    });
                                  
                console.log(errors)}
        };

    const loadOptionsTipoEstoque = async () => {
            try {
                let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/tipos/listar`;
        
                const response = await axios.get(url,{
                    params: {
                        size: 100,
                        status: 'ATIVO'
                    }
                });
                const data = await response.data.content;
                
                console.log(data)  
                return data.map((item) => ({
                    value: item.id,
                    label: `${item.titulo}`,
                })
                )
            } catch (error) {
                console.error('Erro ao buscar opções:', error);
                return [];
        };
        }
    
    const editarEstoque = async () => {
                try {
                    let url = `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/estoques/${dataLine[1]}`;
                    if(selectedEmpresa?.value && selectedTipoEstoque && titulo ){

                    const response = await axios.put(url, {
                        "idEmpresa": selectedEmpresa?.value,
                        "idEstoqueTipos": selectedTipoEstoque?.value,
                        "titulo": titulo?titulo:'',
                        "descricao": descricao?descricao:'',
                        "status": status
                    });
                    Swal.fire({
                        icon: 'success',
                          title: `Estoque editado com sucesso`,
                          timer: 3000,
                          timerProgressBar: true,

                        }).then(() => {
                            onClose()
                            loadEstoques()
                        });
                    setTimeout(() => {
                    },3000)
    
                } }
                catch (errors: any) {
                      Swal.fire({
                        icon: 'info',
                          title: `Erro na requisicao`,
                          timer: 3000,
                          timerProgressBar: true,
                        });
                                      
                    console.log(errors)}
            };

    const submitButton = (
        <Button
          type='submit'
          variant='success'
          onClick={() => isEdit?editarEstoque():cadastrarEstoque()}
        >
          {isLoading ? <Spinner animation='border' size='sm' /> : isEdit?"Editar":"Cadastrar"}
        </Button>
      );

    const modalConfigs: ModalProps = {
        isOpen,
        actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };
      useEffect(()=>{
            if(dataLine && dataLine.length>0){
            setStatus(dataLine[5])
            setDescricao(dataLine[2])
            setTitulo(dataLine[0])
            setSelectedEmpresa({label:'', value: dataLine[3]})
            setSelectedTipoEstoque({label:`${dataLine[7]}`, value: dataLine[6]})
            console.log(dataLine[3])
            }     
            else{
                setSelectedEmpresa(null)
                setSelectedTipoEstoque(null)
            }       
        },[dataLine])
    return (
        <div>
            <Modal  {...modalConfigs}>
                <h1 className='fw-bolder' style={{ color: '#3f3f3f' }}> {isEdit?"Editar":"Cadastrar"} Estoque</h1>
                <div style={{ marginBottom: '8px' }}>
                <div style={{ borderBottom: '2px solid #878787' }}></div>
            </div>  
                <div>
            <form action="">
                <Row className='col-12 mb-4 row mt-8'>
                    <label htmlFor="" className="form-label">Empresa:</label>
                    <Col className="" style={{zIndex: '100'}}>
                    <AsyncSelectEmpresa  handleOptions={(empresa)=>{setSelectedEmpresa(empresa)}} inputOption={selectedEmpresa} onKeyDown={()=>{setSelectedEmpresa(null)}} ></AsyncSelectEmpresa>
                    {selectedEmpresa === null && <span className={`form-label text-danger`}>*Por favor, informe a empresa</span>}
                    </Col>
                </Row>
                <Row className="mt-4 col-12 mb-4 row">
                    <Col>
                        <label htmlFor="" className="form-label"  >Titulo:</label>
                        <input  type="text" value={titulo?titulo:""} className="form-control" 
                        onChange={(e) => {
                            setTitulo(e.target.value)}}  placeholder="Digite o titulo" />
                        {(titulo === null || titulo === ''|| titulo === undefined)  && <span className={`form-label text-danger`}>*Por favor, informe o Titulo</span>}

                    </Col>
                
                    <Col>
                        <label htmlFor="" className="form-label">Tipo de Estoque:</label>    
                        <AsyncSelect  
                        defaultOptions
                        value={selectedTipoEstoque}
                        onKeyDown={(e) => {
                        if (e.key === 'Backspace') {
                            setSelectedTipoEstoque(null);
                        }
                    }} onChange={(selectedOption: ClienteOption | null) => {
                        if (selectedOption) {
                            const idValue = isNaN(Number(selectedOption.value))
                                ? selectedOption.value // Caso seja texto, mantêm como string.
                                : Number(selectedOption.value);
                            console.log(selectedOption.value)
                        }
                        setSelectedTipoEstoque(selectedOption);
                        console.log(selectedOption)
                    }} loadOptions={loadOptionsTipoEstoque} placeholder="Selecione o tipo de estoque" className="mt-1"></AsyncSelect>
                        {selectedTipoEstoque === null && <span className={`form-label text-danger`}>*Por favor, informe o Tipo de estoque</span>}

                    </Col>
                    <Col>
                        <label htmlFor="" className="form-label">Status:</label>    
                        <select className="form-select" 
                        onChange={(e) => {
                            handleChangeStatus(e.target.value)
                        }} 
                            value={status?status:""}
                            
                            
                            name="" id="">
                            <option value="ATIVO" >ATIVO</option>
                            <option value="INATIVO">INATIVO</option>
                        </select>
                        {status === null && <span className={`form-label text-danger`}>*Por favor, informe a empresa</span>}

                    </Col>
                </Row>
                <Row className="mt-4 col-12 mb-4 row">
                    <label htmlFor="" className="form-label">Descrição:</label>
                    <Col className="">
                         <textarea name="" value={descricao?descricao:""} onChange={(e) => {
                            setDescricao(e.target.value)}} placeholder="Digite a descrição"  className="form-control" id=""></textarea>
                    </Col>
                </Row>
            </form>
        </div>
            </Modal>
        </div>
    )
}

export default ModalEstoques