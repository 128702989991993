import {useEffect} from 'react';
import {Button, Tooltip, OverlayTrigger} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import * as S from './description.styles';

type Props = {
  description: string;
  withLink?: boolean;
  redirectTo?: string;
  buttonTitle?: string;
  buttonTitle2?: string;
  buttonTitle3?: string;
  isSub?: boolean;
  isButtonAction?: boolean;
  isButtonAction2?: boolean;
  isButtonAction3?: boolean;
  variantButton?: string;
  variantButton2?: string;
  variantButton3?: string;
  handleAction?: () => void;
  handleAction2?: () => void;
  handleAction3?: () => void;
  isDisabled?: boolean;
  isDisabled2?: boolean;
  isDisabled3?: boolean;
  showTooltip?: boolean; // Nova prop para controlar o tooltip
  tooltipText?: string; // Nova prop para definir o texto do tooltip
  icon?: React.ComponentType
};

const DescriptionPage: React.FC<Props> = ({
  description,
  withLink,
  redirectTo,
  buttonTitle,
  isSub,
  isButtonAction,
  handleAction,
  variantButton,
  isButtonAction2,
  isButtonAction3,
  buttonTitle2,
  buttonTitle3,
  handleAction2,
  handleAction3,
  variantButton2,
  variantButton3,
  isDisabled,
  isDisabled2,
  isDisabled3,
  showTooltip = false, // Valor padrão para não mostrar tooltip
  tooltipText = 'Ação indisponível no momento.', // Texto padrão para o tooltip
  icon: Icon
}) => {
  useEffect(() => {
    console.log('disable in component', isDisabled);
  }, [isDisabled]);

  // Tooltip content usando o texto passado como prop
  const renderTooltip = (props: any) => (
    <Tooltip id='button-tooltip' {...props}>
      {tooltipText}
    </Tooltip>
  );

  return (
    <>
      <div
        className='mb-3 form-label-container'
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: '30px',
        }}
      >
        <h1 className='fw-bolder' style={{color: '#3f3f3f', fontSize: isSub ? '1.4rem' : '2rem', display: 'flex', gap: '10px'}}>
          {description} {Icon && <Icon />}
        </h1>

        {withLink && redirectTo ? (
          <Link to={redirectTo} className='btn btn-success btn-sm' style={{fontSize: '14px'}}>
            {buttonTitle}
          </Link>
        ) : null}

        {isButtonAction || isButtonAction2 || isButtonAction3 ? (
          <S.ContainerButton>
            {isButtonAction3 && (
              <Button
                variant={variantButton3 || 'success'}
                disabled={isDisabled3}
                onClick={handleAction3}
                style={{
                  opacity: isDisabled3 ? '0.5' : '1',
                  pointerEvents: isDisabled3 ? 'none' : 'auto',
                }}
              >
                {buttonTitle3}
              </Button>
            )}

            {isButtonAction2 && (
              <Button
                variant={variantButton2 || 'success'}
                disabled={isDisabled2}
                onClick={handleAction2}
                style={{
                  opacity: isDisabled2 ? '0.5' : '1',
                  pointerEvents: isDisabled2 ? 'none' : 'auto',
                }}
              >
                {buttonTitle2}
              </Button>
            )}

            {isButtonAction && handleAction ? (
              <OverlayTrigger
                placement='top'
                overlay={isDisabled && showTooltip ? renderTooltip : <></>}
              >
                <span className='d-inline-block'>
                  <Button
                    variant={variantButton || 'success'}
                    onClick={() => handleAction()}
                    disabled={isDisabled}
                    style={{
                      opacity: isDisabled ? '0.5' : '1',
                      pointerEvents: isDisabled ? 'none' : 'auto',
                    }}
                  >
                    {buttonTitle}
                  </Button>
                </span>
              </OverlayTrigger>
            ) : null}
          </S.ContainerButton>
        ) : (
          <></>
        )}
      </div>
      <div style={{marginBottom: '8px'}}>
        <div style={{borderBottom: '2px solid #878787'}}></div>
      </div>
    </>
  );
};

export default DescriptionPage;
