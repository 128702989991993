import styled from 'styled-components';

export const ContainerExpedicaoInfo = styled.div`
  width: 100%;
  display: flex;

  padding: 5px;
  gap: 20px;
  margin-top: 10px;

  border-bottom: 1px solid #ccc;

  .container-left {
    width: 30%;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  .container-right {
    width: 70%;

    max-height: 600px;

    @media (max-width: 600px) {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const BoxInfoAditional = styled.div`
  width: 100%;
`;

export const ContainerTitle = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 5px;
  border-bottom: 1px solid #ccc;

  .title-left {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .title-right {
    width: 70%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ContainerActions = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 5px;
`;

export const ContainerNotFound = styled.div`
  width: 100%;
  height: 500px;

  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    color: #ccc;
  }
`;
