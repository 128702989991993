import { useEffect, useMemo, useState } from "react";
import { formatarData,formatDateTimeWithHours,IGerarPrazos,ISkuResumo,Modal, useModal } from "../../../../shared";
import AvatarConsultor from "./AvatarConsultor";
import { Badge, Button, ModalProps, Spinner } from "react-bootstrap";
import { formataCliente, formataCnpjCpf, formatarHorario, formatarTotal, formatClone, formatStatus, formatStatusPagamento } from "./formsPedido";
import Swal from "sweetalert2";
import { Column, ColumnInstance, useTable } from 'react-table';
import { getProdutosPrazo, getResumoSku } from '../../listagem_requests/listagem_requests';
import ModalInputData from "./ModalInputData";
import axios from "axios";

const API_URL_SERVER = process.env.REACT_APP_API_SERVER_URL;
const API_SERVER_PORT = process.env.REACT_APP_API_SERVER_PORT;


type Props = Pick<ModalProps, 'isOpen' | 'onHide'> & {
    selectItens?: object;
};

const ModalGerarPrazo: React.FC<Props> = ({ isOpen, onHide, selectItens }) => {
    const [prevenda, setPrevenda] = useState<object | null>(null)
    const [idProdutoSelected, setIdProdutoSelected] = useState<number| null>(null)
    const [isSkuResumoData,         setIsResumoSkuData] = useState<IGerarPrazos[]>([]);
    const resumoSkuColumns: Column<IGerarPrazos>[] = [
        {
            Header: 'Id do Produto',
            accessor: 'idProduto',
            Cell: ({ value }) => <div className='text-center'>{value? value : '...'}</div>,
        },
        {
            Header: 'Produto',
            accessor: 'produto',
            Cell: ({ value, row }) => (
                <div
                    className='text-start'
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                    }}
                >
                    {value? value : '...'}

                    {row.original.isProdutoParcial ? (
                        <Badge style={{ color: '#fff', marginTop: '10px' }} bg='info'>
                            PARCIAL
                        </Badge>
                    ) : (
                        <></>
                    )}
                </div>
            ),
        },
        {
            Header: 'Tipo de Entrega',
            accessor: 'tipoEntrega',
            Cell: ({ value }) => <div className='text-center'>{value? value : '...'}</div>,
        },
        {
            Header: 'Tem Estoque',
            accessor: 'temEstoque',
            Cell: ({ value }) => <div className='text-center'>{value ? value : '...'}</div>,
        },
        {
            Header: 'Qtd Solicitada',
            accessor: 'qtdSolicitada',
            Cell: ({ value }) => <div className='text-center'>{value ? value : '...'}</div>,
        },
        {
            Header: 'Total em Estoque',
            accessor: 'qtdEstoque',
            Cell: ({ value }) => (
                <div className='text-center d-flex justify-content-center' style={{ marginRight: '10px' }}>
                    {value ? value : '...'}
                </div>
            ),
        },
        {
            Header: 'DATA ACORDADA COM CLIENTE',
            accessor: 'dataPrazo',
            Cell: ({ value, row }) => (
                <div className='text-center d-flex justify-content-around align-items-center'>
                    <p className="text-truncate ">
                        {value?formatarData(value):value}
                    </p>
                </div>
            ),
        },
        
        {
            Header: 'AÇÃO',
            accessor: 'acao',
            Cell: ({ value, row }) => (
                <div className='text-center d-flex justify-content-around align-items-center'>
                    <button
                          onClick={() => {
                            setIdProdutoSelected(row.original.id)
                            openInputDataModal()
                          }}
                        className='btn btn-success btn-sm bi bi-pencil mx-5'
                        data-toggle='tooltip'
                        data-placement='top'
                        title={'Editar'}
                    ></button>
                </div>
            ),
        },
    ];
      const [selectedId, setSelectedId] = useState({}); // Vetor para armazenar IDs dos itens selecionados.
      const [isGerarPedidoModal, openGerarPedidoModal, closeGerarPedidoModal] = useModal();
      const [isInputDataModal, openInputDataModal, closeInputDataModal] = useModal();
    const data = useMemo(() => isSkuResumoData, [isSkuResumoData]);
    const columns = useMemo(() => resumoSkuColumns, []);
    const { getTableProps, getTableBodyProps, headers, rows, prepareRow } = useTable({
        columns,
        data,
    });

    function formatDate(inputDate) {
        // Cria um objeto Date a partir da string de entrada
        const date = new Date(inputDate);
        
        // Extrai o dia, mês e ano
        const day = String(date.getDate()).padStart(2, '0'); // Garantir dois dígitos para o dia
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Meses começam de 0, então somamos 1
        const year = date.getFullYear();
        
        // Retorna a data no formato dd/MM/yyyy
        return `${day}/${month}/${year}`;
      }
    useEffect(() => {
        // if (selectItens!['id']) {
            // getResumoSkuData(prevenda!['id']);
        // }
        // if(isOpen){
        //     getResumoSkuData(selectItens!['id']);
        // }
        // executarProcedure()
        // getResumoSkuData(prevenda!['id']);
    }, [prevenda]);


    const getDadosTabela = async () => {
        try {
            let response= await axios.get(
                `https://${API_URL_SERVER}:${API_SERVER_PORT}/api/v1/prevendas/produtos-prazos/listar?idPrevenda=${selectItens!['id']}`
            );
            const { data } = response;
            // console.log(data.content[0].id)

            console.log(`data response`, data)


            setIsResumoSkuData(data.content);
        } catch (errors: any) {
        }
    };

    const onClose = () => {
        onHide!();
    };


    const modalConfigs: ModalProps = {

        isOpen,
        // actions: [submitButton],
        onHide: onClose,
        size: 'xl',
    };


    useEffect(() => {
        setPrevenda(selectItens!)
        // fetchGerarPrazo()
        // getResumoSku(prevenda!['id'])
    }, [isOpen])

    useEffect(()=>{
        if(isOpen ){
            // executarProcedure()
            getDadosTabela()
        }
        console.log(isOpen)
    },[isOpen])
    return (
        <>
            {isOpen ?
                (<>
                    <Modal  {...modalConfigs} >
                       <div >
                       <h1 className=" mx-auto my-0 fw-bolder">Dados Proposta</h1>
                        <hr className=" mx-auto my-0 mb-5   bg-black" style={{ "height": '2px' }} />
                        <table  className='table overflow-scroll mx-auto table-hover table-striped table-rounded table-row-bordered border gy-7 gs-7 p-6'>
                            <thead className='thead-dark'>
                                <tr className='fw-bold fs-6 text-gray-800'>
                                    <th className='text-center'>Id</th>
                                    <th className='text-center'>Empresa/G2</th>
                                    <th className='text-center'>Data Emissão</th>
                                    <th className='text-center'>Cliente</th>
                                    <th className='text-center'>Total Geral</th>
                                    <th className='text-center'>Status</th>
                                    <th className='text-center'>Consultor</th>
                                </tr>
                            </thead>
                            <tbody className='' >
                                {/* {prevendas.map((prevenda) => ( */}
                                <tr >
                                    <td className='text-center'>{prevenda!['id']}</td>
                                    <td className='text-center'>
                                        {prevenda!['empresa']}
                                        <br></br>
                                        <Badge bg='info' style={{ color: 'white' }}>
                                            { }
                                        </Badge>
                                    </td>
                                    {(prevenda!['dataemissao']) && (<td className='text-center'>
                                        <div>{formatarData(prevenda!['dataemissao'])}</div>
                                        <div>{formatarHorario(prevenda!['dataemissao'])}</div>
                                        {prevenda!['isAmbientado'] && (
                                            <div className='mt-2'>
                                                <Badge style={{ color: 'white' }} bg='info'>
                                                    AMBIENTADO
                                                </Badge>
                                            </div>
                                        )}
                                        {prevenda!['fretestatus'] !== 0 && (
                                            <div className='mt-2'>
                                                <Badge style={{ color: 'white' }} bg='warning'>
                                                    FRETE
                                                </Badge>
                                            </div>
                                        )}
                                    </td>)}
                                    {(prevenda!['clienteInfo']) && (<td className='text-center'>
                                        <div
                                            // onClick={() => handleClickCompras(prevenda!.clienteInfo)}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            {prevenda!['clienteInfo']['nome'] || ''}
                                        </div>
                                        <div>{formataCliente(prevenda!['clienteInfo']['status']) || ''}</div>
                                        <div className='mt-2'>
                                            {formataCnpjCpf(prevenda!['clienteInfo']['cpf'], prevenda!['clienteInfo']['cnpj'])}
                                        </div>
                                    </td>)}
                                    <td className='text-center'>
                                        {formatarTotal(prevenda!['total'])}
                                        {prevenda!['margemProposta'] && (
                                            <div className='mt-2'>
                                                <Badge style={{ color: 'white' }}>Mg: {prevenda!['margemProposta']}%</Badge>
                                            </div>
                                        )}
                                    </td>
                                    <td className='text-center'>
                                        <div>{formatStatus(prevenda!['status']) || 'N/A'}</div>
                                        <div style={{ marginTop: '5px' }}>
                                            {formatClone(prevenda!['versao'], prevenda!['idPrevendaOriginal'])}
                                        </div>
                                        <div style={{ marginTop: '5px' }}>
                                            {formatStatusPagamento(prevenda!['statusPagamento'])}
                                            {prevenda!['kanban'] && prevenda!['kanban'].length > 0 && (
                                                <div style={{ marginTop: '5px' }}>
                                                    {prevenda!['kanban'].map((kanbanItem, index) => (
                                                        <Badge
                                                            key={index}
                                                            style={{
                                                                backgroundColor: '#e0f7e8',
                                                                color: '#fff',
                                                                textTransform: 'uppercase',
                                                                marginRight: '5px',
                                                                marginBottom: '5px',
                                                            }}
                                                        >
                                                            Kanban: {kanbanItem.kanban || 'N/A'}
                                                        </Badge>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    </td>
                                    {prevenda!['consultorInfo'] && (<td className='text-center'>
                                        <div className='d-flex justify-content-center align-items-center'>
                                            <AvatarConsultor
                                                idFuncionario={prevenda!['consultorInfo']['idFuncionario']}
                                                funcionario={prevenda!['consultorInfo']['funcionario']}
                                            />
                                        </div>
                                    </td>)}
                                </tr>
                            </tbody>
                        </table>
                        <div className='table-responsive ' style={{maxHeight:'350px'}} >
                            <table
                                id='kt_table_formularios'
                                className='table table-rounded table-row-bordered border'
                                {...getTableProps()}
                            >
                                <thead className='thead-dark'>
                                    <tr className='text-muted fw-bolder fs-7 text-uppercase gs-0'>
                                        {headers.map((column: ColumnInstance<IGerarPrazos>, index: number) => (
                                            <th
                                                style={{ marginRight: '10px', paddingRight: '10px' }}
                                                key={column.id}
                                                className={'text-center'} // Alinha a primeira coluna à direita, as demais à esquerda
                                            >
                                                {column.render('Header')}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>

                                <tbody className='text-gray-600 fw-bold' {...getTableBodyProps()}>
                                    {rows.length > 0 ? (
                                        rows.map((row) => {
                                            prepareRow(row);
                                            const isRowRed =
                                                row.original.temEstoque == null &&
                                                row.original.qtdSolicitada == null &&
                                                row.original.saldo == null &&
                                                row.original.qtdEstoque == null;

                                            return (
                                                <tr
                                                    {...row.getRowProps()}
                                                    style={{
                                                        color: isRowRed ? 'red' : 'inherit',
                                                    }}
                                                >
                                                    {row.cells.map((cell) => {
                                                        return (
                                                            <td {...cell.getCellProps()}
                                                                 className='text-end'>
                                                                {cell.render('Cell')}
                                                            </td>
                                                        );
                                                    })}
                                                </tr>
                                            );
                                        })
                                    ) : (
                                        <tr>
                                            <td colSpan={4}>
                                                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                                                    Sem Dados
                                                </div>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                       </div>
                    </Modal>

                    <ModalInputData
                        isOpen={isInputDataModal}
                        onHide={closeInputDataModal}
                        selectItens={selectedId}
                        // setDataTabela={setDataTabela}
                        id={idProdutoSelected}
                        getResumoSkuData={getDadosTabela}
                    ></ModalInputData>
                </>
                ) : (<div></div>)}

        </>
    )
}

export default ModalGerarPrazo